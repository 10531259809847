// @ts-nocheck
import React from 'react';

import { LoadingSpinner } from 'components/Common/LoadingSpinner';
import blankMap from './mapdisabled.jpg';
import styles from './styles.module.scss';

type EmptyMapType = {
  text?: string,
  isLoading?: boolean,
};

const EmptyMap = (
  {
    text,
    isLoading,
  }:EmptyMapType,
) => (

  <div
    className={styles.emptyContainer}
    style={{ backgroundImage: `url(${blankMap})` }}
  >

    {isLoading && <LoadingSpinner className={styles.loadingSpinner} />}
    {text}
  </div>
);

EmptyMap.defaultProps = {
  text: 'There are no live projects at this time.',
  isLoading: false,
};

export default EmptyMap;
