// @ts-nocheck
import { useQuery } from '@apollo/client';
import classNames from 'classnames';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';

import ReactSelect from 'react-select/creatable';
import ClickableDiv from 'common-ui/ClickableDiv';
import { ChevronLeft, Close } from 'common-ui/Icon';
import { Tag } from 'common-ui/Labels';



import { PRODUCTS_BY_COMPANY_ID_QUERY } from 'pages/Sites/graphql';
import { useCompanyContext } from 'utils/Hooks';
import styles from './styles.module.scss';



type ProductInputProps = {
  values: Array<any>,
  onChange: (param:string[]) => void,
  siteID: string,
  initialValues: { products: Array<any> },

};

const SitesProductInput = ({
  values,
  onChange,
  siteID,
  initialValues,
}: ProductInputProps) => {
  const [products, setProducts] = useState([]);
  const selectedValues = values ? values.map(({ name, id }) => ({ label: name, id })) : [];


  const addProduct = (value) => {
    const { label, id } = value[0];

    onChange(_.uniqBy([...values, { name: label, id }], 'name'));
  };


  const createProduct = (value) => {

    setProducts([...products, { name: value, id: null }]);

    onChange(_.uniqBy([...values, { name: value, id: 0 }], 'name'));
  };

  const productDropdownOptions = products.length > 0
    ? products.map(({ name, id }) => ({ label: name, id }))
    : [];

  let queryLoading = false;
  let queryError = false;

  let queryData = null;
  const { selectedCompanyID } = useCompanyContext();

  const { loading, error, data } = useQuery(PRODUCTS_BY_COMPANY_ID_QUERY, {
    variables: {
      companyID: selectedCompanyID,
    },
    skip: !selectedCompanyID || selectedCompanyID === '0',
  });
  queryLoading = loading;

  queryError = error;
  queryData = data;

  useEffect(() => {
    if (siteID && selectedCompanyID === initialValues.companyId) {

      onChange(initialValues.products);
    } else {

      onChange([]);
    }
  }, [selectedCompanyID]);

  useEffect(() => {

    if (!queryLoading && !queryError && queryData) {

      setProducts(queryData.productsByCompanyID);
    }
  }, [queryData, queryLoading, queryError, selectedCompanyID]);

  return (

    <div className={styles.tagInput}>

      <ReactSelect
        classNamePrefix="react-select"
        isMulti
        isSearchable
        placeholder="Select and/or create products"
        noOptionsMessage={() => 'No products. Create a product by typing in a name.'}
        createOptionPosition="first"
        onChange={addProduct}
        options={productDropdownOptions}
        controlShouldRenderValue={false}
        onCreateOption={createProduct}
        value={[]}
        components={{
          IndicatorSeparator: null,
          DropdownIndicator: ({
            // innerProps and selectProps come from react-select library

            innerProps,

            selectProps,
          }: any) => (
            <div

              className={classNames(styles.dropdownIndicator, {
                [styles.open]: selectProps.menuIsOpen,
              })}
              {...innerProps}
            >
              <ChevronLeft />
            </div>
          ),
        }}

        theme={theme => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: styles.colorSelected,
            primary25: styles.colorSelectedLight,
            primary50: styles.colorSelectedMedium,
          },
        })}
      />


      <div className={styles.tagsList}>
        {selectedValues.map(item => (
          <ClickableDiv
            key={item.label}
            onClick={() => {
              const filteredSelectedProducts = values
                ? values.filter(product => product.name !== item.label)
                : [];

              onChange(filteredSelectedProducts);
            }}
          >
            <Tag
              text={item.label}
              className={styles.tagChip}
              rightIcon={<Close />}
            />
          </ClickableDiv>
        ))}
      </div>
    </div>
  );
};

export default SitesProductInput;
