import React, {SVGProps} from 'react';

const Info = (props: SVGProps<SVGSVGElement>) => (

  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >

    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.99984 2.6665C5.05432 2.6665 2.66651 5.05432 2.66651 7.99984C2.66651 10.9454 5.05432 13.3332 7.99984 13.3332C10.9454 13.3332 13.3332 10.9454 13.3332 7.99984C13.3332 5.05432 10.9454 2.6665 7.99984 2.6665ZM7.99984 1.33317C4.31794 1.33317 1.33317 4.31794 1.33317 7.99984C1.33317 11.6817 4.31794 14.6665 7.99984 14.6665C11.6817 14.6665 14.6665 11.6817 14.6665 7.99984C14.6665 4.31794 11.6817 1.33317 7.99984 1.33317Z"
      fill="#ADADAD"
    />

    <circle
      cx="7.99984"
      cy="5.33333"
      r="0.666667"
      transform="rotate(-180 7.99984 5.33333)"
      fill="#ADADAD"
    />

    <rect
      x="8.6665"
      y="11.3335"
      width="1.33333"
      height="4.66667"
      rx="0.666667"
      transform="rotate(-180 8.6665 11.3335)"
      fill="#ADADAD"
    />
  </svg>
);

export default Info;
