import React, {SVGProps} from 'react';

const Site = (props: SVGProps<SVGSVGElement>) => (

  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >

    <title>Site</title>

    <desc>Created with Sketch.</desc>

    <g
      id="Icon/Site"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >

      <g transform="translate(5.000000, 2.000000)">

        <mask id="icon-mask-4" fill="white">
          <path d="M2,2.5625 L15.0351603,6.22863884 C15.5680266,6.37850747 16,6.94913031 16,7.49895656 L16,8.50104344 C16,9.05275191 15.5720387,9.62036413 15.0351603,9.77136116 L2,13.4375 L2,19.0081969 C2,19.5559546 1.55613518,20 1,20 C0.44771525,20 0,19.5446944 0,19.0081969 L0,0.99180311 C0,0.444045377 0.443864822,0 1,0 C1.55228475,0 2,0.455305576 2,0.99180311 L2,2.5625 Z M2,4.65179443 L2.0172493,11.3550524 L14,8.00342345 L2,4.65179443 Z" />
        </mask>

        <path
          fill="#4D4D4D"
          d="M2,2.5625 L15.0351603,6.22863884 C15.5680266,6.37850747 16,6.94913031 16,7.49895656 L16,8.50104344 C16,9.05275191 15.5720387,9.62036413 15.0351603,9.77136116 L2,13.4375 L2,19.0081969 C2,19.5559546 1.55613518,20 1,20 C0.44771525,20 0,19.5446944 0,19.0081969 L0,0.99180311 C0,0.444045377 0.443864822,0 1,0 C1.55228475,0 2,0.455305576 2,0.99180311 L2,2.5625 Z M2,4.65179443 L2.0172493,11.3550524 L14,8.00342345 L2,4.65179443 Z"
        />

        <g mask="url(#icon-mask-4)" fill="#000000">
          <g transform="translate(-9.000000, -2.000000)">
            <rect x="0" y="0" width="31" height="26" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Site;
