
// @ts-nocheck

import {forEach, reduce, chain, uniqBy} from 'lodash';
import { useState, useEffect } from 'react';


import { sendAlert } from '../../../apolloClient/client';
import { getFilterOptions, liveAssignmentFilterTypes, getDriverStatusFilterOptions } from '../../../components/Common/Search/util/filters';

import { addSiteMetadata } from '../../Sites/utils/site.utils';
import { createMarkerFromAssignment, updateAssignmentCounts } from 'pages/Live/utils/assignment.utils';
import { formatName } from 'utils/format';

// useLiveMapHandlers returns a bunch of useful handlers that the live map sidebar uses
export const useLiveMapHandlers = (liveData: any) => {
  const [markerId, setMarkerId] = useState(0);
  const [closedProjectIds, setClosedProjectIds] = useState({});

  const toggleMarkerOpen = (id: any) => {
    setMarkerId(markerId === id ? 0 : id);
  };

  const toggleAssignmentOpen = (projectId: any) => {
    setClosedProjectIds((prev) => {

      return { ...prev, [projectId]: !prev[projectId] };
    });
  };

  const toggleAssignmentsOpen = (projectIds: any) => {
    setClosedProjectIds((prev) => {
      const toggledProjectIds = { ...prev };
      forEach(projectIds, (pId: any) => {

        toggledProjectIds[pId] = !prev[pId];
      });
      return toggledProjectIds;
    });
  };

  const setAssignmentsClosed = (projectIds: any) => {
    setClosedProjectIds((prev) => {
      const toggledProjectIds = { ...prev };
      forEach(projectIds, (pId: any) => {

        toggledProjectIds[pId] = true;
      });
      return toggledProjectIds;
    });
  };

  const onRefresh = () => {
    if (liveData && liveData.refetch) {
      liveData.refetch();
    }
  };

  return {
    markerId,
    closedProjectIds,
    toggleMarkerOpen,
    toggleAssignmentOpen,
    toggleAssignmentsOpen,
    setAssignmentsClosed,
    onRefresh,
  };
};

// Hook to limit projects display on mount
export const useLiveProjectsDisplayLimit = (projects: any, limitInitialDisplay: any) => {

  const { setAssignmentsClosed } = useLiveMapHandlers();

  useEffect(() => {
    if (limitInitialDisplay) {
      const totalAssignments = reduce(
        projects,
        (total: any, proj: any) => (total + proj.assignments.length),
        0,
      );

      // If too many assignments, close everything but the first
      if (totalAssignments > 30) {
        const closeIds = chain(projects)
          .slice(1)
          .map(
            (p: any) => p.id,
          )
          .value();

        setAssignmentsClosed(closeIds);

        sendAlert({
          status: 0,
          alertType: 'Alert',
          info: {
            numberOfProjects: projects.length,
          },
          intlId: 'messages.live.showing_only_one_project',
          message: 'There are a lot of Projects, showing {numberOfProjects} Project.',
        });
      }
    }
  }, []);
};

// Filter items based on filterable Assignment Items;
const getFilterItems = (items: any) => getFilterOptions(
  items, item => item.filterProperties, liveAssignmentFilterTypes,
);

// Collect distinct sites from the returned projects
const collectSites = (projects: any) => {
  const sitesByID = {};

  projects.forEach(({
    assignments
  }: any) => {
    assignments.forEach(({
      pickUpSite,
      dropOffSite
    }: any) => {
      if (pickUpSite?.id) {

        sitesByID[pickUpSite.id] = pickUpSite;
      }

      if (dropOffSite?.id) {

        sitesByID[dropOffSite.id] = dropOffSite;
      }
    });
  });


  const sites = Object.values(sitesByID).map(addSiteMetadata);
  return sites;
};

// useLiveAssignments transforms a list of Projects into a list of Marker objects
// recognized by the Live Markers component, as well as a flattened list of assignments
// for the Live Sidebar component
export const useLiveAssignments = (filteredProjects: any, closedProjectIds: any) => {
  const aList: any = [];
  const newMarkers: any = [];

  filteredProjects.forEach((project: any) => {
    const newAssignment = {
      data: project,
      client: project.client,
      name: project.name,
      companyName: project.company.name,
      jobNumber: project.jobNumber,
      foreman: formatName(project.foremanInfo, project.foreman || ''),
      markers: [],
      counts: {
        idle: 0, online: 0, disconnected: 0, badData: 0,
      },
      isClosed: closedProjectIds[project.id] !== true,
    };

    project.assignments
      .filter((as: any) => !!as && as.instance && as.instance.loggingStart)
      .forEach((liveAssignment: any) => {
        // determine bad data parameters
        newAssignment.counts = updateAssignmentCounts(
          newAssignment.counts,
          liveAssignment,
        );

        if (newAssignment.isClosed) {
          const newMarker = createMarkerFromAssignment(liveAssignment, project);
          // push to both new Assignment (for sidebar), and total list of markers (for map)

          newAssignment.markers.push(newMarker);
          newMarkers.push(newMarker);
        }
      });

    aList.push(newAssignment);
  });

  return {
    liveAssignments: aList,
    markers: uniqBy(newMarkers, 'id'),
    sites: collectSites(filteredProjects),
  };
};

// getLiveFilterOptions returns filter options for the Live sidebar filter/search
export const getLiveFilterOptions = (filterableProjects: any) => {
  return {
    currentOptions: getFilterItems(filterableProjects),
    driverStatusOptions: getDriverStatusFilterOptions(),
  };
};

export default useLiveMapHandlers;
