// @ts-nocheck
import classNames from 'classnames';
import React, { ReactNode } from 'react';
import { Button } from 'common-ui/Button';

import { Close } from 'common-ui/Icon';
import styles from './styles.module.scss';

// SplitModal content is a grid of 12 columns
// Need to pass gridColumn values (leftColumn and rightColumn)
// to tell the content which columns to take up

type SplitModalProps = {
  onSubmit: () => void;
  onClose: () => void;
  actionText: ReactNode;
  headerText: ReactNode;
  className: string;
  leftChild: ReactNode;
  rightChild: ReactNode;
  leftColumn: string;
  rightColumn: string;
  disabled: boolean;
  showActions?: boolean;
};

const SplitModal = ({
  onSubmit,
  onClose,
  actionText,
  headerText,
  className,
  leftChild,
  rightChild,
  leftColumn,
  rightColumn,
  disabled,
  showActions = true,
}: SplitModalProps) => (

  <div className={classNames(styles.splitModal, className)}>

    <div className={styles.header}>

      <div className={styles.textHeader}>{headerText}</div>

      <div className={styles.close}>

        <Close onClick={onClose} className={styles.close} />
      </div>
    </div>

    <div className={styles.childContent}>

      <div

        className={classNames(styles.childContentColumn, styles.leftCol)}
        style={{ gridColumn: leftColumn }}
      >
        {leftChild}
      </div>

      <div

        className={classNames(styles.childContentColumn, styles.rightCol)}
        style={{ gridColumn: rightColumn }}
      >
        {rightChild}
      </div>
    </div>
    {showActions && (
      <div className={styles.splitModalButtons}>
        <Button text='Cancel' onClick={onClose} />

        <Button
          disabled={disabled}
          text={actionText}
          className={styles.actionButton}
          onClick={() => {
            onSubmit();
            onClose();
          }}
        />
      </div>
    )}
  </div>
);

export default SplitModal;
