import { gql } from '@apollo/client';

const FETCH_UPLOAD_URL = gql`
  mutation FetchUploadURL($input: FetchUploadURLInput!) {
    fetchUploadURL(input: $input) {
      id
      expiresAt
      putURL
      getURL
    }
  }
`;

export default FETCH_UPLOAD_URL;
