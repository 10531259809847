// @ts-nocheck
import PhoneNumber from 'awesome-phonenumber';
import React from 'react';

import useAddCollaborator from '../useAddCollaborator';
import useEditCollaborator from '../useEditCollaborator';

import { parseNetworkErrorToAlert } from 'apolloClient/alerts';
import Flag, { useFlag } from 'components/Common/Flag';
import type { FormikValues } from 'formik';


const useCollaboratorsFormikConfig = (
  collaborator: any, // TODO: type

  onComplete: (any) => void = () => {},

  onError: (any) => void = () => {},
) => {
  const isEditing = !!collaborator;

  const createCollaborator = useAddCollaborator();
  const editCollaborator = useEditCollaborator();

  const { addFlag } = useFlag();

  return {
    enableReinitialize: true,
    initialValues: {
      id: collaborator ? collaborator.id : null,
      firstName: collaborator ? collaborator.firstName : '',
      lastName: collaborator ? collaborator.lastName : '',
      phoneNumber: collaborator ? collaborator.phoneNumber : '',
      companyName: collaborator ? collaborator.companyName : '',
      email: collaborator ? collaborator.email : '',
      role: collaborator ? collaborator.role : null,
    },
    validate: (values: FormikValues) => {
      const errors = {};

      // We add the errors to a separate variable so they don't actually appear
      if (!values.phoneNumber) {

        errors.form = 'Must enter a valid phone number';
      } else {
        const inputtedNumber = new PhoneNumber(values.phoneNumber, 'CA');
        if (!inputtedNumber.isPossible()) {

          errors.form = 'Must enter a valid phone number';
        }
      }

      if (!values.firstName) {

        errors.form = 'Please enter a first name';
      }

      if (!values.lastName) {

        errors.form = 'Please enter a last name';
      }

      if (!values.companyName) {

        errors.form = 'Please enter a company';
      }

      if (!values.role) {

        errors.form = 'Please specify a role';
      }

      return errors;
    },
    onSubmit: (values: FormikValues, {
      setSubmitting,
      setFieldError
    }: any) => {
      if (isEditing) {
        return editCollaborator(collaborator.id, values)
          .then(onComplete);
      }
      return createCollaborator(values)
        .then((newCollab) => {
          if (newCollab) {
            addFlag(
              'New Collaborator Flag',
              <Flag
                style={Flag.Styles.SUCCESS}
                title={`${newCollab.firstName} ${newCollab.lastName} added as a Guest`}
              />,
            );
          }
          return newCollab;
        })
        .catch((err) => {
          if (err.networkError.result.identifier === 'APP_HAE') {
            const parsedErr = parseNetworkErrorToAlert(err.networkError, '');
            setFieldError('phoneNumber', parsedErr.message);
            setSubmitting(false);
            return parsedErr;
          }
          return onError(err);
        })
        .then((collab) => {
          if (collab.id) {
            return onComplete(collab);
          }
        });
    },
  };
};

export default useCollaboratorsFormikConfig;
