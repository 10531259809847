import React, {SVGProps} from 'react';

const ExpandCollapseSection = (props: SVGProps<SVGSVGElement>) => (

  <svg
    width="14"
    height="7"
    viewBox="0 0 14 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >

    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.4956 6.76285C12.7153 6.96477 13.0406 7.04575 13.3465 6.97467C13.6525 6.90358 13.8914 6.6915 13.9715 6.41993C14.0515 6.14836 13.9603 5.85957 13.7329 5.66455L7.60786 0.227418C7.26617 -0.0758051 6.7123 -0.0758051 6.37061 0.227418L0.245622 5.66455C-0.0859783 5.96933 -0.0812348 6.45378 0.256282 6.75339C0.593798 7.05301 1.13954 7.05722 1.48287 6.76286L6.98924 1.87487L12.4956 6.76285Z"
      fill="#767676"
    />
  </svg>
);

export default ExpandCollapseSection;
