import React, {SVGProps} from 'react';

const ChevronLeft = (props: SVGProps<SVGSVGElement>) => (

  <svg
    width={11}
    height={20}
    viewBox="0 0 11 20"
    preserveAspectRatio={undefined}
    {...props}
  >

    <title>Arrow - Left</title>

    <path
      d="M10.395 2.053A1.194 1.194 0 1 0 8.707.365L.35 8.722a1.194 1.194 0 0 0 0 1.688l8.357 8.358a1.194 1.194 0 0 0 1.688-1.688L2.882 9.566l7.513-7.513z"
      fill="#162093"
      fillRule="evenodd"
    />
  </svg>
);

export default ChevronLeft;
