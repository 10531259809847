import React, {SVGProps} from 'react';

const LogoYellow = (props: SVGProps<SVGSVGElement>) => (

  <svg width={156} height={116} viewBox="0 0 156 116" {...props}>

    <title>Tread Logo - Yellow</title>

    <g fillRule="nonzero" fill="none">

      <path
        fill="#000"
        d="M0 80h26v7.202h-8.785V116h-8.43V87.202H0zM41.59 80c6.53 0 11.491 3.624 11.491 10.69.231 4.796-2.76 9.122-7.214 10.431L58 116H47.793L37.03 101.776V116H29V80h12.59zm-3.623 16.008c3.036 0 6.66-.215 6.66-4.708s-3.635-4.707-6.66-4.707h-.936v9.415h.936zM86 87.2H72.767v7.155h12.675v7.2H72.767v7.245H86v7.2H64V80h22zM123 116h-8.661l-2.61-7.202H98.271L95.662 116H87l14.275-36h7.46L123 116zm-17.956-24.835h-.088l-3.943 10.487h7.974l-3.943-10.487zM137.58 80C147.585 80 156 87.5 156 97.955 156 108.41 147.63 116 137.58 116H127V80h10.58zm-.97 28.635c6.159 0 10.355-4.624 10.4-10.635.045-6.011-4.207-10.68-10.4-10.68h-1.286v21.315h1.286z"
      />

      <path
        d="M59.585 50.927l-2.389-2.354L79.84 27.246l21.624 21.284-2.445 2.3-1.416-.371L79.67 32.825 60.763 50.644l-1.178.283zm20.153-32.694L57.083 39.56l2.808 2.779 19.666-18.527L98.362 42.34l2.989-2.812-21.613-21.295zm12.476 30.809L79.76 36.76 66.548 49.195l7.823-1.951 5.186-4.883 4.743 4.665 7.914 2.016zM79.76 8.969L57.117 30.286l2.808 2.78L79.59 14.537l18.805 18.527 2.955-2.779L79.761 8.97zM80.077 0L57.434 21.317l2.808 2.779L79.908 5.569l18.805 18.527 2.989-2.8L80.077 0zm-27.41 26.025l2.967 2.92 3.011-2.79-3-2.964-2.978 2.834zm-5.038 4.62l7.631 7.499 2.99-2.801-7.643-7.52-2.978 2.823zm-4.528 4.36l12.318 12.13 2.989-2.801-12.341-12.152-2.966 2.823zm-5.152 4.948L51.22 53.03l4.675-1.09-14.967-14.822-2.978 2.834zM48.41 53.696L36.398 41.86 15 62l7.925-1.95 13.303-12.61 7.552 7.422 4.63-1.166zm-8.083 2.016l-3.963-3.902-7.065 6.648 11.028-2.746zm63.017-32.826l-3 2.965 2.988 2.8 2.967-2.92-2.955-2.845zm5.038 4.621l-7.642 7.52 2.989 2.8 7.596-7.475-2.943-2.845zm4.528 4.36l-12.329 12.216 3.034 2.78 12.262-12.098-2.967-2.899zm5.14 4.936l-14.978 14.691 4.676 1.264 13.269-13.078-2.966-2.877zm-2.853 17.754l7.552-7.422 13.326 12.62 7.925 1.95-21.398-20.14-12.046 11.836 4.641 1.156zm14.458 3.65l-7.064-6.647-3.963 3.901 11.027 2.746z"
        fill="#FDD405"
      />
    </g>
  </svg>
);

export default LogoYellow;
