// @ts-nocheck

import { makeVar } from '@apollo/client';
import _ from 'lodash';

// parseAlert parses the alert based on the identifier, overrides base alert error fields
const parseAlert = (identifier: string) => {
  switch (identifier) {
    case 'APP_ADNA':
      return {
        message: 'Hire is not allowed on that assignment.',
        intlId: 'messages.error.assignment.draft_not_allowed',
      };
    case 'APP_HAE':
      return {
        message: 'A hire with this phone number is already part of your company!',
        intlId: 'messages.error.hires.already_exists',
      };
    case 'APP_AHBD':
      // hire is already disabled
      return {
        message: 'Hire is disabled.',
        intlId: 'messages.error.hires.disabled',
      };
    case 'APP_HPIF':
      return {
        message: 'Phone Number provided must be a valid format.',
        intlId: 'messages.error.hires.invalid_phone_number',
      };
    case 'APP_LEADNE':
      return {
        message: 'Phone Number does not exist.',
        alertType: 'errorLogin',
        intlId: 'messages.error.phone_number_does_not_exist',
      };
    case 'APP_LCI':
      return {
        message: 'Login code is incorrect.',
        intlId: 'messages.error.login_code_incorrect',
      };
    case 'APP_TMLA':
      return {
        message: 'You have attempted to login too many times.',
        intlId: 'messages.error.too_many_login_attempts',
      };
    case 'APP_RemoveAdminHire':
      return {
        message: 'You cannot remove a hire if they are an admin.',
        intlId: 'messages.error.remove_admin_hire',
      };
    case 'APP_RemoveHireOnlyCompany':
      return {
        message: 'Unable to remove a hire from their only company.',
        intlId: 'messages.error.remove_hire_only_company',
      };
    default:
      return {};
  }
};

type networkErrorType = {
  statusCode: string,
  result?: {
    msg?: string,
    identifier?: string,
  }
};

type operationType = {
  operationName: string,
};

export const parseNetworkErrorToAlert = (
  networkError: networkErrorType,
  operation: operationType,
) => {
  const operationName = (operation && operation.operationName);
  const statusCode = (networkError && networkError.statusCode);
  const alertObject = {
    status: statusCode,
    alertType: 'error',
    message: (networkError && networkError.result) ? networkError.result.msg : 'An error occurred. Please try again',
    info: {},
    intlId: (networkError && networkError.result) ? '' : 'messages.error.generic',
  };


  if (operationName === 'AccountMetadataQuery' && statusCode === 403) {
    return {
      ...alertObject,
      alertType: 'errorLogin',
    };
  }

  // If has identifier, use identifier to return the correct alert text
  const identifier = networkError && networkError.result && networkError.result.identifier;

  const parsedAlert = parseAlert(identifier, statusCode, networkError.result);

  return {
    ...alertObject,
    ...parsedAlert,
  };
};

// Create a send message service,
// with a singleton clearAlertAfterWait that debounces after 6000ms.
const alertService = () => {
  const alertVar = makeVar({
    status: 0,
    alertType: 'alert',
    message: '',
    intlId: '',
    messageInfo: '',
    dataName: '',
    typeName: '',
  });

  const sendMessage = (alert: any) => {
    const {
      status,
      alertType,
      info,
      message,
      intlId,
    } = alert;

    return alertVar({
      status,
      message,
      alertType,
      intlId,
      messageInfo: JSON.stringify(info),
      dataName: 'clientAlert',
      typeName: 'ClientAlert',
    });
  };

  const clearAlertAfterWait = _.debounce(
    () => (
      sendMessage({
        status: 0,
        alertType: 'alert',
        info: {},
        message: '',
        intlId: '',
      })
    ),
    6000,
  );

  return {
    sendMessage,
    alertVar,
    clearAlertAfterWait,
  };
};

export default alertService;
