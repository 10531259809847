// @ts-nocheck
import { useQuery, gql } from '@apollo/client';
import { useMemo } from 'react';

import { publicClient } from 'apolloClient';

const SHARED_LIVE_PROJECT_QUERY = gql`
  query sharedLiveProject($uuid: String!) {
    sharedLiveProject(uuid: $uuid) {
      enabled
      project {
        id
        name
        client
        jobNumber
        pickUpSiteID
        pickUpSiteName
        pickUpSite {
          id
          companyId
          company {
            id
            name
            measurementType
            timeOnSiteGoalMinutes
          }
          name
          radius
          address
          coordinates {
            latitude
            longitude
          }
          status
          type
          geofenceType
        }
        dropOffSiteID
        dropOffSiteName
        dropOffSite {
          id
          companyId
          company {
            id
            name
            measurementType
            timeOnSiteGoalMinutes
          }
          name
          radius
          address
          coordinates {
            latitude
            longitude
          }
          status
          type
          geofenceType
        }
        company {
          id
          name
          logo {
            getURL
            filename
          }
        }
        companyClient {
          id
          name
        }
        foreman
        foremanID
        foremanInfo {
          id
          firstName
          lastName
        }
        showName
        showPhoneNumber
        showStatus
        showTimecard
        viewLiveMap
        showLicensePlate
        lastSharedAt
        assignments {
          id
          instance {
            id
            loggingStart
          }
          assignedTo {
            id
            assignee {
              id
              accountId
              companyId
              firstName
              lastName
              phoneNumber
              truckNumber
              companyName
              instanceTruckNumber
              licensePlate
              instanceLicensePlate
            }
            gps {
              id
              latitude
              longitude
              bearing
              idleSince
              lastPing
              isIdle
              isDisconnected
            }
          }
          pickUpSite {
            id
            companyId
            company {
              id
              name
              measurementType
              timeOnSiteGoalMinutes
            }
            name
            radius
            address
            coordinates {
              latitude
              longitude
            }
            status
            type
            geofenceType
          }
          pickUpSiteID
          pickUpSiteName
          quantity
          quantityUnit
        }
      }
    }
  }
`;

export const useSharedLiveProject = (uuid: string) => {
  const { data, loading, error } = useQuery(SHARED_LIVE_PROJECT_QUERY, {
    variables: {
      uuid,
    },
    fetchPolicy: 'no-cache',
    pollInterval: 10000,
    client: publicClient,
  });

  return useMemo(() => {
    if (loading) return [null, loading];
    if (error || !data) return [null, loading];
    return [data.sharedLiveProject, loading];
  }, [data, loading, error]);
};

export default useSharedLiveProject;
