// @ts-nocheck
import classNames from 'classnames';
import React from 'react';

import { SiteIcon } from 'common-ui/Sites';
import { useHover } from 'utils/Hooks';
import styles from './styles.module.scss';


type SiteDetailProps = {
  siteName: string,
  siteAddress: string,
  siteType: string,
  mobile?: boolean,
  skipTransition?: boolean,
};

const SiteDetail = ({
  siteName, siteAddress, siteType, mobile, skipTransition,
}: SiteDetailProps) => {
  const [ref, isHovered] = useHover();
  if (!siteName) return siteAddress || '-';
  return (

    <div ref={ref} className={classNames(styles.projectSiteIndicator, { [styles.mobile]: mobile })}>

      <SiteIcon type={siteType} />

      <div className={classNames(styles.siteDetailText, {
        [styles.hidden]: isHovered,
        [styles.skipTransition]: skipTransition,
      })}
      >
        {siteName}
      </div>

      <div className={classNames(styles.siteDetailText, { [styles.hidden]: !isHovered })}>
        {siteAddress}
      </div>
    </div>
  );
};

SiteDetail.defaultProps = {
  mobile: false,
  skipTransition: false,
};

export default SiteDetail;
