import React, {SVGProps} from 'react';

const Expand = (props: SVGProps<SVGSVGElement>) => (

  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >

    <path d="M8 10.6667L4.5359 7.16669H11.4641L8 10.6667Z" fill="#4A4A4A" />
  </svg>
);

export default Expand;
