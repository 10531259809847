// @ts-nocheck
import { useQuery, useMutation, gql } from '@apollo/client';
import { useMemo, useCallback } from 'react';

import { SiteType, Product } from '../types';
import { useCompany } from 'pages/Settings/CompanySettings/graphql';
import { useCompanyContext } from 'utils/Hooks';


import { measurementTypes, feetToMetres } from 'utils/format';
// eslint-disable-next-line import/namespace

const COMPANIES_QUERY = gql`
  query CompaniesQuery($isAdmin: Boolean, $isExternalGuest: Boolean) {
    companies(isAdmin: $isAdmin, isExternalGuest: $isExternalGuest) {
      id
      name
      measurementType
    }
  }
`;

export const useCompanies = () => {
  const { loading, error, data } = useQuery(COMPANIES_QUERY, {
    variables: {
      isAdmin: true,
    },
  });
  return useMemo(() => (
    (!loading && !error)
      ? data.companies
      : []
  ), [loading, error, data]);
};

const ADD_SITE_MUTATION = gql`
  mutation createSite($input: SiteInput!) {
    createSite(input: $input) {
      id
      companyId
      company {
        id
        name
        measurementType
      }
      name
      radius
      address
      coordinates {
        latitude
        longitude
      }
      status
      unit
      notes
      type
      geofenceType
      products {
        id
        name
      }
      pointLocation {
        latitude
        longitude
      }
      externalID
    }
  }
`;


const radiusConversion = (values: any, company: any) => {
  return {
    ...values,
    radius: company && company.measurementType === measurementTypes.IMPERIAL ? feetToMetres(values.radius) : values.radius,
  };
};

export const useAddSite = () => {
  const [addSite] = useMutation(ADD_SITE_MUTATION);
  const { selectedCompanyID } = useCompanyContext();
  const company = useCompany(selectedCompanyID);

  return useCallback(values => (
    addSite({
      variables: {
        input: radiusConversion(values, company),
      },
      refetchQueries: ['SitesQuery', 'retrieveProductsByCompanyID'],
    })
  ), [addSite, company]);
};

const UPDATE_SITE_MUTATION = gql`
  mutation updateSite($id: ID!, $input: SiteInput!) {
    updateSite(id: $id, input: $input) {
      id
      companyId
      company {
        id
        name
        measurementType
      }
      name
      radius
      address
      coordinates {
        latitude
        longitude
      }
      status
      unit
      notes
      type
      geofenceType
      pointLocation {
        latitude
        longitude
      }
      externalID
    }
  }
`;

export const useUpdateSite = (id: any) => {
  const [updateSite] = useMutation(UPDATE_SITE_MUTATION);
  const { selectedCompanyID } = useCompanyContext();
  const company = useCompany(selectedCompanyID);
  return useCallback(values => (
    updateSite({
      variables: {
        id,
        input: radiusConversion(values, company),
      },
      refetchQueries: ['SitesQuery', 'retrieveProductsByCompanyID'],
    })
  ), [updateSite, id, company]);
};

const SITES_QUERY = gql`
  query SitesQuery($companyID: ID!, $filters: [FilterInput!], $sort: SortInput, $includeParentSites: Boolean = false) {
    sites(companyID: $companyID, filters: $filters, sort: $sort, includeParentSites: $includeParentSites) {
      id
      companyId
      company {
        id
        name
        measurementType
      }
      name
      radius
      address
      coordinates {
        latitude
        longitude
      }
      status
      notes
      type
      geofenceType
      externalID
      products {
        id
        name
      }
      createdBy {
        id
        firstName
        lastName
      }
      createdAt
      pointLocation {
        latitude
        longitude
      }
    }
  }
`;

export const EQUIPMENT_BY_COMPANY_ID_QUERY = gql`
  query retrieveEquipmentByCompanyID ($companyID: ID!) {
    equipmentByCompanyID (companyID: $companyID) {
      id
      name
      capacity
      unit
      rate
      rateUnit
      code
      description
    }
  }
`;


export const useGetEquipmentByCompanyId = (companyId: any): [Equipment[], boolean] => {
  const { loading, error, data } = useQuery(EQUIPMENT_BY_COMPANY_ID_QUERY, {
    variables: {
      companyID: companyId.toString(),
    },
    fetchPolicy: 'cache-and-network',
    skip: !companyId,
  });

  if (loading) {
    return [[], true];
  }
  if (!loading && !error && data) {
    return [data.equipmentByCompanyID, false];
  }
  return [[], false];
};


export const useGetEquipmentByCompanyIdAndEquipmentName = (companyId: any, equipmentName: any): Equipment => {
  const [equipmentList] = useGetEquipmentByCompanyId(companyId);
  const equipment = equipmentList.find(e => e.name === equipmentName);
  return equipment ? [equipment] : [];
};

const PRODUCTS_BY_COMPANY_ID_QUERY = gql`
  query retrieveProductsByCompanyID ($companyID: ID!) {
    productsByCompanyID (companyID: $companyID) {
     id
     companyID
     name
     description
     code
     tags
     createdAt
     updatedAt
    }
  }
`;

export const useGetProductsByCompanyId = (companyID: any): [Product[], boolean] => {
  const { loading, error, data } = useQuery(PRODUCTS_BY_COMPANY_ID_QUERY, {
    variables: {
      companyID: companyID.toString(),
    },
    fetchPolicy: 'cache-and-network',
  });
  return useMemo(() => {
    if (loading) {
      return [[], true];
    }
    if (!loading && !error) {
      const productsByCompanyID = (data && data.productsByCompanyID) || [];
      return [productsByCompanyID, false];
    }
    return [[], false];
  }, [loading, error, data, companyID]);
};

// useGetSites returns a tuple containing an array of Sites, and the loading state
const useGetSites = (includeParentSites = false, fetchPolicy = 'cache-first'): [Array<SiteType>, boolean] => {
  const { selectedCompanyID } = useCompanyContext();
  const { loading, error, data } = useQuery(SITES_QUERY, {
    variables: {
      includeParentSites,
      companyID: selectedCompanyID,
    },

    fetchPolicy,
    skip: !selectedCompanyID || selectedCompanyID === '0',
  });
  return useMemo(() => {
    if (loading) {
      return [[], true];
    }
    if (!loading && !error && data) {
      return [data.sites, false];
    }
    return [[], false];
  }, [loading, error, data, includeParentSites, fetchPolicy]);
};

// useGetSiteSiteIDSet returns a set of all 'Site ID' (ExternalID) values for form validation
const useGetSiteIDSet = (): [Set<string>, boolean] => {
  const [sites, loading] = useGetSites();
  return useMemo(() => {
    if (loading) {
      return [new Set(), true];
    }
    const siteSiteIDSet = new Set(sites.map(site => site.externalID));
    return [siteSiteIDSet, false];
  }, [loading, sites]);
};

export {
  SITES_QUERY,
  COMPANIES_QUERY,
  PRODUCTS_BY_COMPANY_ID_QUERY,
  useGetSites,
  useGetSiteIDSet,
};
