import React, {ReactNode} from 'react';

import Count from './Count';

import Tag from './Tag';
import styles from './styles.module.scss';

type PillProps = {
  text: string | ReactNode;
  value: number;
  countClassName?: string;
  tagClassName?: string;
};

const Pill = ({text, value, countClassName, tagClassName}: PillProps) => (

  <div className={styles.pill}>

    <span className={styles.pillCount}>

      <Count value={value} className={countClassName} />
    </span>

    <span className={styles.pillTag}>

      <Tag
        text={text}

        leftIcon={<div className={styles.pillSpacer} />}
        className={tagClassName}
      />
    </span>
  </div>
);

Pill.defaultProps = {
  countClassName: undefined,
  tagClassName: undefined,
};

export default Pill;
