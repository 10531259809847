// @ts-nocheck

import { ApolloClient, ApolloLink, InMemoryCache, split } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';

import { createUploadLink } from 'apollo-upload-client';
import { apiURL, apiWSURL } from '../utils/api';
import alerts, { parseNetworkErrorToAlert } from './alerts';

const { sendMessage, alertVar, clearAlertAfterWait } = alerts();

// Singleton for sending Alert
export const sendAlert = (alert: any) => {
  sendMessage(alert);
  clearAlertAfterWait();
};

export const sendErrorAlert = (error: any, operation: any) => {
  sendAlert(parseNetworkErrorToAlert(error, operation));
};

export const clearAlert = clearAlertAfterWait;

// This tells apollo client how to retrieve cached individual entries by id,
// and objects that might already exist in the cache:
// https://www.apollographql.com/docs/react/advanced/caching.html#cacheRedirect
// TODO Since these are cache redirects, how do we handle multiple dispatchers
// updating projects in the same company?
// Once a mutation occurs, the cache is updated,
// but it DOES NOT refetch from server unless query explicitly refreshed

// This is intended to be used with components that explicitly query individual objects
// such as the Project Details sidebar querying a project based on ID
// So we don't need to pass in the entire project object from the parent list view,
// instead we can just have the details view GQLquery the project by ID from the cache
// This is possible because apollo cache normalizes every query result
// https://stackoverflow.com/questions/49614938/automatic-cache-updates-do-not-work-in-react-apollo
const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        project: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'Project',

              id: args.id
            });
          },
          merge(existing, incoming) {
            return incoming;
          }
        },
        requests: {
          merge(_existing = [], incoming = []) {
            return incoming;
          }
        },
        notifications: {
          merge(_existing = [], incoming = []) {
            return incoming;
          }
        },
        clientAlert: {
          read() {
            return alertVar();
          }
        },
        assignment: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'Assignment',

              id: args.id
            });
          }
        },
        assignments: {
          read(_, { args, toReference }) {

            return args.ids.map((id: any) => {
              return toReference({
                __typename: 'Assignment',
                id,

                enableHoursScheduled: args.enableHoursScheduled
              });
            });
          }
        }
      }
    },
    // This is the Apollo 3.0 way of defining custom cache object IDs
    Request: {
      keyFields: ['project', ['id']]
    },
    RecommendedAssignee: {
      keyFields: ['accountID', 'companyID', 'lastWorkedOn']
    },
    RecommendedSubcontractor: {
      keyFields: ['id', 'lastWorkedOn']
    },
    LiveProject: {
      keyFields: (obj, { typename }) => {
        return `${typename}:${obj.id}:${
          
          obj.assignments && obj.assignments[0] ? obj.assignments[0].id : '-'
        }`;
      }
    },
    ConfigurableFieldData: {
      keyFields: ['id', 'value']
    },
    Project: {
      fields: {
        assignments: {
          merge(_existing = [], incoming = []) {
            return incoming;
          }
        },
        cancelledAssignments: {
          merge(_existing = [], incoming = []) {
            return incoming;
          }
        }
      }
    }
  }
});

// (2021-10-21) There appears to be a Typescript issue between apollo client ^3.4.5 and
// apollo-upload-client https://github.com/jaydenseric/apollo-upload-client/issues/268 but this
// does not seem to cause an issue functionally
const httpLink = createUploadLink({
  uri: apiURL(),
  credentials: 'include',
  fetchOptions: { mode: 'cors' }
});

const wsLink = new WebSocketLink({

  uri: apiWSURL(),
  options: {
    reconnect: true,
    minTimeout: 50000,
    // Lazy connections will only be established when the first subscriptions is made for websockets
    // See: https://github.com/enisdenjo/graphql-ws/blob/master/docs/interfaces/client.ClientOptions.md#lazy
    lazy: true,
  }
});

// splitLink will split the protocol used for contacting core. Query/Mutations will
// use httpLink, and subscriptions will use wsLink
// https://www.apollographql.com/docs/react/data/subscriptions/#3-split-communication-by-operation-recommended
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink
);

const retryLink = new RetryLink({
  delay: {
    initial: 10000,
    max: Infinity,
    jitter: true
  },
  attempts: {
    max: 0
  }
});

const apolloClient = new ApolloClient({
  link: ApolloLink.from([
    retryLink,
    onError(({ operation, networkError }) => {
      if (
        (!!operation && operation.operationName === 'AccountMetadataQuery') ||
        networkError
      ) {
        // we can use sendErrorAlert here before declaration as it's hoisted && not used directly
        // eslint-disable-next-line no-use-before-define
        sendErrorAlert(networkError, operation);
      }
    }),
    splitLink
  ]),
  cache,
  queryDeduplication: true
});

export default apolloClient;
