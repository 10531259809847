import React, {SVGProps} from 'react';

const DashedClock = (props: SVGProps<SVGSVGElement>) => (

  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >

    <g clipPath="url(#clip0)">

      <path
        d="M10.4582 18.4257C9.0665 18.4257 7.67484 18.0923 6.4415 17.459C6.03317 17.2507 5.87484 16.7423 6.08317 16.334C6.2915 15.9257 6.79984 15.7673 7.20817 15.9757C8.2165 16.4923 9.3165 16.759 10.4665 16.759C14.3998 16.759 17.5915 13.559 17.5915 9.63398C17.5915 5.70898 14.3915 2.50065 10.4582 2.50065C6.52484 2.50065 3.33317 5.70065 3.33317 9.62565C3.33317 10.084 2.95817 10.459 2.49984 10.459C2.0415 10.459 1.6665 10.084 1.6665 9.62565C1.6665 4.77565 5.60817 0.833984 10.4582 0.833984C15.3082 0.833984 19.2498 4.77565 19.2498 9.62565C19.2498 14.4757 15.3082 18.4257 10.4582 18.4257Z"
        fill="#767676"
      />

      <path
        d="M13.8167 14.2835C13.5833 14.2835 13.3583 14.1918 13.1917 14.0001L9.625 9.95013V4.67513C9.625 4.2168 10 3.8418 10.4583 3.8418C10.9167 3.8418 11.2917 4.2168 11.2917 4.67513V9.3168L14.4417 12.9001C14.75 13.2418 14.7083 13.7751 14.3667 14.0751C14.2083 14.2085 14.0083 14.2835 13.8167 14.2835Z"
        fill="#767676"
      />

      <path
        d="M4.89967 16.1177C5.35071 16.1177 5.71634 15.7521 5.71634 15.301C5.71634 14.85 5.35071 14.4844 4.89967 14.4844C4.44864 14.4844 4.08301 14.85 4.08301 15.301C4.08301 15.7521 4.44864 16.1177 4.89967 16.1177Z"
        fill="#767676"
      />

      <path
        d="M3.50807 14.2661C3.95911 14.2661 4.32474 13.9005 4.32474 13.4495C4.32474 12.9984 3.95911 12.6328 3.50807 12.6328C3.05704 12.6328 2.69141 12.9984 2.69141 13.4495C2.69141 13.9005 3.05704 14.2661 3.50807 14.2661Z"
        fill="#767676"
      />
    </g>

    <defs>

      <clipPath id="clip0">

        <rect
          width="17.5917"
          height="17.5917"
          fill="white"
          transform="translate(1.6665 0.833984)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default DashedClock;
