// @ts-nocheck
import classNames from 'classnames';
import moment from 'moment-timezone';
import React from 'react';


import { useTimezone } from 'common-ui/Hooks';
import { Calendar } from 'components/Common/Calendar';
import styles from './styles.module.scss';


export type MultiSelectCalendarProps = {

  selectedDates: [moment, moment],
  setSelectedDates: (params: any) => void,
};

const MultiSelectCalendar = ({
  selectedDates,
  setSelectedDates,
  ...calendarProps
}: MultiSelectCalendarProps) => {
  const timezone = useTimezone();
  const currentDate = moment(moment().tz(timezone).format('YYYY-MM-DD'));
  const startDate = !!selectedDates && selectedDates[0] ? selectedDates[0] : currentDate;
  const endDate = !!selectedDates && selectedDates[1] ? selectedDates[1] : currentDate;
  const calendarValue = selectedDates ? [startDate.toDate(), endDate.toDate()] : null;
  return (
    <Calendar
      value={calendarValue}

      onChange={newValues => setSelectedDates(newValues)}
      selectRange
      className={classNames(styles.reactCalendarOverrides, styles.reactMultiCalendarOverrides)}

      tileContent={({ date, view }) => {
        const tileDate = moment(date);
        if (view !== 'month') return null;

        if (tileDate.isSame(currentDate, 'day') && !tileDate.isBetween(startDate, endDate, '[]')) {
          return <div className={styles.todayIndicator} />;
        }
        return null;
      }}
      {...calendarProps}
    />
  );
};

export default MultiSelectCalendar;
