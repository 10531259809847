// @ts-nocheck
import classnames from 'classnames';
import moment from 'moment';
import React from 'react';

import { CyclePlay, CycleSite } from 'common-ui/Icon';
import styles from './styles.module.scss';

const CycleCard = ({
  cycle,
  pickupSite,
  dropoffSite
}: any) => {
  if (!cycle || !pickupSite || !dropoffSite) {
    return null;
  }

  let state;

  if (cycle.dropoffDeparture) {
    state = 'returning';
  } else if (cycle.dropoffArrival) {
    state = 'dropoff';
  } else if (cycle.pickupDeparture) {
    state = 'driving';
  } else if (cycle.pickupArrival) {
    state = 'pickup';
  }

  const playIcon = (

    <div className={classnames(styles.playIcon, styles.iconContainer)}>

      <CyclePlay />
    </div>
  );

  return (

    <div className={styles.cycleCard}>
      {/* Left indicator bar */}

      <div className={classnames(styles.indicatorArea, {
        [styles.drivingToPickup]: state === 'driving',
        [styles.drivingToDropoff]: state === 'returning'
      })}>

        <div className={classnames(styles.iconContainer, {
          [styles.pickupIcon]: state === 'pickup' || state === 'returning'
        })}>
           <CycleSite />
        </div>

        <div className={classnames(styles.iconContainer, {
          [styles.dropoffIcon]: state === 'dropoff' || state === 'driving'
        })}>
           <CycleSite dropoff />
        </div>
      </div>

      {/* Pickup section */}

      <div className={classnames(styles.pickupArea, styles.dataContainer)}>

        <div className={styles.title}>
          {pickupSite.name}
          {state === 'pickup' && playIcon}
        </div>

        <TimingData from={cycle.pickupArrival} to={cycle.pickupDeparture} />
      </div>

      {/* Driving section */}

      <div className={classnames(styles.drivingArea, styles.dataContainer)}>

        <div className={styles.title}>
          Driving
          {(state === 'driving' || state === 'returning') && playIcon}
        </div>
        {state === 'returning' ?
           <TimingData from={cycle.dropoffDeparture} to={null} /> :
           <TimingData from={cycle.pickupDeparture} to={cycle.dropoffArrival} />
        }
      </div>

      {/* Dropoff section */}

      <div className={classnames(styles.dropoffArea, styles.dataContainer)}>

        <div className={styles.title}>
          {dropoffSite.name}
          {state === 'dropoff' && playIcon}
        </div>

        <TimingData from={cycle.dropoffArrival} to={cycle.dropoffDeparture} />
      </div>
    </div>
  );
};

const TimingData = ({
  from,
  to
}: any) => {
  if (to) {
    return (

      <div className={styles.data}>

        From {moment(from).format('h:mm a')} - {moment(to).format('h:mm a')}
      </div>
    );
  } if (from) {
    return (

      <div className={styles.data}>

        Since {moment(from).format('h:mm a')} &bull; {moment.duration(moment().diff(moment(from))).asMinutes().toFixed(0)}m
      </div>
    );
  }

  return (

    <div className={styles.data}>
      &nbsp;
    </div>
  );
};

export default CycleCard;
