// @ts-nocheck
import { connect, Formik } from 'formik';
import React from 'react';


import EquipmentInput from '../EquipmentInput';
import { FormField } from 'common-ui/Form';

type FormikEquipmentInputProps = {
  formik: FormikProps,
  label: string,
  required?: boolean,
  name: string,
  locked?: boolean,
  readOnly?: boolean,
  readOnlyValue?: ReactNode,
  id?: string,
  hint?: string,
  canCreateEquipment?: boolean,
  openModal:any
  closeModal:any,
  embedded:boolean
};

const FormikEquipmentInput = ({
  formik,
  label,
  required,
  name,
  locked,
  embedded,
  readOnly,
  readOnlyValue,
  id,
  hint,
  canCreateEquipment,
  openModal,
  closeModal,
  ...inputProps
}: FormikEquipmentInputProps) => (

  <FormField
    label={label}
    required={required}
    error={formik.touched[name] && formik.errors[name]}
    locked={locked}
    embedded={embedded}
    hint={hint}
  >
    {!readOnly ? (

      <EquipmentInput

        id={id}
        value={formik.values[name]}
        onChange={value => formik.setFieldValue(name, value)}
        onBlur={() => formik.setFieldTouched(name)}
        name={name}
        embedded={embedded}
        canCreateEquipment={canCreateEquipment}
        openModal={openModal}
        closeModal={closeModal}
        {...inputProps}
      />
    ) : (

      <div id={id}>
        {readOnlyValue || (formik.values[name] && formik.values[name].name) || '-'}
      </div>
    )}
  </FormField>
);

FormikEquipmentInput.defaultProps = {
  required: false,
  locked: false,
  readOnly: false,
  readOnlyValue: '',
  id: undefined,
  hint: '',
  canCreateEquipment: true,
};

export default connect(FormikEquipmentInput);
