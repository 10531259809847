// @ts-nocheck
import classNames from 'classnames';
import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './styles.module.scss';
import type { MenuLinkPropTypes } from '../../types';

const MenuLink = ({
  path,
  menuIcon,
  mobile,
  onChange,
  text,
}: MenuLinkPropTypes) => (

  <NavLink
    to={path}

    isActive={(match, location) => (path && path.pathname === '/dispatch' && location.pathname === '/')

      || (path && path.pathname === '/dispatch' && location.pathname === '/projects')

      || (path && path.pathname === '/people' && location.pathname === '/hires')

      || (!!match && match.url === path.pathname)
    }
    onClick={onChange}

    className={classNames(styles.link, { [styles.mobile]: mobile })}
    activeClassName={styles.activeLink}
  >
    {menuIcon}
    {!!text && (

      <div className={styles.label}>
        {text}
      </div>
    )}
  </NavLink>
);

MenuLink.defaultProps = {
  text: '',
  mobile: false,
  onChange: () => {},
};

export default MenuLink;
