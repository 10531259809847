// @ts-nocheck
import classNames from 'classnames';
import React, { useLayoutEffect, useState, useRef } from 'react';

import { Draggable } from 'react-beautiful-dnd';

import ClickableDiv from 'common-ui/ClickableDiv';
import type { ModifiedAssignmentType } from 'pages/Projects/types';
import { useOutsideClickHandler } from 'utils/Hooks';
import { HireChip } from './HireChip';
import HireTooltip from './HireTooltip';
import styles from './styles.module.scss';
import type { DisplayHire, DisplaySubcontractor } from '../types';





type DraggableHireChipProps = {
  draggableId: string,
  hire: DisplayHire | DisplaySubcontractor,
  assignmentInstance?: any,
  index: number,
  assignments?: Array<ModifiedAssignmentType>,
  onLoad: () => void,
  className: string,
};

const DraggableHireChip = ({
  draggableId,
  hire,
  assignmentInstance,
  index,
  assignments,
  onLoad,
  className,
}: DraggableHireChipProps) => {
  // There are some extra things we include for hires assigned to smart
  // assignments.
  if (hire.isAssignedToSmartAssignment) {
    const [isTooltipVisible, setTooltipVisible] = useState(false);
    const tooltipRef = useRef();
    useOutsideClickHandler(tooltipRef, () => setTooltipVisible(false));

    return (

      <div className={classNames([className, styles.draggableHireChip])}>

        {isTooltipVisible && <HireTooltip />}

        <ClickableDiv
          ref={tooltipRef}
          onMouseDown={() => setTooltipVisible(!isTooltipVisible)}
          onClick={() => setTooltipVisible(isTooltipVisible)}
          disabled
        >
          <Draggable
            draggableId={draggableId}
            index={index}
            isDragDisabled
          >
            {(provided: any, snapshot: any) => {
              useLayoutEffect(onLoad, []);

              return (
                <HireChip
                  hire={hire}
                  assignmentInstance={assignmentInstance}
                  provided={provided}
                  snapshot={snapshot}
                  isAssigned
                />
              );
            }}
          </Draggable>
        </ClickableDiv>
      </div>
    );
  }

  // Classic assignment
  return (

    <Draggable
      draggableId={draggableId}
      index={index}
    >
      {(provided: any, snapshot: any) => {
        useLayoutEffect(onLoad, []);

        return (
          <div className={classNames([className, styles.draggableHireChip])}>
            <HireChip
              hire={hire}
              assignmentInstance={assignmentInstance}
              provided={provided}
              snapshot={snapshot}
              isAssigned={assignments && assignments.length > 0}
            />
          </div>
        );
      }}
    </Draggable>
  );
};

DraggableHireChip.defaultProps = {
  assignments: null,
  assignmentInstance: null,
  onLoad: () => {},
  className: '',
};

export default DraggableHireChip;
