// @ts-nocheck

import type { SiteType, SiteActivityItemType } from 'pages/Sites/types';

const buildCreatedActivity = (site: SiteType): SiteActivityItemType => {
  const name = site.createdBy.firstName.concat(' ', site.createdBy.lastName);

  return {
    time: site.createdAt,
    name,
    action: 'sites.activity.created_by',
    details: name,
  };
};

const buildLastUsedActivity = (site: SiteType): SiteActivityItemType => {
  return {
    time: site.lastProject.updatedAt,
    name: site.lastProject.name,
    action: 'sites.activity.last_used',
    details: `for project ${site.lastProject.name}`,
  };
};

const buildSiteActivities = (site: SiteType): Array<SiteActivityItemType> => {
  const activities = [];

  if (site) {
    activities.push(buildCreatedActivity(site));

    if (site.lastProject) {
      activities.push(buildLastUsedActivity(site));
    }
  }

  return activities;
};

export { buildSiteActivities as default };
