// @ts-nocheck

import classNames from 'classnames';
import React, { ReactNode } from 'react';

import Select, { components } from 'react-select';
import { ChevronLeft, Close } from '../../Icon';
import styles from './styles.module.scss';

export type MultiSelectOption = {
  value?: string,
  label?: string,
};

type MultiSelectDropdownProps = {
  options: Array<MultiSelectOption>,
  onChange: () => void;
  onClearValue: () => void;
  value: Array<MultiSelectOption>,
  embedded?: boolean,
  placeholderText: string,
};

type SelectedOptionProps = {
  children: ReactNode,
  value: MultiSelectOption,

  onClearValue: (param:MultiSelectOption) => void,
};

const SelectedOption = ({
  children,
  value,
  onClearValue,
}: SelectedOptionProps) => (

  <div className={styles.selectedValue}>
    {children}

    <button
      className={styles.clearButton}

      onClick={() => onClearValue(value)}

      onKeyPress={() => onClearValue(value)}
      type="button"
    >

      <Close />
    </button>
  </div>
);

const Input = (props: any) => {
  const {
    selectProps: { placeholderText },
  } = props;
  return (

    <components.Input
      className={styles.searchSelect}
      placeholder={placeholderText}
      {...props}
    />
  );
};

const MultiSelectDropdown = ({
  options,
  onChange,
  onClearValue,
  value,
  embedded,
  placeholderText,
  ...inputProps
}: MultiSelectDropdownProps) => (

  <>

    <Select
      isMulti
      classNamePrefix="react-select"
      options={options}
      styles={{

        control: (provided, state) => ({
          ...provided,
          border: embedded && !state.isFocused ? '0 !important' : 'inherit',
          height: embedded ? '100%' : 'inherit',
          backgroundColor: embedded && !state.isFocused ? 'transparent' : provided.backgroundColor,
          minHeight: '40px',
        }),

        container: (provided) => ({
          ...provided,
          height: embedded ? '100%' : 'inherit',
        }),
      }}
      components={{
        Input,
        ClearIndicator: () => null,
        IndicatorSeparator: null,
        MultiValueContainer: () => null,
        DropdownIndicator: ({
          // innerProps and selectProps come from react-select library

          innerProps, // eslint-disable-line

          selectProps, // eslint-disable-line
        }) => (
          <div

            className={classNames(styles.dropdownIndicator, {
              // eslint-disable-next-line react/prop-types
              [styles.open]: selectProps.menuIsOpen,
            })}
            {...innerProps}
          >
            <ChevronLeft />
          </div>
        ),
      }}

      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: styles.colorSelected,
          primary25: styles.colorSelectedLight,
          primary50: styles.colorSelectedMedium,
        },
      })}
      placeholder=""
      {...{
        ...inputProps,
        placeholderText,
        value,
        onChange,
      }}
    />
    {value.length > 0 && (

      <div className={styles.valueList}>
        {value.map(v => (
          <SelectedOption
            key={`selected-${v.value}`}
            value={v}
            {...{ onClearValue }}
          >
            {v.label}
          </SelectedOption>
        ))}
      </div>
    )}
  </>
);

MultiSelectDropdown.defaultProps = {
  embedded: false,
};

export default MultiSelectDropdown;
