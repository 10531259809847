// @ts-nocheck
import React, { ReactNode } from 'react';
import { Button } from 'common-ui/Button';
import styles from './styles.module.scss';

type ActionableModalProps = {
  children: ReactNode,
  onSubmit: () => void,
  onClose: () => void,
  actionText: ReactNode,
  headerText: ReactNode,
  backText?: string,
};

const ActionableModal = ({
  children,
  onSubmit,
  onClose,
  actionText,
  headerText,
  backText,
}: ActionableModalProps) => (

  <div className={styles.baseModal}>

    <div className={styles.textHeader}>{headerText}</div>
    {children}

    <div className={styles.baseModalButtons}>

      <Button text={backText || 'Cancel'} onClick={onClose} />

      <Button
        text={actionText}
        className={styles.actionButton}
        onClick={() => {
          onSubmit();
          onClose();
        }}
      />
    </div>
  </div>
);

ActionableModal.defaultProps = {
  backText: null,
};

export default ActionableModal;
