import React, {SVGProps} from 'react';

const Conflict = (props: SVGProps<SVGSVGElement>) => (

  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >

    <circle
      cx="12"
      cy="12"
      r="9"
      fill="none"
      stroke="#E2164A"
      strokeWidth="2"
    />

    <circle cx="12" cy="16" r="1" fill="#E2164A" />

    <rect x="11" y="7" width="2" height="7" rx="1" fill="#E2164A" />
  </svg>
);

export default Conflict;
