// @ts-nocheck
import React, { useState } from 'react';


import { Toggle } from '../../../components/Common/Form';
import { Search } from '../../../components/Common/Search';
import {  SortOptionType } from '../../../components/Common/Search/types';
import { Sidebar, withSidebar } from '../../../components/Sidebar';

import { default as Popup } from 'components/Common/Popup';
import { TooltipWrapper } from 'components/Common/Tooltip';
import CompanySideBar from './CompanySideBar';
import DriverStatusFilter, { DriverStatusButton } from './DriverStatusFilter';
import MarkerSideBar from './MarkerSideBar';
import styles from './styles.module.scss';
import type { CompanyWithData, IDType } from '../types';

type LiveMapProps = {
  projects: Array<CompanyWithData>,
  chosenMarkerId?: IDType,

  openMarker: (markerId?: IDType) => any,

  toggleAssignmentOpen: () => any,

  toggleAssignmentsOpen: () => any,

  currentOptions: any,

  driverStatusOptions: any,
  intl: any,

  setProjectFilterItems: (param:any) => void,
  projectFilterItems: any,

  onProjectSort: (param:any) => any,
  liveSortOptions: Array<SortOptionType>,
  disableSearch: boolean,
  hideShareButton: boolean,
  openModal:any
  closeModal:any
  disableToggleAllAssignments:any
};

export const LiveSidebarContents = (props: LiveMapProps) => {

  const { projects, toggleAssignmentsOpen, disableToggleAllAssignments } = props;

  let projectIds = [];
  const closedProjects = projects.filter(p => p.isClosed);

  if (closedProjects.length > 0) {
    projectIds = closedProjects.map(p => p.data.id);
  } else {
    projectIds = projects.map(p => p.data.id);
  }

  return (

    <div className={styles.liveSidebarContents}>

      <div className={`${styles.textHeader} ${styles.header}`}>
        Live Projects
        {!disableToggleAllAssignments && (
          <TooltipWrapper
            message="Show/Hide all Live Projects"
          >
            <Toggle
              checked={closedProjects.length > 0}

              onChange={() => toggleAssignmentsOpen(projectIds)}
              slim
            />
          </TooltipWrapper>
        )}
      </div>

      <LiveProjects {...props} />
    </div>
  );
};

LiveSidebarContents.defaultProps = {
  chosenMarkerId: null,
};

export const LiveProjects = ({
  projects,
  chosenMarkerId,
  openMarker,
  toggleAssignmentOpen,
  currentOptions,
  driverStatusOptions,
  setProjectFilterItems,
  projectFilterItems,
  onProjectSort,
  liveSortOptions,
  intl,
  openModal,
  closeModal,
  disableSearch,
  hideShareButton,
}: LiveMapProps) => {
  // Select the first sort option by default
  // TODO: Improve once sort state is fixed
  React.useEffect(() => {
    if (liveSortOptions && onProjectSort) {
      onProjectSort(liveSortOptions[0]);
    }
  }, []);


  const currentStatusFilter = projectFilterItems && projectFilterItems.find(item => item.filterType && item.filterType.value === 'driverStatus');
  const [statusSelected, setStatusSelected] = useState(currentStatusFilter && currentStatusFilter.filterValue);

  return (

    <>
      {!disableSearch && (

        <div className={styles.searchWrapper}>
          <Search
            onFiltersChange={setProjectFilterItems}
            setStatusSelected={setStatusSelected}
            currentOptions={currentOptions}
            filterItems={projectFilterItems}
            onSort={onProjectSort}
            sortBoxText={intl.formatMessage({
              id: 'live.side_bar.sort_box_text',
              defaultMessage: 'Sort Projects By',
            })}
            placeholderText={intl.formatMessage({
              id: 'live.side_bar.placeholder_text',
              defaultMessage: 'Start typing. ex. \'Client:\'...',
            })}
            clearAllText={intl.formatMessage({
              id: 'common.search.clear_all_text',
              defaultMessage: 'Clear All Filters',
            })}

            formatCreateLabel={label => (
              label
                ? intl.formatMessage({
                  id: 'common.search.label_text',
                  defaultMessage: 'Search with "{label}"',
                }, { label })
                : intl.formatMessage({
                  id: 'common.search.label_empty_text',
                  defaultMessage: 'Search with ...',
                })
            )}
            sortOptions={liveSortOptions}
            Filter={(
              <div className={styles.filterPopup}>
                <Popup
                  className={styles.popup}
                  leftAligned

                  button={({ setMenuOpen, menuOpen }) => (
                    <DriverStatusButton
                      statusSelected={currentStatusFilter ? currentStatusFilter.filterValue : null}
                      onClose={() => {
                        setStatusSelected(null);
                        setProjectFilterItems(projectFilterItems

                          .filter(item => item !== currentStatusFilter));
                      }}
                      onClick={() => setMenuOpen(!menuOpen)}
                    />

                  )}

                  body={({ setMenuOpen }) => (
                    <div className={styles.filterMenu}>
                      <DriverStatusFilter
                        currentStatusFilter={currentStatusFilter}
                        statusSelected={statusSelected}
                        setStatusSelected={setStatusSelected}
                        filterItems={projectFilterItems}
                        onFilterChange={setProjectFilterItems}
                        options={driverStatusOptions}
                        setMenuOpen={setMenuOpen}
                      />
                    </div>
                  )}
                />
              </div>
            )}
          />
        </div>
      )}

      <div>
        {projects.map(c => (
          <div key={c.data.id} className={styles.companySectionSidebar}>
            <CompanySideBar
              project={c}
              counts={c.counts}
              toggleAssignmentOpen={toggleAssignmentOpen}
              openModal={openModal}
              closeModal={closeModal}
              disableShare={c.data.lastSharedAt}
              hideShareButton={hideShareButton}
            >
              {c.markers.sort((a, b) => a.name.localeCompare(b.name)).map(d => (
                <MarkerSideBar
                  key={d.id}
                  marker={d}
                  markerId={d.id}
                  openMarker={openMarker}
                  isHighlighted={chosenMarkerId === d.id}
                />
              ))}
            </CompanySideBar>
          </div>
        ))}
      </div>
    </>
  );
};

LiveProjects.defaultProps = {
  chosenMarkerId: null,
};

type LiveSidebarProps = {
  liveSidebar: any
};

const LiveSidebar = ({ liveSidebar, ...rest }: LiveSidebarProps) => (

  <Sidebar
    sidebarStateObject={liveSidebar}
    SidebarComponent={LiveSidebarContents}
    className={styles.liveSidebar}
    {...rest}
  />
);

export default withSidebar('liveSidebar', true)(LiveSidebar);
