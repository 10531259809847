// @ts-nocheck
import React,{ReactNode} from 'react';
import { compose, withHandlers, HOC } from 'recompose';
import { Close } from 'common-ui/Icon';
import styles from './styles.module.scss';




type onClickClearProps = {
  id?: string | number,

  onClickClear: (param?: string |number) => any
};

type SelectedTermOptionUIProps = {

  onClickClear: () => any,
  children?: ReactNode
};

type SelectedTermOptionHOCProps = {
  id?: string | number,

  onClickClear: (param:string | number) => any,
  children?: ReactNode
};

const SelectedTermOptionUI = ({
  onClickClear,
  children,
}: SelectedTermOptionUIProps) => (

  <div className={styles.selectedTerm}>

    <div className={styles.selectedText}>
      {children}
    </div>

    <button
      className={styles.closeIcon}
      onClick={onClickClear}
      onKeyPress={onClickClear}
      type="button"
    >

      <Close />
    </button>
  </div>
);

SelectedTermOptionUI.defaultProps = {
  children: null,
};

const SelectedTermOptionHOC: HOC<any, SelectedTermOptionHOCProps> = compose(
  withHandlers({
    onClickClear: ({ id, onClickClear }: onClickClearProps) => () => {

      onClickClear(id);
    },
  }),
);

export default SelectedTermOptionHOC(SelectedTermOptionUI);
