// @ts-nocheck
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import { useCompanyContext } from 'utils/Hooks';

const useGetPathSearchParams = (pathname: string) => {
  const location = useLocation();
  const storage = window.localStorage;
  storage.setItem(location.pathname, location.search);

  const { selectedCompanyID } = useCompanyContext();

  const storageItem = storage.getItem(pathname);

  const { company } = queryString.parse(storageItem);

  const searchParams = company === selectedCompanyID
    ? storageItem
    : queryString.stringify({
      company: selectedCompanyID,
    });

  return searchParams;
};

export default useGetPathSearchParams;
