// @ts-nocheck
import { connect } from 'formik';
import React, { ReactNode } from 'react';

import { FormField, TextArea } from '..';

type NestedName = {
  arrayName: string,
  index: number,
  name: string,
};

type FormikTextAreaProps = {
  formik: any,
  label: string,
  required?: boolean,
  name: string,
  embedded?: boolean,
  readOnly?: boolean,
  readOnlyValue?: ReactNode,
  error?: string,
  nestedName?: NestedName,
  hideError?: boolean,
};

const fieldArrayValidatorHelper = (touched: any, errors: any, nestedName: any) => {
  touched = touched[nestedName.arrayName];

  if (!Array.isArray(touched) || !Array.isArray(errors)) {
    return '';
  }

  if (!touched[nestedName.index] || !errors[nestedName.index]) {
    return '';
  }

  if (nestedName.name in touched[nestedName.index]) {
    if (touched[nestedName.index][nestedName.name]) {
      if (nestedName.name in errors[nestedName.index]) {
        return errors[nestedName.index][nestedName.name];
      }
    }
  }

  return '';
};

const FormikTextArea = ({
  formik,
  label,
  required,
  name,
  embedded,
  readOnly,
  readOnlyValue,
  error,
  nestedName,
  hideError,
  ...inputProps
}: FormikTextAreaProps) => (

  <FormField
    label={label}
    required={required}
    error={
      !nestedName
        ? formik.touched[name] && formik.errors[name]
        : fieldArrayValidatorHelper(formik.touched, formik.errors, nestedName)
    }
    embedded={embedded}
    hideError={hideError}
  >
    {!readOnly ? (

      <TextArea

        value={
          !nestedName
            ? formik.values[name]
            : formik.values[nestedName.arrayName][nestedName.index][
              nestedName.name
            ]
        }
        onChange={(value) => formik.setFieldValue(name, value)}
        onBlur={() => formik.setFieldTouched(name)}
        name={name}
        embedded={embedded}
        {...inputProps}
      />
    ) : (

      <>{readOnlyValue || formik.values[name] || '-'}</>
    )}
  </FormField>
);

FormikTextArea.defaultProps = {
  required: false,
  embedded: false,
  readOnly: false,
  readOnlyValue: '',
  error: '',
  nestedName: null,
  hideError: false,
};

export default connect(FormikTextArea);
