// @ts-nocheck
import { useMutation, gql } from '@apollo/client';
import { useCallback } from 'react';

import { WHO_AM_I_QUERY } from '../../graphql/user.graphql';
import type { Company } from 'common-ui/types';

const UPDATE_COMPANY_MUTATION = gql`
  mutation updateCompany($id: ID!, $input: CompanyInput!) {
    updateCompany(id: $id, input: $input) {
      id
      name
      address1
      timezone
      measurementType
      loadTracking
      requestTimesheets
      timeOnSiteGoalMinutes
      timesheetDefaultAdjustments {
        id
        type
        minutes
        timing
        deleted
      }
      logo {
        getURL
        filename
      }
      driverMinimumScheduledMinutes
      driverMaximumScheduledMinutes
      defaultForwardingEmails
    }
  }
`;

export const useUpdateCompany = (company: Company) => {
  const [updateCompanyClient] = useMutation(UPDATE_COMPANY_MUTATION);
  return useCallback(
    (values: Company) => updateCompanyClient({
      variables: {
        id: company.id,
        input: {
          ...company,
          ...values,
          timesheetDefaultAdjustments: values.timesheetDefaultAdjustments.map(
            (t: any) => ({
              type: t.type,
              minutes: t.minutes,
              id: parseInt(t.id, 10),
              timing: t.timing,
              deleted: t.deleted || false
            }),
          ),
          logo: values.logo && values.logo.getURL,
        },
      },
      refetchQueries: [{ query: WHO_AM_I_QUERY }],
    }),
    [updateCompanyClient, company],
  );
};

export default useUpdateCompany;
