// @ts-nocheck
import {Form, withFormik} from 'formik';
import React, {useEffect} from 'react';

// HOC
import {compose, withHandlers} from 'recompose';


import {sendErrorAlert} from '../../apolloClient/client';
import {post} from '../../utils/axios';
import Analytics from 'common-ui/Analytics/SegmentAnalytics';
import {Events, Actions} from 'common-ui/Analytics/types';

// Components - Quark
import {Button} from 'common-ui/Button';
import ID from 'common-ui/ConsistentIDs';
import {ChevronRight} from 'common-ui/Icon';


import {FormikTextInput} from 'components/Common/Form';


import {formatPhoneNumber} from 'utils/format';

const RequestCodeForm = () => {
  useEffect(() => {
    Analytics.pushEvent(Events.login, Actions.startLogin);
    Analytics.startTiming(Actions.startLogin);
  }, []);
  return (

    <Form>

      <FormikTextInput
        label="Phone Number"
        type="tel"
        name="phoneNumber"
        placeholder="ex. 416 555 5555"
        autoFocus
        id={ID.create(ID.Areas.Login, ID.Types.Input, ID.Labels.PhoneNumber)}
      />

      <Button
        type="submit"
        styleName="primary"
        text="Get verification code"

        icon={<ChevronRight />}
        rightIcon
        fullWidth
        id={ID.create(ID.Areas.Login, ID.Types.Button, ID.Labels.VerificationCode)}
      />
    </Form>
  );
};

const formConfig = {
  mapPropsToValues: () => ({ phoneNumber: '' }),
  validate: (values: any) => {
    const errors = {};
    if (!/^[\d()\-+\s]+$/.test(values.phoneNumber)) {
      errors.phoneNumber = 'Must be valid phone number';
    } else if ((values.phoneNumber && values.phoneNumber.replace(/\D/g, '').length >= 12)) {
      errors.phoneNumber = 'Too long. Must be valid phone number';
    } else if (values.phoneNumber && values.phoneNumber.replace(/\D/g, '').length < 6) {
      errors.phoneNumber = 'Too short. Must be valid phone number';
    }
    return errors;
  },
  handleSubmit: ({
    phoneNumber
  }: any, {
    props,
    setErrors,
    setSubmitting
  }: any) => {
    setSubmitting(true);
    props
      .requestLoginCode(phoneNumber)
      .then(() => {
        props.onSuccess();
      })
      .catch((error: any) => {
        setSubmitting(false);

        if (error.response && error.response.data) {
          const {
            response: {
              status,
              data,
            },
          } = error;

          Analytics.pushEvent(Events.login, Actions.loginFailure, error.response && error.response.data.toString());

          sendErrorAlert({
            statusCode: status,
            result: data,
          }, {
            operationName: 'setClientAlert',
          });

          if (data && data.msg) {
            setErrors({ phoneNumber: data.msg });
          } else {
            setErrors({ phoneNumber: data.toString() });
          }
        }
      });
  },
};

export default compose(
  withHandlers({
    requestLoginCode: () => (phoneNumber: any) => {
      const params = new URLSearchParams();
      params.append('phone_number', formatPhoneNumber(phoneNumber));
      params.append('source', 'dispatcher_app');
      return post('token/code', params);
    },
  }),
  withFormik(formConfig),
)(RequestCodeForm);
