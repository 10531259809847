// @ts-nocheck
import classNames from 'classnames';
import React, { useRef, useEffect } from 'react';
import ID from 'common-ui/ConsistentIDs';
import styles from './styles.module.scss';
import { OverlaySidebarPropTypes } from './types';
/*
  OverlaySidebar contains both the "Pusher" (the container that the sidebar overlaps)
  and the Sidebar itself.

  Pass the contents of the Pusher as children to this component, and
  specify the component to use as the Sidebar via the SidebarComponent prop
*/
const OverlaySidebar = ({
  SidebarComponent,
  sidebarContent,
  sidebarStateObject,
  children,
  ...rest
}: OverlaySidebarPropTypes) => {
  const componentRef = useRef();

  useEffect(() => {
    if (!sidebarStateObject.visible) {
      componentRef?.current?.ref?.current?.scrollTo({ top: 0, left: 0 });
    }
  }, [sidebarStateObject.visible]);

  return (
    <div className={classNames('pushable', styles.overlaySidebarPushable)}>
      <div
        id={ID.create(ID.Areas.General, ID.Types.ReadOnly, ID.Labels.ActivityStreamSidebar)}
        className={classNames(
          styles.sidebar,
          'ui',
          'overlay',
          'right',
          'wide',
          { visible: sidebarStateObject.visible },
          { animating: !sidebarStateObject.visible },
          'sidebar',
        )}
        ref={componentRef}
      >
        {sidebarStateObject.visible && SidebarComponent && (
          // Legacy way of rendering sidebar using sidebarStateObject HOC
          <SidebarComponent visible={sidebarStateObject.visible} {...rest} />
        )}
        {sidebarContent}
      </div>

      <div className={classNames('pusher', styles.overlaySidebarPusher)}>{children}</div>
    </div>
  );
};

export default OverlaySidebar;
