import * as React from 'react';


const SiteInactiveIcon = (props: any) => <svg
  width={41}
  height={41}
  xmlns="http://www.w3.org/2000/svg"
  xmlnsXlink="http://www.w3.org/1999/xlink"
  {...props}
>

  <defs>

    <filter
      x="-48%"
      y="-48%"
      width="196%"
      height="196%"
      filterUnits="objectBoundingBox"
      id="b"
    >

      <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />

      <feGaussianBlur
        stdDeviation={4}
        in="shadowOffsetOuter1"
        result="shadowBlurOuter1"
      />

      <feColorMatrix
        values="0 0 0 0 0.0629116815 0 0 0 0 0.436385899 0 0 0 0 0.931867074 0 0 0 0.256637893 0"
        in="shadowBlurOuter1"
      />
    </filter>

    <circle id="a" cx={12.5} cy={12.5} r={12.5} />

    <path
      d="m1.409 1.805 9.181 2.582c.375.106.68.508.68.895v.706a.98.98 0 0 1-.68.894L1.409 9.465v3.923a.7.7 0 0 1-.705.699.707.707 0 0 1-.704-.699V.698A.7.7 0 0 1 .704 0c.39 0 .705.32.705.699v1.106Zm0 1.471.012 4.722 8.44-2.36-8.452-2.362Z"
      id="d"
    />
  </defs>

  <g fill="none" fillRule="evenodd">

    <g transform="translate(8 8)">

      <mask id="c" fill="#fff">

        <use xlinkHref="#a" />
      </mask>

      <use fill="#000" filter="url(#b)" xlinkHref="#a" />

      <use fill="#9B9B9B" xlinkHref="#a" />

      <g mask="url(#c)" fill="#9B9B9B">

        <path d="M0 0h25v25H0z" />
      </g>
    </g>

    <g transform="translate(15.522 13.409)">

      <mask id="e" fill="#fff">

        <use xlinkHref="#d" />
      </mask>

      <use fill="#4D4D4D" xlinkHref="#d" />

      <g mask="url(#e)" fill="#FFF">

        <path d="M-6.34-1.409h22v18h-22z" />
      </g>
    </g>
  </g>
</svg>;

export default SiteInactiveIcon;
