import {get, xorWith, isEqual} from 'lodash';
import {useRef, useCallback, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import usePageContext from '../Hooks/usePageContext';
import {useWhoAmI} from 'common-ui/Hooks';
import {useCompanyContext} from 'utils/Hooks/index';
import {EventType, Events, ActionType} from './types';
export const AnalyticsController = {
  trackClick: (action: ActionType, label?: string) => {
    if (window.analytics) {
      window.analytics.track(Events.buttonClick, {
        action,
        label: label || null,
      });
    }
  },
  pushEvent: (event: EventType, action: ActionType, label?: string) => {
    if (window.analytics) {
      window.analytics.track(event, {
        action,
        label: label || null,
      });
    }
  },
  startTiming: (action: ActionType) => {
    if (window.analytics) {
      window.analytics.track(action, {
        event: 'started',
        time: Date.now(),
      });
    }
  },
  stopTiming: (category: string, action: ActionType) => {
    if (window.analytics) {
      window.analytics.track(action, {
        event: 'stopped',
        timingCategory: category,
        timingVariable: action,
        timingValue: 0, // Timing is retrieved using previously pushed data layer variables
      });
    }
  },

  stopTimingWithValue: (category: string, action: ActionType, elapsedTime: number) => {
    if (elapsedTime && elapsedTime > 0 && elapsedTime < 1800000) {
      if (window.analytics) {
        window.analytics.track(action, {
          event: 'stopped',
          timingCategory: category,
          timingVariable: action,
          timingValue: elapsedTime,
        });
      }
    }
  },
};

export const useSegmentAnalytics = () => {
  const {selectedCompany} = useCompanyContext();
  const [user] = useWhoAmI();

  const location = useLocation();

  const identify = useCallback(() => {
    const {id, firstName, lastName, email, phoneNumber} = user;
    const company = {
      id: get(selectedCompany, 'id', ''),
      name: get(selectedCompany, 'name', ''),
    };

    if (window.analytics && id && company.id) {
      const userData = {
        name: `${firstName} ${lastName}`,
        email,
        phone: phoneNumber,
        company,
      };

      // set user identifier
      window.analytics.identify(user.id, userData);

      // group by company
      window.analytics.group(company.id, company);
    }
  }, [window.analytics, user?.id, get(selectedCompany, 'id')]);

  useEffect(() => {
    identify();
  }, [identify]);

  useEffect(() => {
    if (window.analytics) {
      window.analytics.page(location.pathname);
    }
  }, [window.analytics, location?.pathname]);
};

interface FieldDto {
  [key: string]: any;
}
export const useTrackForm = (formName: string) => {
  const fieldTimes = useRef({});

  const onFocus = (fieldName: string) => {
    const fieldTimesMap: FieldDto = fieldTimes.current || {};

    if (fieldName && !fieldTimesMap[fieldName]) fieldTimesMap[fieldName] = Date.now();
    fieldTimes.current = fieldTimesMap;
  };

  const onBlur = (fieldName: string) => {
    const fieldTimesMap: FieldDto = fieldTimes.current || {};

    const elapsedTime = Date.now() - fieldTimesMap[fieldName];
    if (elapsedTime && elapsedTime > 0 && elapsedTime < 1800000) {
      if (window.analytics) {
        window.analytics.track(Events.fieldTiming, {
          timingCategory: `${formName} Form Field Timing`,
          timingVariable: fieldName,
          timingValue: elapsedTime,
        });
      }
    }

    delete fieldTimesMap[fieldName];
  };

  return [onFocus, onBlur];
};

export const useTrackFilters = (lastSavedFilters: any, currentFilters: any) => {
  const { pageID } = usePageContext();

  return useCallback(() => {
    const changedFilters = xorWith(lastSavedFilters, currentFilters, isEqual);
    // We need to ignore filters that were removed since we only want to track filters added
    const addedFilters = changedFilters.filter((c: any) => c.value !== '' && !lastSavedFilters.includes(c));
    // Send Analytics events here
    addedFilters.forEach((f: any) => {
      const action = `${pageID}-${f.field}` as unknown as ActionType;

      AnalyticsController.pushEvent(Events.filterUpdate, action);
    });
  }, [pageID, lastSavedFilters, currentFilters, AnalyticsController]);
};

export default AnalyticsController;
