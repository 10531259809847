export const measurementTypes = {
  METRIC: 'metric',
  IMPERIAL: 'imperial',
};

// convert metres number into feet
export const metresToFeet = (radius: number) => {
  return radius / 0.3048;
};

// convert feet into metres
export const feetToMetres = (radius: number) => {
  return radius * 0.3048;
};
