declare global {
  interface Window {
    analytics: any;
  }
}

export interface TrackOptionsDto {
  author?: string;
  subtitle?: string;
  title: string;
}

export interface TrackDto {
  group: string; // may be replaced to enum list of allowed values
  options: TrackOptionsDto;
}

export interface CompanyDto {
  id?: string;
  name?: string;
}
export interface UserDto {
  company: CompanyDto,
  id: string;
  email: string;
  name: string;
  phone: string;
}

export enum Events {
  buttonClick = 'buttonClick',
  fieldTiming = 'fieldTiming',
  formTiming = 'formTiming',
  filterUpdate = 'filterUpdate',
  login = 'login',
  formChange = 'formChange',
  dragAndDrop = 'dragAndDrop',
  sortUpdate = 'sortUpdate',
}


export type EventType = `${Events}`;

export enum Actions {
  newProjectClick =  'newProjectClick',
  editProjectClick =  'editProjectClick',
  viewProjectClick =  'viewProjectClick',
  copyProjectClick =  'copyProjectClick',
  copyAllProjectClick =  'copyAllProjectClick',
  copyRequestClick =  'copyRequestClick',
  newSmartProjectClick =  'newSmartProjectClick',
  sidebarToggleClick =  'sidebarToggleClick',
  filterChange =  'filterChange',
  sortChange =  'sortChange',
  filterUpdate =  'filterUpdate',
  removeHireClick =  'removeHireClick',
  removeHireConfirmClick =  'removeHireConfirmClick',
  cancelAssignmentsClick =  'cancelAssignmentsClick',
  cancelAssignmentsConfirmClick =  'cancelAssignmentsConfirmClick',
  addAssignmentClick =  'addAssignmentClick',
  selectAllProjectsClick =  'selectAllProjectsClick',
  editAssignmentClick =  'editAssignmentClick',
  hiresListAllClick =  'hiresListAllClick',
  createNewProject =  'createNewProject',
  updateProject =  'updateProject',
  openSubcontractorModalClick =  'openSubcontractorModalClick',
  createNewSmartProject =  'createNewSmartProject',
  updateAssignment =  'updateAssignment',
  bulkAssignDriversModalClick =  'Bulk Assign',
  startLogin =  'startLogin',
  startVerifyCode =  'startVerifyCode',
  loginSuccess =  'loginSuccess',
  loginFailure =  'loginFailure',
  projectsDownload =  'projectsDownload',
  historyDownload =  'historyDownload',
  bulkEditProjectsClick =  'bulkEditProjectsClick',
  historyDownloadClick =  'historyDownloadClick',
  searchHistoryDownloadClick =  'searchHistoryDownloadClick',
  searchDispatchDownloadClick =  'searchDispatchDownloadClick',
  viewActivityStream =  'viewActivityStream',
  viewAssignmentActivityStream =  'viewAssignmentActivityStream',
  selectAllTicketsClick =  'selectAllTicketsClick',
  downloadTicketClick =  'downloadTicketClick',
  removeSignoffClick =  'removeSignoffClick',
  ticketSignoffClick =  'ticketSignoffClick',
  removeTicketClick =  'removeTicketClick',
  detachAttachment =  'detachAttachment',
  acceptEstimatedDuration =  'acceptEstimatedDuration',
  declineEstimatedDuration =  'declineEstimatedDuration',
  viewDriverRoute =  'viewDriverRoute',
  dispatchViewScheduleClick =  'dispatchViewScheduleClick',
  scheduleViewApplyFilterClick =  'scheduleViewApplyFilterClick',
  scheduleViewDragAndDropEdit =  'scheduleViewDragAndDropEdit',
  scheduleViewEditAssignmentClick =  'scheduleViewEditAssignmentClick',
}

export type ActionType = `${Actions}`;
