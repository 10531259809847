import * as React from 'react';


const LostConnectionIcon = (props: any) => <svg
  width={47}
  height={47}
  xmlns="http://www.w3.org/2000/svg"
  xmlnsXlink="http://www.w3.org/1999/xlink"
  {...props}
>

  <defs>

    <filter
      x="-52.2%"
      y="-52.2%"
      width="204.3%"
      height="204.3%"
      filterUnits="objectBoundingBox"
      id="a"
    >

      <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />

      <feGaussianBlur
        stdDeviation={3}
        in="shadowOffsetOuter1"
        result="shadowBlurOuter1"
      />

      <feColorMatrix
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
        in="shadowBlurOuter1"
        result="shadowMatrixOuter1"
      />

      <feMerge>

        <feMergeNode in="shadowMatrixOuter1" />

        <feMergeNode in="SourceGraphic" />
      </feMerge>
    </filter>

    <filter
      x="-84.4%"
      y="-79.4%"
      width="275%"
      height="264.7%"
      filterUnits="objectBoundingBox"
      id="d"
    >

      <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />

      <feGaussianBlur
        stdDeviation={2.5}
        in="shadowOffsetOuter1"
        result="shadowBlurOuter1"
      />

      <feColorMatrix
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
        in="shadowBlurOuter1"
        result="shadowMatrixOuter1"
      />

      <feMerge>

        <feMergeNode in="shadowMatrixOuter1" />

        <feMergeNode in="SourceGraphic" />
      </feMerge>
    </filter>

    <ellipse id="b" cx={11.831} cy={11.5} rx={11} ry={11.5} />
  </defs>

  <g fill="none" fillRule="evenodd">

    <g filter="url(#a)" transform="translate(12 12)">

      <mask id="c" fill="#fff">

        <use xlinkHref="#b" />
      </mask>

      <use fill="#46A4FF" xlinkHref="#b" />

      <g mask="url(#c)" fill="#000">

        <path d="M-12-11.5h48v45h-48z" />
      </g>
    </g>

    <g filter="url(#d)" transform="translate(16 1)" fill="#FFF">

      <path d="M.916 5.262C2.486 1.388 5.523 0 7.998 0c2.474 0 5.152.5 7.081 5.262 1.93 4.762 1.534 11.437 0 12.216-1.534.778-.22-2.307-1.598-2.407-1.379-.099-.945 2.147-2.028 2.147-.787 0-.787-2.094-2.381-2.147-1.594-.052-2.013 2.237-3.37 2.147-1.356-.09-.532-2.147-2.074-2.147-1.542 0-1.896 3.314-2.712 2.407-.816-.908-1.57-8.342 0-12.216Zm9.51 2.086a1.48 1.48 0 0 0 1.488-1.47 1.48 1.48 0 0 0-1.489-1.47 1.48 1.48 0 0 0-1.49 1.47c0 .812.668 1.47 1.49 1.47Zm-2.235 2.94a.74.74 0 0 0 .745-.736.74.74 0 0 0-.745-.735.74.74 0 0 0-.744.735.74.74 0 0 0 .744.735Zm-3.723-2.94a1.48 1.48 0 0 0 1.49-1.47 1.48 1.48 0 0 0-1.49-1.47 1.48 1.48 0 0 0-1.49 1.47c0 .812.667 1.47 1.49 1.47Z" />
    </g>
  </g>
</svg>;

export default LostConnectionIcon;
