// @ts-nocheck
import { useQuery } from '@apollo/client';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { GET_ALERT } from 'apolloClient/clientState';
import ID from 'common-ui/ConsistentIDs';
import styles from './styles.module.scss';
import type { AlertType, parseAlertDataType } from '../../types';


const AlertContent = ({ alert } : {alert: AlertType}) => {
  if (alert.alertType === 'errorLogin') {
    return (

      <FormattedMessage
        id="messages.error.login_failed"
        defaultMessage="New To Tread? {link} to get Started."
        values={{
          link: (
            <a target="_blank" rel="noopener noreferrer" href="https://bit.ly/newtotread">
              <FormattedMessage
                id="messages.error.login_failed.contact_us"
                defaultMessage=" contact us "
              />
            </a>
          ),
        }}
      />
    );
  }

  if (alert.intlId) {
    return (

      <FormattedMessage
        id={alert.intlId}
        defaultMessage={alert.message}

        values={alert.messageInfo}
      />
    );
  }

  if (alert.alertType === 'error' && !alert.message) {
    return (

      <FormattedMessage
        id="messages.error.generic"
        defaultMessage="An error occurred. Please try again."
      />
    );
  }


  return <>{alert.message}</>;
};

export const parseAlertData = (alert: parseAlertDataType) => {
  let messageInfo;

  try {

    messageInfo = JSON.parse(alert ? alert.messageInfo : undefined);
  } catch (e) {
    // no-op
  }

  return { ...alert, messageInfo };
};

const useGetAlert = () => {
  const { data, loading, error } = useQuery(GET_ALERT);

  return useMemo(() => ((!loading && !error && data)
    ? parseAlertData(data.clientAlert)
    : { status: 0, message: '' }));
};

const AlertToast = () => {
  const alert = useGetAlert();

  if (!alert) return null;

  return (

    <div
      className={styles.errorToast}
      id={ID.create(ID.Areas.General, ID.Types.Alert, ID.Labels.ErrorMessage)}
    >

      <AlertContent alert={alert} />
    </div>
  );
};

export default AlertToast;
