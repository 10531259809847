// @ts-nocheck
import React from 'react';

import { Conflict, Warning } from 'common-ui/Icon';

import { TooltipWrapper } from 'components/Common/Tooltip';
import styles from './styles.module.scss';


type WarningHoverIconProps = {
  message: string,

  exceedsBenchmark?: bool,
};

const WarningHoverIcon = ({ message, exceedsBenchmark }:WarningHoverIconProps) => (

  <span className={styles.hoverIconContainer}>

    <TooltipWrapper
      message={message}
      className={styles.titleInfoIcon}
    >

      {exceedsBenchmark ? <Conflict /> : <Warning /> }
    </TooltipWrapper>
  </span>
);

WarningHoverIcon.defaultProps = {
  exceedsBenchmark: false,
};

export default WarningHoverIcon;
