// @ts-nocheck
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { useUpdateSite } from '../../graphql';
import { EditFormPropTypes } from '../../types';
import { geofenceTypes } from '../../utils/site.utils';

import BaseForm from './BaseForm';

const EditForm = ({ site, showHeading = true, ...props }: EditFormPropTypes) => {
  const {
    id,
    companyId,
    status,
    name,
    location,
    radius,
    notes,
    siteType,
    products,
    geofenceType,
    pointLocation,
    externalID,
  } = site;
  const pointLocationFromCoords =
    geofenceType === geofenceTypes.CIRCLE && !pointLocation
      ? {
          longitude: site.coordinates[0] && site.coordinates[0].longitude,

          latitude: site.coordinates[0] && site.coordinates[0].latitude,
        }
      : null;
  const updateSite = useUpdateSite(id);
  const geoPoints =
    geofenceType === geofenceTypes.POLYGON
      ? site.coordinates.map((c: any) => [c.longitude, c.latitude])
      : [];
  return (
    <BaseForm
      siteID={id}
      site={site}
      showHeading={showHeading}
      title={<FormattedMessage id='sites.edit.title' defaultMessage='Update Site' />}
      submitText={<FormattedMessage id='sites.update.submit' defaultMessage='Update Site' />}
      initialValues={{
        companyId: companyId.toString(),
        status,
        name,
        location,
        radius: radius.toFixed(0),
        pointLocation: pointLocationFromCoords || pointLocation,
        notes,
        siteType,
        products,
        geofenceType,
        geoPoints,
        externalID,
      }}
      onSubmit={updateSite}
      {...{
        ...props,
      }}
    />
  );
};

export default EditForm;
