// @ts-nocheck
import {graphql} from '@apollo/client/react/hoc';
import React from 'react';
import Helmet from 'react-helmet';
import {Redirect} from 'react-router-dom';

// Higher Order Components
import {
  compose,
  withStateHandlers,
  mapProps,
  branch,
  renderComponent,
} from 'recompose';

// Components
import {GET_ALERT} from '../../apolloClient/clientState';
import {
  AlertToast,
  parseAlertData,
} from '../MainContainer/components/AlertToast';
import {LogoYellow, Triangles} from 'common-ui/Icon';

import {WHO_AM_I_QUERY} from 'pages/Settings/graphql';
import RequestCodeForm from './RequestCodeForm';
import VerifyCodeForm from './VerifyCodeForm';
import styles from './styles.module.scss';

const Login = ({form, handleLogin, handleBack, alert}:any) => (
  <>
    <div className={styles.triangles}>
      <Triangles />
    </div>
    <div className={styles.backgroundColorContainer}>
      <AlertToast alert={alert} />
      <div className={styles.container}>
        <div className={styles.largeLogo}>
          <LogoYellow />
        </div>
        <div className={styles.loginCard}>
          <Helmet title="Login" />

          {form === 'request' ? (
            <div className={styles.padY}>
              <div className={styles.padY}>
                <div className={styles.textHeader}>Your Account</div>
                <div className={styles.textBody}>
                  Enter your phone number below to get started!
                </div>
              </div>
              <RequestCodeForm onSuccess={handleLogin} />
            </div>
          ) : (
            <div className={styles.padY}>
              <div className={styles.padY}>
                <div className={styles.textHeader}>Verification Code</div>
                <div className={styles.textBody}>
                  Check your text message for a 5-digit number.
                </div>
              </div>
              <VerifyCodeForm {...{handleBack}} />
            </div>
          )}
        </div>
      </div>
    </div>
  </>
);

export default compose(
  withStateHandlers(
    {form: 'request'},
    {
      handleLogin: () => () => ({form: 'verify'}),
      handleBack: () => () => ({form: 'request'}),
    }
  ),
  graphql(GET_ALERT),
  mapProps(({data, ...props}) => ({
    alert:
      !data.loading && !data.error
        ? parseAlertData(data.clientAlert)
        : {status: 0, message: ''},
    ...props,
  })),
  // This metadata query must not use the cache
  // or we'll redirect to /projects when trying to logout (due to cached account metadata)
  graphql(WHO_AM_I_QUERY, {
    options: {
      fetchPolicy: 'no-cache',
    },
  }),
  branch(
    (props) => props.data && props.data.whoami && props.data.whoami.id > 0,
    renderComponent(() => <Redirect to="/projects" />)
  )
)(Login);
