// @ts-nocheck
import {
  compose,
  HOC,
} from 'recompose';


import { withStateField } from 'utils/HOC';


type withSidebarHOCType = (param:string | boolean, param?:boolean) => HOC<any, any>;

/*
  This HOC is a helper meant to be used with
  OverlaySidebar and Sidebar

  It generates a state shape containing a visibilty and an action field,
  along with their handlers

  An example shape:
  projectDetailsSidebar: {
    visible,
    action,
    setVisible,
    setAction,
  }

  This allows you to explicitly reference the sidebar's state/updaters
  as a property of its state object, instead of having to use ugly strings
*/

const withSidebar: withSidebarHOCType = (
  sidebarName,
  defaultVisibilty = false,
  defaultAction = 'new',
) => compose(
  withStateField(sidebarName, 'visible', defaultVisibilty),
  withStateField(sidebarName, 'action', defaultAction),
);

export default withSidebar;
