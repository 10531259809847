// @ts-nocheck
/* global google */
/* eslint-disable camelcase */
// expects parent to import this
// <script src="https://maps.googleapis.com/maps/api/js?key=[API_KEY]&libraries=places"></script>
import _ from 'lodash';
import {
  useEffect, useRef, useState, useCallback,
} from 'react';
import { useInterval } from 'utils/Hooks';

export const createGoogleAutocomplete = ({
  inputRef,
  options,
  onPlaceChange,
}) => {
  const autocomplete = new google.maps.places.Autocomplete(inputRef, options);

  autocomplete.setTypes(['address']);

  const infowindow = new window.google.maps.InfoWindow();

  autocomplete.addListener('place_changed', () => {
    infowindow.close();
    const place = autocomplete.getPlace();
    onPlaceChange(place);
  });

  return autocomplete;
};
export const parseGoogleAddressComponent = (place) => {
  const {
    geometry,
    formatted_address: formattedAddress,
    address_components: addressComponents,
  } = place;

  const address = {
    fullAddress: formattedAddress || '',
  };

  if (!!geometry && !!geometry.location) {
    address.lat = geometry.location.lat();
    address.lgn = geometry.location.lng();
  }

  if (addressComponents) {
    const map = {
      street_number: 'street',
      route: 'street',
      locality: 'city',
      administrative_area_level_1: 'state',
      postal_code: 'zip',
      country: 'country',
    };
    for (let ii = 0; ii < addressComponents.length; ii += 1) {
      _.forOwn(map, (value, key) => {
        const xx = key;

        if (addressComponents[ii].types.indexOf(xx) > -1) {
          if (
            (xx === 'street_number' || xx === 'route')
            && address.street !== undefined
          ) {
            if (xx === 'street_number') {
              address.street = `${addressComponents[ii].short_name} ${
                address.street
              }`;
            } else if (xx === 'route') {
              address.street = `${address.street} ${
                addressComponents[ii].short_name
              }`;
            }
          } else if (xx === 'locality') {
            address[value] = addressComponents[ii].long_name;
          } else {
            address[value] = addressComponents[ii].short_name;
          }
        }
      });
    }
  }

  return address;
};

export const initGoogleAutocomplete = () => {
  // Need to instantiate this just for autocomplete calls to work
  const service = new google.maps.places.AutocompleteService();
  return service;
};

export const initGooglePlaceService = () => {
  const service = new google.maps.places.PlacesService(document.createElement('div'));
  return service;
};

// This default bounds is the area from
// the northernmost part of Ontario to the southern part of the US
export const locationBiasBounds = {
  north: 55.123093,
  east: -69.798055,
  south: 24.671722,
  west: -124.316307,
};

export const autocompleteService = initGoogleAutocomplete();
export const placesService = initGooglePlaceService();

export const getPlaceAutocomplete = ({
  inputValue,
  setSuggestions,
  sessionToken,
}) => {
  const request = {
    input: inputValue,
    types: ['geocode'],
    bounds: locationBiasBounds,
    sessionToken,
  };
  const suggestions = [];
  if (!inputValue || inputValue.length <= 0) {
    return;
  }

  if (autocompleteService) {
    // TODO Only get these predictions if the input element is focused!!
    // Also debounce these requests
    const getAddresses = new Promise((resolve) => {
      autocompleteService.getPlacePredictions(request, (results, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          results.forEach((r, i) => {
            // Set the default value to the regular address
            let fullAddress = r.description;

            // Try to get details about the address to retrive a more accurate/specific location.
            // If a more accurate full address can't be found, use the default.
            const getDetails = new Promise((resolveDetails) => {
              placesService.getDetails({
                placeId: r.place_id,
                fields: ['formatted_address', 'types'],
              }, (details, detailsStatus) => {
                if (detailsStatus === google.maps.places.PlacesServiceStatus.OK
              && details.types.includes('street_address')) {
                  fullAddress = details.formatted_address;
                }

                resolveDetails();
              });
            });

            // Once the get details promise has completed, add the address to the suggestions
            getDetails.finally(() => {
              suggestions.push({
                value: fullAddress,
                label: fullAddress,
                type: 'autocomplete_address',
              });

              // Once we've iterated through all the results, resolve the parent promise
              if (i === results.length - 1) {
                resolve();
              }
            });
          });
        }
      });
    });

    // Once the parent promise is resolve, set the retrived suggestions
    // to display to the user
    getAddresses.finally(() => {
      setSuggestions(suggestions);
    });
  }
};

export const useAutocompleteSessionToken = ({ setSessionToken }) => {
  // Use a session token, and reset after 3 minutes
  const sessionTokenTimeout = useRef(0);
  return useEffect(() => {
    sessionTokenTimeout.current = window.setInterval(
      () => {
        setSessionToken(new google.maps.places.AutocompleteSessionToken());
      },
      180000,
    );
    return () => {
      clearInterval(sessionTokenTimeout.current);
    };
  }, []);
};

export const useAddressAutocomplete = (inputValue) => {
  const [suggestions, setSuggestions] = useState([]);
  const [sessionToken, setSessionToken] = useState(
    new google.maps.places.AutocompleteSessionToken(),
  );
  useAutocompleteSessionToken({
    setSessionToken,
  });
  // Calls autocomplete every 250ms if
  // the input has not changed.
  useInterval(
    () => {
      getPlaceAutocomplete({
        inputValue,
        setSuggestions,
        sessionToken,
      });
    },
    250,
    [inputValue],
  );
  return [suggestions, setSuggestions];
};

export const useGeocoderService = () => {
  const service = new google.maps.Geocoder();
  const getPlaceDetails = useCallback((address, callback) => {
    // TODO maybe using reference is better? Is it equivalent to placeID?
    const req = {
      address,
    };
    service.geocode(req, callback);
  }, [service]);
  return getPlaceDetails;
};
