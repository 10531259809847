// @ts-nocheck

import React from 'react';

import { Edit } from 'common-ui/Icon';
import { FormField } from 'components/Common/Form';

import SitesAddressInput from 'components/Common/Form/AddressInput/SitesAddressInput';

import { SiteDetail } from 'components/Common/ProjectCard/components';

import { getSiteAddress } from 'pages/Projects/utils/projects.utils';

const wrapperStyle = { position: 'relative', display: 'inline-grid' };
const editIconStyle = { position: 'absolute', right: '4px', bottom: '6px', cursor: 'pointer' };
const FormikSitesAddressInput = ({
  formik,
  displayValue,
  label,
  required,
  name,
  embedded,
  readOnly,
  readOnlyProp,
  enableFreeformText,
  isEditEnabled,
  editSiteCallBack,
  selectedValue,
  hint,
  tooltip,
  id,
  ...rest
}: any) => (
  <FormField
    label={label}
    required={required}
    error={formik.touched[name] && formik.errors[name]}
    embedded={embedded}
    hint={hint}
    tooltip={tooltip}
    id={id}
  >
    <div style={wrapperStyle}>
      {!readOnly ? (
        <SitesAddressInput
          {...{
            formik,
            displayValue,
            label,
            required,
            name,
            embedded,
            selectedValue,
            enableFreeformText,
            ...rest,
          }}
        />
      ) : (
        <SiteDetail
          siteName={readOnlyProp && readOnlyProp.name}
          siteType={readOnlyProp && readOnlyProp.type}
          siteAddress={getSiteAddress(readOnlyProp) || formik.values[name]}
        />
      )}
      {isEditEnabled && selectedValue && (
        <button
          style={editIconStyle}
          disabled={!selectedValue}
          onClick={(e) => {
            // keep otherwise Formik submit form is triggered
            e?.preventDefault?.();
            e?.stopPropagation?.();
            if (selectedValue) {
              editSiteCallBack?.(selectedValue);
            }
          }}
        >
          <Edit width={14} height={14} fill={selectedValue ? '#162093' : '#6f6c6c'} />
        </button>
      )}
    </div>
  </FormField>
);
export default FormikSitesAddressInput;
