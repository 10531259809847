
export const travelTimeValueType = {
  minutes: {
    label: 'Minutes',
    value: 'CONST_MINUTES',
    exampleLabel: 'ex. minutes',
    step: 5,
  },
  hours: {
    label: 'Hours',
    value: 'HOURS',
    exampleLabel: 'ex. hours',
    step: 0.5,
  },
  travelTime: {
    label: 'Travel Time',
    value: 'travelTime',
  },
};

export default travelTimeValueType;
