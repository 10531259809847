// @ts-nocheck
import React, { useMemo } from 'react';

import CreatableSelect from 'react-select/creatable';

import { Tag } from 'common-ui/Icon';
import TagChip from './TagChip';
import styles from './styles.module.scss';

type TagInputProps = {
  values: string[], // list of tag ids
  onChange: (params:string[]) => void,

  onCreateOption: (param:string) => Promise<any>,
  tags: any[],
  labelField?: string,
  valueField?: string,
  createMessage?: string,
  placeholder?: string,
  createableSelectProps?: Record<string, unknown>,
  onBlur?: () => void,

  validateCreation?: (param:string) => boolean,
};

const TagInput = ({
  values,
  onChange,
  onCreateOption,
  tags,
  labelField,
  valueField,
  createMessage,
  placeholder,
  createableSelectProps,
  onBlur,
  validateCreation,
}: TagInputProps) => {
  const valueToLabel = useMemo(() => (

    tags.reduce((acc, tag) => ({ ...acc, [tag[valueField]]: tag[labelField] }), {})
  ), [tags, labelField, valueField]);

  return (

    <CreatableSelect
      isMulti
      isClearable={false}
      closeMenuOnSelect={false}
      pageSize={6}
      menuPlacement="top"
      maxMenuHeight={250}
      classNamePrefix="tag-input"

      options={tags.map(t => ({ label: t[labelField], value: t[valueField] }))}
      value={values.map(value => ({ label: valueToLabel[value], value }))}

      onChange={(newVal) => {
        if (newVal === null) {

          onChange([]);
        } else if (newVal) {

          onChange(newVal.map(v => v.value));
        } else {

          onChange(values);
        }
      }}

      onCreateOption={(newTag) => {

        if (validateCreation(newTag)) {
          onCreateOption(newTag)

            .then((createdTag) => onChange([...values, createdTag && createdTag[valueField]]));
        }
      }}
      placeholder={placeholder}
      onBlur={onBlur}
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,

        MultiValueContainer: ({ data: { label, value } }:any) => (
          <TagChip
            removable
            name={label}

            onMouseDown={() => onChange(values.filter(v => v !== value))}
          />
        ),
      }}

      formatCreateLabel={inputVal => (

        <div className={styles.createLabel}>
          <Tag />
          {createMessage}:&nbsp;
          {inputVal}
        </div>
      )}

      theme={theme => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: styles.colorSelected,
          primary25: styles.colorSelectedLight,
          primary50: styles.colorSelectedMedium,
        },
      })}
      {...createableSelectProps}
    />
  );
};

TagInput.defaultProps = {
  labelField: 'name',
  valueField: 'id',
  createMessage: 'Create new tag',
  placeholder: 'Type to search or create a new tag',
  createableSelectProps: {},
  onBlur: () => {},
  validateCreation: () => true,
};

export default TagInput;
