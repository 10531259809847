// @ts-nocheck
import React, { ReactNode } from 'react';
import {  intlShape } from 'react-intl';
import { Button } from 'common-ui/Button';

import ID from 'common-ui/ConsistentIDs';
import { injectIntlHOC } from 'utils/HOC';
import styles from './styles.module.scss';


type ConfirmModalProps = {
  children: ReactNode,
  onConfirm: () => void,
  onClose: () => void,

  intl: intlShape,
  bodyText: ReactNode,
  cancelText: ReactNode,
  confirmText: ReactNode,
  headerText: ReactNode,
};

const ConfirmModal = ({
  children,
  onConfirm,
  onClose,
  bodyText,
  cancelText,
  confirmText,
  headerText,
  intl,
}: ConfirmModalProps) => (

  <div className={styles.baseModal}>

    <div className={styles.baseModalHeader}>

      <div className={styles.textHeader}>
        {headerText || intl.formatMessage({ id: 'modals.confirm_header', defaultMessage: 'Are you sure?' })}
      </div>
    </div>
    {children}

    <div className={styles.baseModalButtons}>

      <Button
        text={cancelText || intl.formatMessage({ id: 'modals.cancel_button', defaultMessage: 'Cancel' })}
        onClick={onClose}
      />

      <Button
        id={ID.create(ID.Areas.General, ID.Types.Button, ID.Labels.ConfirmModalCancelButton)}
        text={confirmText}
        className={styles.confirmButton}
        onClick={() => {
          onConfirm();
          onClose();
        }}
      />
    </div>
  </div>
);


export default injectIntlHOC()(ConfirmModal);
