// @ts-nocheck

import _ from 'lodash';
import qs from 'query-string';
import React, { Suspense, useEffect } from 'react';


// HOC
import { Redirect, Route, Switch, useHistory, withRouter } from 'react-router-dom';

// //
// eslint-disable-next-line import/default
import { LoadingSpinner } from '../Common/LoadingSpinner';
import {
  TimezoneContext,
  useIntercomAuthenticated,
  useIntercomHistoryListener,
  useIntercomUserUpdate
} from 'common-ui/Hooks';
import { useWhoAmI } from 'common-ui/Hooks/useWhoAmI';
import { NotificationContainer } from 'components/Common/NotificationContainer';
import MainContainer from 'components/MainContainer';
import { SplitWrapper } from 'components/Split';
import { useHiresCompanies } from 'pages/Hires/gql';
import RequestDemo from 'pages/RequestDemo';
import ShareLiveProject from 'pages/ShareLiveProject';
import {
  CompanyContext,
  DetectMobileContextWrapper,
  ModalContext,
  TreadModal,
  useCompanyContext,
  useOpenableContent
} from 'utils/Hooks';

// Components

const Projects = React.lazy(() => import('pages/Projects'));
const ProjectsMobile = React.lazy(
  () => import('../../pages/Projects/Mobile/ProjectsMobile')
);

const Hires = React.lazy(() => import('pages/Hires'));
const HiresMobile = React.lazy(
  () => import('../../pages/Hires/Mobile/HiresMobile')
);


const Sites = React.lazy(() => import('pages/Sites'));

const History = React.lazy(() => import('pages/History'));
const HistoryMobile = React.lazy(
  () => import('../../pages/History/Mobile/HistoryMobile')
);
const RequestsMobile = React.lazy(
  () => import('../../pages/Requests/Mobile/RequestsMobile')
);

const Requests = React.lazy(() => import('pages/Requests'));

const Insights = React.lazy(() => import('pages/Insights'));

const Settings = React.lazy(() => import('pages/Settings'));

const Payments = React.lazy(() => import('pages/Payments'));

const Targets = React.lazy(() => import('pages/Targets'));
const TargetsMobile = React.lazy(
  () => import('../../pages/Targets/Mobile/TargetsMobile')
);


const Search = React.lazy(() => import('pages/Search'));

const LivePage = React.lazy(() => import('pages/Live/LivePage'));

const AuthApp = () => {
  const [whoami, loading, error] = useWhoAmI();

  const history = useHistory();
  useIntercomAuthenticated(whoami, 'dispatcher');
  useIntercomUserUpdate(whoami, 'dispatcher');
  useIntercomHistoryListener(history);

  const { company, ...search } = qs.parse(history.location.search || '');

  const companies = useHiresCompanies(true, true);
  const companyID = qs.parse(history.location.search || '').company;
  const selectedCompanyID =
    companyID && _.some(companies, (c: any) => c.id === companyID)
      ? companyID
      : (companies && companies[0] && companies[0].id) || '0';

  const setSelectedCompanyID = (id: any) => {
    history.push({
      search: qs.stringify({
        ...search,
        company: id,
      }),
    });
  };

  const selectedCompany = companies.find((c: any) => c.id === selectedCompanyID);
  const timezone =
    (selectedCompany && selectedCompany.timezone) ||
    whoami.timezone ||
    'America/Toronto';

  useEffect(() => {
    if (!companyID || companyID === '0') {
      // companyID = 0 means the query hasn't loaded//
      setSelectedCompanyID(companies && companies[0] ? companies[0].id : '0');
    }
  }, [company, companies]);

  const [visible, modalContent, openModal, closeModal] = useOpenableContent();

  if (history.location && history.location.pathname === '/shareliveproject') {
    return (

      <DetectMobileContextWrapper>
        <ShareLiveProject />
      </DetectMobileContextWrapper>
    );
  }

  if ((whoami && whoami.error) || error) {

    return <RedirectToLogin />;
  }

  if (loading) {
    return null;
  }

  return (

    <DetectMobileContextWrapper>

      <CompanyContext.Provider
        value={{
          selectedCompany,
          selectedCompanyID,
          setSelectedCompanyID,
          isClient:
            selectedCompany && selectedCompany.tierName === 'Client Only',
          isExternalDOT: selectedCompany && selectedCompany.isExternalDOT,
        }}
      >
        <SplitWrapper>
          <TimezoneContext.Provider value={timezone}>
            <ModalContext.Provider value={{ openModal, closeModal, visible }}>
              <TreadModal visible={visible} closeModal={closeModal}>
                {modalContent}
              </TreadModal>
              <MainContainer RenderComp={DeviceRouter} />
            </ModalContext.Provider>
          </TimezoneContext.Provider>
        </SplitWrapper>
      </CompanyContext.Provider>
    </DetectMobileContextWrapper>
  );
};
const LoaderAbsolute = () => {
  return (

    <div
      style={{
        position: 'absolute',
        height: '100%',
        margin: '0 auto',
        left: 0,
        right: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >

      <LoadingSpinner />
    </div>
  );
};
const DeviceRouter = ({
  desktop,
}: any) => {
  const { selectedCompanyID, isClient, isExternalDOT } = useCompanyContext();

  if (!selectedCompanyID || selectedCompanyID === '0') return null;
  const search = selectedCompanyID ? `?company=${selectedCompanyID}` : '';

  return (

    <>
      <NotificationContainer />

      <Suspense fallback={<LoaderAbsolute />}>

        <Switch>
          {/* DOT route */}
          {isExternalDOT && (
            <Redirect
              from="/dispatch"
              to={{ pathname: '/payments/tickets', search }}
            />
          )}
          {/* Client only routes// */}
          {isClient && (
            <Redirect exact from="/" to={{ pathname: '/live', search }} />
          )}
          {isClient && <Route path="/requestDemo" component={RequestDemo} />}
          {/* Full company only routes */}
          {!isClient && (
            <Redirect exact from="/" to={{ pathname: '/dispatch', search }} />
          )}
          {!isClient && (
            <Route
              path="/dispatch"
              component={desktop ? Projects : ProjectsMobile}
            />
          )}
          {!isClient && <Redirect from="/projects" to="/dispatch" />}
          {!isClient && (
            <Route path="/accounts" component={desktop ? Hires : HiresMobile} />
          )}
          {!isClient && <Redirect from="/hires" to="/accounts" />}
          {!isClient && <Redirect from="/people" to="/accounts" />}
          {!isClient && (
            <Route
              path="/targets"
              component={desktop ? Targets : TargetsMobile}
            />
          )}
          {!isClient && (
            <Route
              path="/history"
              component={desktop ? History : HistoryMobile}
            />
          )}
          {!isClient && <Route path="/insights" component={Insights} />}
          {(isExternalDOT || !isClient) && (
            <Route path="/payments" component={Payments} />
          )}
          {!isClient && <Redirect from="/tickets" to="/payments/tickets" />}
          {!isClient && desktop && <Route path="/sites" component={Sites} />}
          {!isClient && desktop && <Route path="/search" component={Search} />}
          {/* Shared routes */}
          <Route path="/live" component={LivePage} />
          <Route
            path="/requests"
            component={desktop ? Requests : RequestsMobile}
          />
          <Route path="/settings" component={Settings} />
          <Redirect to={{ pathname: '/', search }} />
        </Switch>
      </Suspense>
    </>
  );
};


const RedirectToLogin = () => <Redirect to="/login" />;

export default withRouter(AuthApp);
