// @ts-nocheck
import { useQuery, gql } from '@apollo/client';
import { useMemo } from 'react';


import { usePermissions } from 'common-ui/Hooks';

import { AllAccountRoles } from 'pages/Settings/PersonalSettings/utils';
import { useCompanyContext } from 'utils/Hooks';

const COLLABORATORS_QUERY = gql`
  query collaborators($companyID: ID!) {
    collaborators(companyID: $companyID) {
      id
      accountId
      firstName
      lastName
      roles
      phoneNumber
      email
      companyName : externalCompanyName
    }
  }
`;

const useCollaborators = () => {
  const { selectedCompanyID } = useCompanyContext();
  const { ViewInternalHires } = usePermissions(selectedCompanyID);

  const { data, loading, error } = useQuery(COLLABORATORS_QUERY, {
    variables: {
      companyID: selectedCompanyID,
    },
    skip: !ViewInternalHires,
    fetchPolicy: 'cache-and-network',
  });

  return useMemo(() => {
    if (loading || error || !data) return [];
    const collaborators = data.collaborators.map((g: any) => {
      return ({
        ...g,
        role: (g.roles[0] === AllAccountRoles.DOT || g.roles[0] === AllAccountRoles.EXTERNALSUPERVISOR) ? g.roles[0] : AllAccountRoles.FOREMAN,
      }
      );
    });
    return collaborators;
  }, [data, loading, error]);
};

export default useCollaborators;
