// @ts-nocheck
import React from 'react';
import { Link } from 'react-router-dom';
import { compose, withHandlers, HOC } from 'recompose';


import Analytics from 'common-ui/Analytics/SegmentAnalytics';
import {Actions} from 'common-ui/Analytics/types';
import ClickableDiv from 'common-ui/ClickableDiv';
import ID from 'common-ui/ConsistentIDs';
import { usePermissions } from 'common-ui/Hooks';
import { Export } from 'common-ui/Icon';


import { InfoCard } from 'components/Common/Hires';
import { useCompanyContext, useDetectMobile } from 'utils/Hooks';
import styles from './styles.module.scss';
import type { Marker, IDType } from '../types';








const getStyleType = (type, showStatus) => {
  if (!showStatus) {
    return 'notavailable';
  }
  switch (type) {
    case 'onlineDirection':
    case 'online':
      return 'online';
    case 'lostConnection':
      return 'lost';
    case 'badData':
      return 'missing';
    default:
    case 'idle':
    case 'idleDirection':
      return 'idle';
  }
};

type MarkerBarUIProps = {
  marker: Marker,
  isHighlighted: boolean,

  openMarker: (markerId?: IDType) => any
};

const MarkerBarUI = ({
  marker,
  isHighlighted,
  openMarker,
}: MarkerBarUIProps) => {
  const style = `${styles.job}  ${isHighlighted ? styles.highlightedJob : ''} `;
  const { showName, showStatus } = marker;
  const { selectedCompanyID } = useCompanyContext();
  const isMobile = useDetectMobile();
  const {
    ViewDriverRoute: showDriverRoute,
  } = usePermissions(selectedCompanyID);
  return (

    <button

      id={marker.id}
      className={style}

      onClick={!marker.badData ? openMarker : null}

      onKeyPress={!marker.badData ? openMarker : null}
      type="button"
    >

      <div className={styles.nameSection}>

        <InfoCard
          accountId={marker.accountId}
          firstName={marker.firstName}
          lastName={marker.lastName}
          truckNumber={marker.truckNumber}
          company={marker.companyName}
          styleName={getStyleType(marker.type, showStatus)}
          hideName={!showName}
        />
        {showDriverRoute && isHighlighted && !isMobile && (
          <Link to={{ pathname: `/live/history/${marker.accountId}`, search: `?company=${selectedCompanyID}` }}>
            <ClickableDiv
              id={ID.create(ID.Areas.Live, ID.Types.Button, ID.Labels.DriverChipViewRouteInfo)}
              className={styles.driverRoutesIcon}
              onClick={() => Analytics.trackClick(Actions.viewDriverRoute)}
            >
              <Export />
            </ClickableDiv>
          </Link>
        )}
      </div>
    </button>
  );
};

MarkerBarUI.defaultProps = {};

type MarkerBarHOCProps = {
  markerId: IDType
} & MarkerBarUIProps;


const MarkerBarHOC: HOC<any, MarkerBarHOCProps> = compose(
  withHandlers({

    openMarker: ({ markerId, openMarker }) => () => {
      if (openMarker) {
        openMarker(markerId);
      }
    },
  }),
);

export default MarkerBarHOC(MarkerBarUI);
