// @ts-nocheck
import { connect, Formik } from 'formik';
import React from 'react';


import { FormikCreatableDropdown } from 'common-ui/Form/Formik';

import { formatDuration } from 'pages/Payments/Timesheets/utils';

// Creates minute values in 15 minute increments from 0:00 to 4:00
const createDropdownValues = (options: any, currentValue: any) => {
  const values = options || [];
  // Default 0 to 4h
  if (!options || options.length <= 0) {
    for (let i = 0; i <= 240; i += 15) {
      values.push(i);
    }
  }

  if (currentValue && !values.includes(currentValue)) {
    values.push(currentValue);
  }
  return values.sort((a: any, b: any) => a - b);
};

const formatOptions = (options: any) => options.map((v: any) => ({
  label: formatDuration(v),
  value: v
}));

type TimeDropdownProps = {
  name: string,
  label: string,
  formik: Formik,
  readOnly: boolean,
  tooltip:any,
  disabled:boolean,
  onDispatch?: boolean,
  hideError?: boolean,
  options?: [],
  id?: string
}

const TimeDropdown = ({
  name,
  label,
  formik,
  readOnly,
  tooltip,
  disabled,
  onDispatch,
  hideError,
  options,
  id,
}: TimeDropdownProps) => {
  const addedOptions = createDropdownValues(options, formik.values[name]);
  const formattedOptions = formatOptions(addedOptions);
  return (

    <>
      {readOnly && !onDispatch

        && <div id={id}>{formik.values[name]}m</div>
      }
      {(!readOnly || onDispatch) && (

        <FormikCreatableDropdown
          id={id}
          options={formattedOptions}
          name={name}
          label={label}
          tooltip={tooltip}
          readOnly={readOnly}
          disabled={disabled}
          hideError={hideError}
          isDurationInput
        />
      )}
    </>
  );
};

TimeDropdown.defaultProps = {
  onDispatch: false,
  hideError: false,
  options: [],
  id: undefined,
};

export default connect(TimeDropdown);
