// @ts-nocheck
import queryString from 'query-string';
import { useLocation, useHistory } from 'react-router-dom';

// For the filters, we manually encode and decode the user's input
// So that we can use our own characters as separators
const useTagFilterURL = () => {
  const history = useHistory();
  const location = useLocation();
  const urlTagFilters = queryString.parse(location.search || '').tagFilters;
  const filters = urlTagFilters ? urlTagFilters.split('\t') : [];

  const setFilters = (newFilters: [number | string]) => {
    const formattedURL = queryString.parse(location.search || '');
    history.push({
      ...location,
      search: queryString.stringify({
        ...formattedURL,
        tagFilters: newFilters.join('\t'),
      }),
    });
  };
  return [filters, setFilters];
};

export default useTagFilterURL;
