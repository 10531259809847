// @ts-nocheck
/* eslint no-bitwise: "off" */
import _ from 'lodash';
import PropTypes from 'prop-types';
import {PositionType} from '../types';
// Colors for Start and End of trip
export const START_COLOR = '#B538DE';
export const END_COLOR = '#B538DE';

export const driverIdentifiers = {
  truckNumbers: 'truckNumbers',
  driverInitials: 'driverInitials',
};

type MakeBoundsPropsType = Array<PositionType>;

// Make bounds for the map viewport (fit all)
export const makeBounds = (objects: MakeBoundsPropsType) => {
  const firstEntry = objects && objects.length > 0 ? objects[0] : null;
  let isSame = true;
  const results = objects.map((obj) => {
    isSame =
      !!firstEntry &&
      isSame &&
      obj.lat === firstEntry.lat &&
      obj.lng === firstEntry.lng;
    return [obj.lat, obj.lng];
  });

  // get corners;
  const bounds = _.reduce(
    results,

    (result, value) => ({
      minLat: value[0] < result.minLat ? value[0] : result.minLat,
      minLng: value[1] < result.minLng ? value[1] : result.minLng,
      maxLat: value[0] > result.maxLat ? value[0] : result.maxLat,
      maxLng: value[1] > result.maxLng ? value[1] : result.maxLng
    }),
    {
      minLat: results[0][0],
      minLng: results[0][1],
      maxLat: results[0][0],
      maxLng: results[0][1],
    }
  );

  // show min 1/10 on each side, to min 0.003
  const latBound =
    _.max([Math.abs(bounds.maxLat - bounds.minLat) / 10, 0.003]) || 0;
  const lngBound =
    _.max([Math.abs(bounds.maxLng - bounds.minLng) / 10, 0.003]) || 0;

  // WebMercatorViewport.fitBounds expects [[lng, lat], [lng, lat]]
  return [
    [bounds.minLng - lngBound, bounds.minLat - latBound],
    [bounds.maxLng + lngBound, bounds.maxLat + latBound],
  ];
};

export const MarkerPropType = {
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  color: PropTypes.string,
  lastPingTime: PropTypes.object,
  imgURL: PropTypes.string,
  phoneNumber: PropTypes.string,
  idleSince: PropTypes.string,
  badData: PropTypes.bool,
};

export const PositionPropType = {
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
};
