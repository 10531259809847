// @ts-nocheck
import React, {Fragment} from 'react';
import Helmet from 'react-helmet';
import {Switch, Route} from 'react-router-dom';
import {Login, Logout} from '../Login';

import AuthApp from './AuthApp';

import 'react-notifications-component/dist/theme.css';


const App = () => (

  <Fragment>
    <Helmet
      htmlAttributes={{lang: 'en'}}
      titleTemplate="Tread Admin - %s"
      defaultTitle="Tread Admin"
    />

    <Switch>

      <Route exact path="/login" component={Login} />

      <Route exact path="/logout" component={Logout} />

      <Route component={AuthApp} />
    </Switch>
  </Fragment>
);

export default App;
