// @ts-nocheck
import classNames from 'classnames';
import React from 'react';
import { compose, withProps } from 'recompose';

import { LiveHOC } from '../Live';
import LiveMap from '../components/LiveMap';
import { LiveProjects } from '../components/LiveSidebar';
import { ContextSwitcher } from 'common-ui/ContextSwitcher';
import styles from './styles.module.scss';


import type { LivePropUITypes } from '../types';

export const MAP_OPTION = 'Map';
export const PROJECTS_OPTION = 'Projects';

export const getToggleOptions = (intl: any) => {
  return [
    {
      label: `${intl.formatMessage({
        id: 'live.footer.toggle_map',
        defaultMessage: 'Map',
      })}`,
      value: MAP_OPTION,
    },
    {
      label: `${intl.formatMessage({
        id: 'live.footer.toggle_projects',
        defaultMessage: 'Projects',
      })}`,
      value: PROJECTS_OPTION,
    }];
};

const LiveMobile = ({
  calcMarkers,
  liveMapLoading,
  sites,
  projects,
  onRefresh,
  currentOptions,

  driverStatusOptions,

  setProjectFilterItems,

  projectFilterItems,
  toggleMarkerOpen,
  onProjectSort,
  markerId,
  toggleAssignmentOpen,
  liveSortOptions,
  intl,
  bounds,
  showSatellite,
  showTraffic,
  setAutoZoom,
  openedPopup,
  openPopup,
  openModal,
  closeModal,
}: LivePropUITypes) => {
  const [togglePageValue, setTogglePage] = React.useState(MAP_OPTION);
  const toggleOptions = getToggleOptions(intl);

  return (

    <div className={styles.overviewContent}>

      <div

        className={classNames(
          styles.mainContent,
          togglePageValue !== MAP_OPTION && styles.hideMap,
        )}
      >

        <LiveMap
          mobile
          liveMapLoading={liveMapLoading}
          filters={projectFilterItems}
          noRefreshButton
          noZoomButtons={togglePageValue !== MAP_OPTION}
          calcMarkers={calcMarkers || []}
          sites={sites}
          openMarkerId={markerId}

          toggleMarkerOpen={toggleMarkerOpen}
          {...{
            bounds,
            showTraffic,
            showSatellite,
            onRefresh,
            intl,
            setAutoZoom,
            openedPopup,
            openPopup,
          }}
        />
      </div>
      {togglePageValue === MAP_OPTION
        ? null
        : (
          <div className={styles.sidebarContent}>
            <LiveProjects
              {...{
                projects,
                onRefresh,
                currentOptions,
                driverStatusOptions,
                setProjectFilterItems,
                projectFilterItems,
                onProjectSort,
                liveSortOptions,
                intl,
                openModal,
                closeModal,
              }}
              chosenMarkerId={markerId}
              openMarker={(props) => {
                setTogglePage(MAP_OPTION);

                toggleMarkerOpen(props);
              }}

              toggleAssignmentOpen={toggleAssignmentOpen}
            />
          </div>
        )
      }

      <ContextSwitcher
        onChange={setTogglePage}
        selectedValue={togglePageValue}
        options={toggleOptions}
      />
    </div>
  );
};

LiveMobile.defaultProps = {
  markerId: null,
  projects: null,
  useFlyTo: false,
  boundsOptions: { padding: [1, 1] },
};

export default compose(
  withProps(() => ({ limitInitialDisplay: true })),
  LiveHOC,

)(LiveMobile);
