// @ts-nocheck

import { internalHiresFilter, subcontractorsFilter, hireTagFilter } from 'pages/Hires/utils';


export const getFilteredHires = (hires: DisplayHire[], search: string, tagFiltersSelected: [string] = []) => (
  hires
    .filter(hire => internalHiresFilter(search, hire))
    .filter(hire => hireTagFilter(tagFiltersSelected, hire))
    .sort((a, b) => (
      a.lastName.localeCompare(b.lastName)
      || a.firstName.localeCompare(b.firstName)
    ))
);

export const getFilteredSubcontractors = (hires: any, search: any, tagFiltersSelected = []) => (
  hires
    .filter((hire: any) => subcontractorsFilter(search, hire))
    .filter((hire: any) => hireTagFilter(tagFiltersSelected, hire))
    .sort((a: any, b: any) => a.name.localeCompare(b.name))
);

export const getFilteredCompanyClientHires = (hires: any, subcontractors: any, search: string, filters: any) => {
  const filteredSubs = subcontractors.filter((hire: any) => hireTagFilter(filters, hire));
  const subIds = new Set(filteredSubs.map((hire: any) => parseInt(hire.subcontractorID, 10)));

  // `hireTagFilter` is not required here since it's already been applied
  // to `filteredSubcontractors`, the output of `getFilteredSubs`.
  return hires
    .filter((hire: any) => internalHiresFilter(search, hire))
    .filter((hire: any) => subIds.has(hire.companyId))
    .sort((a: any, b: any) => (
      a.lastName.localeCompare(b.lastName)
      || a.firstName.localeCompare(b.firstName)
    ));
};
