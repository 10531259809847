// @ts-nocheck
import React, { ReactNode } from 'react';

import { NotificationsSidebar } from 'components/NotificationsSidebar';
import { OverlaySidebar, withSidebar } from 'components/Sidebar';
import type { withSidebarType } from 'components/Sidebar/types';
import styles from './styles.module.scss';



type PagePropTypes = {
  children: Node,
  notificationsSidebar: withSidebarType,
  mobile?: boolean,
};

const Page = ({ children, notificationsSidebar, mobile }: PagePropTypes) => (

  <div className={mobile ? styles.mobilePage : styles.page}>

    <OverlaySidebar
      SidebarComponent={NotificationsSidebar}
      sidebarStateObject={notificationsSidebar}
      notificationsSidebar={notificationsSidebar}
    >
      {children}
    </OverlaySidebar>
  </div>
);

Page.defaultProps = {
  mobile: false,
};

// This is the container for everything
// that would fall under the main navigation header
// (or everything would be overlayed by the sidebar on mobile)

export default withSidebar('notificationsSidebar', false)(Page);
