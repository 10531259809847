// @ts-nocheck

export const SiteTypes = {
  SOURCE: 'source',
  RECEIVING: 'receiving',
  BOTH: 'source_receiving',
};

export const getColourForSiteType = (type: string) => {
  switch (type) {
    case SiteTypes.SOURCE:
      return '#3064F5';
    case SiteTypes.RECEIVING:
      return '#00195F';
    case SiteTypes.BOTH:
    default:
      return '#002FB0';
  }
};
