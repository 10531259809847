// @ts-nocheck
import { useFormikContext } from 'formik';
import React from 'react';

import { FormField, TagInput } from '..';

type FormikTagInputProps = {
  label: string,
  name: string,
  tags: any[],

  onCreateOption: (param:string) => Promise<any>,
  labelField?: string,
  valueField?: string,
  createMessage?: string,
  placeholder?: string,
  createableSelectProps?: Record<string, unknown>,

  validateCreation?: (param:string) => boolean;
};

const FormikTagInput = ({
  label,
  name,
  tags,
  onCreateOption,
  labelField,
  valueField,
  createMessage,
  placeholder,
  createableSelectProps,
  validateCreation,
}: FormikTagInputProps) => {
  const {
    touched,
    errors,
    values,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext();

  return (

    <FormField label={label} error={touched[name] && errors[name]}>

      <TagInput
        values={values[name] || []}

        onChange={(value: any) => setFieldValue(name, value)}
        onCreateOption={onCreateOption}
        tags={tags}
        labelField={labelField}
        valueField={valueField}
        createMessage={createMessage}
        placeholder={placeholder}
        createableSelectProps={createableSelectProps}
        onBlur={() => setFieldTouched(name)}
        validateCreation={validateCreation}
      />
    </FormField>
  );
};

FormikTagInput.defaultProps = {
  labelField: 'name',
  valueField: 'id',
  createMessage: 'Create new tag',
  placeholder: 'Type to search or create a new tag',
  createableSelectProps: {},
  validateCreation: () => true,
};

export default FormikTagInput;
