// @ts-nocheck
import classNames from 'classnames';
import _ from 'lodash';
import moment from 'moment-timezone';
import React, { useState, useRef } from 'react';
import { compose, mapProps, HOC } from 'recompose';

import { ChevronLeft, Check, InverseConflict } from 'common-ui/Icon';

import { TooltipWrapper } from 'components/Common/Tooltip';

import { withUserTimezone } from 'utils/HOC';

import { useOutsideClickHandler, useScrollToRef } from 'utils/Hooks';
import styles from './styles.module.scss';

type TimePickerOptionProps = {
  value: number | string,
  onSelect: () => void,
  selected: boolean,
  selectedRef?: { current: null | HTMLDivElement },
};

const TimePickerOption = ({
  value,
  onSelect,
  selected,
  selectedRef,
}: TimePickerOptionProps) => (

  <div

    className={classNames(styles.timePickerOption, { [styles.selected]: selected })}
    onMouseDown={(e) => {
      e.preventDefault(); // Prevent input from losing focus on click
      onSelect();
    }}
    onKeyPress={onSelect}
    role="menuitem"
    tabIndex={0}
    ref={selected ? selectedRef : null}
  >
    {value}
  </div>
);

TimePickerOption.defaultProps = {
  selectedRef: undefined,
};

type TimePickerProps = {

  value: moment,

  onChange: (newValue: moment) => void,
  embedded?: boolean,
  disabled?: boolean,
  error?: string,
};

const TimePicker = ({
  onChange,
  value,
  embedded,
  disabled,
  error,
  ...inputProps
}: TimePickerProps) => {
  const [hidden, setHidden] = useState(true);
  const [typedValue, setTypedValue] = useState(null);
  const timePickerRef = useRef();
  const inputRef = useRef();
  const onSubmit = () => {

    const parsedTime = moment(typedValue, ['h:m a', 'H:m']);
    if (parsedTime.isValid()) onChange(parsedTime);
    setTypedValue(null);
  };

  // Picker defaults to moment() on change if value is not valid

  const pickerValue = value.isValid() ? value : moment();

  useOutsideClickHandler(timePickerRef, () => {
    setHidden(true);
    onSubmit();
  }, typedValue);

  // For scrolling to selected options
  const hourRef = useRef();
  const minuteRef = useRef();
  useScrollToRef(hourRef, hidden);
  useScrollToRef(minuteRef, hidden);

  const handleKey = (e: KeyboardEvent) => {
    switch (e.key) {
      case 'ArrowUp':
        onChange(pickerValue.add(15, 'minutes'));
        e.preventDefault();
        break;
      case 'Escape':
        setHidden(true);
        e.preventDefault();
        break;
      case 'ArrowDown':
        if (hidden) {
          setHidden(false);
        } else {
          onChange(pickerValue.subtract(15, 'minutes'));
        }
        e.preventDefault();
        break;
      case 'Enter':
        setHidden(true);
        onSubmit();

        if (inputRef.current) inputRef.current.blur();
        e.preventDefault();
        break;
      default:
        break;
    }
  };

  return (
  // Disable eslint since inner divs are all focusable

    // eslint-disable-next-line
    <div

      className={classNames(styles.timePicker, {
        [styles.embedded]: !!embedded,
        [styles.disabled]: !!disabled,
        [styles.error]: error,
      })}

      ref={timePickerRef}

      onKeyDown={handleKey}
    >

      <div className={styles.errorMessage}>

        <TooltipWrapper message={error}>
          <InverseConflict />
        </TooltipWrapper>
      </div>

      <input

        ref={inputRef}
        disabled={disabled}

        className={classNames(styles.timePickerField, {
          [styles.focused]: !hidden,
          [styles.embedded]: !!embedded,
        })}
        autoComplete="off"
        value={typedValue === null && value.isValid() ? value.format('h:mm a') : (typedValue || '')}
        onClick={() => setHidden(false)}
        {...{
          ...inputProps,

          onChange: event => setTypedValue(event.target.value),
        }}
      />

      <div className={classNames(styles.timePickerMenu, { [styles.hidden]: hidden })}>

        <div className={styles.timePickerScroller}>
          {_.times(12).map((val: any) => val + 1).map((hour: any) => <TimePickerOption
            value={hour}
            key={hour}
            onSelect={() => {
              if (pickerValue.hour() < 12) {
                pickerValue.set({ hour: hour % 12 });
              } else {
                pickerValue.set({ hour: 12 + (hour % 12) });
              }
              onChange(pickerValue);
            }}
            selected={(value.hour() % 12) === (hour % 12)}

            selectedRef={hourRef}
          />)}
        </div>

        <div className={styles.timePickerScroller}>
          {_.times(60, (minute: any) => <TimePickerOption
            value={minute.toString().padStart(2, '0')}
            key={minute.toString().padStart(2, '0')}
            onSelect={() => {
              pickerValue.set({ minute });
              onChange(pickerValue);
            }}
            selected={value.minute() === minute}

            selectedRef={minuteRef}
          />)}
        </div>

        <div className={styles.timePickerScroller}>
          <TimePickerOption
            value="AM"
            key="AM"
            onSelect={() => {
              if (pickerValue.hour() > 11) {
                onChange(pickerValue.subtract(12, 'hours'));
              }
            }}
            selected={value.hour() < 12}
          />
          <TimePickerOption
            value="PM"
            key="PM"
            onSelect={() => {
              if (pickerValue.hour() < 12) {
                onChange(pickerValue.add(12, 'hours'));
              }
            }}
            selected={value.hour() > 11}
          />
        </div>
      </div>
      {!disabled && (

        <div

          className={classNames(styles.timePickerIcon, { [styles.expanded]: !hidden })}
          onClick={() => {
            if (hidden) {
              setHidden(false);
            } else {
              setHidden(true);
              onSubmit();
            }
          }}
          onKeyPress={() => {
            if (hidden) {
              setHidden(false);
            } else {
              setHidden(true);
              onSubmit();
            }
          }}
          role="button"

          tabIndex={!disabled ? 0 : null}
        >
          {hidden ? <ChevronLeft /> : <Check />}
        </div>
      )}
    </div>
  );
};

TimePicker.defaultProps = {
  embedded: false,
  disabled: false,
  error: null,
};

type TimePickerInputProps = {
  value: string,
  onChange: (newValue: string) => void,
  embedded?: boolean,
};

const enhance: HOC<TimePickerProps, TimePickerInputProps> = compose(
  withUserTimezone,

  mapProps(({
    value,
    onChange,
    timezone,
    ...props
  }) => ({
    value: moment.tz(value, timezone),
    // When the initial value is empty, the returned value defaults to the
    // current date and must be modified separately

    onChange: (newValue: any) => onChange(moment.tz(value || undefined, timezone).set({
      hour: newValue.hour(),
      minute: newValue.minute(),
      second: 0,
    }).format()),
    ...props,
  })),
);

export default enhance(TimePicker);
