import React, {SVGProps} from 'react';

const Stopwatch = (props: SVGProps<SVGSVGElement>) => (

  <svg
    width="24"
    height="24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >

    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.98 2.064C8.98 1.476 9.454 1 10.042 1h4.384c.588 0 1.064.476 1.064 1.064v1.184c0 .588-.476 1.064-1.064 1.064H13.32v1.173a8.545 8.545 0 013.795 1.457l1.257-1.278a1.064 1.064 0 011.517 0l.81.824a1.064 1.064 0 010 1.492l-1.282 1.304a8.898 8.898 0 011.5 4.965c0 4.878-3.887 8.832-8.682 8.832s-8.683-3.954-8.683-8.832c0-4.504 3.315-8.221 7.598-8.764V4.312h-1.107a1.064 1.064 0 01-1.064-1.064V2.064zm3.255 18.809c-3.596 0-6.512-2.966-6.512-6.624 0-3.659 2.916-6.625 6.512-6.625 3.596 0 6.512 2.966 6.512 6.625 0 3.658-2.916 6.624-6.512 6.624zm-.021-12.144c-.588 0-1.064.476-1.064 1.064v4.496c0 .588.476 1.064 1.064 1.064h.043c.587 0 1.063-.476 1.063-1.064V9.793c0-.588-.476-1.064-1.063-1.064h-.043z"
      fill="#4A4A4A"
    />
  </svg>
);

export default Stopwatch;
