import React, {SVGProps} from 'react';

const Calendar = (props: SVGProps<SVGSVGElement>) => (

  <svg width={20} height={20} viewBox="0 0 20 20" {...props}>

    <title>Calendar</title>

    <path
      d="M10.476 14.286v-2.853a.952.952 0 0 0-1.905-.004.953.953 0 0 0 0 1.904v.953a.95.95 0 0 0-.952.952c0 .53.426.952.951.952h1.907a.95.95 0 0 0 .952-.952.95.95 0 0 0-.952-.952zm6.667-7.62V4.763a.95.95 0 0 0-.948-.952h-.005a.95.95 0 0 1-.952.952.95.95 0 0 1-.952-.952H12.38a.95.95 0 0 1-.952.952.95.95 0 0 1-.953-.952H8.571a.95.95 0 0 1-.952.952.95.95 0 0 1-.952-.952H4.762a.95.95 0 0 1-.952.952.95.95 0 0 1-.953-.952h-.005a.95.95 0 0 0-.947.952v1.905h15.238zm0 1.905v8.572a.95.95 0 0 1-.948.952H2.852a.95.95 0 0 1-.947-.952V8.57h15.238zm-2.857-6.666H12.38V.952A.95.95 0 0 0 11.429 0a.95.95 0 0 0-.953.952v.953H8.571V.952A.95.95 0 0 0 7.62 0a.95.95 0 0 0-.952.952v.953H4.762V.952A.95.95 0 0 0 3.81 0a.95.95 0 0 0-.953.952v.953h-.005A2.854 2.854 0 0 0 0 4.762v12.38A2.855 2.855 0 0 0 2.852 20h13.343a2.854 2.854 0 0 0 2.853-2.857V4.762a2.855 2.855 0 0 0-2.853-2.857h-.005V.952A.95.95 0 0 0 15.238 0a.95.95 0 0 0-.952.952v.953z"
      fill="#162093"
      fillRule="evenodd"
    />
  </svg>
);

export default Calendar;
