import React, {SVGProps} from 'react';

const View = (props: SVGProps<SVGSVGElement>) => (

  <svg width={22} height={16} viewBox="0 0 22 16" {...props}>

    <title>View</title>

    <path
      d="M11 16c-2.996 0-5.747-1.131-7.918-3.002C1.244 11.415 0 9.414 0 8c0-1.414 1.242-3.415 3.078-4.998C5.248 1.132 8 0 11 0s5.752 1.13 7.922 2.998C20.759 4.58 22 6.581 22 8c0 1.418-1.243 3.42-3.082 5.002C16.748 14.871 13.996 16 11 16zm0-2c2.491 0 4.789-.943 6.613-2.514C19.054 10.246 20 8.724 20 8c0-.725-.944-2.247-2.384-3.487C15.793 2.943 13.496 2 11 2c-2.494 0-4.792.945-6.616 2.517C2.945 5.757 2 7.28 2 8c0 .719.947 2.242 2.387 3.483C6.213 13.055 8.51 14 11 14zm0-2a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm.085-5.998a2 2 0 1 0 1.914 1.914 1.5 1.5 0 0 1-1.914-1.914z"
      fill="#162093"
      fillRule="evenodd"
    />
  </svg>
);

export default View;
