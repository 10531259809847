import React, {SVGProps} from 'react';

const Tag = (props: SVGProps<SVGSVGElement>) => (

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.06 24.13" {...props}>

    <path d="M23.22,13.35C20.28,10.47,13.37,3.5,11.49,1.66A2.75,2.75,0,0,0,9.85.88L5.26.38,3.13.15A2.77,2.77,0,0,0,.07,3.2L.31,5.34l.5,4.57a2.76,2.76,0,0,0,.79,1.66c1.86,1.87,8.68,8.87,11.54,11.74a2.79,2.79,0,0,0,3.92,0l6.15-6A2.79,2.79,0,0,0,23.22,13.35ZM5.85,8.1A2.16,2.16,0,1,1,8,6,2.15,2.15,0,0,1,5.85,8.1Z" />
  </svg>
);

export default Tag;
