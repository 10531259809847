// @ts-nocheck

import Piexif from 'piexifjs';

// Taken from frontent/common-ui
export const BlobToDataURL = (blob: any) => {
  const a = new FileReader();
  return new Promise((resolve) => {
    a.onload = (e) => {

      resolve(e.target.result);
    };
    a.readAsDataURL(blob);
  });
};

export const DataURItoBlob = (dataURI: any) => {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1]);
  else byteString = unescape(dataURI.split(',')[1]);

  // separate out the mime component
  const mimeString = dataURI
    .split(',')[0]
    .split(':')[1]
    .split(';')[0];

  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
};

export const readFileAsURL = (file: any) => {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    try {
      reader.addEventListener('load', (e) => {

        resolve(e.target.result);
      });
      reader.readAsDataURL(file);
    } catch (err) {
      reject(err);
    }
  });
};

export const IsImage = (file: any) => /^image\/.*/.test(file.type);

export const IsJPEG = (file: any) => /^image\/jpeg/.test(file.type);

export const LoadImage = (dataURL: any) => new Promise((resolve, reject) => {
  try {
    const img = new Image();
    img.addEventListener('load', () => {
      resolve(img);
    });
    img.src = dataURL;
  } catch (err) {
    reject(err);
  }
});

export const ResizeCompressImage = (img: any, targetRes: any) => {
  // Setup Canvas
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  // Calculate resizing ratio and target dimensions
  const imgRes = img.height * img.width;
  const ratio = img.width / img.height;
  let ratioResize = 1;
  if (imgRes > targetRes) {
    // Formula derived by Simon
    ratioResize = Math.sqrt(targetRes / ratio) / img.height;
  }

  const targetHeight = img.height * ratioResize;
  const targetWidth = img.width * ratioResize;

  canvas.height = targetHeight;
  canvas.width = targetWidth;

  // Create a canvas to put the image on
  const subCanvas = document.createElement('canvas');

  const octx = subCanvas.getContext('2d');

  subCanvas.width = img.width;
  subCanvas.height = img.height;

  octx.drawImage(img, 0, 0, subCanvas.width, subCanvas.height);

  // Draw img and resize to new ratio

  octx.imageSmoothingQuality = 'medium';

  octx.drawImage(
    subCanvas,
    0,
    0,
    subCanvas.width * ratioResize,
    subCanvas.height * ratioResize,
  );

  // Resize to final size

  ctx.drawImage(
    subCanvas,
    0,
    0,
    targetWidth,
    targetHeight,
    0,
    0,
    targetWidth,
    targetHeight,
  );

  return canvas.toDataURL('image/jpeg', 0.9);
};

// Restore EXIF (only for JPEG images)
export const RestoreEXIF = (imgURL: any, originalImgURL: any) => {
  // Insert EXIF data into the resized image as its removed
  const exifObj = Piexif.load(originalImgURL);
  const exifbytes = Piexif.dump(exifObj);
  return Piexif.insert(exifbytes, imgURL);
};

export const ImgOrientation = (file: any) => new Promise((resolve, reject) => {
  try {
    const fileReader = new FileReader();
    fileReader.onloadend = () => {

      const scanner = new DataView(fileReader.result);
      let idx = 0;
      let orientation = 1; // Non-rotated is the default

      if (fileReader.result.length < 2 || scanner.getUint16(idx) !== 0xffd8) {
        // Not a JPEG
        resolve(orientation);
        return;
      }
      idx += 2;
      let maxBytes = scanner.byteLength;
      while (idx < maxBytes - 2) {
        const uint16 = scanner.getUint16(idx);
        idx += 2;
        switch (uint16) {
          case 0xffe1: {
            // Start of EXIF
            const exifLength = scanner.getUint16(idx);
            maxBytes = exifLength - idx;
            idx += 2;
            break;
          }
          case 0x0112: {
            // Orientation tag
            // Read the value, its 6 bytes further out
            // See page 102 at the following URL
            // http://www.kodak.com/global/plugins/acrobat/en/service/digCam/exifStandard2.pdf
            orientation = scanner.getUint16(idx + 6, false);
            maxBytes = 0; // Stop scanning
            break;
          }
          default:
            break;
        }
      }
      resolve(orientation);
    };
    fileReader.readAsArrayBuffer(file);
  } catch (err) {
    reject(err);
  }
});

export const ImgOrientationCSS = {
  1: 'rotate(0deg)',
  3: 'rotate(180deg)',
  6: 'rotate(90deg)',
  8: 'rotate(270deg)',
};

export const CompressIfImage = (file: any) => new Promise((resolve, reject) => {
  if (!IsImage(file)) {
    resolve(file);
  }
  readFileAsURL(file)
    .then(imgURL => LoadImage(imgURL).then((img) => {
      let imgResized = ResizeCompressImage(img, 2000000);
      if (IsJPEG(file)) {
        imgResized = RestoreEXIF(imgResized, imgURL);
      }

      const dataBlob = DataURItoBlob(imgResized);

      dataBlob.name = file.name;

      resolve(dataBlob);
    }))
    .catch((err) => {
      reject(err);
    });
});
