// @ts-nocheck
import { useEffect, useState } from 'react';

import { stringContains } from 'utils/format';
import type { InternalHire, Subcontractor } from './types';

export const HireTypes = {
  INTERNAL: 'Hire',
  SUBCONTRACTOR: 'Subcontractor',
  CLIENT: 'CompanyClient',
  GUEST: 'Guest',
};

export const PermissionTypes = {
  ON_GOING: 'ON_GOING',
  PROJECT: 'PROJECT',
  HOUR: 'HOUR',
};

export const getCompanyClientPermission = (type: string) => {
  switch (type) {
    case PermissionTypes.ON_GOING:
      return 'Ongoing';
    case PermissionTypes.PROJECT:
      return 'Per Project';
    case PermissionTypes.HOUR:
      return 'For the Day';
    default:
      return '-';
  }
};

export const internalHiresFilter = (filter: string, hire: InternalHire, roles: any) => (
  (!filter
  || stringContains(`${hire.firstName || ''} ${hire.lastName || ''}`, filter)
  || stringContains(hire.companyName, filter)
  || stringContains(hire.truckNumber, filter)
  || stringContains(hire.licensePlate, filter))
  && (!roles
    || Object.entries(roles).filter(([, value]) => value === true).length === 0
    || hire.roles.some((role: any) => roles[role]))
);

export const hireTagFilter = (filters: [string], hire: InternalHire | Subcontractor) => {
  const tags = hire && hire.hireTags && hire.hireTags.map((ht: any) => ht.id);
  let result = true;
  filters.forEach((f) => {
    if (!tags || tags.indexOf(f) <= -1) {
      result = false;
    }
  });
  return (result);
};

export const subcontractorsFilter = (filter: string, hire: Subcontractor) => (
  (!filter
    || stringContains(hire.companyName, filter)
    || stringContains(hire.name, filter)
  )
);

export const clientsFilter = (filter: string, companyClient: any) => (
  !filter || stringContains(companyClient.client.name, filter)
);

// Build a map of toggle keys to booleans based off a list of items
//
// `keyFn` takes an item and returns a list of toggle keys from that item
//
// Toggle keys are de-duplicated.

export const useToggles = (items: any[], keyFn: (any) => string[], defaultVal = false) => {
  const [toggles, setToggles] = useState(Object.create(null));

  useEffect(() => {
    const newToggles = Object.create(null);

    items.forEach((item) => {
      const keys = keyFn ? keyFn(item) : item;

      if (Array.isArray(keys)) {
        keys.forEach((key) => { newToggles[key] = defaultVal; });
      } else {
        newToggles[keys] = defaultVal;
      }
    });

    setToggles(newToggles);
  }, [items]);

  const toggle = (key: any) => {
    setToggles((t: any) => ({
      ...t,
      [key]: !t[key]
    }));
  };

  return [toggles, toggle];
};

export const getToggleOptions = (canViewInternalHires: any, canViewSubcontractors: any) => {
  const toggleOptions = [];
  if (canViewInternalHires) {
    toggleOptions.push({ key: 'toggle-internal', label: 'Internal', value: HireTypes.INTERNAL });
    toggleOptions.push({ key: 'toggle-guests', label: 'Guests', value: HireTypes.GUEST });
  }
  if (canViewSubcontractors) {
    toggleOptions.push({ key: 'toggle-subcontractor', label: 'Subcontractors', value: HireTypes.SUBCONTRACTOR });
  }
  toggleOptions.push({ key: 'toggle-clients', label: 'Clients', value: HireTypes.CLIENT });
  return toggleOptions;
};

export const getAddAccountButtonName = (currentHireType: any) => {
  if (currentHireType === HireTypes.INTERNAL) {
    return 'Add Driver';
  }
  if (currentHireType === HireTypes.SUBCONTRACTOR) {
    return 'Add Subcontractor';
  }
  if (currentHireType === HireTypes.CLIENT) {
    return 'Add Client';
  }
  if (currentHireType === HireTypes.GUEST) {
    return 'Add Guest';
  }
  return 'Add New';
};
