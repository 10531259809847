// @ts-nocheck
import classNames from 'classnames';
import React from 'react';

import ClickableDiv from 'common-ui/ClickableDiv';
import styles from './styles.module.scss';

type DropdownOption = {
  label: string,
  description?: string,
  onClick: () => void,
  disabled?: boolean,
}

type DropdownSelectionProps = {
  options: DropdownOption[],
  id?: string,
}

const DropdownSelection = ({
  options,
  id,
}: DropdownSelectionProps) => (

  <>
    {options.map((o, i) => (

      <ClickableDiv
        key={`download-button-${o.label}-${o.description}`}
        id={o.id ? o.id : `${id}-option-${i}`}
        onClick={o.onClick}
        disabled={!!o.disabled}

        className={classNames(styles.dropdownOptionContainer, { [styles.disabled]: !!o.disabled })}
      >
        {o.icon && (
          <div className={styles.displayIcon}>{o.icon}</div>
        )}

        <div>
          {o.label && (
            <div className={styles.title}>{o.label}</div>
          )}
          {o.description && (
            <div className={styles.description}>{o.description}</div>
          )}
        </div>
      </ClickableDiv>
    ))}
  </>
);

DropdownSelection.defaultProps = {
  id: undefined,
};

export default DropdownSelection;
