import React from 'react';
import styles from './styles.module.scss';
const termsLink = 'https://tread.io/tread-terms-of-subscription/';
const privacyLink = 'https://tread.io/privacy-policy';
const copyRightLink = 'https://tread.io/';
export const Footer = ()=>{

  const year = new Date()?.getFullYear();
  return(
    <div className={styles.footerContainer}>
      <div className={styles.footerItem}><a href={copyRightLink} target="_blank" rel="noopener noreferrer">© {year} Tread</a></div>
      <div className={styles.footerItem}> <a href={termsLink} target="_blank" rel="noopener noreferrer">Terms</a></div>
      <div className={styles.footerItem}> <a href={privacyLink} target="_blank" rel="noopener noreferrer">Privacy Policy</a></div>
    </div>
  );
};
