// @ts-nocheck
import React  from 'react';
import { createPortal } from 'react-dom';

const fullScreenPortalID = 'full-screen-portal';

// The full screen component will be rendered wherever this is placed
// Intended usage is to place it outside where the window header is created

export const FullScreenPortal = () => <div id={fullScreenPortalID} />;

export const useFullScreenRender = () => {
  const renderFullScreen = (component: Node) => {
    const fullScreenPortal = document.getElementById(fullScreenPortalID);
    if (!fullScreenPortal) return null;
    return createPortal(component, fullScreenPortal);
  };
  return renderFullScreen;
};
