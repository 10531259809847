// @ts-nocheck
import React from 'react';
import { FormattedMessage } from 'react-intl';


import { useAddSite } from '../../graphql';

import {  AddFormPropTypes } from '../../types';
import { geofenceTypes, siteTypes, siteStatuses } from '../../utils/site.utils';
import BaseForm from './BaseForm';



const AddFormUI = ({
  ...props
}: AddFormPropTypes) => {
  const addSite = useAddSite();

  return (

    <BaseForm
      title={(

        <FormattedMessage
          id="sites.add.title"
          defaultMessage="Create Site"
        />
      )}
      submitText={(

        <FormattedMessage
          id="sites.add.submit"
          defaultMessage="Add Site"
        />
      )}
      initialValues={{
        status: siteStatuses.ACTIVE,
        name: '',
        location: '',
        pointLocation: null,
        radius: '200',
        geoPoints: [],
        products: [],
        siteType: siteTypes.SOURCE_RECEIVING,
        notes: '',
        geofenceType: geofenceTypes.CIRCLE,
      }}
      onSubmit={addSite}
      {...props}
    />
  );
};

export default AddFormUI;
