import React, {SVGProps} from 'react';

// NOTE: To convert from SVG, you must rename any 'xlink:href' to
// 'xlinkHref' and 'xmlns:xlink' to 'xmlnsXlink'
// https://github.com/facebook/react/issues/4908
const Stop = (props: SVGProps<SVGSVGElement>) => (

  <svg
    width="16px"
    height="16px"
    viewBox="0 0 16 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >

    <title>Stop</title>

    <defs>

      <rect id="stop-path-1" x="0" y="0" width="16" height="16" />
    </defs>

    <g
      id="stop-Components"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >

      <g
        id="stop-Colors,-Text,-Icons"
        transform="translate(-480.000000, -1874.000000)"
      >

        <g id="stop-Icon/Stop" transform="translate(475.000000, 1869.000000)">
          <g id="stop-Colors/White" transform="translate(5.000000, 5.000000)">
            <g id="stop-Colors/Black" mask="url(#stop-mask-2)" fill="#000000">
              <g
                transform="translate(-2.000000, -3.000000)"
                id="stop-Rectangle-18"
              >
                <rect x="0" y="0" width="20" height="20" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Stop;
