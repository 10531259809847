// @ts-nocheck
import classNames from 'classnames';
import React from 'react';

import Analytics from 'common-ui/Analytics/SegmentAnalytics';
import {Actions} from 'common-ui/Analytics/types';

import { MinimalButton } from 'common-ui/Button';
import { ChevronLeft, ChevronRight } from 'common-ui/Icon';
import styles from './styles.module.scss';
import type { SidebarPropTypes } from './types';

const ToggleableSidebar = ({
  className,
  visible,
  setVisible,
  children,
}: SidebarPropTypes) => (

  <>
    { visible && (

      <div className={classNames(styles.sidebar, className)}>
        {children}
      </div>
    )}

    <div className={styles.sidebarDivider}>

      <MinimalButton
        onClick={() => {
          setVisible(!visible);
          Analytics.trackClick(Actions.sidebarToggleClick);
        }}
        className={styles.sidebarButton}
      >

        {visible ? <ChevronLeft /> : <ChevronRight />}
      </MinimalButton>
    </div>
  </>
);

export default ToggleableSidebar;
