// @ts-nocheck
import classNames from 'classnames';
import React, { useRef, useState } from 'react';

import AutosizeInput from 'react-input-autosize';


import useAddTag from '../useAddTag';
import useCompanyTags from '../useCompanyTags';
import useTagsMap from '../useTagsMap';
// eslint-disable-next-line import/namespace
import ClickableDiv from 'common-ui/ClickableDiv';
import { TagChip } from 'common-ui/Form';
import { Eyeglass, Tag } from 'common-ui/Icon';
import TagListItem from './TagListItem';
import styles from './styles.module.scss';

type TagListProps = {
  tags: string[],
  setTags: (param:string[]) => void,
  filterOnly?: boolean,
  icon?: any,
  closeOnEnter?: () => void,
};

const TagList = ({ tags, setTags, filterOnly, icon, closeOnEnter }: TagListProps) => {
  const companyTags = useCompanyTags();
  const addTag = useAddTag();

  const tagsSet = new Set(tags);

  const tagsMap = useTagsMap(companyTags);

  const tagNamesSet = new Set(companyTags.map(t => t.name));

  const [filter, setFilter] = useState('');

  const inputRef = useRef();

  let placeholderText = 'Type to search or create a new tag';
  if (filterOnly) {
    placeholderText = 'Type to search for a tag';
  }

  return (

    <div className={styles.tagList}>

      <div className={classNames(styles.tagListInput, { [styles.icon]: icon })}>

        {icon || <Eyeglass /> }

        <div className={styles.inputSection}>
          {tags.map(tagId => (

            <TagChip
              key={`${tagId}`}
              name={tagsMap[tagId]}
              removable

              onClick={() => setTags(tags.filter(t => t !== tagId))}
            />
          ))}
          <ClickableDiv className={styles.autoInputWrapper} onClick={() => inputRef.current.focus()}>
            <AutosizeInput
              inputClassName={styles.tagListInputElement}
              placeholder={placeholderText}
              value={filter}
              ref={inputRef}
              autoFocus

              onChange={e => setFilter(e.target.value)}

              onKeyDown={(e) => {
                if (e.key === 'Enter') {

                  const tagList = companyTags.filter(t => !tagsSet.has(t.id)).filter(t => !filter.trim() || t.name.toLowerCase().includes(filter.trim().toLowerCase()));
                  if (filterOnly || tagList.length) {

                    setTags([...tags, tagList[0].id]);

                    closeOnEnter();
                  } else if (!tagNamesSet.has(filter.trim()) && filter.trim() !== '') {

                    addTag(filter.trim()).then(newTag => setTags([...tags, newTag && newTag.id]));
                  }
                  setFilter('');
                } else if ((e.key === 'Delete' || e.key === 'Backspace') && filter === '') {

                  setTags(tags.splice(0, tags.length - 1));
                }
              }}
            />
          </ClickableDiv>
        </div>
      </div>

      <div className={styles.tagListItems}>
        {companyTags

          .filter(t => !tagsSet.has(t.id))

          .filter(t => !filter.trim() || t.name.toLowerCase().includes(filter.trim().toLowerCase()))

          .map(t => (
            <TagListItem
              key={t.id}
              label={t.name}
              onClick={() => {

                setTags([...tags, t.id]);
                setFilter('');

                closeOnEnter();
              }}
            />
          ))}
        {!!filter.trim() && !tagNamesSet.has(filter.trim()) && !filterOnly && (
          <TagListItem
            label={(
              <div className={styles.createTagOption}>
                <Tag />
                Create new tag:&nbsp;
                {filter}
              </div>
            )}
            onClick={() => {

              addTag(filter.trim()).then(newTag => setTags([...tags, newTag && newTag.id]));
              setFilter('');
            }}
          />
        )}
      </div>
    </div>
  );
};

TagList.defaultProps = {
  filterOnly: false,
  icon: undefined,
  closeOnEnter: () => {},
};

export default TagList;
