// @ts-nocheck
import classNames from 'classnames';
import moment from 'moment';
import React from 'react';

import { Calendar } from 'common-ui/Icon';

import { Calendar as CalendarControl, MultiSelectCalendar } from 'components/Common/Calendar';

import { useOutsideClickHandler } from 'utils/Hooks';
import styles from './styles.module.scss';
import type { MultiSelectCalendarProps } from './MultiSelectCalendar';


type DateControlProps = {

  date: moment,
  setSelectedDate: (moment: any) => void,
  displayClassName?: string,
  showDisplay: boolean,
  openPopupLeft: boolean,
  isShowOpenStyle: boolean,
};

export const MobileDateControl = ({
  date,
  setSelectedDate,
  displayClassName,
  showDisplay = true,
  openPopupLeft = true,
  isShowOpenStyle = false,
  ...calendarProps
}: DateControlProps) => {
  const [popupVisible, setPopupVisible] = React.useState(false);
  const dateControlRef = React.useRef();
  const openCalendarPopupStyle = openPopupLeft ? styles.calendarPopup : styles.calendarPopupRight;
  useOutsideClickHandler(dateControlRef, () => setPopupVisible(false));
  return (

    <div ref={dateControlRef} style={{ position: 'relative' }}>

      <div
        className={styles.dateControl}
        onClick={() => setPopupVisible(!popupVisible)}
        onKeyPress={() => setPopupVisible(!popupVisible)}
        role="button"
        tabIndex={0}
      >

        <div className={classNames(styles.dateControlIcon, { [styles.dateBorder]: popupVisible && isShowOpenStyle })}>
          <Calendar />
        </div>
        {showDisplay && (
          <div className={classNames(styles.dateControlDateDisplay, displayClassName)}>

            {moment(date).format('ddd MMM D')}
          </div>
        )}
      </div>

      <div className={classNames(openCalendarPopupStyle, { [styles.visible]: popupVisible }, {[styles.dateBorder]: popupVisible && isShowOpenStyle})}>

        <CalendarControl
          selectedDate={date}
          setSelectedDate={(...args: any[]) => {

            setSelectedDate(...args);
            setPopupVisible(false);
          }}
          isShowOpenStyle={isShowOpenStyle}
          isOpen={popupVisible}
          {...calendarProps}
        />
      </div>
    </div>
  );
};

MobileDateControl.defaultProps = {
  displayClassName: undefined,
};

export const MultiMobileDateControl = ({
  selectedDates,
  setSelectedDates,
  className,
  ...calendarProps
}: MultiSelectCalendarProps) => {
  const [popupVisible, setPopupVisible] = React.useState(false);
  const dateControlRef = React.useRef();
  useOutsideClickHandler(dateControlRef, () => setPopupVisible(false));
  return (

    <div ref={dateControlRef} style={{ position: 'relative' }}>

      <div
        className={styles.dateControl}
        onClick={() => setPopupVisible(!popupVisible)}
        onKeyPress={() => setPopupVisible(!popupVisible)}
        role="button"
        tabIndex={0}
      >

        <div className={styles.dateControlIcon}>
          <Calendar />
        </div>

        <div className={styles.dateControlDateDisplay}>
          {!selectedDates[0].isSame(selectedDates[1], 'day') && (
            <>
              {selectedDates[0].format('MMM D')}
              &nbsp;-&nbsp;
            </>
          )}
          {selectedDates[1].format('MMM D')}
        </div>
      </div>

      <div className={classNames(styles.calendarPopup, className, { [styles.visible]: popupVisible })}>

        <MultiSelectCalendar
          selectedDates={selectedDates}
          setSelectedDates={(...args: any[]) => {

            setSelectedDates(...args);
            setPopupVisible(false);
          }}
          {...calendarProps}
        />
      </div>
    </div>
  );
};
