import React from 'react';

import Flag from 'components/Common/Flag';

import { useCompanyContext } from 'utils/Hooks';

const NewEquipmentFlag = ({
  name
}: any) => {
  const { selectedCompanyID } = useCompanyContext();
  return (

    <Flag
      style={Flag.Styles.SUCCESS}
      title={`${name} created`}
      body="Add more details like code and notes in Settings."
      linkText="Manage Equipment"
      linkRef={`settings/data?company=${selectedCompanyID}`}
    />
  );
};

export default NewEquipmentFlag;
