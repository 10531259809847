// @ts-nocheck
import { useEffect } from 'react';

type Ref = { current: null | HTMLDivElement };

// Pass variables that should trigger a rescroll to rescrollOn

const useScrollToRef = (elementRef: Ref, rescrollOn?: any) => {
  const ref = elementRef;
  useEffect(() => {
    if (ref.current && ref.current.parentNode) {
      ref.current.parentNode.scrollTop = (
        ref.current.offsetTop - ref.current.parentNode.offsetTop
      );
    }
  }, [rescrollOn]);
};

export default useScrollToRef;
