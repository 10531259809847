// @ts-nocheck
import React, { ReactNode } from 'react';
import { intlShape } from 'react-intl';
import { Button } from 'common-ui/Button';

import { injectIntlHOC } from 'utils/HOC';
import styles from './styles.module.scss';

type UpdateModalProps = {
  children: ReactNode,
  onConfirm: () => void,
  onClose: () => void,

  intl: intlShape,
  cancelText: ReactNode,
  updateText: ReactNode,
  headerText: ReactNode,
};

const UpdateModal = ({
  children,
  onConfirm,
  onClose,
  cancelText,
  updateText,
  headerText,
  intl,
}: UpdateModalProps) => (

  <div className={styles.baseModal}>

    <div className={styles.baseModalHeader}>

      <div className={styles.textHeader}>
        {headerText || intl.formatMessage({ id: 'modals.update_header', defaultMessage: 'Update' })}
      </div>
    </div>
    {children}

    <div className={styles.baseModalButtons}>

      <Button
        text={cancelText || intl.formatMessage({ id: 'modals.cancel_button', defaultMessage: 'Cancel' })}
        onClick={onClose}
      />

      <Button
        text={updateText || intl.formatMessage({ id: 'modals.update_button', defaultMessage: 'Yes, Update' })}
        className={styles.actionButton}
        onClick={() => {
          onConfirm();
          onClose();
        }}
      />
    </div>
  </div>
);

export default injectIntlHOC()(UpdateModal);
