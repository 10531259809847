// @ts-nocheck
import { useMutation, gql } from '@apollo/client';
import { useCallback } from 'react';

const UPDATE_PROJECT_GUESTS_MUTATION = gql`
  mutation updateProjectGuests($id: ID!, $collaboratorIDs: [ID!]!) {
    updateProjectGuests(id: $id, collaboratorIDs: $collaboratorIDs){
      id
      collaborators {
        id
        accountId
        companyName : externalCompanyName
        roles
        phoneNumber
      }
    }
  }
`;

const useUpdateProjectGuests = () => {
  const [updateProjectGuests] = useMutation(UPDATE_PROJECT_GUESTS_MUTATION);
  return useCallback((id: string, collaboratorIDs: string[]) => {
    updateProjectGuests({
      variables: {
        id,
        collaboratorIDs: collaboratorIDs.map(i => i.toString()),
      },
    });
  }, [updateProjectGuests]);
};

export default useUpdateProjectGuests;
