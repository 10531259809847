import { useState, useEffect } from 'react';

const useDebouncedValue = (value: any, timeout: any) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  // Only set the debouncedValue if value hasn't changed within timeout
  // Otherwise, useEffect will call the cleanup function
  // and timeoutHandler will not have changed the debouncedValue
  useEffect(() => {
    const timeoutHandler = setTimeout(() => {
      setDebouncedValue(value);
    }, timeout);

    return () => {
      clearTimeout(timeoutHandler);
    };
  }, [value]);

  return debouncedValue;
};

export default useDebouncedValue;
