// @ts-nocheck
import { useEffect } from 'react';

type Ref = { current: null | HTMLDivElement };

// If there are some outside variables used in the clickHandler,
// pass them in the dependents variable/array

const useOutsideClickHandler = (elementRef: Ref, clickHandler: () => void, dependents?: any) => {
  const handleClick = (e: MouseEvent) => {
    if (e.target instanceof Node
      && elementRef
      && elementRef.current
      && !elementRef.current.contains(e.target)
    ) {
      clickHandler();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [].concat(dependents));
};

export default useOutsideClickHandler;
