// @ts-nocheck
import React, { ReactNode } from 'react';
import { intlShape } from 'react-intl';
import { Button } from 'common-ui/Button';

import { injectIntlHOC } from 'utils/HOC';
import styles from './styles.module.scss';


type InfoModalProps = {
  onClose: () => void,

  intl: intlShape,
  textHeader: ReactNode,
  textBody: ReactNode,
  children: ReactNode,
};

const InfoModal = ({
  onClose,
  textHeader,
  textBody,
  intl,
  children,
}: InfoModalProps) => (

  <div className={styles.baseModal}>

    <div className={styles.baseModalHeader}>

      <div className={styles.textHeader}>
        {textHeader}
      </div>
    </div>

    <div className={styles.textBody}>
      {textBody}
    </div>
    {children}

    <div className={styles.baseModalButtons}>

      <Button
        fullWidth
        text={intl.formatMessage({ id: 'modal_templates.info.okay_button', defaultMessage: 'Okay, got it' })}
        styleName="primary"
        onClick={onClose}
      />
    </div>
  </div>
);

export default injectIntlHOC()(InfoModal);
