// @ts-nocheck
import PhoneNumber from 'awesome-phonenumber';
import classNames from 'classnames';
import React, { useState, useRef } from 'react';

import ClickableDiv from 'common-ui/ClickableDiv';
import { Ellipsis } from 'common-ui/Icon';


import { CollaboratorOption } from 'components/Common/Form/CollaboratorInput/components';
import { AllAccountRoles } from 'pages/Settings/PersonalSettings/utils';
import { useOutsideClickHandler } from 'utils/Hooks';
import styles from './styles.module.scss';



type CollaboratorShareCardProps = {
  collaborator: any, // TODO: type
  onEdit: () => void,
  onRemove: () => void,
};

const CollaboratorShareCard = ({
  collaborator,
  onEdit,
  onRemove,
}: CollaboratorShareCardProps) => {
  const menuRef = useRef();
  const [menuOpen, setMenuOpen] = useState(false);

  // TODO: depend on external vs internal
  const canEdit = (collaborator && (collaborator.role === AllAccountRoles.DOT || collaborator.role === AllAccountRoles.EXTERNALSUPERVISOR)) || false;

  useOutsideClickHandler(menuRef, () => setMenuOpen(false));

  const readablePhoneNumber = collaborator ? new PhoneNumber(collaborator.phoneNumber).getNumber('national') : '';

  return (

    <div className={styles.collaboratorShareCard}>

      <CollaboratorOption
        firstName={collaborator ? collaborator.firstName : ''}
        lastName={collaborator ? collaborator.lastName : ''}
        companyName={collaborator ? collaborator.companyName : ''}
        secondaryText={readablePhoneNumber}
      />

      <div className={styles.collaboratorRole}>{collaborator ? collaborator.role : ''}</div>

      <ClickableDiv
        ref={menuRef}
        className={styles.collaboratorMenuButton}
        onClick={() => setMenuOpen(!menuOpen)}
      >

        <div className={styles.hoverContainer}>
          <div className={styles.iconContainer}>
            <Ellipsis className={styles.collaboratorMenuIcon} />
            <div className={classNames(styles.collaboratorMenu, { [styles.visible]: menuOpen })}>
              {canEdit && (
                <ClickableDiv
                  className={styles.collaboratorMenuItem}
                  onClick={onEdit}
                >
                  Edit Guest
                </ClickableDiv>
              )}
              <ClickableDiv

                className={classNames(styles.collaboratorMenuItem, styles.removeOption)}
                onClick={onRemove}
              >
                Remove From Project
              </ClickableDiv>
            </div>
          </div>
        </div>
      </ClickableDiv>
    </div>
  );
};

export default CollaboratorShareCard;
