// @ts-nocheck
import classNames from 'classnames';
import React from 'react';


import MultiSelectWithoutValues from '../components/MultiSelectWithoutValues';

import SelectedTermOption from '../components/SelectedTermOption';

import { Close, Eyeglass } from 'common-ui/Icon';
import styles from './styles.module.scss';
import type { SearchUIProps } from '../types';


const SearchUI = ({
  disabled,
  selectedTerms,
  possibleOptions,
  onCreateOption,
  onSelectTerm,
  clearAll,
  onClickClear,
  placeholderText,
  clearAllText,
  formatCreateLabel,
  includeClose,
  className,
}: SearchUIProps) => (

  <div className={classNames(styles.searchWrapper, className)}>

    <div className={styles.wrapSelection}>

      <div className={styles.searchArea}>

        <MultiSelectWithoutValues
          disabled={disabled}
          {...{
            onSelectTerm,
            onCreateOption,
            possibleOptions,
            selectedTerms,
            placeholderText,
            formatCreateLabel,
          }}
        />

        <div className={styles.eyeglassIcon}>
          <Eyeglass />
        </div>
      </div>
    </div>
    {selectedTerms.length > 0 && (

      <div className={styles.filterList}>
        {selectedTerms.map((selectedTerm) => {
          const { filterType, filterValue, value } = selectedTerm;
          const filterText = filterType
            ? `${filterType.label}: ${filterValue}`
            : filterValue;

          return (
            <SelectedTermOption
              key={value}
              id={value}
              term={selectedTerm}
              onClickClear={onClickClear}
            >
              {filterText}
            </SelectedTermOption>
          );
        })}
        {includeClose && selectedTerms.length > 0 && (
          <button
            className={styles.clearAllButton}
            onClick={clearAll}
            type="button"
          >
            {clearAllText}
            <div className={styles.clearButton}>
              <Close />
            </div>
          </button>
        )}
      </div>
    )}
  </div>
);

SearchUI.defaultProps = {
  placeholderText: 'Start typing to filter e.g. \'Client:\'...',
  formatCreateLabel: (label: any) => (label.length === 0) ? 'Filter with ...' : `Filter with "${label}"`,
  clearAllText: 'Clear all',
  includeClose: false,
  className: null,
};

export default SearchUI;
