/* eslint-disable radix */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck has to be fixed
import { capitalize, memoize, concat, orderBy } from 'lodash';
import moment from 'moment-timezone';
import { textForStatus } from 'components/Common/Labels';
import downloadFilePrompt from 'utils/download';
import { quantityUnits } from 'utils/format/rateUnits';
import { isAssignmentTypeSmart, getAssignmentPickUpAndPickUpSite } from './assignments.utils';
import type {
  ModifiedProjectType,
  ModifiedAssignmentType,
  AssignmentKey,
  // eslint-disable-next-line import/namespace
} from '../types';

export const assignedStatuses = {
  MISSING: 'MISSING',
  DRAFT: 'DRAFT',
  SENT: 'SENT',
  CANCELLED: 'CANCELLED',
  ACCEPTED: 'ACCEPTED',
  PENDING: 'PENDING',
  LOGGING: 'LOGGING',
  PAUSED: 'PAUSED',
  DONE: 'DONE',
  DECLINED: 'DECLINED',
  AUTO: 'AUTO',
  UNASSIGNED: 'UNASSIGNED',
  REASSIGNED: 'REASSIGNED',
};

export const sendStatuses = {
  ACCEPTED: 'ACCEPTED',
  PENDING: 'PENDING',
  LOGGING: 'LOGGING',
  DONE: 'DONE',
  TERMINATED: 'TERMINATED',
};

export const instanceStatuses = {
  TERMINATED: 'TERMINATED',
  REASSIGNED: 'REASSIGNED',
  CANCELLED: 'CANCELLED',
};

export const projectViews = {
  LIST: 'LIST',
  SCHEDULE: 'SCHEDULE',
};

export const projectTypes = {
  AGGREGATE: 'aggregate',
  EXCAVATION: 'excavation',
  PAVING: 'paving',
  OTHER: 'other',
};

export const projectTypeOptions = [
  { label: 'Aggregate', value: projectTypes.AGGREGATE },
  { label: 'Excavation', value: projectTypes.EXCAVATION },
  { label: 'Paving', value: projectTypes.PAVING },
  { label: 'Other', value: projectTypes.OTHER },
];

// these values are used when filtering smart and classic projects
export const projectTypesForFiltering = {
  SMART: 'Smart',
  CLASSIC: 'Classic',
  APEX: 'Apex',
};

export const loadTracking = {
  GPS: 'GPS',
  TICKET: 'TICKET',
};

export const formatQuantity = ({ quantity, quantityUnit }) => {
  let ret = '-';
  if (quantity > 0 && quantityUnit === quantityUnits.LOAD) {
    ret = `${quantity} Loads`;
  } else if (quantity > 0 && quantityUnit === quantityUnits.TONNE) {
    ret = `${quantity} Tonnes`;
  } else if (quantity > 0 && quantityUnit === quantityUnits.TON) {
    ret = `${quantity} Tons`;
  } else if (quantity > 0 && quantityUnit === quantityUnits.YARD) {
    ret = `${quantity} Yards`;
  } else if (quantity > 0 && quantityUnit === quantityUnits.METER) {
    ret = `${quantity} Meters`;
  } else if (quantity > 0 && quantityUnit === quantityUnits.LITER) {
    ret = `${quantity} Liters`;
  } else if (quantity > 0 && quantityUnit === quantityUnits.FOOT) {
    ret = `${quantity} Feet`;
  } else if (quantity > 0 && quantityUnit === quantityUnits.GALLON) {
    ret = `${quantity} Gallons`;
  } else if (quantity > 0 && quantityUnit === quantityUnits.BUSHEL) {
    ret = `${quantity} Bushels`;
  } else if (quantity > 0 && quantityUnit === quantityUnits.CUBICMETER) {
    ret = `${quantity} Cubic Meters`;
  }
  return ret;
};

export const getScheduledStart = (copyDate, project) => {
  // TODO when we get user data, use user's timezone
  const startTime = moment(project.startTime);
  const scheduledStart = moment(copyDate).set({
    hour: startTime.hour(),
    minute: startTime.minute(),
    second: startTime.second(),
  });

  return scheduledStart;
};

// Update Attachmnts if they are not attached to the correct companyId
export const updateProjectAttachments = ({ attachments, companyId, updateAttachments }) => {
  // get the Attachments that are already attached to the correct Project.
  const companyAttachments = attachments.filter(a => {
    const {
      input: { id },
    } = a;
    return parseInt(id, 10) === parseInt(companyId, 10);
  });

  // get the attachments that are not yet attached to the correct Project and attach them.
  const attachmentsToUpdate = attachments.filter(a => {
    const {
      input: { id },
    } = a;
    return parseInt(id, 10) !== parseInt(companyId, 10);
  });

  // / Update Attachments if there are any to update the company for
  const updateAttachmentsPromise =
    attachmentsToUpdate.length === 0
      ? Promise.resolve([])
      : updateAttachments({
        variables: {
          input: {
            attachments: attachmentsToUpdate.map(a => ({
              getURL: a.getURL,
              filename: a.filename,
            })),
            companyId: parseInt(companyId, 10),
            domain: 'COMPANY',
          },
        },
      }).then(results => {
        const {
          data: { updateAttachments: updatedAttachments },
        } = results;
        return updatedAttachments.map(a => ({ getURL: a.getURL, filename: a.filename }));
      });

  return updateAttachmentsPromise.then(updatedAttachments => {
    const finalAttachments = concat(updatedAttachments, companyAttachments);
    return finalAttachments;
  });
};

// If there is no end Time, assume the start Time plus 1 day is the end Time.
export const getProjectEndTime = project => {
  const endTime = project.endTime
    ? moment(project.endTime)
    : moment(project.startTime).add(1, 'day');

  return endTime;
};

export const keyForAssignment = (assignment: ModifiedAssignmentType): AssignmentKey =>
  `${assignment.id}`;

export const calculateAssignmentHires = (projects: ModifiedProjectType[]) => {
  const assignmentHires = {};
  const scheduleList = {};
  projects.forEach(project => {
    project.assignments.forEach(assignment => {
      if (assignment.assignedTo) {
        const hireId = assignment.assignedTo;
        if (!scheduleList[assignment.scheduleId]) {
          assignmentHires[hireId] = assignmentHires[hireId] + 1 || 1;
          if (assignment.scheduleId) {
            scheduleList[assignment.scheduleId] = true;
          }
        }
      }
    });
  });
  return assignmentHires;
};

export const calculateAssignmentSubcontractors = (projects: ModifiedProjectType[]) => {
  const assignmentSubs = {};
  const smartAssignmentList = {};
  projects.forEach(project => {
    project.assignments.forEach(assignment => {
      if (assignment.subcontractedTo) {
        const subcontractorId = assignment.subcontractedTo;
        const assignmentList = smartAssignmentList[subcontractorId] || [];
        const scheduleIdCounted = assignmentList.some(el => el === assignment.scheduleId);

        if (assignment.scheduleId && !scheduleIdCounted) {
          assignmentSubs[subcontractorId] = assignmentSubs[subcontractorId] + 1 || 1;
          smartAssignmentList[subcontractorId] = [...assignmentList, assignment.scheduleId];
        } else if (!assignment.scheduleId) {
          assignmentSubs[subcontractorId] = assignmentSubs[subcontractorId] + 1 || 1;
        }
      }
    });
  });
  return assignmentSubs;
};

export const sendableDraftsForProjects = (
  projects: ModifiedProjectType[],
): ModifiedAssignmentType[] =>
  projects
    .reduce((a, p) => [...a, ...p.assignments], [])
    .filter(a => a.isDraft && (!!a.assignedTo || !!a.subcontractedTo));

const canEditAssignmentFunction = (assignment: ModifiedAssignmentType) => {
  if (!assignment) return false;
  return (
    !assignment.hasEnded &&
    assignment.sendStatus !== assignedStatuses.DONE &&
    assignment.assignedStatus !== assignedStatuses.UNASSIGNED &&
    assignment.assignedStatus !== assignedStatuses.REASSIGNED &&
    assignment.sendStatus !== sendStatuses.TERMINATED &&
    assignment.assignedStatus !== assignedStatuses.CANCELLED
  );
};

export const canEditAssignment = memoize(canEditAssignmentFunction);

export const getStartTime = (date, userTimezone, defaultTime) => {
  if (moment().isSame(date, 'day')) {
    const hours = moment().tz(userTimezone).startOf('hour').hours() + 1;
    return moment().tz(userTimezone).startOf('day').hours(hours).format();
  }
  return defaultTime;
};

export const undefinedOrNull = (obj: any) => obj === undefined || obj === null;

export const getSiteAddress = projectSite => {
  if (projectSite && projectSite.address) {
    return `${projectSite.address}`;
  }
  if (projectSite && projectSite.coordinates) {
    return `${projectSite.coordinates[0].latitude}, ${projectSite.coordinates[0].longitude} `;
  }
  return '';
};

export const projectsToCSV = (
  projects: Array<ModifiedProjectType>,
  timezone: moment.Timezone,
  filename?: string,
) => {
  const header =
    'ID,Date,Dispatch Company,Dispatcher,Project Name,Client Name,Billing Name,Project Type,Job Number,Foreman,Pickup Site Name,Pickup Address,Drop-Off Site Name,Drop-Off Address,Scheduled Start Time,Hire Name,Hire Company,Assigned Status,Operator Status,Created By Hire?,Equipment,Phase,Product,Quantity,Quantity Unit,Project Quantity,Project Quantity Unit,Invoice Rate,Invoice Rate Unit,Hauler Rate,Hauler Rate Unit,Notes,Hire Truck Number,Hire License Plate, Hire Trailer Number';
  let csv = `${header}\n`;
  const timeFormat = 'YYYY-MM-DD HH:mm:ss z';
  projects.forEach(p => {
    const name = p.name ? `"${p.name}"` : '';
    const date = moment(p.startTime).tz(timezone).format(timeFormat);
    const dispatchCompany = p.companyName;
    const dispatcher = `"${p.createdBy.firstName} ${p.createdBy.lastName}"`;
    const clientName = p.client;
    const billingName =
      !undefinedOrNull(p.clientInfo) && !undefinedOrNull(p.clientInfo.billingName)
        ? `${p.clientInfo.billingName}`
        : '';
    const jobNumber = p.jobNumber || '';
    const foreman = p.foremanInfo
      ? `${p.foremanInfo.firstName || ''} ${p.foremanInfo.lastName || ''}`
      : p.foreman || '';
    const createdByHire = p.selfLog || '';
    const projectType = p.type || '';
    // The arrays were bing set to undefined when empty when they should be empty arrays
    const allAssignments = p.assignments
      .concat(p.unassignedAssignments || [])
      .concat(p.reassignedAssignments || [])
      .concat(p.cancelledAssignments);

    allAssignments.forEach(a => {
      const hireName = a?.assignee ? `"${a.assignee.firstName} ${a.assignee.lastName}"` : '';
      const hireCompany = a?.subcontractor ? `"${a.subcontractor.name}"` : '';
      const { id: assignmentID } = a;
      const scheduledStartTime = moment(a.startTime).tz(timezone).format(timeFormat);
      const assignedStatus = a.assignedStatus || '';
      const equipment = a.equipment ? `"${a.equipment}"` : '';
      const phase = a.phase || '';
      const product = a.product ? `"${a.product}"` : '';
      const invoiceRate = a.invoiceRate ? a.invoiceRate : '';
      const invoiceRateUnit = a.invoiceRateUnit || '';
      const haulerRate = a.haulerRate || a.haulerRate === 0 ? a.haulerRate : '';
      const haulerRateUnit = a.haulerRateUnit || '';
      const notes = a.notes ? `"${a.notes}"` : '';
      const [pickUp, pickUpSite] = getAssignmentPickUpAndPickUpSite(a);
      const pickUpSiteName = pickUpSite ? `"${pickUpSite.name}"` : '';
      const pickUpSiteAddress = pickUpSite
        ? `"${getSiteAddress(pickUpSite)}"`
        : `"${pickUp || ''}"`;
      const dropOffSiteName = a.dropOffSite ? `"${a.dropOffSite.name}"` : '';
      const dropOff = a.dropOffSite ? `"${getSiteAddress(a.dropOffSite)}"` : `"${a.dropOff || ''}"`;
      const quantity = a.quantity ? a.quantity : '';
      const quantityUnit = a.quantityUnit || '';
      const projectQuantity = p.quantity || '';
      const projectQuantityUnit = p.quantityUnit || '';

      let truckNumber = '';
      let licensePlate = '';
      let trailerNumber = '';
      if (a.assignee) {
        truckNumber = !undefinedOrNull(a.assignee.instanceTruckNumber)
          ? a.assignee.instanceTruckNumber
          : !undefinedOrNull(a.assignee.truckNumber)
            ? a.assignee.truckNumber
            : '';
        licensePlate = !undefinedOrNull(a.assignee.instanceLicensePlate)
          ? a.assignee.instanceLicensePlate
          : !undefinedOrNull(a.assignee.licensePlate)
            ? a.assignee.licensePlate
            : '';
        trailerNumber = !undefinedOrNull(a.assignee.instanceTrailerNumber)
          ? a.assignee.instanceTrailerNumber
          : !undefinedOrNull(a.assignee.trailerNumber)
            ? a.assignee.trailerNumber
            : '';
      }

      const operatorStatus = a.sendStatus ? textForStatus(a.sendStatus).toUpperCase() : '';

      const row = `${assignmentID},${date},${dispatchCompany},${dispatcher},${name},${clientName},${billingName},${capitalize(
        projectType,
      )},${jobNumber},${foreman},${pickUpSiteName},${pickUpSiteAddress},${dropOffSiteName},${dropOff},${scheduledStartTime},${hireName},${hireCompany},${assignedStatus},${operatorStatus},${createdByHire},${equipment},${phase},${product},${quantity},${quantityUnit},${projectQuantity},${projectQuantityUnit},${invoiceRate},${invoiceRateUnit},${haulerRate},${haulerRateUnit},${notes},${truckNumber},${licensePlate},${trailerNumber}\n`;
      csv += row;
    });
  });
  let downloadFilename = filename;
  if (!filename) {
    downloadFilename = 'projects_download.csv';
  }
  downloadFilePrompt(downloadFilename, csv, 'text/csv;charset=utf-8;');
};

export const getAllProjectIds = projects =>
  projects.length > 0 ? projects.reduce((values, p) => values.concat(p.id), []) : [];

// TODO: Update this check when a flag to check for smart project becomes available
export const isProjectTypeSmart = (type: string): boolean => false;

export const isProjectEmpty = (project: ModifiedProjectType): boolean =>
  !project.assignments.length &&
  !project.assignmentDrafts.length &&
  !project.cancelledAssignments.length;

export const separateSmartAndClassicAssignments = project => {
  const { smartAssignments, classicAssignments } = project.assignments.reduce(
    (acc, a) => {
      // smart assignment
      if (isAssignmentTypeSmart(a)) {
        acc.smartAssignments.push(a);
      } else {
        acc.classicAssignments.push(a);
      }
      return acc;
    },
    { smartAssignments: [], classicAssignments: [] },
  );

  const classicUnassignedAssignments = project.unassignedAssignments || [];
  const classicReassignedAssignments = project.reassignedAssignments || [];

  const cancelledAssignments = project.cancelledAssignments || [];
  const { smartCancelledAssignments, classicCancelledAssignments } = cancelledAssignments.reduce(
    (acc, c) => {
      if (isAssignmentTypeSmart(c)) {
        acc.smartCancelledAssignments.push(c);
      } else {
        acc.classicCancelledAssignments.push(c);
      }
      return acc;
    },
    { smartCancelledAssignments: [], classicCancelledAssignments: [] },
  );

  return {
    classicAssignments,
    smartAssignments,
    smartCancelledAssignments,
    classicUnassignedAssignments,
    classicReassignedAssignments,
    classicCancelledAssignments,
  };
};

export const groupAssignments = (timezone: string) => (project: ModifiedProjectType) => {
  // Since draft assignments take precedence,
  // Map shallow copy of draft to its assignment id
  // TODO get backend to set isDraft
  const hasEnded = !getProjectEndTime(project).isAfter(moment());
  const isSubcontract = !!project.parentId && project.parentId > 0;
  const assignmentDrafts = project.assignmentDrafts.map(d => ({
    ...d,
    isDraft: true,
    project,
    hasEnded,
    isSubcontract,
    startTimeTz: moment(d.startTime).tz(timezone),
  }));
  const assignmentDraftsMap = project.assignmentDrafts.reduce(
    (o, d) => (d.assignmentId ? { ...o, [d.assignmentId]: d } : o),
    {},
  );

  // Strip assignments that have drafts
  const finalAssignments = orderBy(
    project.assignments
      .filter(a => !Object.prototype.hasOwnProperty.call(assignmentDraftsMap, a?.id))
      .map(a => ({
        ...a,
        project,
        hasEnded,
        isSubcontract,
        startTimeTz: moment(a.startTime).tz(timezone),
      }))
      .concat(assignmentDrafts),
    [a => moment(a.startTime), 'id'],
    ['asc', 'asc'],
  );

  // Cancelled Assignments are filterable, and always real (non-draft) assignments.
  const cancelledAssignments = orderBy(
    project.cancelledAssignments
      .filter(a => !Object.prototype.hasOwnProperty.call(assignmentDraftsMap, a?.id))
      .map(a => ({
        ...a,
        isDraft: false,
        project,
        hasEnded,
        isSubcontract,
        startTimeTz: moment(a.startTime).tz(timezone),
      })),
  );

  return {
    ...project,
    isSubcontract,
    hasEnded,
    assignments: finalAssignments,
    cancelledAssignments,
    filterableAssignments: [...finalAssignments, ...cancelledAssignments],
    originalAssignments: [...project.assignments, ...project.cancelledAssignments],
  };
};

export const getClassicProjectsCount = projects =>
  projects.filter(project => !isProjectTypeSmart(project.type)).length;

export const getSmartProjectsCount = projects =>
  projects.filter(project => isProjectTypeSmart(project.type)).length;

export const isCompleteSmartProjectWithEmptyAssignments = project => {
  const hasCompleteSmartProject =
    project.optimizerRequest && project.optimizerRequest.status === 'COMPLETE';

  return isProjectEmpty(project) && hasCompleteSmartProject;
};

// Currently only smart projects without assignments are selectable
// All other projects can be selected using assignments
export const getAllSelectableProjectIds = ({ projects }) => {
  const filteredProjects = projects.filter(
    p =>
      isProjectTypeSmart(p.type) &&
      isProjectEmpty(p) &&
      !isCompleteSmartProjectWithEmptyAssignments(p),
  );

  return filteredProjects.map(p => p.id);
};
export const hasOptimizerRequest = projects => projects.some(project => project.optimizerRequest);

export const combineDateAndTime = (date, time, timezone) =>
  moment.tz(timezone).set({
    hours: time.hour(),
    minutes: time.minute(),
    second: time.second(),
    year: date.year(),
    month: date.month(),
    date: date.date(),
  });
