// @ts-nocheck
import classNames from 'classnames';
import React from 'react';

import { SmallButton } from 'common-ui/Button';
import { Tag } from 'common-ui/Icon';

import DotSeparator from 'components/Common/DotSeparator';
import styles from './styles.module.scss';

type HireTagFilterButtonProps = {
  isSelected: boolean,
  onClick: () => void,
  numSelected: number,
  textButton?: boolean,
  icon?: ReactNode,
}

const HireTagFilterButton = ({
  isSelected, onClick, numSelected, textButton, icon,
}: HireTagFilterButtonProps) => {
  return (
    textButton
      ? (

        <div

          className={classNames(styles.filterTagsTextButton, { [styles.selected]: isSelected })}
        >
          <button
            className={styles.statusText}
            type="button"
            onClick={onClick}
          >
            <>
              Filter Tags
              {isSelected && (
                <>
                  <DotSeparator />
                  {numSelected}
                </>
              )}
            </>
          </button>
        </div>
      )
      : (

        <SmallButton

          className={classNames(styles.filterTagsIconButton, { [styles.selected]: isSelected })}
          icon={icon || <Tag />}
          onClick={onClick}
        />
      )
  );
};

HireTagFilterButton.defaultProps = {
  textButton: false,
  icon: null,
};

export default HireTagFilterButton;
