import React, {SVGProps} from 'react';

const InverseConflict = (props: SVGProps<SVGSVGElement>) => (
   
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
     
    <circle
      cx="10.0001"
      cy="9.99984"
      r="7.5"
      fill="white"
      stroke="#E2164A"
      strokeWidth="1.66667"
    />
     
    <circle cx="10.0001" cy="13.3333" r="0.833333" fill="#E2164A" />
     
    <rect
      x="9.16675"
      y="5.8335"
      width="1.66667"
      height="5.83333"
      rx="0.833333"
      fill="#E2164A"
    />
  </svg>
);

export default InverseConflict;
