// @ts-nocheck
import classNames from 'classnames';
import moment, {  Moment } from 'moment';
import React, { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';

import { Check, Pause } from 'common-ui/Icon';
import type { AssignedStatus, SendStatus } from 'pages/Projects/types';
import styles from './styles.module.scss';


const textForStatus = (assignedStatus: AssignedStatus, sendStatus: SendStatus, sentTime?: Moment) => {
  // Send (operator) status is higher specificity than assigned status
  switch (sendStatus) {
    case 'PENDING':
      return 'Pending';
    case 'ACCEPTED':
      return 'Accepted';
    case 'LOGGING':
      return 'Logging';
    case 'PAUSED':
      return 'Paused';
    case 'DONE':
      // Auto is a special case that takes higher priority
      return assignedStatus === 'AUTO' ? 'Auto' : 'Done';
    case 'TERMINATED':
      return assignedStatus === 'CANCELLED' ? 'Cancelled' : 'Ended';
    default:
      // Fallback to assigned status
  }

  // Fallback to assigned status when there's no send (operator) status
  switch (assignedStatus) {
    case 'MISSING':
      return 'Missing';
    case 'DRAFT':
      return 'Draft';
    case 'SENT':
      return 'Sent';
    case 'CANCELLED':
      return 'Cancelled';
    case 'AUTO':
      return 'Auto';
    case 'UNASSIGNED':
      return 'Unassigned';
    case 'REASSIGNED':
      return 'Reassigned';
    default:
      return '';
  }
};

const classNameForStatus = (assignedStatus: AssignedStatus, sendStatus: SendStatus) => {
  // Send (operator) status is higher specificity than assigned status
  switch (sendStatus) {
    case 'PENDING':
      return styles.pending;
    case 'ACCEPTED':
      return styles.accepted;
    case 'LOGGING':
      return styles.logging;
    case 'PAUSED':
      return styles.paused;
    case 'DONE':
      return styles.done;
    case 'TERMINATED':
      return assignedStatus === 'CANCELLED' ? styles.cancelled : styles.terminated;
    default:
      // Fallback to assigned status
  }

  // Fallback to assigned status when there's no send (operator) status
  switch (assignedStatus) {
    case 'MISSING':
      return styles.missing;
    case 'DRAFT':
      return styles.draft;
    case 'SENT':
      return styles.sent;
    case 'CANCELLED':
      return styles.cancelled;
    case 'AUTO':
      return styles.done;
    case 'UNASSIGNED':
      return styles.unassigned;
    case 'REASSIGNED':
      return styles.reassigned;
    default:
      return '';
  }
};

type AssignmentStatusTagProps = {
  assignedStatus: AssignedStatus,
  sendStatus: SendStatus,
  updatedAt?: string,
  lastUpdateBy: string,
};

const AssignmentStatusTag = ({
  assignedStatus,
  sendStatus,
  updatedAt,
  lastUpdateBy,
}: AssignmentStatusTagProps) => {

  useEffect(() => ReactTooltip.rebuild(), []);

  return (

    <span
      data-tip={updatedAt && `Last updated by ${lastUpdateBy} at ${updatedAt}`}
      data-for="tooltip"

      className={classNames(styles.tag, classNameForStatus(assignedStatus, sendStatus))}
    >

      {textForStatus(assignedStatus, sendStatus)}
    </span>
  );
};

AssignmentStatusTag.defaultProps = {
  sentTime: '',
  updatedAt: null,
};

export default AssignmentStatusTag;
