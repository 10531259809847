// @ts-nocheck
import classNames from 'classnames';
import React from 'react';

import cannotAssignHireToAssignment from '../../utils';
import { HireChipMobile } from '../HireChip';
import { HiresContextConsumer } from '../HiresListContext';
import { assignedStatuses } from 'pages/Projects/utils/projects.utils';
import { useOutsideClickHandler, useScrollToRef } from 'utils/Hooks';
import { HireDropProps } from './HireDrop';
import styles from './styles.module.scss';





const HireDropTablet = ({
  assignments,
  assignmentInstance,
  hire,
  companyId,
  children,
  assignedStatus
}: HireDropProps) => {
  const [listOpen, setListOpen] = React.useState(false);
  const hiresListRef = React.useRef();
  const selectedRef = React.useRef();
  useOutsideClickHandler(hiresListRef, () => setListOpen(false));
  useScrollToRef(selectedRef, selectedRef.current);

  const cancelled = assignedStatus === assignedStatuses.CANCELLED;
  return (

    <HiresContextConsumer>
      {({ hires, subcontractors, onAssign }) => (

        <div

          className={classNames(styles.hireDropTablet, {
            [styles.open]: listOpen,
            [styles.missing]: !cancelled && !hire,
            [styles.withHire]: !cancelled && !!hire,
            [styles.cancelled]: !!cancelled,
          })}

          ref={hiresListRef}
        >
          <div
            className={styles.tabletHireContainer}
            onClick={() => setListOpen(!listOpen)}
            onKeyPress={() => setListOpen(!listOpen)}
            tabIndex={0}
            role="menu"
          >
            {children}
          </div>
          {listOpen && (
            <div className={classNames(styles.tabletHireList, { [styles.open]: listOpen })}>
              <div className={styles.tabletHireListScroller}>
                {hires
                  .filter(h => h.companyId === companyId)
                  .filter(h => !cannotAssignHireToAssignment(h, assignments))
                  .map((h) => {
                    const isSelected = !!hire && !!hire.accountId && hire.accountId === h.accountId;
                    return (
                      <div ref={isSelected ? selectedRef : null} key={h.id}>
                        <HireChipMobile
                          hire={h}
                          onSelect={() => {
                            if (isSelected) {

                              onAssign(assignments, null, true);
                            } else {

                              onAssign(assignments, { id: h.accountId }, true);
                            }
                            setListOpen(false);
                          }}
                          selected={isSelected}
                        />
                      </div>
                    );
                  })
                }
                {subcontractors.map((s) => {
                  const isSelected = !!hire && hire.id === s.id;
                  return (
                    <div ref={isSelected ? selectedRef : null} key={s.id}>
                      <HireChipMobile
                        hire={s}
                        assignmentInstance={assignmentInstance}
                        key={s.id}
                        onSelect={() => {
                          if (isSelected) {

                            onAssign(assignments, null, false);
                          } else {

                            onAssign(assignments, s, false);
                          }
                          setListOpen(false);
                        }}
                        selected={isSelected}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      )}
    </HiresContextConsumer>
  );
};

export default HireDropTablet;
