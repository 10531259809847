// @ts-nocheck
import classNames from 'classnames';
import React from 'react';
import { useHistory, useLocation, matchPath } from 'react-router-dom';


import useGetPathSearchParams from '../utils';
import ClickableDiv from 'common-ui/ClickableDiv';
import { usePermissions } from 'common-ui/Hooks';

import { Receipt, Stopwatch, ChevronLeft } from 'common-ui/Icon';
import DropdownButton from 'components/Common/DropdownButton';


import DropdownSelection from 'components/Common/DropdownSelection';

import { useCompanyContext } from 'utils/Hooks';
import styles from './styles.module.scss';
import type { MenuLinkPropTypes } from '../../types';

const TicketIcon = () => (

  <div className={styles.dropdownIcon}><Receipt /></div>
);

const TimesheetIcon = () => (

  <div className={styles.dropdownIcon}><Stopwatch /></div>
);

const MenuLink = ({
  menuIcon,
  text,
}: MenuLinkPropTypes) => {
  const history = useHistory();
  const location = useLocation();

  const ticketPathname = '/payments/tickets';
  const timesheetPathname = '/payments/timesheets';

  const ticketSearch = useGetPathSearchParams(ticketPathname);
  const timesheetSearch = useGetPathSearchParams(timesheetPathname);

  const { selectedCompanyID } = useCompanyContext();
  const {
    ViewTimesheetsModule: canViewTimesheets,
    ViewTicketsModule: canViewTickets,
  } = usePermissions(selectedCompanyID);

  const isActive = matchPath(ticketPathname, {
    path: location.pathname,
    exact: true,
    strict: false,
  }) || matchPath(timesheetPathname, {
    path: location.pathname,
    exact: true,
    strict: false,
  });

  return (

    <div>

      <DropdownButton
        dropdownStyle={styles.dropdownContainer}
        button={({
          setDropdown
        }: any) => (
          <ClickableDiv

            className={classNames(styles.link, {
              [styles.activeLink]: isActive,
            })}
            onClick={() => setDropdown(true)}
            onMouseOver={() => setDropdown(true)}
            onFocus={() => setDropdown(true)}
          >
            {menuIcon}
            {!!text && (
              <div className={styles.label}>
                {text}
              </div>
            )}
            <span className={styles.indicator}><ChevronLeft /></span>
          </ClickableDiv>
        )}
        dropdownSection={({
          setDropdown
        }: any) => {
          const options = [];
          const ticketOption = {
            label: 'Tickets',
            description: 'View & sign off on load tickets',
            onClick: () => {
              history.push({ pathname: ticketPathname, search: ticketSearch });
              setDropdown(false);
            },
            icon: <TicketIcon />,
          };
          const timesheetOption = {
            label: 'Timesheets',
            description: 'View & sign off on timesheets',
            onClick: () => {
              history.push({ pathname: timesheetPathname, search: timesheetSearch });
              setDropdown(false);
            },
            icon: <TimesheetIcon />,
          };
          if (canViewTickets) {
            options.push(ticketOption);
          }

          if (canViewTimesheets) {
            options.push(timesheetOption);
          }
          return (
            <DropdownSelection
              options={options}
            />
          );
        }}
      />
    </div>
  );
};

MenuLink.defaultProps = {
  text: '',
  mobile: false,
  onChange: () => {},
};

export default MenuLink;
