import React, {SVGProps} from 'react';

const Line = (props: SVGProps<SVGSVGElement>) => (

  <svg width={45} height={5} viewBox="0 0 45 5" {...props}>

    <title>Line</title>

    <g id="Symbols" stroke="none" strokeWidth="1" fill="none">

      <g
        id="Pieces/Bottom-Slide"
        transform="translate(-168.000000, -5.000000)"
        fill="#EEEEEE"
      >

        <rect id="Rectangle-4" x="168" y="5" width="45" height="5" rx="2.5" />
      </g>
    </g>
  </svg>
);

export default Line;
