// @ts-nocheck
import classNames from 'classnames';
import React from 'react';

import { Truck } from 'common-ui/Icon';
import styles from './styles.module.scss';


const getBorderClassName = (styleName, hasPhoto) => {
  switch (styleName) {
    case 'online':
      return styles.iconborderActive;
    case 'hire':
      return styles.iconborderHire;
    case 'idle':
      return styles.iconborderIdle;
    case 'lost':
      return styles.iconborderLost;
    case 'missing':
      return styles.iconborderMissing;
    case 'cancelled':
      return styles.iconborderCancelled;
    case 'notavailable':
      return styles.iconborderNotAvailable;
    case 'none':
    default:
      return hasPhoto ? styles.iconborderNone : styles.iconborderHire;
  }
};

export type StyleName = 'online' | 'hire' | 'idle' | 'lost' | 'missing' | 'cancelled' | 'none' | 'notavailable';

type HireInitialsProps = {
  large?: boolean,
  firstName: string, // eslint-disable-line
  lastName: string, // eslint-disable-line
  styleName?: StyleName,
  className?: string,
  initialsClassName?: string,
};

const HireInitials = ({
  large,
  firstName,
  lastName,
  styleName,
  className,
  initialsClassName,
}: HireInitialsProps) => (

  <div
    className={classNames(
      styles.profilePhoto,
      getBorderClassName(styleName),
      { [styles.large]: !!large },
      className,
    )}
  >

    <div
      className={classNames(
        styles.initials,
        { [styles.large]: !!large },
        initialsClassName,
      )}
    >
      {!firstName && !lastName

        ? <Truck />
        : (
          <>
            {firstName ? firstName.charAt(0).toUpperCase() : ''}
            {lastName ? lastName.charAt(0).toUpperCase() : ''}
          </>
        )}
    </div>
  </div>
);

HireInitials.defaultProps = {
  large: false,
  firstName: "", // eslint-disable-line
  lastName: "", // eslint-disable-line
  styleName: 'none',
  className: undefined,
  initialsClassName: undefined,
};

type SubcontractorInitialsProps = {
  name: string,
  priority?: string,
  large?: boolean,
};


const getPriorityClassName = (priority) => {
  switch (priority) {
    case 'HIGH':
      return styles.priorityHigh;
    case 'MEDIUM':
      return styles.priorityMedium;
    case 'LOW':
    default:
      return styles.priorityDefault;
  }
};

export const SubcontractorInitials = ({ name, priority, large }: SubcontractorInitialsProps) => {
  const initials = name
    .split(' ')
    .filter(w => w.charAt(0).match(/[a-z]/i))
    .map(w => w.charAt(0).toUpperCase())
    .slice(0, 2)
    .join('');
  return (
    initials ? (

      <div
        className={classNames(
          styles.profilePhoto,
          getPriorityClassName(priority),
          {
            [styles.large]: !!large,
          },
        )}
      >

        <div className={classNames(styles.initials, { [styles.large]: !!large })}>
          {initials}
        </div>
      </div>
    ) : null
  );
};

SubcontractorInitials.defaultProps = {
  large: false,
};

export default HireInitials;
