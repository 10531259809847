import React,{SVGProps} from 'react';

const Ellipsis = (props: SVGProps<SVGSVGElement>): JSX.Element => (

  <svg width={16} height={4} viewBox="0 0 16 4" {...props}>

    <title>More</title>

    <path
      d="M2 0C.9 0 0 .9 0 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zM8 0C6.9 0 6 .9 6 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
      fill="#000B9D"
      fillRule="evenodd"
    />
  </svg>
);

export default Ellipsis;
