// @ts-nocheck

import { graphql } from '@apollo/client/react/hoc';
import _ from 'lodash';
import {
  compose,
  withProps,
  HOC,
} from 'recompose';



import { withProjectSearch, withLiveAssignmentSearch } from '../../components/Common/Search';
import { injectIntlHOC, withCompanyContext, withOverlaidModal } from '../../utils/HOC';

import { usePermissions } from 'common-ui/Hooks';
import { ConnectMapControls, ConnectLiveMarkerControls } from 'components/Common/Map';
import {
  useLiveMapHandlers,
  useLiveProjectsDisplayLimit,
  useLiveAssignments,
  getLiveFilterOptions,
} from './Hooks/useLiveMapHandlers';
import LiveUI from './Live.ui';
import { LIVE_DATA_QUERY, COMPANIES_QUERY } from './graphql';

import type { LivePropTypes } from './types';


// NOTE: to test with fake data, uncomment.
// import withTestDataHoc from './utils/testLiveData.utils';

export const liveSortOptions = [{ id: 'Customer', name: 'Customer', sortProp: 'client' }];


export const LiveHOC: HOC<any, LivePropTypes> = compose(

  injectIntlHOC(), // Provides the 'intl' prop
  withCompanyContext,

  withProps(({ selectedCompanyID }) => {
    const { ViewLiveCycleInsights } = usePermissions(selectedCompanyID);
    return { ViewLiveCycleInsights };
  }),
  graphql(LIVE_DATA_QUERY, {
    name: 'liveData',

    options: ({ selectedCompanyID, ViewLiveCycleInsights }) => ({
      variables: {
        companyID: selectedCompanyID,
        liveCycles: ViewLiveCycleInsights,
      },
      fetchPolicy: 'no-cache',
      pollInterval: 10000,
    }),
  }),
  graphql(COMPANIES_QUERY, {
    name: 'companiesData',
    options: {
      variables: {
        isAdmin: true,
      },
    },
  }),

  withProps(({ liveData }) => {
    const projects = liveData && liveData.liveProjects
      ? _.sortBy(liveData.liveProjects, ['id'])
      : [];

    return {
      projects,
      sortOption: liveSortOptions[0],
      liveSortOptions,
      liveMapLoading: liveData.loading,
    };
  }),
  withProjectSearch,
  withLiveAssignmentSearch,

  withProps(({ filteredProjects, filteredLiveAssignments }) => {

    const filteredAssignmentIds = new Set(filteredLiveAssignments.map(a => a.id));
    return {
      filteredProjects: filteredProjects

        .map(p => ({
          ...p,

          assignments: p.assignments.filter(a => filteredAssignmentIds.has(a.id)),
        }))

        .filter(p => p.assignments.length),
    };
  }),
  // TODO this HOC still exists because ConnectLiveMarkerControls needs the markers prop to work

  withProps(({ liveData, filteredProjects, filterableProjects, limitInitialDisplay }) => {
    const {
      markerId,
      closedProjectIds,
      toggleMarkerOpen,
      toggleAssignmentOpen,
      toggleAssignmentsOpen,
      setAssignmentsClosed,
      onRefresh,
    } = useLiveMapHandlers(liveData);

    // TODO do useeffects in withProps even work??
    useLiveProjectsDisplayLimit(filteredProjects, limitInitialDisplay);

    const {
      liveAssignments,
      markers,
      sites,
    } = useLiveAssignments(filteredProjects, closedProjectIds);

    const {
      currentOptions,
      driverStatusOptions,
    } = getLiveFilterOptions(filterableProjects);

    return {
      markerId,
      closedProjectIds,
      toggleMarkerOpen,
      toggleAssignmentOpen,
      toggleAssignmentsOpen,
      setAssignmentsClosed,
      onRefresh,
      sideBarVisible: true,
      currentOptions,
      driverStatusOptions,
      projects: liveAssignments,
      markers,
      sites,
    };
  }),
  ConnectLiveMarkerControls,
  ConnectMapControls,
  withOverlaidModal,
);

export default LiveHOC(LiveUI);
