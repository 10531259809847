import { gql } from '@apollo/client';

export const NOTIFICATIONS_QUERY = gql`
query NotificationsQuery {
  notifications {
    id
    receiverAccountId
    aboutAccountId
    aboutCompanyId
    aboutHire {
      id
      accountId
      companyId
      firstName
      lastName
      companyName
      truckNumber
    }
    aboutCompany {
      id
      name
    }
    notificationType
    notificationExtraInfo
    clearedAt
    createdAt
  }
}
`;

export const ALL_NOTIFICATIONS_AND_REQUESTS_QUERY = gql`
query NotificationsAndRequestsQuery($companyID: ID) {
  requests(companyID: $companyID) {
    requestedBy {
      id
      accountId
      companyId
      firstName
      lastName
      companyName
    }
    project {
      id
      companyId
      clientId
      companyName
      jobNumber
      startTime
      endTime
      assignments {
        id
        assignedStatus
      }
      requestedBy {
        id
        accountId
        companyId
        firstName
        lastName
        companyName
      }
    }
    schedule {
      scheduleId
      startTime
      projects {
        id
        companyId
        companyName
        jobNumber
        startTime
        endTime
      }
      assignments {
        id
        assignedStatus
      }
    }
  }
  notifications(companyID: $companyID){
    id
    receiverAccountId
    aboutAccountId
    aboutCompanyId
    aboutHire {
      id
      accountId
      companyId
      firstName
      lastName
      companyName
      truckNumber
    }
    aboutCompany {
      id
      name
    }
    notificationType
    notificationExtraInfo
    clearedAt
    createdAt
  }
}
`;

export const CLEAR_NOTIFICATIONS_MUTATION = gql`
mutation ClearNotifications {
  clearAllNotifications {
    id
    receiverAccountId
  }
}
`;
