// @ts-nocheck
import React, { ReactNode } from 'react';

import CompanyLogo from 'components/Common/CompanyLogo';
import styles from './styles.module.scss';

type CompanyLogoRectangleProps = {
  value: ReactNode,
  multiple: boolean,
};

const CompanyLogoRectangle = ({ value, multiple }: CompanyLogoRectangleProps) => {
  const selectedCompanyLogo = value && value.logo && value.logo.getURL;
  const companyName = (value && value.name) || '';
  return (

    <div className={styles.imagePreviewRectangle}>
      {selectedCompanyLogo && (

        <img className={styles.rectangleLogo} src={selectedCompanyLogo} alt="Company logo" />
      )}
      {!selectedCompanyLogo && multiple && (

        <div className={styles.companyNoLogo}>
          <CompanyLogo
            value={selectedCompanyLogo}
            companyName={companyName}
          />
          <div className={styles.companyNameText}>{companyName}</div>
        </div>
      )}
      {!selectedCompanyLogo && !multiple && (

        <span className={styles.homeLink}>TREAD</span>
      )}
    </div>
  );
};

export default CompanyLogoRectangle;
