// @ts-nocheck
import React from 'react';
import { createRoot } from 'react-dom/client';
import { addLocaleData, IntlProvider } from 'react-intl';
import en from 'react-intl/locale-data/en';
import fr from 'react-intl/locale-data/fr';
import { BrowserRouter } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import 'what-input';

import 'common-ui/semantic-ui/reset.min.css';
import 'common-ui/semantic-ui/sidebar.min.css';
import './index.css';

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// import * as serviceWorker from './serviceWorker';
// serviceWorker.unregister();
// Third Party
// eslint-disable-next-line import/order
import Raven from 'raven-js';

// Apollo Client
// eslint-disable-next-line import/order
import { ApolloProvider } from '@apollo/client/react';
// eslint-disable-next-line import/order
import { ApolloProvider as ApolloHooksProvider } from '@apollo/client';
import { apolloClient } from './apolloClient';
import App from './components/App';

// Languages
import enMessages from './locales/en.json';

// Style
import './common-ui/base.scss';
import 'mapbox-gl/dist/mapbox-gl.css';

addLocaleData([...en, ...fr]);

const messages: Record<string, Record<string, string>> = {
  en: enMessages,
};

// Different browsers have the locale code stored in different places
const language =
  (navigator.languages && navigator.languages[0]) || navigator.language || navigator?.userLanguage;

// This removes the region code
const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];

// Set Up Raven (Error Reporting)
if (process.env?.REACT_APP_SENTRY_DSN) {
  const config = {
    environment: process.env.REACT_APP_ENV,
    ignoreErrors: [
      // list of error messages or regular expressions to ignore
      // with string value it will be partially matched to meet the condition
      '{"right": NaN}',
    ],
  };
  Raven.config(process.env.REACT_APP_SENTRY_DSN, config).install();
}

// Execute the first render of our app.

const container = document.getElementById('app');

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
  <ApolloProvider client={apolloClient}>
    <ApolloHooksProvider client={apolloClient}>
      <IntlProvider
        locale={language}
        messages={messages[languageWithoutRegionCode]}
        textComponent={React.Fragment}
      >
        <BrowserRouter>
          <App tab='home' />
          <ReactTooltip
            id='tooltip'
            place='top'
            effect='solid'
            multiline
            clickable
            backgroundColor='#000'
            className='react-tooltip-style'
          />
        </BrowserRouter>
      </IntlProvider>
    </ApolloHooksProvider>
  </ApolloProvider>,
);
