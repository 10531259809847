// @ts-nocheck

import * as React from 'react';
import { compose, HOC, withHandlers, withProps } from 'recompose';
import { FullScreenPortal, usePermissions } from 'common-ui/Hooks';
import { Footer } from 'components/MainContainer/components/Footer';
import SegmentAnalytics from 'components/MainContainer/components/SegmentAnalytics';
import { useNotificationsAndRequests } from 'components/NotificationsSidebar';
import Page from 'components/Page';

import { withSidebar } from 'components/Sidebar';
import { assignedStatuses } from 'pages/Projects/utils/projects.utils';

import { withCompanyContext } from 'utils/HOC';

import {useCompanyContext, useElementSize} from 'utils/Hooks';
import { DesktopHeader, HidingMobileHeader, MobileNavMenu, useHideyHeader } from './components';
import { AlertToast } from './components/AlertToast';
import styles from './styles.module.scss';
import type { MainContainerPropTypes } from './types';

export const MobileContainer = ({
  RenderComp,
  children,
  requestsCount,
  ...rest
}: MainContainerPropTypes) => {
  const [menuVisible, setMenuVisible] = React.useState(false);
  const [headerVisible, showHeader, hideHeader] = useHideyHeader();

  return (
      <>
        <HidingMobileHeader
            {...{
              headerVisible,
              menuVisible,
              setMenuVisible,
              ...rest,
            }}
        />
        {!menuVisible ? (
            <Page mobile {...rest}>
              <AlertToast/>
              <RenderComp desktop={false} showHeader={showHeader} hideHeader={hideHeader}/>
            </Page>
        ) : (

            <Page mobile>
              <MobileNavMenu requestsCount={requestsCount} onChange={() => setMenuVisible(false)}/>
            </Page>
        )}

        <FullScreenPortal/>
        <Footer/>
      </>
  );
};


export const DesktopContainer = ({RenderComp, ...rest}: MainContainerPropTypes) => (
    <>
      <DesktopHeader {...rest} />
      <Page {...rest}>
        <AlertToast/>
        <RenderComp desktop {...rest} />
      </Page>
      <Footer/>
    </>
);


export const TabletContainer = ({mobile, RenderComp, children, requestsCount, ...rest}: MainContainerPropTypes) => {
  const [menuVisible, setMenuVisible] = React.useState(false);
  const [headerVisible, showHeader, hideHeader] = useHideyHeader();
  return (
      <>
        <HidingMobileHeader
            {...{
              headerVisible,
              menuVisible,
              setMenuVisible,
              ...rest,
            }}
        />
        {!menuVisible ? (
            <Page mobile {...rest}>
              <AlertToast/>
              <RenderComp desktop={false} showHeader={showHeader} hideHeader={hideHeader}/>
            </Page>
        ) : (
            <Page mobile>
              <MobileNavMenu requestsCount={requestsCount} onChange={() => setMenuVisible(false)}/>
            </Page>
        )}

        <FullScreenPortal/>
        <Footer/>
      </>
  );
};

/* NOTE
  The way we structure pages is from the outside in...
    <Sidebar>{things}</Sidebar>
    <Overview>
      <OverviewHeader />
      <OverviewContent>
        {things}
      </OverviewContent>
    </Overview>

  All of this is wrapped by <MainContainer>, which contains and handles
  the main navigational header, responsive breakpoints, and flexing
  the rest of the content below the navigational header
*/
const MainContainer = ({

  notificationsAndRequests: data,
  ...rest
}: MainContainerPropTypes) => {
  const { selectedCompanyID } = useCompanyContext();
  const {
    AcceptDeclineRequests: canAcceptDeclineRequests,
  } = usePermissions(selectedCompanyID);

  const [sizeRef, {width}] = useElementSize();

  const someActionable = ({ project, schedule }) => {
    if (project) {
      return project.assignments

        .some(({ assignedStatus }) => assignedStatus === assignedStatuses.SENT);
    }

    return schedule.assignments

      .some(({ assignedStatus }) => assignedStatus === assignedStatuses.SENT);
  };

  const requestsCount = (canAcceptDeclineRequests && !data.loading && !data.error)
    ? data.requests.filter(someActionable).length
    : 0;

  const notificationsDataCount = (!data.loading && !data.error)
    ? data.notifications.length
    : 0;

  const props = {

    requestsCount,

    notificationsCount: requestsCount + notificationsDataCount,
    ...rest,
  };

  const ResponsiveWidth = React.useMemo(() => ({
    XS: 320,
    S: 786,
    M: 992,
    L: 1440,
    XL: 1920,
  }));

  return (
    <>
      <div className={styles.mainContainer} ref={sizeRef}>
        {width >= ResponsiveWidth.M && <DesktopContainer  {...props} />}
        {width >= ResponsiveWidth.S && width < ResponsiveWidth.M && <TabletContainer mobile {...props} />}
        {width < ResponsiveWidth.S && <MobileContainer mobile {...props} />}
      </div>
      <SegmentAnalytics/>
    </>
  );
};

const MainContainerHOC: HOC<any, MainContainerPropTypes> = compose(
  withCompanyContext,
  withSidebar('notificationsSidebar', false),
  withHandlers({

    toggleNotificationsSidebar: ({ notificationsSidebar }) => () => {
      notificationsSidebar.setVisible(!notificationsSidebar.visible);
    },
  }),

  withProps(({ ...props }) => {
    // poll interval is every 10 seconds
    const notificationsAndRequests = useNotificationsAndRequests(10000);
    return { ...props, notificationsAndRequests };
  }),
);

export default MainContainerHOC(MainContainer);
