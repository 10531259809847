import { useQuery, gql } from '@apollo/client';
import { useMemo } from 'react';

import { usePermissions } from 'common-ui/Hooks';
import { useCompanyContext } from 'utils/Hooks';


const LIVE_DATA_QUERY = gql`
query LiveDataQuery ($companyID: ID, $liveCycles: Boolean = false) {
  liveProjects(companyID: $companyID) {
    id
    name
    client
    jobNumber
    pickUpSiteID
    pickUpSiteName
    pickUpSite {
      id
      companyId
      company {
        id
        name
        measurementType
        timeOnSiteGoalMinutes
      }
      name
      radius
      address
      coordinates {
        latitude
        longitude
      }
      status
      type
      geofenceType
      activeCycles @include(if: $liveCycles) {
        pickupSiteID
        dropoffSiteID
        pickupArrival
        pickupDeparture
        pickupDuration
        dropoffArrival
        dropoffDeparture
        dropoffDuration
      }
      completeCycles @include(if: $liveCycles) {
        pickupSiteID
        dropoffSiteID
        pickupDuration
        dropoffDuration
      }
    }
    company {
      id
      name
      logo {
        getURL
        filename
      }
    }
    companyClient {
      id
      name
    }
    foreman
    foremanID
    foremanInfo {
      id
      firstName
      lastName
    }
    showName
    showPhoneNumber
    showStatus
    showTimecard
    showLicensePlate
    viewLiveMap
    lastSharedAt
    assignments {
      id
      instance {
        id
        loggingStart
        truckNumber
        licensePlate
      }
      assignedTo {
        id
        assignee {
          id
          accountId
          companyId
          firstName
          lastName
          phoneNumber
          truckNumber
          companyName
          instanceTruckNumber
          licensePlate
          instanceLicensePlate
          activeCycle @include(if: $liveCycles) {
            cycleNumber
            pickupSiteID
            dropoffSiteID
            pickupArrival
            pickupDeparture
            dropoffArrival
            dropoffDeparture
          }
        }
        gps {
          id
          latitude
          longitude
          bearing
          idleSince
          lastPing
          isIdle
          isDisconnected
        }
      }
      pickUpSite {
        id
        companyId
        company {
          id
          name
          measurementType
          timeOnSiteGoalMinutes
        }
        name
        radius
        address
        coordinates {
          latitude
          longitude
        }
        status
        type
        geofenceType
        activeCycles @include(if: $liveCycles) {
          pickupSiteID
          dropoffSiteID
          pickupArrival
          pickupDeparture
          pickupDuration
          dropoffArrival
          dropoffDeparture
          dropoffDuration
        }
        completeCycles @include(if: $liveCycles) {
          pickupSiteID
          dropoffSiteID
          pickupDuration
          dropoffDuration
        }
      }
      pickUpSiteID
      pickUpSiteName
      quantity
      quantityUnit
      dropOffSiteID
      dropOffSite {
        id
        companyId
        company {
          id
          name
          measurementType
          timeOnSiteGoalMinutes
        }
        name
        radius
        address
        coordinates {
          latitude
          longitude
        }
        status
        type
        geofenceType
        activeCycles @include(if: $liveCycles) {
          pickupSiteID
          dropoffSiteID
          pickupArrival
          pickupDeparture
          pickupDuration
          dropoffArrival
          dropoffDeparture
          dropoffDuration
        }
        completeCycles @include(if: $liveCycles) {
          pickupSiteID
          dropoffSiteID
          pickupDuration
          dropoffDuration
        }
      }
      phase
      subcontractorCompanyName
    }
  }
}
`;

const COMPANIES_QUERY = gql`
  query CompaniesQuery($isAdmin: Boolean, $isExternalGuest: Boolean) {
    companies(isAdmin: $isAdmin, isExternalGuest: $isExternalGuest) {
      id
      name
      measurementType
    }
  }
`;

export const useLiveProjects = () => {
  const { selectedCompanyID } = useCompanyContext();
  const { ViewLiveCycleInsights } = usePermissions(selectedCompanyID);
  const { data, loading, error } = useQuery(LIVE_DATA_QUERY, {
    fetchPolicy: 'no-cache',
    pollInterval: 10000,
    variables: {
      companyID: selectedCompanyID,
      liveCycles: ViewLiveCycleInsights,
    },
  });
  return useMemo(() => (
    (!loading && !error)
      ? data.liveProjects
      : []
  ), [data, loading, error]);
};

export {
  LIVE_DATA_QUERY,
  COMPANIES_QUERY,
};
