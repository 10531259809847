import classNames from 'classnames';
import React, {ReactNode} from 'react';
import styles from './styles.module.scss';

type EmptyProps = {
  icon?: ReactNode | null;
  title?: string;
  text: string | ReactNode;
  className?: string;
  iconStyle?: string;
  button?: ReactNode | null;
};

export const Empty = ({
  icon,
  title,
  text,
  className,
  iconStyle,
  button,
}: EmptyProps) => (

  <div className={classNames(styles.container, className)}>

    <div className={iconStyle || styles.icon}>{icon}</div>

    <div className={styles.title}>{title}</div>

    <div className={styles.text}>{text}</div>

    {button && <div className={styles.button}>{button}</div>}
  </div>
);

Empty.defaultProps = {
  className: undefined,
  icon: null,
  title: undefined,
  iconStyle: undefined,
  button: null,
};
