// @ts-nocheck
import { useState } from 'react';

import { store } from 'react-notifications-component';
import animations from './animations.module.scss';

// https://github.com/teodosii/react-notifications-component#notification-container
export const FLAG_POSITIONS = {
  topCenter: 'top-center',
  topLeft: 'top-left',
  topRight: 'top-right',
  center: 'center',
  bottomCenter: 'bottom-center',
  bottomLeft: 'bottom-left',
  bottomRight: 'bottom-right',
};

export const useFlag = () => {
  const [visible, setVisible] = useState({});

  const removeFlag = (id: any) => {
    if (store && store.add) {
      store.removeNotification(id);

      if (visible[id]) setVisible(v => ({ ...v, [id]: false }));
    }
  };

  const addFlag = (
    id: any,
    component: any,
    // one of `FLAG_POSITIONS`
    position = FLAG_POSITIONS.bottomLeft,
    // defaults to 10 seconds, set to 0 to ensure it can only be removed manually
    timeout = 10000,
    inAnimation: any,
    outAnimation: any,
  ) => {

    if (visible[id] || !store || !(store.add)) {
      return () => {}; // consistent return
    }

    const animationIn = inAnimation || [];
    const animationOut = outAnimation || [];
    if (!inAnimation) {
      animationIn.push(animations.flagAnimation);
      if (position === FLAG_POSITIONS.topCenter) {
        animationIn.push(animations.flagSlideInDown);
      } else if (position === FLAG_POSITIONS.topLeft || position === FLAG_POSITIONS.bottomLeft) {
        animationIn.push(animations.flagSlideInRight);
      } else if (position === FLAG_POSITIONS.topRight || position === FLAG_POSITIONS.bottomRight) {
        animationIn.push(animations.flagSlideInLeft);
      } else if (position === FLAG_POSITIONS.bottomCenter) {
        animationIn.push(animations.flagSlideInUp);
      }
    }
    if (!outAnimation) {
      animationOut.push(animations.flagAnimation);
      if (position === FLAG_POSITIONS.topCenter) {
        animationOut.push(animations.flagSlideOutUp);
      } else if (position === FLAG_POSITIONS.topLeft || position === FLAG_POSITIONS.bottomLeft) {
        animationOut.push(animations.flagSlideOutLeft);
      } else if (position === FLAG_POSITIONS.topRight || position === FLAG_POSITIONS.bottomRight) {
        animationOut.push(animations.flagSlideOutRight);
      } else if (position === FLAG_POSITIONS.bottomCenter) {
        animationOut.push(animations.flagSlideOutDown);
      }
    }

    if (store && store.add) {
      setVisible(v => ({ ...v, [id]: true }));
      store.addNotification({
        id,
        insert: 'top',
        container: position,
        animationIn,
        animationOut,
        content: component,
        slidingExit: { duration: 0, delay: 250 },
      });
    }

    const timer = setTimeout(() => {
      if (timeout > 0) {
        removeFlag(id);
      }
    }, timeout);

    return () => clearTimeout(timer);
  };

  return { addFlag, removeFlag };
};

export default useFlag;
