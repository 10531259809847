import React, {SVGProps} from 'react';

const MapIcon = (props: SVGProps<SVGSVGElement>) => (

  <svg width={11} height={20} viewBox="0 0 16 16" {...props}>

    <title>Idle</title>

    <circle cx="8" cy="8" r="8" fill="#000000" />
  </svg>
);

export default MapIcon;
