// @ts-nocheck

import { SplitFactory } from '@splitsoftware/splitio-react';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useWhoAmI } from 'common-ui/Hooks';
import SPLIT_CONFIG from './config';
// SplitWrapper is a wrapper around the vendor-provided SplitFactory. until we get a valiud userId
// we pass through and directly render our children. once we receive a valid value we wrap our children
// in a SplitFactory.
//
// This should only be used once in the tree - currently it's the direct descendant of
// CompanyContext.Provider in AuthApp.tsx so once a user has logged in it should work.

export const SplitWrapper = ({ children }) => {
  const [user] = useWhoAmI();

  // split's factory only wants to be instantiated once. if we don't have a userId
  // or that id is 0 then just return our (unwrapped) children.
  const userId = parseInt(user?.id, 10);
  if (!Number.isInteger(userId) || userId === 0) {
    return children;
  }

  // otherwise it's safe to wrap in the split factory.

  // make a deep copy of our base config
  const config = cloneDeep(SPLIT_CONFIG);
  // and add our key value
  Object.assign(config.core, { key: userId });

  return (
    <SplitFactory {...{ config }} updateOnSdkTimedout={true} >
      {children}
    </SplitFactory >
  );
};

SplitWrapper.propTypes = {
  children: PropTypes.node
};

