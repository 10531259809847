import classNames from 'classnames';
import React from 'react';

import { Close } from 'common-ui/Icon';
import styles from './styles.module.scss';

const DriverStatusButton = ({
  statusSelected,
  onClick,
  onClose
}: any) => (

  <div

    className={classNames(styles.statusPill, { [styles.selected]: statusSelected })}
  >

    <button
      className={styles.statusText}
      type="button"
      onClick={onClick}
    >
      {statusSelected || 'Driver Status'}
    </button>
    { statusSelected && (

      <button
        type="button"
        className={styles.closeIcon}
        onClick={onClose}
      >

        <Close />
      </button>
    )}
  </div>
);


export default DriverStatusButton;
