// @ts-nocheck
import { intersection } from 'lodash';
import { useState, useEffect, useMemo } from 'react';

type UseSelectableItemsReturn<T> = {
  selectedItemsSet: Set<T>,
  allItemsSelected: boolean,
  toggleItem: (param:T) => void,
  selectAllItems: () => void,
  clearSelection: () => void,
  selectItem: (param:T) => void,
  deselectItem: (param:T) => void,
};

// eslint-disable-next-line arrow-parens
const useSelectableItems = <T>(items: T[]): UseSelectableItemsReturn<T> => {
  const [selectedItems, setSelectedItems] = useState([]);

  const selectItem = (id: T) => {

    if (items.includes(id)) setSelectedItems(oldItems => [...oldItems, id]);
  };

  const deselectItem = (id: T) => {
    setSelectedItems(oldItems => oldItems.filter(i => i !== id));
  };

  const toggleItem = (id: T) => {

    if (selectedItems.includes(id)) {
      deselectItem(id);
    } else {
      selectItem(id);
    }
  };

  const clearSelection = () => setSelectedItems([]);

  const selectedItemsSet: Set<T> = useMemo(() => (
    new Set(selectedItems)
  ), [selectedItems]);

  const allItemsSelected = useMemo(() => (
    !!items.length && items.length === selectedItemsSet.size
  ), [items, selectedItemsSet]);

  const selectAllItems = () => {
    if (allItemsSelected) {
      setSelectedItems([]);
    } else {

      setSelectedItems(items);
    }
  };

  useEffect(() => {
    const newSelectedItems = intersection(selectedItems, items);
    if (selectedItems.length !== newSelectedItems.length) {


      setSelectedItems(newSelectedItems);
    }
  }, [items]);

  return {
    selectedItemsSet,
    allItemsSelected,
    toggleItem,
    selectAllItems,
    clearSelection,
    selectItem,
    deselectItem,

    _setSelectedItems: setSelectedItems, // generally should not be used unless needed
  };
};

export default useSelectableItems;
