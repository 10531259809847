import classNames from 'classnames';
import React, {ReactNode} from 'react';
import styles from './styles.module.scss';

type TagProps = {
  text: string | ReactNode;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  className?: string;
};
//
const Tag = ({text, leftIcon, rightIcon, className}: TagProps) => (

  <div className={classNames(styles.tag, className)}>

    {!!leftIcon && <span className={styles.tagIcon}>{leftIcon}</span>}

    <span className={styles.tagText}>{text}</span>

    {!!rightIcon && <span className={styles.tagIcon}>{rightIcon}</span>}
  </div>
);

Tag.defaultProps = {
  leftIcon: null,
  rightIcon: null,
  className: styles.defaultTag,
};

export default Tag;
