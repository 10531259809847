// @ts-nocheck
import { difference, intersection, uniq, union } from 'lodash';
import { useState, useEffect, useMemo } from 'react';

type UseSelectableItemsReturn<T> = {
  selectedItemsSet: Set<T>,
  allItemsSelected: boolean,
  toggleItem: (param:T) => void,
  selectAllItems: () => void,
  clearSelection: () => void,
  selectItem: (param:T) => void,
  deselectItem: (param:T) => void,
};

// This hook displays checkboxes over multiple pages
// Use allItemsSelected as a check for select all rather than the size of the selectedItemsSet
// items will not be added to the selectedItemsSet until the page has transitioned

// eslint-disable-next-line arrow-parens
const usePaginatedSelectableItems = <T>(items: T[]): UseSelectableItemsReturn<T> => {
  // Items selected over multiple pages
  const [selectedItems, setSelectedItems] = useState([]);
  // Tells the pages to select all items on that page
  const [allItems, setAllItems] = useState(false);
  // Total items that have been loaded over multiple pages
  const [trackedItems, setTrackedItems] = useState([]);
  // Items from the previous page loaded
  const [prevItem, setPrevItem] = useState([]);

  const selectItem = (id: T) => {

    setSelectedItems(oldItems => [...oldItems, id]);
  };

  const deselectItem = (id: T) => {
    setSelectedItems(oldItems => oldItems.filter(i => i !== id));
  };

  const toggleItem = (id: T) => {

    if (selectedItems.includes(id)) {
      deselectItem(id);
    } else {
      selectItem(id);
    }
  };

  const clearSelection = () => {
    setTrackedItems([...items]);
    setAllItems(false);
    setSelectedItems([]);
  };

  const selectedItemsSet: Set<T> = useMemo(() => (
    new Set(selectedItems)
  ), [selectedItems]);

  const allItemsSelected = useMemo(() => (
    trackedItems.length === selectedItemsSet.size
  ), [items, selectedItemsSet]);

  const selectAllItems = () => {
    if (allItems) {
      setAllItems(false);
      setSelectedItems(difference(selectedItems, items));
    } else {
      setAllItems(true);
      setSelectedItems(union(selectedItems, items));
    }
  };

  useEffect(() => {
    const sameTrackedItems = intersection(trackedItems, items).length === items.length;
    if (!sameTrackedItems) {
      setAllItems(false);
    }
  }, [selectedItems]);

  useEffect(() => {
    const sameItems = intersection(prevItem, items).length === items.length;
    const sameTrackedItems = intersection(trackedItems, items).length === items.length;
    if (!sameItems) {
      setPrevItem(items);
    }
    const newSelectedItems = uniq([...selectedItems, ...items]);
    const newTrackedItems = uniq([...trackedItems, ...items]);
    if (selectedItems.length !== newSelectedItems.length && allItems && !sameItems && !sameTrackedItems) {
      setSelectedItems(newSelectedItems);
    }
    if (trackedItems.length !== newTrackedItems.length) {
      setTrackedItems(newTrackedItems);
    }
  }, [JSON.stringify(items)]);

  return {
    selectedItemsSet,
    allItemsSelected,
    toggleItem,
    selectAllItems,
    clearSelection,
    selectItem,
    deselectItem,
  };
};

export default usePaginatedSelectableItems;
