import React, {SVGProps} from 'react';

const Bulb = (props: SVGProps<SVGSVGElement>) => (

  <svg
    viewBox="0 0 18 25"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >

    <title>Bulb</title>

    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >

      <path
        d="M9,0 C13.9705627,0 18,3.91750844 18,8.75 C18,11.6958297 16.5026702,14.3016538 14.2075798,15.8873354 C13.7083289,16.2322687 13.6228455,16.8432654 13.4982562,17.4997958 L13.5,17.5 L13.5,22.5002406 C13.5,23.1904637 12.9233185,23.75 12.2105194,23.75 L10.8183338,23.7513688 C10.5531847,24.4788989 9.83918592,25 9,25 C8.16081408,25 7.44681526,24.4788989 7.18166618,23.7513688 L5.78948057,23.75 C5.07732011,23.75 4.5,23.1953542 4.5,22.5002406 L4.50004363,17.562124 C4.36335875,16.8716302 4.29492558,16.226957 3.78290667,15.8873355 C1.40801764,14.3120749 0,11.7559722 0,8.75 C0,3.91750844 4.02943725,0 9,0 Z M10.9285714,20 L7.07142857,20 L7.07142857,21.25 L10.9285714,21.25 L10.9285714,20 Z M9,2.5 C5.44959804,2.5 2.57142857,5.29822031 2.57142857,8.75 C2.57142857,10.8985386 3.5338332,12.6957156 5.23211319,13.8221824 C5.90348777,14.2675042 6.35272991,14.8680634 6.63464942,15.5679318 C6.79621236,15.9690136 6.87104084,16.2783058 6.97670879,16.8362509 C7.01272845,17.026441 7.04036158,17.3056207 7.05780839,17.5019186 L7.714,17.5 L7.714,11.256 L5.659513,9.74736991 C5.13071409,9.35896953 4.99762933,8.6502222 5.33024165,8.1096018 L5.40866924,7.99650596 C5.80816678,7.48239592 6.53716403,7.35300795 7.09323072,7.67638104 L7.20955787,7.75263009 L9.00643485,9.07331315 L10.8066077,7.75263009 C11.373178,7.33648682 12.1794633,7.44567377 12.6074964,7.99650596 C13.0069939,8.51061601 12.9357956,9.22774399 12.4638843,9.659416 L12.3566526,9.74736991 L10.285,11.268 L10.285,17.5 L10.940049,17.5012538 C10.9499899,17.3128923 10.9685068,17.0517579 11.0001381,16.8829126 C11.1061131,16.317227 11.1799545,16.0082714 11.3410947,15.603408 C11.618957,14.9052815 12.0601695,14.3040118 12.7180294,13.8494953 C14.4075231,12.6822215 15.4285714,10.8015586 15.4285714,8.75 C15.4285714,5.29822031 12.550402,2.5 9,2.5 Z"
        fill="4D4D4D"
      />
    </g>
  </svg>
);

export default Bulb;
