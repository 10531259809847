// @ts-nocheck
import React from 'react';

import { Button } from 'common-ui/Button';
import { FormikCheckbox } from 'components/Common/Form';

import { useDetectMobile } from 'utils/Hooks';
import styles from './styles.module.scss';

const settingsFormatted = {
  driverName: 'Name',
  phoneNumber: 'Phone Number',
  loggingStatus: 'Logging status (missing, lost, idle)',
  loggingTimestamps: 'Logging timestamps',
  licensePlate: 'License Plate',
};

type EditPermissionsPopupProps = {
  onSave: () => void,
}

const EditPermissionsPopup = ({ onSave }: EditPermissionsPopupProps) => {
  const isMobile = useDetectMobile();

  return (

    <div className={styles.permissionsContainer}>

      <div className={styles.permissionsHeader}>
        Sharing Link Permissions
      </div>

      <div className={styles.checkbox}>
        {Object.entries(settingsFormatted).map(([key, value]) => {
          return (
            <FormikCheckbox
              key={key}
              name={key}
              label={value}
              className={isMobile && styles.mobileCheckbox}
            />
          );
        })}
      </div>
      {isMobile && (

        <div className={styles.saveButton}>
          <Button text="Save Changes" styleName="primary" fullWidth onClick={onSave} />
        </div>
      )}
    </div>
  );
};
export default EditPermissionsPopup;
