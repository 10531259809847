// @ts-nocheck
import classNames from 'classnames';
import React from 'react';
import styles from './styles.module.scss';

type TextAreaProps = {
  onChange: (newValue: string) => void,
  embedded?: boolean,
};

const TextArea = ({
  onChange,
  embedded,
  ...inputProps
}: TextAreaProps) => (

  <textarea

    type="text"

    className={classNames(styles.textArea, { [styles.embedded]: !!embedded })}
    autoComplete="off"
    {...{
      ...inputProps,
      onChange: (e) => onChange(e.target.value),
    }}
  />
);

TextArea.defaultProps = {
  embedded: false,
};

export default TextArea;
