import classNames from 'classnames';
import React from 'react';
import {ReactNotifications} from 'react-notifications-component';
import styles from './styles.module.scss';

type NotificationContainerProps = {
  modal: boolean
};
// fix
const NotificationContainer = ({ modal }: NotificationContainerProps) => (

  <ReactNotifications className={classNames(styles.notificationContainer, { [styles.modalNotification]: modal })} />
);

export default NotificationContainer;
