// @ts-nocheck

import React from 'react';
import { Person, Phone } from 'common-ui/Icon';
import CollaboratorInitials from './CollaboratorInitials';
import styles from './styles.module.scss';

type CollaboratorHoverCardProps = {
  collaborator: any, // TODO add type
};

const CollaboratorHoverCard = ({ collaborator }: CollaboratorHoverCardProps) => {
  return (

    <div className={styles.hoverCard}>

      <div className={styles.headerContainer}>

        <CollaboratorInitials firstName={collaborator.firstName} lastName={collaborator.lastName} />

        <div className={styles.textContainer}>
          <div className={styles.nameRow}>
            {`${collaborator.firstName} ${collaborator.lastName}`}
          </div>
          <div className={styles.companyRow}>
            {collaborator.companyName}
          </div>
        </div>
      </div>

      <div className={styles.roleRow}>
        {collaborator.role}

        <Person />
      </div>

      <div className={styles.phoneRow}>
        {collaborator.phoneNumber}

        <Phone color={styles.colorDarkGray} />
      </div>
    </div>
  );
};

export default CollaboratorHoverCard;
