// @ts-nocheck
import React, { useState } from 'react';

import { HireChipMobile } from '../HireChip';
import { setHiresAssigned, setSubcontractorsAssigned } from '../HiresListContext';
import { TextInput } from 'components/Common/Form';

import HireTagFilter, { HireTagFilterButton } from 'pages/Hires/Tags/HireTagFilter';
import useTagFilterURL from 'pages/Hires/Tags/useTagFilterURL';
import { useHiresInternals, useHiresSubcontractors, useCompanyClientHires } from 'pages/Hires/gql';

import {  InternalHire } from 'pages/Hires/types';
import { useCompanyContext } from 'utils/Hooks';
import styles from './styles.module.scss';
import { getFilteredHires, getFilteredSubcontractors, getFilteredCompanyClientHires } from './utils';




type HiresListMobileProps = {
  assignmentHires: { [accountId: number]: number },
  assignmentSubcontractors: { [id: number]: number },
  companyId: number | null,
  selectedInternal?: number,
  selectedSubcontractor?: number,
  openModal: () => void,
  closeModal: () => void,
  onSelect: (id: number | null, internal: boolean, companyId: number | null) => void,
  isHireValidForAssignment: (param:InternalHire) => boolean,
};

const HiresListMobile = ({
  assignmentHires,
  assignmentSubcontractors,
  selectedInternal,
  selectedSubcontractor,
  openModal,
  closeModal,
  onSelect,
  isHireValidForAssignment,
}: HiresListMobileProps) => {
  const { selectedCompanyID } = useCompanyContext();
  const [searchValue, setSearchValue] = useState('');
  const [tagFiltersSelected, setTagFiltersSelected] = useTagFilterURL();
  const [hires] = useHiresInternals(selectedCompanyID);
  const [subcontractors] = useHiresSubcontractors(selectedCompanyID, false);
  const [companyClientHires] = useCompanyClientHires(selectedCompanyID);


  const getAllFilteredHires = (search, filters) => {
    const assignedHires = setHiresAssigned(hires, assignmentHires);
    const assignedCompanyClientHires = setHiresAssigned(companyClientHires, assignmentHires);
    const assignedSubcontactors = setSubcontractorsAssigned(subcontractors, assignmentSubcontractors);

    const filteredHires = getFilteredHires(assignedHires, search, filters)
      // hires assigned to smart assignments can't be assigned to classic assignments
      // and vice versa
      .filter(isHireValidForAssignment);

    const filteredSubcontractors = getFilteredSubcontractors(assignedSubcontactors, search, filters)
      .filter(isHireValidForAssignment);

    const filteredCompanyClientHires = getFilteredCompanyClientHires(assignedCompanyClientHires, subcontractors, search, filters)
      // hires assigned to smart assignments can't be assigned to classic assignments
      // and vice versa
      .filter(isHireValidForAssignment);

    return [
      ...filteredHires,
      ...filteredSubcontractors,
      ...filteredCompanyClientHires,
    ];
  };

  const filteredHires = getAllFilteredHires(searchValue, tagFiltersSelected);

  return (

    <div className={styles.hiresListMobile}>

      <div className={styles.mobileSearchControl}>

        <TextInput
          value={searchValue}
          onChange={setSearchValue}
          placeholder="Search for drivers"
          styleName={TextInput.styleNames.search}
        />

        <HireTagFilterButton
          isSelected={tagFiltersSelected.length > 0}
          onClick={() => openModal(

            <HireTagFilter
              filters={tagFiltersSelected}
              setFilters={setTagFiltersSelected}
              onClose={closeModal}
              getAllFilteredHires={getAllFilteredHires}
              searchValue={searchValue}
            />,
          )}
        />
      </div>

      <div className={styles.hiresListMobileOptions}>
        {filteredHires
          .map((hire) => {
            if (hire.accountId) {
              const selected = hire.accountId === selectedInternal;
              return (
                <HireChipMobile
                  key={`internal-${hire.id}`}
                  hire={hire}
                  selected={selected}
                  onSelect={() => onSelect(selected ? null : hire.accountId, true, hire.companyId)}
                />
              );
            }
            const selected = parseInt(hire.id, 10) === selectedSubcontractor;
            return (
              <HireChipMobile
                key={`sub-${hire.id}`}
                hire={hire}
                selected={selected}

                onSelect={() => onSelect(selected ? null : parseInt(hire.subcontractorID, 10), false)}
              />
            );
          })}
      </div>
    </div>
  );
};

export default HiresListMobile;
