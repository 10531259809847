// @ts-nocheck
import React, { useRef } from 'react';


import { SortButton } from 'common-ui/Button';
import ClickableDiv from 'common-ui/ClickableDiv';
import ID from 'common-ui/ConsistentIDs';
import { Close, Eyeglass } from 'common-ui/Icon';
import { useOutsideClickHandler } from 'utils/Hooks';
import MultiSelectWithoutValues from './components/MultiSelectWithoutValues';
import SelectedTermOption from './components/SelectedTermOption';
import SortItem from './components/SortItem';
import styles from './styles.module.scss';
import type { SearchUIProps } from './types';






const SearchUI = ({
  selectedTerms,
  possibleOptions,
  sortBoxText,
  sortIsOpen,
  setSortBox,
  sortOptions,
  sortOption,
  onSortClick,
  onSelectSort,
  onCreateOption,
  onSelectTerm,
  clearAll,
  onClickClear,
  placeholderText,
  clearAllText,
  formatCreateLabel,
  Filter,
}: SearchUIProps) => {
  const sortOptionsRef = useRef();

  useOutsideClickHandler(sortOptionsRef, () => setSortBox(false));

  return (

    <div className={styles.searchWrapper}>

      <div className={styles.wrapSelection}>

        <div className={styles.searchArea}>
          <div className={styles.eyeglassIcon}>
            <Eyeglass />
          </div>
          <MultiSelectWithoutValues
            {...{
              onSelectTerm,
              onCreateOption,
              possibleOptions,
              selectedTerms,
              placeholderText,
              formatCreateLabel,
            }}
          />
          <div className={styles.searchArea} ref={sortOptionsRef}>
            <SortButton
              className={styles.sortButton}
              onClick={onSortClick}
              onKeyPress={onSortClick}
            />
            {sortIsOpen ? (
              <div className={styles.sortBox}>
                <div className={styles.sortBoxText}>{sortBoxText}</div>
                <div className={styles.sortBoxItems}>
                  {sortOptions.map((option, key) => {
                    const isSelected = sortOption
                      ? option.id === sortOption.id
                      : false;
                    const isLeft = key === 0;

                    return (
                      <SortItem
                        key={option.id}
                        onClick={onSelectSort}
                        id={option.id}
                        label={option.name}
                        isSelected={isSelected}
                        isLeft={isLeft}
                        option={option}
                      />
                    );
                  })}
                </div>
              </div>
            ) : null}
          </div>
        </div>

      </div>
      {Filter}

      <div className={styles.filterList}>
        {selectedTerms.map((selectedTerm) => {
          const { filterType, filterValue, value } = selectedTerm;
          const filterText = filterType
            ? `${filterType.label}: ${filterValue}`
            : filterValue;
          if (!filterType || filterType.value !== 'driverStatus') {
            return (
              <SelectedTermOption
                key={value}
                id={value}
                term={selectedTerm}
                onClickClear={onClickClear}
              >
                {filterText}
              </SelectedTermOption>
            );
          }
        })}
        {selectedTerms.length > 0 ? (
          <ClickableDiv
            className={styles.clearAllFiltersButton}
            onClick={clearAll}
            id={ID.create(ID.Areas.General, ID.Types.Button, ID.Labels.ClearAllFilters)}
          >
            {clearAllText}
          </ClickableDiv>
        ) : null}
      </div>
    </div>
  );
};

SearchUI.defaultProps = {
  placeholderText: 'Start typing e.g. \'Client:\'...',
  formatCreateLabel: (label: any) => (label.length === 0) ? 'Search with ...' : `Search with "${label}"`,
  sortBoxText: 'Sort By',
  clearAllText: 'Clear all',
};

export default SearchUI;
