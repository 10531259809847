// @ts-nocheck
import queryString from 'query-string';
import React from 'react';
import { useLocation } from 'react-router-dom';

import {
  useLiveMapHandlers,
  useLiveAssignments,

} from 'pages/Live/Hooks/useLiveMapHandlers';
import Legend from 'pages/Live/components/Legend';
import { LiveSidebarContents } from 'pages/Live/components/LiveSidebar';
import type { intlShape } from 'react-intl';
import { injectIntlHOC } from 'utils/HOC';

import { useDetectMobile } from 'utils/Hooks';
import ShareLiveProjectMobile from './Mobile/ShareLiveProjectMobile';
import SharedMap from './components/SharedMap';
import { useSharedLiveProject } from './graphql';
import styles from './styles.module.scss';




type ShareLiveProjectProps = {
  intl: typeof intlShape,
}

const ShareLiveProject = ({
  intl,
}: ShareLiveProjectProps) => {
  const location = useLocation();
  const { uuid } = queryString.parse(location.search || '');
  const [liveProject, loading] = useSharedLiveProject(uuid as string );

  const isMobile = useDetectMobile();
  const sidebarVisible = true;

  const projects = liveProject && liveProject.project ? [liveProject.project] : [];
  const enabled = liveProject && liveProject.enabled;

  const {
    markerId: openedMarkerID,
    closedProjectIds,
    toggleMarkerOpen,
    toggleAssignmentOpen,
    // TODO can we just have one toggleAssignmentsOpen instead of the above too?
    toggleAssignmentsOpen,
  } = useLiveMapHandlers(projects);

  const {
    liveAssignments,
    markers,
    sites,
  } = useLiveAssignments(projects, closedProjectIds);

  const isCalculatingMarkers = markers.length === 0
    && liveProject
    && liveProject.projects
    && liveProject.project.assignments.some((a:any) => a.assignedTo.gps);

  // NOTE
  // Initial 'markers' comes from createMarkerFromAssignment,
  // which calculates a bunch of stuff including icon type (idle, badConn etc)
  // Markers are then recalculated if the markers object changes
  // due to filtering or the data updating
  // done in useLiveMarkerBounds, which just calls makeMarkers each time the markers list changes,
  // this becomes 'calcMarkers'
  // Why not just use these initial markers instead of recalculating in a separate step?
  // LiveMarkerIcon needs:
  //  lat, lng, id, rotation, type, initials, truckNumber, activeCycle,
  //  showName, showPhone etc
  // initial 'markers' has
  //  lat, lng, id, rotation, type, initials, truckNumber, activeCycle
  //  showName, showPhone etc

  // this first div is important because there's some secret rule forcing the
  // first div in the app to flex-direction: column

  return (

    <div>

      <div className={styles.shareLiveProjectPage}>
        {!isMobile ? (
          <>
            {sidebarVisible && (
              <>
                <div className={styles.sidebarContainer}>
                  <span className={styles.treadLogo}>TREAD</span>
                  <LiveSidebarContents
                    intl={intl}
                    // TODO WHYYY IS THIS PROP NAMED PROJECTS
                    projects={liveAssignments}
                    chosenMarkerId={openedMarkerID}
                    openMarker={toggleMarkerOpen}
                    toggleAssignmentOpen={toggleAssignmentOpen}
                    toggleAssignmentsOpen={toggleAssignmentsOpen}
                    disableSearch
                    disableToggleAllAssignments
                    hideShareButton
                  />
                </div>
              </>
            )}
            <SharedMap
              markers={markers}
              sites={sites}
              openedMarkerID={openedMarkerID}
              toggleMarkerOpen={toggleMarkerOpen}
              validLink={enabled}
              liveMapLoading={loading || isCalculatingMarkers}
            />
            <div className={styles.legendContainer}>
              {!isMobile && (<Legend intl={intl} />)}
            </div>
          </>
        ) : (
          <ShareLiveProjectMobile
            projects={liveAssignments}
            markers={markers}
            sites={sites}
            openedMarkerID={openedMarkerID}
            toggleMarkerOpen={toggleMarkerOpen}
            toggleAssignmentOpen={toggleAssignmentOpen}
            validLink={enabled}
            liveMapLoading={loading || isCalculatingMarkers}
          />
        )}
      </div>
    </div>
  );
};

// TODO upgrade react-intl so we can use the hook instead
export default injectIntlHOC(ShareLiveProject);
