// @ts-nocheck
import classNames from 'classnames';
import React, { useState } from 'react';

import ClickableDiv from 'common-ui/ClickableDiv';
import { Plus } from 'common-ui/Icon';
import { Tag } from 'common-ui/Labels';
import styles from './styles.module.scss';



type TagInputProps = {
  values: string[],
  onChange: (param:string[]) => void,
};

const TagInput = ({ values, onChange, ...inputProps }: TagInputProps) => {
  const valuesSet = new Set(values);
  const [userInput, setUserInput] = useState('');

  const addTag = () => {
    const input = userInput.trim();
    if (input) {
      valuesSet.add(input);
      onChange(Array.from(valuesSet));
    }
    setUserInput('');
  };

  return (

    <div className={styles.tagInput}>

      <input
        className={styles.textInputField}
        value={userInput}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            addTag();
            event.preventDefault();
          }
        }}
        onChange={e => setUserInput(e.target.value)}
        {...inputProps}
      />

      <ClickableDiv

        className={classNames(styles.tagAddButton, { [styles.hidden]: !userInput.length })}
        onClick={addTag}
      >

        <Plus />
      </ClickableDiv>

      <div className={styles.tagsList}>
        {values.map(tag => (
          <ClickableDiv
            key={tag}
            onClick={() => {
              valuesSet.delete(tag);

              onChange(Array.from(valuesSet));
            }}
            onTouchEnd={() => {
              valuesSet.delete(tag);

              onChange(Array.from(valuesSet));
            }}
          >
            <Tag
              text={tag}
              className={styles.tagChip}
              rightIcon={<span className={styles.xIndicator}>×</span>}
            />
          </ClickableDiv>
        ))}
      </div>
    </div>
  );
};

export default TagInput;
