// @ts-nocheck
import classNames from 'classnames';
import React from 'react';
import ClickableDiv from '../../ClickableDiv';
import { Check, Line } from '../../Icon';
import styles from './styles.module.scss';

type CheckboxProps = {
  checked: boolean,
  onChange: (checked: boolean) => void,
  label?: string,
  block?: boolean,
  id?: string,
  disabled?: boolean,
  partiallySelected?: boolean,
  className?: string,
};

const Checkbox = ({
  checked,
  onChange,
  label,
  block,
  id,
  disabled,
  partiallySelected,
  className,
  ...inputProps
}: CheckboxProps) => (

  <ClickableDiv

    id={id}
    onClick={(e) => { e.preventDefault(); onChange(!checked); }}
    disabled={disabled}
    aria-checked={disabled ? undefined : checked}
    role="checkbox"

    className={classNames({
      [styles.checkboxContainer]: !block,
      [styles.checkboxBlockContainer]: block,
      [styles.selected]: block && checked,

      [className]: className,
    })}
  >

    <input
      disabled={disabled}
      type="checkbox"
      hidden
      readOnly
      checked={checked}
      {...inputProps}
    />

    <div

      className={classNames(styles.checkboxControl, {
        [styles.selected]: checked || partiallySelected,
        [styles.disabled]: disabled,
        [styles.partiallySelected]: partiallySelected,
      })}
    >
      {!checked && partiallySelected ? (

        <Line preserveAspectRatio="none" />
      ) : (

        <Check />
      )}
    </div>

    {label && <div className={styles.checkboxLabel}>{label}</div>}
  </ClickableDiv>
);

Checkbox.defaultProps = {
  label: '',
  block: false,
  id: undefined,
  disabled: false,
  partiallySelected: false,
  className: undefined,
};

export default Checkbox;
