// @ts-nocheck
import classNames from 'classnames';
import React, { ReactNode } from 'react';

import Select, { createFilter } from 'react-select';
import { ChevronLeft } from '../../Icon';
import styles from './styles.module.scss';

type DropdownProps = {
  options: { label: ReactNode, value: string }[],
  onChange: (newOption?: string) => void,

  value?: string,
  embedded?: boolean,
  id?: string,
  onMenuScrollToBottom?: () => void,
  onInputChange?: (inputText: string) => void,
  disableBuiltInFilter?: boolean,
  shouldOverlay?: boolean,
};

const Dropdown = ({
  options,
  onChange,
  value,
  embedded,
  id,
  onMenuScrollToBottom,
  onInputChange,
  disableBuiltInFilter,
  shouldOverlay,
  ...inputProps
}: DropdownProps) => (

  <Select
    id={id}
    classNamePrefix="react-select"
    filterOption={disableBuiltInFilter

      ? (options) => options
      : createFilter({
        matchFrom: 'any',

        stringify: option => `${option.label}`,
      })}
    options={options}
    menuPosition={shouldOverlay ? 'fixed' : ''}
    styles={{

      menuPortal: provided => ({ ...provided, zIndex: shouldOverlay ? 2 : 1 }),

      control: (provided, state) => ({
        ...provided,
        border: embedded && !state.isFocused ? '0 !important' : 'inherit',
        height: embedded ? '100%' : 'inherit',
        backgroundColor: embedded && !state.isFocused ? 'transparent' : provided.backgroundColor,
        minHeight: '40px',
      }),

      container: (provided) => ({
        ...provided,
        height: embedded ? '100%' : 'inherit',
      }),
    }}
    components={{
      IndicatorSeparator: null,
      DropdownIndicator: ({
        // innerProps and selectProps come from react-select library
        innerProps,
        selectProps,
      }:any) => (

        <div

          className={classNames(styles.dropdownIndicator, {
            [styles.open]: selectProps.menuIsOpen,
          })}
          {...innerProps}
        >
          <ChevronLeft />
        </div>
      ),
    }}
    {...{
      ...inputProps,
      value: options.filter(({ value: v }) => v === value),

      onChange: (newOption) => onChange(newOption ? newOption.value : null),
    }}

    theme={(theme) => ({
      ...theme,
      colors: {
        ...theme.colors,
        primary: styles.colorSelected,
        primary25: styles.colorSelectedLight,
        primary50: styles.colorSelectedMedium,
      },
    })}
    onMenuScrollToBottom={onMenuScrollToBottom}
    onInputChange={onInputChange}
  />
);

Dropdown.defaultProps = {
  embedded: false,
  id: undefined,
  onMenuScrollToBottom: () => { },
  onInputChange: () => { },
  disableBuiltInFilter: false,
  shouldOverlay: false,
};

export default Dropdown;
