// @ts-nocheck

import { SplitTreatments } from '@splitsoftware/splitio-react';
import PropTypes from 'prop-types';
import React from 'react';
import { useCompanyContext } from 'utils/Hooks';

// SplitTreatment is a wrapper around the vendor-provided SplitTreatments component. it
// simplifies the logic required to display different components based on a splitio treatment (aka flag).
//
// <SplitTreatment name="my_split_flag">
//  <SplitTreatment.On>
//    <div>My split flag is on!</div>
//  </SplitTreatment.On>
//  <SplitTreatment.Off>
//    <div>My split flag is off!</div>
//  </SplitTreatment.Off>
// </SplitTreatment>

const On = ({ children }) => children;
On.propTypes = {
  children: PropTypes.node
};

const Off = ({ children }) => children;
Off.propTypes = {
  children: PropTypes.node
};

const ON_STATE = 'on';

export const SplitTreatment = ({ name, children }) => {
  const { selectedCompanyID } = useCompanyContext();
  // TODO: selectedCompanyID comes in as a string even though it's typed as a number?
  // this means that i can't call the line below because ts claims selectedCompanyID
  // is already a number (even though it's demonstrably not)
  const companyID = parseInt(selectedCompanyID, 10);

  // since a user can have multiple companies, splitio expects an array here. in this codebase we can only ever
  // get the currently set company id, so we just wrap that in an array (as long as it's valid)
  const companies = [];
  if (Number.isInteger(companyID) && companyID !== 0) {
    companies.push(companyID);
  }

  return (
    <SplitTreatments attributes={{ companies }} names={[name]} >
      {({ treatments, isReady }) => {
        // TODO: do we want to support a separate component here for this case (eg, loading spinner)?
        if (!isReady) return;

        const state = treatments[name]?.treatment;
        // NOTE: split actually has three states here:
        //
        //   1. 'on' - a split treatment has been explicitly enabled
        //   2. 'off' - a split treatment has been explicitly disabled
        //   3. 'control' - split is unsure for some reason (https://help.split.io/hc/en-us/articles/360020528072-Control-treatment)
        //
        // we simplify that logic here by assuming everything is either 'on' or 'off' with a default of off in the 'control' state.
        //
        // also note it's _possible_ for someone to setup a split with more than just 'on' and 'off'. this should be discouraged.
        if (state === ON_STATE) {
          return React.Children.toArray(children).find(child => child.type.name === On.name);
        } else {
          return React.Children.toArray(children).find(child => child.type.name === Off.name);
        }
      }}
    </SplitTreatments>
  );
};


SplitTreatment.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
};

SplitTreatment.On = On;
SplitTreatment.Off = Off;
