// @ts-nocheck
import { stubFalse } from 'lodash';
import React from 'react';

import { Tag } from 'common-ui/Labels';
import type { InstanceStatus } from 'pages/Projects/types';


import { instanceStatuses } from 'pages/Projects/utils/projects.utils';
import styles from './styles.module.scss';

export const textForStatus = (status: InstanceStatus) => {
  switch (status) {
    case instanceStatuses.CANCELLED:
      return 'Cancelled';
    case instanceStatuses.REASSIGNED:
      return 'Reassigned';
    case instanceStatuses.UNASSIGNED:
      return 'Unassigned';
    case instanceStatuses.TERMINATED:
      return 'Ended';
    default:
      return '';
  }
};

const classNameForStatus = (status: InstanceStatus) => {
  switch (status) {
    case instanceStatuses.CANCELLED:
      return styles.cancelled;
    case instanceStatuses.REASSIGNED:
      return styles.reassigned;
    case  instanceStatuses.UNASSIGNED:
      return styles.reassigned;
    case instanceStatuses.TERMINATED:
      return styles.terminated;
    default:
      return '';
  }
};

type InstanceStatusTagProps = {
  status: InstanceStatus,
};

const InstanceStatusTag = ({ status }: InstanceStatusTagProps) => (

  <Tag text={textForStatus(status)} className={classNameForStatus(status)} />
);

export default InstanceStatusTag;
