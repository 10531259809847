// @ts-nocheck
import classNames from 'classnames';
import React, { useState, useRef, ReactNode } from 'react';

import { Button } from 'common-ui/Button';
import { Expand } from 'common-ui/Icon';
import { useOutsideClickHandler } from 'utils/Hooks';
import styles from './styles.module.scss';


export type ProjectCardActionDropdownProps = {
  children: ReactNode,
  className?: string,
  disableAllProjectActions: boolean,
  id?: undefined,
};

const ProjectCardActionDropdown = ({
  children,
  className,
  disableAllProjectActions,
  id,
}: ProjectCardActionDropdownProps) => {
  const dropdownOptionsRef = useRef();
  const [dropdownState, setDropdownState] = useState(false);
  useOutsideClickHandler(dropdownOptionsRef, () => setDropdownState(false));
  return (

    <div className={classNames(
      className,
      styles.projectActionDropdownWrapper

    )} ref={dropdownOptionsRef}>

      <Button
        id={id}
        text="Actions"

        icon={<Expand />}
        rightIcon
        disabled={disableAllProjectActions}
        onClick={() => { setDropdownState(!dropdownState); }}
        onKeyPress={() => { setDropdownState(!dropdownState); }}
        className={styles.dropdownButton}
      />
      {!!dropdownState && (

        <div className={styles.projectActionDropdown}>
          { children }
        </div>
      )}
    </div>
  );
};

ProjectCardActionDropdown.defaultProps = {
  className: undefined,
  id: undefined,
};

export default ProjectCardActionDropdown;
