import { formatRateUnit } from './rateUnits';

export default function currency(input: any) {
  const formatter = new Intl.NumberFormat('en-CA', {
    style: 'currency',
    currency: 'CAD',
    minimumFractionDigits: 2,
  });
  return input ? formatter.format(input) : formatter.format(0);
}

export function formatPayRate(rate: any, unit: any) {
  const formattedRate = currency(rate);
  const unitValue = formatRateUnit(unit);
  if (!rate && !unitValue) {
    return 'No rate provided';
  }
  if (unitValue && !rate) {
    return `Pay by ${unitValue}`;
  }
  if (!unitValue && rate) {
    return formattedRate;
  }

  return `${formattedRate}/${unitValue}`;
}

export function formatUnit(rate: any, unit: any) {
  const formattedRate = currency(rate);
  const unitValue = formatRateUnit(unit);
  if (!rate && !unitValue) {
    return 'No rate provided';
  }
    return unitValue;
}

export function formatRate(rate: any, unit: any) {
  const formattedRate = currency(rate);
  const unitValue = formatRateUnit(unit);
  if (!rate && !unitValue) {
    return 'No rate provided';
  }
  if (unitValue && !rate) {
    return '-';
  }
    return formattedRate;
}
