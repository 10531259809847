// @ts-nocheck
import classNames from 'classnames';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import {NavLink} from 'react-router-dom';
import styles from '../styles.module.scss';
import ID from 'common-ui/ConsistentIDs';
import {Dropdown} from 'common-ui/Form';
import {usePermissions} from 'common-ui/Hooks';
import {IntercomLauncher} from 'common-ui/Hooks/Intercom';
import {
  Dispatcher,
  Data,
  Person,
  Clock,
  Send,
  Settings,
  Bell,
  Close,
  Site,
  Insights,
  Target,
  Receipt,
  Stopwatch,
} from 'common-ui/Icon';
import {Count} from 'common-ui/Labels';
import BadgeHoverIcon from 'components/Common/BadgeHoverIcon';
import CompanyLogoRectangle from 'components/Common/CompanyLogo/CompanyLogoRectangle';
import {TooltipWrapper} from 'components/Common/Tooltip';
import {
  useGlobalSearch,
  SearchBar,
} from 'components/MainContainer/components/SearchBar';
import {useHiresCompanies} from 'pages/Hires/gql';
import {useCompanyContext} from 'utils/Hooks';
import {CompanyDropdown} from './CompanyDropdown';
import {MenuLink} from './MenuLink';
import {PaymentsMenuLink} from './PaymentsMenuLink';
import useGetPathSearchParams from './utils';
import type {
  NotificationsLinkPropTypes,
  MobileHeaderPropTypes,
  HidingMobileHeaderPropTypes,
  DesktopHeaderPropTypes,
  MobileNavMenuPropTypes,
} from '../types';

const NotificationsLink = ({
  count,
  mobile,
  toggleNotificationsSidebar,
}: NotificationsLinkPropTypes) => (
  <div className={styles.headerButton}>
    {!!count && (
      <div
        className={classNames(styles.notificationsCount, {
          [styles.mobile]: mobile,
        })}
      >
        {count}
      </div>
    )}
    <button
      type="button"
      className={styles.notificationsLink}
      onClick={toggleNotificationsSidebar}
    >
      <BadgeHoverIcon icon={<Bell />} tooltipMessage="Notifications" />
    </button>
  </div>
);

NotificationsLink.defaultProps = {
  mobile: false,
  toggleNotificationsSidebar: () => {},
};

const HamburgerIcon = () => (
  <div className={styles.hamburgerContainer}>
    <div className={styles.hamburgerLine} />
    <div className={styles.hamburgerLine} />
    <div className={styles.hamburgerLine} />
  </div>
);

// Use this hook where the hiding mobile header is used
// and then pass down this state handler
// to the child that should be setting these values on scroll events
export const useHideyHeader = () => {
  const [headerVisible, setHeaderVisible] = React.useState(true);
  const showHeader = React.useCallback(() => setHeaderVisible(true), []);
  const hideHeader = React.useCallback(() => setHeaderVisible(false), []);

  return [headerVisible, showHeader, hideHeader];
};

export const MobileHeader = ({
  menuVisible,
  setMenuVisible,
  notificationsCount,
  toggleNotificationsSidebar,
}: MobileHeaderPropTypes) => {
  const companies = useHiresCompanies(true, true);
  const {selectedCompanyID} = useCompanyContext();
  const selectedCompany = companies.find((x) => x.id === selectedCompanyID);

  return (
    <div className={styles.mobileHeader}>
      <div
        className={styles.mobileMenuButton}
        onClick={() => setMenuVisible(!menuVisible)}
        onKeyPress={() => setMenuVisible(!menuVisible)}
        role="button"
        tabIndex={0}
      >
        {menuVisible ? <Close /> : <HamburgerIcon />}
      </div>
      <div className={styles.mobileCompanyTitle}>
        <CompanyLogoRectangle
          value={selectedCompany}
          multiple={companies.length > 1}
        />
      </div>
      <div className={styles.mobileIconsContainer}>
        <IntercomLauncher mobile />
        <NotificationsLink
          count={notificationsCount}
          mobile
          toggleNotificationsSidebar={toggleNotificationsSidebar}
        />
      </div>
    </div>
  );
};

export const HidingMobileHeader = ({
  headerVisible,
  ...rest
}: HidingMobileHeaderPropTypes) => (
  <div
    className={classNames(styles.hidingHeader, {
      [styles.hidingHeaderVisible]: headerVisible,
      [styles.hidingHeaderHidden]: !headerVisible,
    })}
  >
    <MobileHeader {...rest} />
  </div>
);

export const MobileNavMenu = ({
  requestsCount,
  onChange,
}: MobileNavMenuPropTypes) => {
  const companies = useHiresCompanies(true, true);
  const {selectedCompanyID, setSelectedCompanyID} = useCompanyContext();

  const {
    ViewDispatchModule: canViewDispatch,
    ViewLiveModule: canViewLive,
    ViewPeopleModule: canViewPeople,
    ViewTargetsModule: canViewTargets,
    ViewHistoryModule: canViewHistory,
    ViewRequestsModule: canViewRequests,
    ViewTicketsModule: canViewTickets,
    ViewInsightsDash: canViewInsights,
    ViewTimesheetsModule: canViewTimesheets,
  } = usePermissions(selectedCompanyID);
  const params = selectedCompanyID ? `?company=${selectedCompanyID}` : '';

  const ticketSearch = useGetPathSearchParams('/payments/tickets');
  const timesheetSearch = useGetPathSearchParams('/payments/timesheets');

  return (
    <div className={styles.mobileNavMenu}>
      {companies && companies.length > 1 && (
        <div className={styles.mobileCompanyDropdown}>
          <Dropdown
            name="companyId"
            options={companies.map((company) => ({
              label: company.name,
              value: company.id,
            }))}
            placeholder="Select..."
            value={selectedCompanyID}
            onChange={(value) => setSelectedCompanyID(value)}
          />
        </div>
      )}
      {canViewDispatch && (
        <MenuLink
          text={
            <FormattedMessage
              id="main.header.dispatch"
              defaultText="Dispatch"
            />
          }
          path={{
            pathname: '/dispatch',
            search: params,
          }}
          menuIcon={<Dispatcher />}
          mobile
          onChange={onChange}
        />
      )}
      {canViewLive && (
        <MenuLink
          text={<FormattedMessage id="main.header.live" defaultText="Live" />}
          path={{
            pathname: '/live',
            search: params,
          }}
          menuIcon={<Data />}
          mobile
          onChange={onChange}
        />
      )}
      {canViewPeople && (
        <MenuLink
          text={
            <FormattedMessage
              id="main.header.accounts"
              defaultText="Accounts"
            />
          }
          path={{
            pathname: '/accounts',
            search: params,
          }}
          menuIcon={<Person />}
          mobile
          onChange={onChange}
        />
      )}
      {canViewTargets && (
        <MenuLink
          text={
            <FormattedMessage id="main.header.targets" defaultText="Targets" />
          }
          path={{
            pathname: '/targets',
            search: params,
          }}
          menuIcon={<Target />}
          mobile
          onChange={onChange}
        />
      )}
      {canViewTickets && (
        <MenuLink
          text="Tickets"
          path={{pathname: '/payments/tickets', search: ticketSearch}}
          menuIcon={<Receipt />}
          mobile
          onChange={onChange}
        />
      )}
      {canViewTimesheets && (
        <MenuLink
          text="Timesheets"
          path={{pathname: '/payments/timesheets', search: timesheetSearch}}
          menuIcon={<Stopwatch />}
          mobile
          onChange={onChange}
        />
      )}
      {canViewHistory && (
        <MenuLink
          text={
            <FormattedMessage id="main.header.history" defaultText="History" />
          }
          path={{
            pathname: '/history',
            search: params,
          }}
          menuIcon={<Clock />}
          mobile
          onChange={onChange}
        />
      )}
      {canViewRequests && (
        <MenuLink
          text={
            <>
              <FormattedMessage
                id="main.header.requests"
                defaultText="Requests"
              />
              {requestsCount > 0 && (
                <Count className={styles.requestsCount} value={requestsCount} />
              )}
            </>
          }
          path={{
            pathname: '/requests',
            search: params,
          }}
          menuIcon={<Send />}
          mobile
          onChange={onChange}
        />
      )}
      {canViewInsights && (
        <MenuLink
          text={
            <FormattedMessage
              id="main.header.insights"
              defaultText="Insights"
            />
          }
          path={{
            pathname: '/insights',
            search: params,
          }}
          menuIcon={<Insights />}
          mobile
          onChange={onChange}
        />
      )}
      <MenuLink
        text={
          <FormattedMessage id="main.header.settings" defaultText="Settings" />
        }
        path={{
          pathname: '/settings/profile',
          search: params,
        }}
        menuIcon={<Settings />}
        mobile
        onChange={onChange}
      />
    </div>
  );
};

export const DesktopHeader = ({
  requestsCount,
  notificationsCount,
  toggleNotificationsSidebar,
}: DesktopHeaderPropTypes) => {
  const companies = useHiresCompanies(true, true);
  const {selectedCompanyID, setSelectedCompanyID} = useCompanyContext();
  const {
    ViewDispatchModule: canViewDispatch,
    ViewLiveModule: canViewLive,
    ViewPeopleModule: canViewPeople,
    ViewTargetsModule: canViewTargets,
    ViewSitesModule: canViewSites,
    ViewHistoryModule: canViewHistory,
    ViewRequestsModule: canViewRequests,
    ViewPaymentsModule: canViewPayments,
    ViewInsightsDash: canViewInsights,
    GlobalSearch: canGlobalSearch,
  } = usePermissions(selectedCompanyID);
  const navLayout = 'auto 1fr';
  const params = selectedCompanyID ? `?company=${selectedCompanyID}` : '';
  const selectedCompany = companies.find((x) => x.id === selectedCompanyID);
  const [searchTerms, setSearchTerms] = useGlobalSearch();
  const accountsSearch = useGetPathSearchParams('/accounts');
  const link =
    selectedCompany && selectedCompany.isExternalDOT
      ? `/payments/tickets${params}`
      : `/dispatch${params}` || '/';

  return (
    <div className={styles.navHeader}>
      <div
        className={styles.navHeaderTop}
        style={{ gridTemplateColumns: navLayout }}
      >
        <div className={styles.navHeaderTopLeft}>
          {companies && companies.length > 1 && (
            <CompanyDropdown
              options={companies}
              onChange={setSelectedCompanyID}
            />
          )}
          <div className={styles.headerLogo}>
            <NavLink to={link} className={styles.companyLogo}>
              <CompanyLogoRectangle
                value={selectedCompany}
                multiple={companies.length > 1}
              />
            </NavLink>
          </div>
        </div>
        <div className={styles.navHeaderTopRight}>
          {canGlobalSearch && (
            <SearchBar
              id={ID.create(
                ID.Areas.General,
                ID.Types.Input,
                ID.Labels.GeneralSearchbar
              )}
              searchTerms={searchTerms}
              setSearchTerms={setSearchTerms}
              placeholder="Search Tread"
            />
          )}
          <div className={styles.headerButton}>
            <div className={styles.hoverIconContainer}>
              <TooltipWrapper
                message="Contact Support"
                className={styles.titleInfoIcon}
              >
                <IntercomLauncher />
              </TooltipWrapper>
            </div>
          </div>
          <div className={styles.headerButton}>
            <NavLink
              to={{
                pathname: '/settings/profile',
                search: params,
              }}
              className={styles.notificationsLink}
            >
              <BadgeHoverIcon
                id={ID.create(
                  ID.Areas.General,
                  ID.Types.Link,
                  ID.Labels.SettingsLink
                )}
                icon={<Settings />}
                tooltipMessage="Settings"
              />
            </NavLink>
          </div>
          <NotificationsLink
            count={notificationsCount}
            toggleNotificationsSidebar={toggleNotificationsSidebar}
          />
        </div>
      </div>
      <div className={styles.navHeaderBottom}>
        <div className={styles.menu}>
          {canViewDispatch && (
            <MenuLink
              id={ID.create(
                ID.Areas.General,
                ID.Types.Link,
                ID.Labels.DispatchMenuLink
              )}
              text={
                <FormattedMessage
                  id="main.header.dispatch"
                  defaultText="Dispatch"
                />
              }
              path={{
                pathname: '/dispatch',
                search: params,
              }}
              menuIcon={<Dispatcher />}
            />
          )}
          {canViewLive && (
            <MenuLink
              id={ID.create(
                ID.Areas.General,
                ID.Types.Link,
                ID.Labels.LiveMenuLink
              )}
              text={
                <FormattedMessage id="main.header.live" defaultText="Live" />
              }
              path={{
                pathname: '/live',
                search: params,
              }}
              menuIcon={<Data />}
            />
          )}
          {canViewPeople && (
            <MenuLink
              id={ID.create(
                ID.Areas.General,
                ID.Types.Link,
                ID.Labels.AccountsMenuLink
              )}
              text={
                <FormattedMessage
                  id="main.header.accounts"
                  defaultText="Accounts"
                />
              }
              path={{
                pathname: '/accounts',
                search: accountsSearch,
              }}
              menuIcon={<Person />}
            />
          )}
          {canViewTargets && (
            <MenuLink
              id={ID.create(
                ID.Areas.General,
                ID.Types.Link,
                ID.Labels.TargetsMenuLink
              )}
              text={
                <FormattedMessage
                  id="main.header.targets"
                  defaultMessage="Targets"
                />
              }
              path={{
                pathname: '/targets',
                search: params,
              }}
              menuIcon={<Target />}
            />
          )}
          {canViewSites && (
            <MenuLink
              id={ID.create(
                ID.Areas.General,
                ID.Types.Link,
                ID.Labels.SitesMenuLink
              )}
              text={
                <FormattedMessage id="main.header.sites" defaultText="Sites" />
              }
              path={{
                pathname: '/sites',
                search: params,
              }}
              menuIcon={<Site />}
            />
          )}
          {canViewPayments && (
            <PaymentsMenuLink
              id={ID.create(
                ID.Areas.General,
                ID.Types.Link,
                ID.Labels.PaymentsMenuLink
              )}
              text="Payments"
              menuIcon={<Receipt />}
            />
          )}
          {canViewHistory && (
            <MenuLink
              id={ID.create(
                ID.Areas.General,
                ID.Types.Link,
                ID.Labels.HistoryMenuLink
              )}
              text={
                <FormattedMessage
                  id="main.header.history"
                  defaultText="History"
                />
              }
              path={{
                pathname: '/history',
                search: params,
              }}
              menuIcon={<Clock />}
            />
          )}
          {canViewRequests && (
            <MenuLink
              id={ID.create(
                ID.Areas.General,
                ID.Types.Link,
                ID.Labels.RequestsMenuLink
              )}
              text={
                <>
                  <FormattedMessage
                    id="main.header.requests"
                    defaultText="Requests"
                  />
                  {requestsCount > 0 && (
                    <Count
                      className={styles.requestsCount}
                      value={requestsCount}
                    />
                  )}
                </>
              }
              path={{
                pathname: '/requests',
                search: params,
              }}
              menuIcon={<Send />}
            />
          )}
          {canViewInsights && (
            <MenuLink
              id={ID.create(
                ID.Areas.General,
                ID.Types.Link,
                ID.Labels.InsightsMenuLink
              )}
              text={
                <FormattedMessage
                  id="main.header.insights"
                  defaultText="Insights"
                />
              }
              path={{
                pathname: '/insights',
                search: params,
              }}
              menuIcon={<Insights />}
            />
          )}
        </div>
      </div>
    </div>
  );
};
