const cannotAssignHireToAssignment = (draggedHire: any, assignments: any) => {
  if (!draggedHire) {
    return false;
  }

  const isInternal = draggedHire.isInternal || draggedHire.__typename === 'Hire';
  return isInternal && (
    // hire is already assigned to smart assignment
    // but destination is a classic assignment
    (draggedHire.isAssignedToSmartAssignment && !assignments[0].isSmart)
    // hire is not assigned to smart assignment and already assigned
    // to classic assignments and destination is a smart assignment
    || (!draggedHire.isAssignedToSmartAssignment
        && !draggedHire.isNotAssignedToAnyAssignment
        && assignments[0].isSmart
    )
  );
};

export default cannotAssignHireToAssignment;
