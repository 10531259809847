import React, {SVGProps} from 'react';

const Logout = (props: SVGProps<SVGSVGElement>) => (

  <svg width={19} height={21} viewBox="0 0 19 21" {...props}>

    <title>Logout</title>

    <path
      d="M15.69 7.809l-.273-.273a.976.976 0 1 1 1.376-1.384l1.952 1.94c.383.38.385.998.005 1.38l-1.953 1.965a.976.976 0 0 1-1.384-1.377l.296-.3h-4.975a.972.972 0 0 1-.973-.975c0-.54.442-.976.973-.976h4.957zm-7.881 9.76v2.161c0 1.03-1.02 1.605-1.902 1.06L.875 17.678A1.94 1.94 0 0 1 0 16.108V1.462C0 1.148.093.878.248.668a.392.392 0 0 1 .057-.1A1.464 1.464 0 0 1 1.465 0H12.2c.806 0 1.464.656 1.464 1.46v2.444a.976.976 0 0 1-1.952 0V1.952l-7.31-.004 2.531 1.565c.515.318.875.966.875 1.569v10.535h3.904v-1.952a.976.976 0 1 1 1.952 0v2.444c0 .808-.657 1.46-1.464 1.46H7.809zM5.86 16.498V5.172L1.952 2.744v13.353l3.91 2.38v-1.789a.988.988 0 0 1 0-.19z"
      fill="#000B9D"
      fillRule="evenodd"
    />
  </svg>
);

export default Logout;
