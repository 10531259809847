// @ts-nocheck
import PropTypes from 'prop-types';
import React from 'react';
import {Marker} from 'react-map-gl';

import {PositionPropType, PositionType} from '../utils/map.utils';

type IconMarkerType = {
  position: PositionType;
  color: string;
};

const IconMarker = ({position, color}: IconMarkerType) => {
  return (

    <Marker
      longitude={position?.lng}
      latitude={position?.lat}
      offsetLeft={-8}
      offsetTop={-8}
      key={`marker-${position.id}`}
    >

      <svg width={16} height={16} viewBox="0 0 16 16">

        <circle cx="8" cy="8" r="8" fill={color} />
      </svg>
    </Marker>
  );
};

IconMarker.propTypes = {
  position: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.shape(PositionPropType),
  ]).isRequired,
  color: PropTypes.string.isRequired,
};

export default IconMarker;
