// @ts-nocheck
import React from 'react';

import ClickableDiv from 'common-ui/ClickableDiv';
import styles from './styles.module.scss';

export type ProjectCardActionOptionProps = {
  label: string,
  onClick: () => void,
  id?: string,
};

const ProjectCardActionOption = ({
  label,
  onClick,
  id,
}: ProjectCardActionOptionProps) => (

  <ClickableDiv
    id={id}
    className={styles.dropdownValue}
    onClick={onClick}
  >
    {label}
  </ClickableDiv>
);

ProjectCardActionOption.defaultProps = {
  id: undefined,
};

export default ProjectCardActionOption;
