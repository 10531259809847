import React, {SVGProps} from 'react';

const Insights = (props: SVGProps<SVGSVGElement>) => (

  <svg
    width={24}
    height={20}
    viewBox="0 0 24 20"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >

    <title>Mask</title>

    <defs>

      <path
        d="M2.714 8.967a10.154 10.154 0 0 0-.532 3.255c0 2.016.586 3.939 1.662 5.556.3.014 16.324-.006 16.324-.006a10.073 10.073 0 0 0 1.65-5.55c0-1.14-.187-2.235-.532-3.255l-1.514.89a1.069 1.069 0 0 1-1.473-.401l-.013-.023a1.11 1.11 0 0 1 .395-1.5l1.665-.98c-1.55-2.543-4.19-4.325-7.255-4.67v2.174c0 .607-.484 1.099-1.091 1.099-.602 0-1.09-.493-1.09-1.1V2.284c-3.066.345-5.706 2.127-7.256 4.67l1.665.98c.515.303.693.975.395 1.5l-.013.023a1.069 1.069 0 0 1-1.473.401l-1.514-.89zM21.6 19.557c-.206.279-.529.443-.872.443H3.272c-.343 0-.666-.164-.872-.444A12.32 12.32 0 0 1 0 12.222C0 5.472 5.373 0 12 0s12 5.472 12 12.222a12.32 12.32 0 0 1-2.4 7.334zm-8.603-5.753a2.154 2.154 0 0 1-3.085 0 2.251 2.251 0 0 1 0-3.143c1.567-1.596 5.193-2.813 5.193-2.813.57-.216.863.083.659.654 0 0-1.199 3.705-2.767 5.302z"
        id="a"
      />
    </defs>

    <use fill="#4D4D4D" xlinkHref="#a" fillRule="evenodd" />
  </svg>
);

export default Insights;
