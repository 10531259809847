// @ts-nocheck
import moment from 'moment';
import queryString from 'query-string';
import { useLocation, useHistory } from 'react-router-dom';

import { useTimezone } from 'common-ui/Hooks';

const useGlobalSearch = (

  defaultStart?: moment | null,

  defaultEnd?: moment | null,
  nullDefault = false,

  paramOverrides?: Record<string, unknown> | null = {},
) => {
  const timezone = useTimezone();
  const location = useLocation();
  const history = useHistory();

  const searchBarURL = queryString.parse(location.search || '');
  const searchBarStartDate = searchBarURL.searchStart;
  const searchBarEndDate = searchBarURL.searchEnd;

  const selectedStartDate = searchBarStartDate && moment(searchBarStartDate).isValid()
    ? moment.tz(searchBarStartDate, timezone)

    : (defaultStart || moment().tz(timezone).startOf('day'));

  const selectedEndDate = searchBarEndDate && moment(searchBarEndDate).isValid()
    ? moment.tz(searchBarEndDate, timezone)

    : (defaultEnd || moment().tz(timezone).startOf('day'));
  let selectedDates = [selectedStartDate, selectedEndDate];
  if (nullDefault && !searchBarStartDate && !searchBarEndDate) {

    selectedDates = null;
  }

  const setSelectedDates = (newDates: [string | moment | null, string | moment | null]) => {
    if (newDates[0] === null || newDates[1] === null) {
      const dateNullURL = queryString.parse(location.search || '');
      delete dateNullURL.end;
      delete dateNullURL.start;

      history.push({
        search: queryString.stringify({
          ...dateNullURL,
          ...paramOverrides,
        }),
      });
    } else {

      const startDate = moment(newDates[0]);

      let endDate = moment(newDates[1]);

      const maxDate = moment(newDates[0]).add(7, 'd');
      if (endDate.isAfter(maxDate)) {
        endDate = maxDate;
      }
      const dateNullURL = queryString.parse(location.search || '');
      delete dateNullURL.end;
      delete dateNullURL.start;

      history.push({
        search: queryString.stringify({
          ...dateNullURL,
          searchStart: startDate.format('YYYY-MM-DD'),
          searchEnd: endDate.format('YYYY-MM-DD'),
          ...paramOverrides,
        }),
      });
    }
  };

  const locationSearch = queryString
    .parse(location.search || '');
  const searchPageURLParam = locationSearch.page;
  const searchTermURLParam = locationSearch.searchTerms;
  const searchTerms = searchTermURLParam
    ? searchTermURLParam
      .split(', ')
      .map((s: any) => ({
        label: s,
        value: s
      }))
    : [];
  const setSearchTerms = (terms: any) => {
    const formattedSearch = terms.map((t: any) => t.value).join(', ');
    history.push({
      pathname: '/search',
      search: queryString.stringify({
        ...locationSearch,
        page: searchPageURLParam,
        searchTerms: formattedSearch,
        searchStart: searchBarStartDate,
        searchEnd: searchBarEndDate,
      }),
    });
  };
  return [searchTerms, setSearchTerms, selectedDates, setSelectedDates];
};

export default useGlobalSearch;
