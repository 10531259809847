// @ts-nocheck
import classNames from 'classnames';
import React from 'react';
import { BaseControl } from 'react-map-gl';
import { Switch, Plus, Minus } from 'common-ui/Icon';
import styles from './styles.module.scss';

// We extend BaseControl from react-map-gl to use the `captureClick` and
// `captureDoubleClick` properties.
class CustomZoomControl extends BaseControl {
  _render() {
    const {
      onZoomIn,
      onZoomOut,
      onClickRefresh,
      noRefreshButton,
      noZoomButtons,
    } = this.props;

    return (!noRefreshButton || !noZoomButtons) && (
      <div className={styles.iconControl} ref={this._containerRef}>
        {!noZoomButtons && (
          <>
            <button
              type="button"
              className={classNames(styles.smallButton, styles.default)}
              onClick={onZoomIn}
            >
              <div
                className={classNames(
                  styles.smallButtonIcon,
                  styles.topIconControl,
                  styles.smallButtonBackground,
                )}
              >
                <Plus />
              </div>
            </button>
            <button
              type="button"
              className={classNames(styles.smallButton, styles.default)}
              onClick={onZoomOut}
            >
              <div
                className={classNames(
                  styles.smallButtonIcon,
                  !noRefreshButton ? styles.middleIconControl : styles.bottomMiddleIconControl,
                  styles.smallButtonBackground,
                )}
              >
                <Minus />
              </div>
            </button>
          </>
        )}
        {!noRefreshButton && (
          <button
            type="button"
            className={classNames(styles.smallButton, styles.default)}
            onClick={onClickRefresh}
          >
            <div
              className={classNames(
                styles.smallButtonIcon,
                noZoomButtons ? null : styles.bottomIconControl,
                styles.smallButtonBackground,
              )}
            >
              <Switch />
            </div>
          </button>
        )}
      </div>
    );
  }
}

CustomZoomControl.defaultProps = {
  noRefreshButton: false,
  noZoomButtons: false,
  captureClick: true,
  captureDoubleClick: true,
};

export default CustomZoomControl;
