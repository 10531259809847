// @ts-nocheck
import classNames from 'classnames';



import { isEqual } from 'lodash';
import React, { useState, useEffect } from 'react';


import TagList from '../TagsModal/TagList';
import useCompanyTags from '../useCompanyTags';
import { Button } from 'common-ui/Button';
import ClickableDiv from 'common-ui/ClickableDiv';
import { Close, Tag } from 'common-ui/Icon';
import { useDetectMobile } from 'utils/Hooks';
import styles from './styles.module.scss';

type HireTagFilterProps = {
  filters: [],
  setFilters: () => void,
  onClose: () => void,
  menuOpen: () => void,

  getAllFilteredHires: (string, []) => [],
  searchValue?: string,
  simple?: boolean,
  hireType?: string,
};

const HireTagFilter = ({
  filters, setFilters, onClose, menuOpen, getAllFilteredHires, searchValue, simple, hireType,
}: HireTagFilterProps) => {
  const [tags, setTags] = useState(filters);
  const hires = getAllFilteredHires(searchValue, tags);
  const hireCount = hires ? hires.length : 0;
  const companyTags = useCompanyTags();
  const isMobile = useDetectMobile();

  const [prevFilters, setPrevFilters] = useState(filters);
  useEffect(() => {
    if (!menuOpen && !isEqual(filters, tags)) {
      if (isEqual(filters, prevFilters)) {

        setFilters(tags);
      } else {
        setTags(filters);
      }
    }
  }, [menuOpen, setFilters, setTags, setPrevFilters, tags, filters]);

  useEffect(() => { setPrevFilters(filters); },
    [setPrevFilters, filters]);

  const buttonText = hireType || 'Result';

  return (

    <div className={classNames(styles.filterContainer, { [styles.mobile]: isMobile, [styles.smallContainer]: simple })}>
      {!simple && (

        <div className={styles.filterHeader}>
          <span>
            Filter by Tag
          </span>
          <ClickableDiv
            onClick={() => {
              onClose();

              setFilters(tags);
            }}
            className={styles.closeIcon}
          >
            <Close />
          </ClickableDiv>
        </div>
      )}

      <div className={classNames(styles.tagsListSection, { [styles.smallContainer]: simple })}>

        <TagList
          tags={tags}

          setTags={setTags}
          companyTags={companyTags}
          filterOnly
          icon={<Tag />}
          closeOnEnter={() => {
            if (simple) {
              onClose();
            }
          }}
        />
      </div>
      {!simple && (

        <div className={styles.tagsButtonsSection}>
          <Button
            text="Clear"
            onClick={() => setTags([])}
            disabled={tags.length < 1}
          />
          <Button
            className={styles.saveButton}

            text={hireCount === 1
              ? `View ${hireCount} ${buttonText}` : `View ${hireCount} ${buttonText}s`}
            onClick={() => {

              setFilters(tags);
              onClose();
            }}
          />
        </div>
      )}
    </div>
  );
};

HireTagFilter.defaultProps = {
  searchValue: '',
  simple: false,
  hireType: '',
};

export default HireTagFilter;
