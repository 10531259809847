// @ts-nocheck


import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';


import { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';

import { Close, Eyeglass } from 'common-ui/Icon';
import styles from './styles.module.scss';

type EyeglassWithPlaceholderProps = {
  placeholder: string,
};

const EyeglassWithPlaceholder = ({ placeholder }: EyeglassWithPlaceholderProps) => (

  <div className={styles.searchPlaceHolder}>

    <Eyeglass height={22} width={22} />
    &nbsp;
    {placeholder}
  </div>
);

const SearchTermItem = ({
  data,
  selectProps: { onChange, value }
}: any) => {
  const removeItem = () => {
    const action = {
      action: 'pop-value',
      removedValue: data,
    };
    const newValues = value.filter((v: any) => v.value !== data.value);
    onChange(newValues, action);
  };
  return (

    <div className={styles.searchBarTerm}>
      {data.label.length <= 16 ? data.label : _.truncate(data.label, { length: 16 })}

      <button
        className={styles.clearButton}
        onClick={removeItem}
        onKeyPress={removeItem}
        onTouchEnd={removeItem}
        type="button"
      >

        <Close />
      </button>
    </div>
  );
};


const ValueContainer = (props: any) => <components.ValueContainer className={styles.searchBarValueContainer} {...props} />;

type SearchTerm = {
  isNew: boolean,
  label: string,
  value: string,
}

type SearchBarProps = {
  searchTerms: SearchTerm,
  setSearchTerms: () => void,
  placeholder: string,
  searchBarStyle?: string,
  limit?: number,
};

export const SearchBar = ({
  searchTerms,
  setSearchTerms,
  placeholder,
  searchBarStyle,
  limit,
}: SearchBarProps) => {
  return (

    <div className={classNames(styles.globalSearchBar, searchBarStyle)}>

      <CreatableSelect
        isMulti
        isClearable
        classNamePrefix="global-searchbar"

        placeholder={<EyeglassWithPlaceholder placeholder={placeholder} />}
        value={searchTerms}
        onChange={(newVal: any) => {

          setSearchTerms(newVal || []);
        }}
        onKeyDown={(e: any) => {
          if (limit && searchTerms && searchTerms.length === limit && e.nativeEvent.code !== 'Backspace' && e.nativeEvent.code !== 'Delete') {
            e.preventDefault();
          }
        }}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          Menu: () => null,
          MultiValueContainer: SearchTermItem,
          ValueContainer,
        }}
      />
    </div>
  );
};

SearchBar.defaultProps = {
  searchBarStyle: undefined,
  limit: undefined,
};

export default SearchBar;
