// @ts-nocheck
import React from 'react';


import { CircleMarker, LostConnection } from 'common-ui/Icon';
import styles from './styles.module.scss';


type LegendProps = {
  intl: any
};

const Legend = ({ intl }: LegendProps) => (

  <div className={styles.legend}>

    <div className={`${styles.legendEntry} ${styles.online}`}>

      <CircleMarker />
      {intl.formatMessage({
        id: 'live.legend.online_text',
        defaultMessage: 'Online',
      })}
    </div>

    <div className={`${styles.legendEntry} ${styles.idle}`}>

      <CircleMarker />
      {intl.formatMessage({
        id: 'live.legend.idling_text',
        defaultMessage: 'Idling',
      })}
    </div>

    <div className={`${styles.legendEntry} ${styles.missing}`}>

      <CircleMarker />
      {intl.formatMessage({
        id: 'live.legend.missing_text',
        defaultMessage: 'Missing GPS',
      })}
    </div>

    <div className={`${styles.legendEntry} ${styles.nostatus}`}>

      <CircleMarker />
      {intl.formatMessage({
        id: 'live.legend.no_status_text',
        defaultMessage: 'No Status Available',
      })}
    </div>

    <div className={styles.legendEntry}>

      <LostConnection />
      {intl.formatMessage({
        id: 'live.legend.lost_connection_text',
        defaultMessage: 'Lost Connection',
      })}
    </div>
  </div>
);

export default Legend;
