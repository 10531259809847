// @ts-nocheck
import { connect, Formik } from 'formik';
import { useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';



type FormikAutoSaveProps = {
  formik: Formik,
  debounceTime?: number, // in ms
};

/**
 * Functional component that will handled auto submission with a certain amount of input delay.
 *
 * Precondition: Must be child of Formik and Form i.e.
 * <Formik ...>
 *   <Form ...>
 *     ....inputs
 *     <FormikAutosave debounceTime={1200} />
 *   </Form>
 * </Formik>
 *
 * @param formik: this is the formik object passed in. Change this to use the hook once
 * we've upgraded our formik version to 2.0 or above.
 * @param debounceTime: this is the amount of time the user stops typing in order for
 * the submission to happen. This shouldn't be lower than 1000 otherwise it can cause
 * issues when the user is trying to type.
 */
const FormikAutosave = ({
  formik,
  debounceTime,
}: FormikAutoSaveProps) => {
  const [debouncedSubmitCaller] = useDebouncedCallback((ctx: typeof formik) => {
    if (ctx.isValid) {
      ctx.submitForm();
    }

  }, debounceTime);

  useEffect(() => {
    if (formik.isValid && formik.dirty && !formik.isSubmitting) {
      debouncedSubmitCaller(formik);
    }
  }, [debouncedSubmitCaller, formik]);

  return null;
};

FormikAutosave.defaultProps = {
  debounceTime: 2000,
};

export default connect(FormikAutosave);
