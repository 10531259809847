// @ts-nocheck
import React from 'react';

import LiveMap from './components/LiveMap';
import LiveSidebar from './components/LiveSidebar';


import type { LivePropUITypes } from './types';

const LiveUI = ({
  liveMapLoading,
  sideBarVisible,
  projects,
  onRefresh,
  currentOptions,

  driverStatusOptions,

  setProjectFilterItems,

  projectFilterItems,
  toggleMarkerOpen,
  onProjectSort,
  markerId,
  toggleAssignmentOpen,

  toggleAssignmentsOpen,
  liveSortOptions,
  calcMarkers,
  sites,
  bounds,
  intl,
  setAutoZoom,
  openPopup,
  openedPopup,
  openModal,
  closeModal,
}: LivePropUITypes) => (

  <>
    {sideBarVisible && (

      <LiveSidebar
        {...{
          projects,
          onRefresh,
          currentOptions,
          driverStatusOptions,
          setProjectFilterItems,
          projectFilterItems,
          onProjectSort,
          liveSortOptions,
          intl,
        }}
        chosenMarkerId={markerId}
        openMarker={toggleMarkerOpen}
        toggleAssignmentOpen={toggleAssignmentOpen}
        toggleAssignmentsOpen={toggleAssignmentsOpen}
        openModal={openModal}
        closeModal={closeModal}
      />
    )}

    <LiveMap
      liveMapLoading={liveMapLoading}
      filters={projectFilterItems}
      openMarkerId={markerId}

      toggleMarkerOpen={toggleMarkerOpen}

      onRefresh={onRefresh}
      openedPopup={openedPopup}

      toggleAssignmentOpen={toggleAssignmentOpen}

      intl={intl}
      calcMarkers={calcMarkers || []}
      sites={sites}
      {...{
        bounds,
        onRefresh,
        toggleAssignmentOpen,
        intl,
        setAutoZoom,
        openPopup,
      }}
    />
  </>
);

LiveUI.defaultProps = {
  markerId: null,
  projects: null,
};

export default LiveUI;
