// @ts-nocheck
import { useQuery, gql } from '@apollo/client';
import { useMemo } from 'react';

const COMPANY_QUERY = gql`
  query company($id: ID!) {
    company(id: $id) {
      id
      name
      address1
      address2
      city
      description
      provinceState
      postalCodeZip
      country
      timezone
      isAdmin
      measurementType
      loadTracking
      ticketDefaults
      requestTimesheets
      timeOnSiteGoalMinutes
      timesheetDefaultAdjustments {
        id
        type
        minutes
        timing
        deleted
      }
      logo {
        getURL
        filename
      }
      driverMinimumScheduledMinutes
      driverMaximumScheduledMinutes
      scheduleViewStartHour
      scheduleViewTotalHours
      scheduleViewSlotsPerHour
      scheduleViewDefaultDuration
      defaultForwardingEmails
    }
  }
`;

// We need to provide some defaults while we're loading from the network.
const defaults = {
  scheduleViewStartHour: 0,
  scheduleViewTotalHours: 24,
  scheduleViewSlotsPerHour: 4,
  scheduleViewDefaultDuration: 90,
};

export const useCompany = (id: string) => {
  const { data, loading, error } = useQuery(COMPANY_QUERY, {
    variables: {
      id,
    },
    skip: !id || id === '0',
  });
  return useMemo(() => {
    if (loading || error || !data) return defaults;
    return data.company;
  }, [data, loading, error]);
};

export default useCompany;
