// @ts-nocheck
import React from 'react';
import CollaboratorInitials from './CollaboratorInitials';
import styles from './styles.module.scss';

type CollaboratorOptionProps = {
  firstName: string,
  lastName: string,
  companyName: string,
  secondaryText: string,
}

const CollaboratorOption = ({
  firstName,
  lastName,
  companyName,
  secondaryText,
}: CollaboratorOptionProps) => {
  return (

    <div className={styles.optionLabel}>

      <CollaboratorInitials firstName={firstName} lastName={lastName} />

      <div className={styles.textContainer}>

        <div className={styles.optionNameRow}>
          <span className={styles.name}>
            {`${firstName} ${lastName} `}
          </span>
          <span className={styles.secondary}>
            {secondaryText}
          </span>
        </div>

        <div className={styles.optionCompanyRow}>
          {companyName}
        </div>
      </div>
    </div>
  );
};

export default CollaboratorOption;
