// @ts-nocheck
import React from 'react';

import { QuestionMark } from 'common-ui/Icon';

import { TooltipWrapper } from 'components/Common/Tooltip';
import styles from './styles.module.scss';


type QuestionMarkHoverIconProps = {
  message: string,
};

const QuestionMarkHoverIcon = ({ message }:QuestionMarkHoverIconProps) => (

  <span className={styles.hoverIconContainer}>

    <TooltipWrapper
      message={message}
      className={styles.titleInfoIcon}
    >

      <QuestionMark />
    </TooltipWrapper>
  </span>
);

export default QuestionMarkHoverIcon;
