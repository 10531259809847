import { gql } from '@apollo/client';

// Deprecated, now using reactive variables for handling
// alert local state https://www.apollographql.com/docs/react/local-state/managing-state-with-field-policies/#storing-local-state-in-reactive-variables
export const SET_ALERT = gql`
  mutation setClientAlert($status: number, $message: string, $alertType: string, $intlId: string, $messageInfo: string) {
    setClientAlert(status: $status, message: $message, alertType: $alertType, intlId: $intlId, messageInfo: $messageInfo) @client
  }
`;

export const GET_ALERT = gql`
  query clientAlertQuery {
    clientAlert @client {
      status
      message
      alertType
      intlId
      messageInfo
    }
  }
`;
