import React, {SVGProps} from 'react';

const QuestionMark = (props: SVGProps<SVGSVGElement>) => (

  <svg
    width={20}
    height={20}
    viewBox="3 2 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >

    <defs />

    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >

      <g id="Icon/Chat-or-Help" fill="#271A75">

        <path
          d="M13,22 C7.4771525,22 3,17.5228475 3,12 C3,6.4771525 7.4771525,2 13,2 C18.5228475,2 23,6.4771525 23,12 C23,17.5228475 18.5228475,22 13,22 Z M13,20 C17.418278,20 21,16.418278 21,12 C21,7.581722 17.418278,4 13,4 C8.581722,4 5,7.581722 5,12 C5,16.418278 8.581722,20 13,20 Z M13,8 C11.8954305,8 11,8.8954305 11,10 C11,10.5522847 10.5522847,11 10,11 C9.44771525,11 9,10.5522847 9,10 C9,7.790861 10.790861,6 13,6 C15.2317369,6 17.0061035,7.60488225 17.0061035,9.7980957 C17.0061035,10.9964187 16.5854519,11.7302422 15.687271,12.5032499 C15.5950421,12.5826255 15.2491095,12.8698896 15.1708303,12.9369131 C15.0604877,13.0313897 14.9524078,13.1184107 14.8111005,13.2283085 C14.7754512,13.2560338 14.729728,13.2913849 14.654332,13.3496868 C14.5973787,13.3938265 14.5555175,13.4266319 14.5167353,13.4576429 C14.1001882,13.790721 14.0130005,13.9412622 14.0130005,14.4390869 C14.0130005,14.9913717 13.5652852,15.4390869 13.0130005,15.4390869 C12.4607157,15.4390869 12.0130005,14.9913717 12.0130005,14.4390869 C12.0130005,13.2690193 12.4122584,12.579647 13.26771,11.8956134 C13.2341941,11.9224133 13.7403319,11.5287786 13.8700632,11.4177011 C13.9643173,11.3369997 14.3076283,11.0519127 14.3826375,10.9873569 C14.8771227,10.5617847 15.0061035,10.3367787 15.0061035,9.7980957 C15.0061035,8.75673056 14.1694581,8 13,8 Z M13,18 C12.4477153,18 12,17.5522847 12,17 C12,16.4477153 12.4477153,16 13,16 C13.5522847,16 14,16.4477153 14,17 C14,17.5522847 13.5522847,18 13,18 Z"
          id="Combined-Shape"
        />
      </g>
    </g>
  </svg>
);

export default QuestionMark;
