// @ts-nocheck
import queryString from 'query-string';
import { useLocation, useHistory } from 'react-router-dom';

const useSiteIdUrl = () => {
  const history = useHistory();
  const location = useLocation();
  const siteId = queryString.parse(location.search || '').siteId || '0';

  const setSiteId = (id: number) => {
    const parsedURL = queryString.parse(location.search || '');

    if (id > 0) {
      history.push({
        search: queryString.stringify({
          ...parsedURL,
          siteId: id,
        }),
      });
    } else {
      delete parsedURL.siteId;
      history.push({
        search: queryString.stringify(parsedURL),
      });
    }
  };

  return [siteId, setSiteId];
};

export default useSiteIdUrl;
