// @ts-nocheck
import { useQuery, gql } from '@apollo/client';
import { useMemo } from 'react';

import { useCompanyContext } from 'utils/Hooks';

export const COMPANY_HIRE_TAGS_QUERY = gql`
  query company($id: ID!) {
    company(id: $id) {
      id
      hireTags {
        id
        name
        deleted
      }
    }
  }
`;

const useCompanyTags = () => {
  const { selectedCompanyID } = useCompanyContext();
  const { data, loading, error } = useQuery(COMPANY_HIRE_TAGS_QUERY, {
    variables: {
      id: selectedCompanyID,
    },
    skip: !selectedCompanyID || selectedCompanyID === '0',
  });
  return useMemo(() => {
    if (loading || error || !data) return [];
    return data.company.hireTags.filter((t: any) => !t.deleted).sort((a: any, b: any) => a.name.localeCompare(b.name));
  }, [data, loading, error]);
};

export default useCompanyTags;
