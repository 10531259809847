import React, {SVGProps} from 'react';

const Mask = (props: SVGProps<SVGSVGElement>) => (

  <svg
    width="14"
    height="17"
    viewBox="0 0 14 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >

    <path
      d="M8.6189 8.86868L6.10517 11.438C5.82881 11.7204 5.37796 11.7204 5.10079 11.4372C4.82378 11.154 4.82343 10.6933 5.1 10.4106L8.6189 6.8139C8.89647 6.5302 8.89647 6.07022 8.6189 5.78651C8.34133 5.50281 7.8913 5.50281 7.61373 5.78651L4.09482 9.38319C3.26276 10.2336 3.26382 11.6144 4.09562 12.4645C4.92793 13.3153 6.27884 13.3152 7.11035 12.4654L9.62408 9.89607L12.8905 6.5575C14.1391 5.28121 14.1386 3.2091 12.8909 1.9338C11.6419 0.657208 9.61621 0.657609 8.36718 1.93425L5.10079 5.27282L1.58268 8.86868C-0.0828428 10.571 -0.0829547 13.3306 1.58268 15.033C3.24827 16.7354 5.94795 16.7356 7.61373 15.033L12.137 10.4098C12.4146 10.1261 12.4146 9.66608 12.137 9.38238C11.8594 9.09867 11.4094 9.09867 11.1318 9.38238L6.60855 14.0056C5.49795 15.1408 3.69834 15.1407 2.58786 14.0056C1.47738 12.8706 1.47746 11.031 2.58785 9.89608L6.10597 6.30021L9.37235 2.96163C10.0663 2.25232 11.192 2.2521 11.8857 2.96119C12.5784 3.66919 12.5787 4.82136 11.8853 5.53011L8.6189 8.86868Z"
      fill="#106FEE"
    />
  </svg>
);

export default Mask;
