// @ts-nocheck
import { useMutation, gql } from '@apollo/client';
import { useCallback } from 'react';

import { COLLABORATORS_QUERY } from 'components/Common/Form/CollaboratorInput/hooks';
import { useCompanyContext } from 'utils/Hooks';


const DISABLE_COLLABORATORS_MUTATION = gql`
  mutation disableGuests($input: [DisableHireInput!]!) {
    disableGuests(input: $input)
  }
`;

const useDisableCollaborators = () => {
  const [disableCollaborators] = useMutation(DISABLE_COLLABORATORS_MUTATION);
  const { selectedCompanyID } = useCompanyContext();

  return useCallback((accountIds: string[]) => disableCollaborators({
    variables: {
      input: accountIds.map(id => ({
        accountId: parseInt(id, 10),
        companyId: parseInt(selectedCompanyID, 10),
      })),
    },
    refetchQueries: [{
      query: COLLABORATORS_QUERY,
      variables: {
        companyID: selectedCompanyID,
      },
    }],
  }), [disableCollaborators, selectedCompanyID]);
};

export default useDisableCollaborators;
