// @ts-nocheck
import classNames from 'classnames';
import React, {useEffect} from 'react';
import ReactTooltip from 'react-tooltip';
import Declined from 'common-ui/Icon/icon/Declined';
import styles from './styles.module.scss';

const toolTipID = 'InfoCardInfoTip';

const buildMeta = (timeDeclined, jobName) => {
  const data = [
    {
      time: timeDeclined,
      job: jobName,
      message: `Declined ${jobName} at ${timeDeclined}`,
    },
  ];
  return JSON.stringify(data);
};

export const InfoCardTooltip = () => (
  <div className={styles.tooltipContainer}>
    <ReactTooltip
      className={styles.tooltip}
      id={toolTipID}
      type="dark"
      place="top"
      effect="solid"
      // This, along with the pointer-events 'auto', makes it easier to mouse over
      // the tooltip and keep it open
      delayHide={200}
      getContent={(dataTip) => {
        if (!dataTip) {
          return null;
        }
        const entries = JSON.parse(dataTip);
        return (
          <div className={styles.InfoCardTooltip}>
            {entries.map(({message}, index) => (
              <div key={`infoCard--${index}`} className={styles.label}>{message}</div>
            ))}
          </div>
        );
      }}
    />
  </div>
);

export const InfoCardTooltipIcon = ({timeDeclined, jobName, scheduleView}:any) => {
  let toolTipMetadata = buildMeta(timeDeclined, jobName);

  useEffect(() => {
    toolTipMetadata = buildMeta(timeDeclined, jobName);
    ReactTooltip.rebuild();
  }, [timeDeclined, jobName]);

  if (toolTipMetadata === '[]') {
    return null;
  }

  return (
    <div
      data-tip={toolTipMetadata}
      data-for={toolTipID}
      className={classNames(styles.infoTooltipIcon, {
        [styles.iconSchedule]: scheduleView,
        [styles.iconDispatch]: !scheduleView,
      })}
    >
      <Declined />
    </div>
  );
};
