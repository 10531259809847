// @ts-nocheck
import classNames from 'classnames';
import React, { ReactNode } from 'react';

import ClickableDiv from 'common-ui/ClickableDiv';
import { useFullScreenRender } from 'common-ui/Hooks';


import { ChevronLeft } from 'common-ui/Icon';
import styles from './styles.module.scss';

type PageDisplayProps = {
  title: string | null,
  goBack: () => void,
  visible: boolean,
  children: ReactNode,
};

const PageDisplay = ({
  title,
  goBack,
  visible,
  children,
}: PageDisplayProps) => {
  const fullScreenRender = useFullScreenRender();

  return fullScreenRender(

    <div className={classNames(styles.pageDisplay, visible && styles.visible)}>

      <div className={styles.displayHeader}>

        <ClickableDiv onClick={goBack} className={styles.backButton}>
          <ChevronLeft />
        </ClickableDiv>
        {title}
      </div>
      {children}
    </div>,
  );
};

export default PageDisplay;
