// @ts-nocheck
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { compose, withStateHandlers } from 'recompose';
import { Checkbox, Radio } from '../../Form';
import { SmallButton } from 'common-ui/Button';

import { Settings } from 'common-ui/Icon';

import { driverIdentifiers } from 'components/Common/Map/utils/map.utils';
import styles from './styles.module.scss';


export const getMapStyle = ({ showSatellite = false, showTraffic = false }) => {
  const street = 'mapbox://styles/treadinc/cjsc7m75102991fo3lr4mev0v';
  const satellite = 'mapbox://styles/treadinc/cjsc82f2102pn1fp2u94hfxd1';
  const traffic = 'mapbox://styles/treadinc/cjsc7s8dz02gg1fp9bv5hh1be';
  const satelliteTraffic = 'mapbox://styles/treadinc/cjsc7tv1z02ic1fnjcnvobcis';

  if (!!showSatellite && !!showTraffic) {
    return satelliteTraffic;
  }

  if (!showSatellite && !!showTraffic) {
    return traffic;
  }

  if (!!showSatellite && !showTraffic) {
    return satellite;
  }

  return street;
};

type CustomLayerControlPropsType = {
  includeTraffic?: boolean,
  includeDriverIdentifier?: boolean,
  isOpen: boolean,

  openDialog: () => any,

  setMapStyle: ({ showTraffic: boolean, showSatellite: boolean }) => void,
  driverIdentifier: string,
  setDriverIdentifier:  (param:string) => void,
};

const CustomLayerControl = ({
  includeTraffic,
  includeDriverIdentifier,
  isOpen,
  openDialog,
  setMapStyle,
  driverIdentifier,
  setDriverIdentifier,
}: CustomLayerControlPropsType) => {
  const driverDisplayOptions = [
    { label: 'Truck Numbers', value: driverIdentifiers.truckNumbers },
    { label: 'Driver Initials', value: driverIdentifiers.driverInitials },
  ];

  const [showTraffic, setShowTraffic] = useState(false);
  const [showSatellite, setShowSatellite] = useState(false);

  useEffect(() => {
    const style = getMapStyle({ showSatellite, showTraffic });

    setMapStyle(style);
  }, [showTraffic, showSatellite]);

  return (

    <div className={styles.layerControls}>

      <SmallButton
        styleName="default"

        icon={<Settings />}
        onClick={openDialog}

        className={classNames(styles.layerControlButton, {
          [styles.active]: isOpen,
        })}
      />
      {isOpen ? (

        <div className={styles.layerControlDialogWrapper}>
           <div className={styles.clickControl}>
               <div className={styles.textBold}>Show..</div>
            {includeTraffic && (
                   <Checkbox
                type="checkbox"
                checked={!!showTraffic}
                onChange={setShowTraffic}
                label="Traffic"
              />
            )}
               <Checkbox
              type="checkbox"
              checked={showSatellite}
              onChange={setShowSatellite}
              label="Satellite"
            />
          </div>
          {includeDriverIdentifier ? (
               <div className={styles.clickControl}>
                   <div className={styles.textBold}>Identify drivers by..</div>
                   <Radio
                options={driverDisplayOptions}
                value={driverIdentifier}
                onChange={setDriverIdentifier}
                vertical
              />
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

CustomLayerControl.defaultProps = {
  includeTraffic: true,
};

const ControlLayerUIHOC = compose(
  withStateHandlers(
    {
      isOpen: false,
    },
    {
      openDialog: ({ isOpen }) => () => ({ isOpen: !isOpen }),
    },
  ),
);

export default compose(
  ControlLayerUIHOC,

)(CustomLayerControl);
