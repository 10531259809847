// @ts-nocheck
import React, { useState, useEffect } from 'react';

import type { Site } from 'common-ui/types';

import { useFlag, FLAG_POSITIONS } from 'components/Common/Flag';

import { DisplayLimitToast, DisplayLimitToastID } from 'pages/Sites/components/Toasts';

// useLimitedSites takes in a list of sites, the selectedSiteId (if it exists), and a max number
// of sites that should be displayed. It returns a list of sites that is no larger than the limit,
// including the selected site if it exists. It also shows a flag informing the user that some sites
// are not displayed.
const useLimitedSites = (
  sites: Site[],
  selectedSiteId: string | null,
  displayLimit = 200,
) => {
  const { addFlag, removeFlag } = useFlag();

  // Only recalculate sitemarkers when sites changes
  const [limitedSites, setLimitedSites] = useState([]);
  useEffect(() => {
    let displayed: Site[] = sites;

    if (sites.length >= displayLimit) {
      displayed = sites.slice(0, displayLimit - 1);
      addFlag(
        DisplayLimitToastID,

        () => <DisplayLimitToast total={sites.length} displayedCount={displayLimit} />,
        FLAG_POSITIONS.topCenter,
        0,
      );
    } else {
      removeFlag(DisplayLimitToastID);
    }

    // If the selected site isn't already one of the displayed ones, add it to the list
    const selectedSite = selectedSiteId && sites.find(s => s.id === selectedSiteId);
    if (selectedSite && !displayed.some(s => s.id === selectedSite.id)) {
      displayed.push(selectedSite);
    }


    setLimitedSites(displayed);
  }, [sites, selectedSiteId, displayLimit]);

  return limitedSites;
};

export default useLimitedSites;
