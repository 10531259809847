import React, { SVGProps } from 'react';

// Note: converted SVG from Figma to Typescript component using: https://react-svgr.com/playground/?typescript=true
const Alert = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.86 2.573 1.213 12a1.333 1.333 0 0 0 1.14 2h11.294a1.332 1.332 0 0 0 1.14-2L9.14 2.573a1.333 1.333 0 0 0-2.28 0v0Z"
      fill="#E2164A"
      stroke="#E2164A"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 6v2.667"
      stroke="#fff"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 11.334h.007"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Alert;
