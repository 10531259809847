import { ApolloClient, InMemoryCache } from '@apollo/client';
// import { PUBLIC_API_URL } from 'utils/api';
import { PUBLIC_API_URL } from '../utils/api';

const publicClient = new ApolloClient({
  uri: PUBLIC_API_URL,
  cache: new InMemoryCache()
});

export default publicClient;
