import React, {SVGProps} from 'react';

const Send = (props: SVGProps<SVGSVGElement>) => (

  <svg width={24} height={18} viewBox="0 0 24 18" {...props}>

    <title>Send</title>

    <path
      d="M20.167 15.673l-5.472-5.47-1.266 1.265a.98.98 0 0 1-1.382.004l-1.945-1.925a.98.98 0 1 1 1.379-1.392l1.255 1.261 7.435-7.457H.98A.98.98 0 1 1 .98 0h20.574c1.082 0 1.958.875 1.958 1.96v13.713c0 1.082-.875 1.96-1.958 1.96H6.857a.98.98 0 0 1 0-1.96h13.31zm1.386-1.385V3.343L16.08 8.817l5.473 5.471zM1.96 5.878c0-.541.428-.98.982-.98h4.892c.543 0 .983.435.983.98 0 .54-.43.98-.983.98H2.943a.979.979 0 0 1-.982-.98zm1.959 5.877c0-.54.434-.98.98-.98h2.938c.541 0 .98.435.98.98 0 .541-.435.98-.98.98H4.9a.977.977 0 0 1-.98-.98z"
      fill="#000B9D"
      fillRule="evenodd"
    />
  </svg>
);

export default Send;
