// @ts-nocheck
import { useCallback, useState, useRef } from 'react';

import useDetectMobile from './useDetectMobile';

// Hook found from https://usehooks.com/useHover/
const useHover = (hoverOnMobile: any) => {
  const isMobile = useDetectMobile();
  const [value, setValue] = useState(false);

  const ref = useRef/* ::<HTMLDivElement | null> */(null);
  const handleMouseOver = useCallback(() => setValue(true), []);
  const handleMouseOut = useCallback(() => setValue(false), []);

  const callbackRef = useCallback((node: HTMLDivElement) => {
    if (!isMobile || hoverOnMobile) {

      if (ref.current) ref.current.removeEventListener('mouseover', handleMouseOver);

      if (ref.current) ref.current.removeEventListener('mouseout', handleMouseOut);


      ref.current = node;


      if (ref.current) ref.current.addEventListener('mouseover', handleMouseOver);

      if (ref.current) ref.current.addEventListener('mouseout', handleMouseOut);
    }
  }, [handleMouseOver, handleMouseOut]);

  return [callbackRef, value];
};

export default useHover;
