// @ts-nocheck
import type {ComponentType} from 'react';
import {
  filter as lodashFilter,
  map as lodashMap,
  reduce as lodashReduce,
  orderBy,
  get,
  flatten,
  capitalize,
  concat,
} from 'lodash';
import {
  compose, withStateHandlers, withProps, HOC,
} from 'recompose';
import { assignedStatuses } from '../../../../pages/Projects/utils/projects.utils';


import Analytics from 'common-ui/Analytics/SegmentAnalytics';
import {Events, Actions} from 'common-ui/Analytics/types';
import {textForStatus} from 'components/Common/Labels';
import {injectIntlHOC} from 'utils/HOC';

import { formatRateUnit, formatName } from 'utils/format';
import {
  projectFilterTypes,
  projectAssignmentFilterTypes,
  liveAssignmentFilterTypes,
  itemPassesFilters,
  getFilterOptions,
  driverStatus,
} from './filters';
import type {
  Filter,
  FilterType,
  Assignment,
  Project,
  FilterableAssignment,
  FilterableProject,
  withSearchComponentType,
} from '../types';



interface Filterable {
  id:any,
  filterProperties:any
}

const createFilterableAssignmentItems = (
  assignments: Array<Assignment>,

): Array<FilterableAssignment> => assignments.map(assignment => ({
  ...assignment,
  filterProperties: {
    id: assignment.id,
    company: assignment.companyName,
    customer: assignment.customerName,
    foreman: assignment.foreman,
    jobId: assignment.jobID,
  },
}));

const getHireName = (assignment: FilterableAssignment) => {
  if (assignment.assignee && assignment.assignee.accountId) {
    return `${assignment.assignee.firstName} ${assignment.assignee.lastName}`;
  }

  if (assignment.subcontractor && assignment.subcontractor.name) {
    return assignment.subcontractor.name;
  }

  return '';
};

const getCreatedByName = (project: Project) => {
  const account = project.parent ? project.parent.createdBy : project.createdBy;

  if (account && account.firstName) {
    return `${account.firstName} ${account.lastName}`;
  }

  return '';
};

const getAssignmentFilterStatus = (assignment: FilterableAssignment) => {
  if (assignment.isDraft && assignment.assignedStatus === 'SENT') {
    return 'Sent Draft';
  }

  if (assignment.assignedStatus) {
    return capitalize(assignment.assignedStatus);
  }

  return 'is empty';
};

const getProjectType = (project: Project) => {
  if (project.type) {
    return capitalize(project.type);
  }

  return 'is empty';
};

const getDriverStatus = (assignment: FilterableAssignment) => {
  const activeCycle = assignment.assignedTo && assignment.assignedTo.assignee && assignment.assignedTo.assignee.activeCycle;
  if (activeCycle) {
    if (activeCycle.pickupArrival && !activeCycle.pickupDeparture) {
      return driverStatus.PICKUP;
    }
    if ((activeCycle.pickupDeparture && !activeCycle.dropoffArrival) || activeCycle.dropoffDeparture) {
      return driverStatus.DRIVING;
    }
    if (activeCycle.dropoffArrival && !activeCycle.dropoffDeparture) {
      return driverStatus.DROPOFF;
    }
  }
  return null;
};

const createFilterableProjectAssignmentItems = (
  projects: Array<Project>,
): Array<FilterableAssignment> => {
  const projectAssignmentItems = lodashReduce(
    projects,

    (result, project) => {

      const assignments = project.assignments.filter(({ assignedStatus }) => assignedStatus !== assignedStatuses.CANCELLED);

      const cancelledAssignments = project.assignments.filter(({ assignedStatus }) => assignedStatus === assignedStatuses.CANCELLED);

      const assignmentItems = lodashMap(concat(assignments, cancelledAssignments), assignment => ({
        filterProperties: {
          id: assignment.id,
          targetId: project.target ? project.target.targetName : 'is empty',
          company: project.companyName,
          name: project.name,
          client: project.client,
          foreman: project.foreman,
          jobNum: project.jobNumber,
          hireName: getHireName(assignment),
          createdByName: getCreatedByName(project),
          equipment: assignment.equipment || 'is empty',
          rateUnit: formatRateUnit(assignment.haulerRateUnit),
          startTime: assignment.startTimeTz ? assignment.startTimeTz.format('ha') : '',
          assignedStatus: getAssignmentFilterStatus(assignment),
          hirePhase: textForStatus(assignment.sendStatus) || 'is empty',
          phase: assignment.phase || 'is empty',
          product: assignment.product || 'is empty',
          pickUpSiteName: (assignment.pickUpSite && assignment.pickUpSite.name) || 'is empty',
          dropOffSiteName: (project.dropOffSite && project.dropOffSite.name) || 'is empty',
          truckNumber: assignment.assignee ? assignment.assignee.truckNumber : '',
          licensePlate: assignment.assignee ? assignment.assignee.licensePlate : '',
          subcontractingCompanyName: assignment.subcontractor ? assignment.subcontractor.name : project.subcontractingCompanyName,
          type: getProjectType(project),
        },
        ...assignment,
      }));

      return concat(result, assignmentItems);
    },
    [],
  );
  return projectAssignmentItems;
};

const createFilterableProjectItems = (
  projects : Array<Project>

): Array<FilterableProject> => projects.map(p => ({
  ...p,
  filterProperties: {
    id: p.id,
    customer: p.client,
    foreman: p.foreman,
    jobNum: p.jobNumber,
    pickUpSiteName: (p.pickUpSite && p.pickUpSite.name) || 'is empty',
    dropOffSiteName: (p.dropOffSite && p.dropOffSite.name) || 'is empty',
  },
}));

const createFilterableRequestItems = (
  requests: Array<{ project: Project }>,

): Array<FilterableProject> => requests.map(r => ({
  ...r,
  filterProperties: {
    id: r.project.id,
    customer: r.project.client,
    foreman: r.project.foreman,
    jobNum: r.project.jobNumber,
  },
}));

const createFilterableLiveAssignmentItems = (
  projects: Array<Project>,
): Array<FilterableAssignment> => {
  const assignmentsWithFilterProps = projects.map(project => (
    project.assignments.map(assignment => ({
      ...assignment,
      filterProperties: {
        id: assignment.id,
        customer: project.client,

        name: project.name,
        phase: assignment.phase,
        subcontractorCompanyName: assignment.subcontractorCompanyName,
        foreman: project.foreman,
        jobNum: project.jobNumber,
        company: project.company && project.company.name,
        pickUpSiteName: (assignment.pickUpSite && assignment.pickUpSite.name) || 'is empty',
        dropOffSiteName: (assignment.dropOffSite && assignment.dropOffSite.name) || 'is empty',
        hireName: assignment.assignedTo
          && assignment.assignedTo.assignee
          && formatName(assignment.assignedTo.assignee),
        truckNumber: assignment.assignedTo
          && assignment.assignedTo.assignee
          && assignment.assignedTo.assignee.instanceTruckNumber,
        driverStatus: getDriverStatus(assignment),
      },
    }))
  ));

  return flatten(assignmentsWithFilterProps);
};

const filterAssignmentItems = (
  assignments: Array<FilterableAssignment>,
  filters: Array<Filter>,
) => lodashFilter<FilterableAssignment>(
  assignments,

  (assignment: FilterableAssignment) => itemPassesFilters(assignment.filterProperties, filters),
);

export const getFiltered = (

  items,

  filters,

  filterTypes,

  filterByOr,
) => lodashFilter(items, i => itemPassesFilters(i.filterProperties, filters, filterTypes, filterByOr));

type withFilterProps = {
  filterItemsName: string,
  sortOptionName: string,
  onSortName: string,
  setFilterItemsName: string
};

const withFilter:(name:withFilterProps) => HOC<any, any> = (names: withFilterProps) => {
  const {
    filterItemsName = 'filterItems',
    sortOptionName = 'sortOption',
    onSortName = 'onSort',
    setFilterItemsName = 'setFilterItems',
  } = names;

  return withStateHandlers(
    props => ({
      [filterItemsName]: [],

      [sortOptionName]: props[sortOptionName] || null,
    }), {
      [onSortName]: () => option => ({ [sortOptionName]: option }),
      [setFilterItemsName]: () => (filterItems) => {
        Analytics.pushEvent(Events.filterUpdate, Actions.filterChange, filterItems);
        return { [filterItemsName]: filterItems };
      },
    },
  );
};

const getFilterItems = (
  items: Array<Record<string, unknown>>,
  filterTypes: Array<FilterType>,
) => getFilterOptions(items, item => item.filterProperties, filterTypes);

type withSearchProps = {
  searchDataName: string,
  filterItemsName: string,
  filterableObjectsName: string,
  filterTypes: Array<FilterType>,
  filteredObjectsName: string,
  sortOptionName: string,
  onSortName: string,
  setFilterItemsName: string,
  createFilterables: (param:Array<any>) => Array<Filterable>
};

export const filterByValues = {
  OR: 'or',
  AND: 'and',
};


const withAndOr = (names) => {
  const {
    filterByAndOrOptions,
    filterByOrName,
    filterByAndOrValueName,
    onChangeFilterByAndOrName,
  } = names;

  // if not defined, ignore
  if (!filterByOrName || !filterByAndOrValueName || !onChangeFilterByAndOrName) {

    return Comp => (Comp);
  }


  return Comp => compose(
    injectIntlHOC(), // Provides the 'intl' prop

    withProps(({ intl }) => ({
      filterByAndOrOptions: filterByAndOrOptions || [
        {
          label: intl.formatMessage({
            id: 'projects.header.filter_by_or',
            defaultMessage: 'Or',
          }),
          value: filterByValues.OR,
        }, {
          label: intl.formatMessage({
            id: 'projects.header.filter_by_and',
            defaultMessage: 'And',
          }),
          value: filterByValues.AND,
        },
      ],
    })),
    withStateHandlers(
      {
        [filterByAndOrValueName]: filterByValues.AND,
        [filterByOrName]: false,
      },
      {
        [onChangeFilterByAndOrName]: () => value => ({
          [filterByAndOrValueName]: value,
          [filterByOrName]: value === filterByValues.OR,
        }),
      },
    ),
  )(Comp);
};

// Generic search HOC that accepts searchData and createFilterable props from the
// previous HOC chain
// This lets you decouple the search logic from the actual component props
const withSearch = (searchProps: withSearchProps) => {
  const {
    searchDataName,
    filterItemsName,
    filterableObjectsName,
    filteredObjectsName,
    sortOptionName,
    onSortName,
    setFilterItemsName,
    createFilterables,
    filterTypes,

    filterByAndOrOptions,
    filterByOrName,

    filterByAndOrValueName,

    onChangeFilterByAndOrName,
  } = searchProps;

  return (Comp: ComponentType<withSearchComponentType>) => compose(
    withAndOr({
      filterByAndOrOptions,
      filterByOrName,
      filterByAndOrValueName,
      onChangeFilterByAndOrName,
    }),
    withFilter({
      filterItemsName,
      sortOptionName,
      onSortName,
      setFilterItemsName,
    }),
    withProps(({
      ...restProps
    }) => {
      const searchData = restProps[searchDataName];
      const filterItems = restProps[filterItemsName];
      const sortOption = restProps[sortOptionName];
      const filterByOr = restProps[filterByOrName] || false;
      const ordered = sortOption
        ? orderBy(searchData, [i => get(i, sortOption.sortProp), 'id'])
        : searchData;


      const filterableObjects = createFilterables(ordered);
      const filteredObjects = getFiltered(filterableObjects, filterItems, filterTypes, filterByOr);

      return {
        [filterableObjectsName]: filterableObjects,
        [filteredObjectsName]: filteredObjects,
      };
    }),

  )(Comp);
};

const withProjectSearch = withSearch({
  searchDataName: 'projects',

  createFilterables: createFilterableProjectItems,
  filterTypes: projectFilterTypes,
  filterItemsName: 'projectFilterItems',
  sortOptionName: 'sortOption',
  onSortName: 'onProjectSort',
  setFilterItemsName: 'setProjectFilterItems',
  filterableObjectsName: 'filterableProjects',
  filteredObjectsName: 'filteredProjects',
  filterByOrName: 'filterByOr',
  filterByAndOrValueName: 'filterByAndOrValue',
  onChangeFilterByAndOrName: 'onChangeFilterByAndOr',
});

const withProjectAssignmentSearch = withSearch({
  searchDataName: 'projects',
  createFilterables: createFilterableProjectAssignmentItems,
  filterTypes: projectAssignmentFilterTypes,
  filterItemsName: 'projectAssignmentFilterItems',
  sortOptionName: 'projectSortOption',
  onSortName: 'onProjectSort',
  setFilterItemsName: 'setProjectAssignmentFilterItems',
  filterableObjectsName: 'filterableProjectAssignments',
  filteredObjectsName: 'filteredProjectAssignments',
  filterByOrName: 'filterByOr',
  filterByAndOrValueName: 'filterByAndOrValue',
  onChangeFilterByAndOrName: 'onChangeFilterByAndOr',
});

const withRequestSearch = withSearch({
  searchDataName: 'requests',

  createFilterables: createFilterableRequestItems,
  filterTypes: projectFilterTypes,
  filterItemsName: 'requestFilterItems',
  sortOptionName: 'sortOption',
  onSortName: 'onSort',
  setFilterItemsName: 'setRequestFilterItems',
  filterableObjectsName: 'filterableRequests',
  filteredObjectsName: 'filteredRequests',
  filterByOrName: 'filterByOr',
  filterByAndOrValueName: 'filterByAndOrValue',
  onChangeFilterByAndOrName: 'onChangeFilterByAndOr',
});

const withLiveAssignmentSearch = withSearch({
  searchDataName: 'filteredProjects',
  createFilterables: createFilterableLiveAssignmentItems,
  filterTypes: liveAssignmentFilterTypes,
  filterItemsName: 'projectFilterItems',
  sortOptionName: 'sortOption',
  onSortName: 'onProjectSort',
  setFilterItemsName: 'setProjectFilterItems',
  filterableObjectsName: 'filterableProjects',
  filteredObjectsName: 'filteredLiveAssignments',
  filterByOrName: 'filterByOr',
  filterByAndOrValueName: 'filterByAndOrValue',
  onChangeFilterByAndOrName: 'onChangeFilterByAndOr',
});

export {
  createFilterableAssignmentItems,
  filterAssignmentItems,
  withProjectSearch,
  createFilterableProjectItems,
  getFilterItems,
  withSearch,
  withRequestSearch,
  withProjectAssignmentSearch,
  withLiveAssignmentSearch,
  createFilterableProjectAssignmentItems,
};
