import React from 'react';
import {useSegmentAnalytics} from 'common-ui/Analytics/SegmentAnalytics';

const SegmentAnalytics = () => {
  useSegmentAnalytics();

  return <></>;
};

export default SegmentAnalytics;
