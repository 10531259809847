// @ts-nocheck
import React, { ReactNode } from 'react';
import { intlShape } from 'react-intl';
import { Button } from 'common-ui/Button';

import { injectIntlHOC } from 'utils/HOC';
import styles from './styles.module.scss';

type ConfirmSidebarCloseModalProps = {
  onConfirm: () => void,
  onDiscard: () => void,

  intl: intlShape,
  bodyText: ReactNode,
  confirmText: ReactNode,
  headerText: ReactNode,
};

const ConfirmSidebarCloseModal = ({
  onConfirm,
  onDiscard,
  bodyText,
  confirmText,
  headerText,
  intl,
}: ConfirmSidebarCloseModalProps) => (

  <div className={styles.baseModal}>

    <div className={styles.baseModalHeader}>

      <div className={styles.textHeader}>
        {headerText || intl.formatMessage({ id: 'modals.unsaved_changes_header', defaultMessage: 'Unsaved Changes' })}
      </div>
    </div>

    <div className={styles.textBody}>
      {bodyText}
    </div>

    <div className={styles.baseModalButtons}>

      <Button
        text={intl.formatMessage({ id: 'modals.discard_button', defaultMessage: 'Discard' })}
        onClick={onDiscard}
      />

      <Button
        text={confirmText || intl.formatMessage({ id: 'modals.save_changes', defaultMessage: 'Save Changes' })}
        className={styles.actionButton}
        onClick={onConfirm}
      />
    </div>
  </div>
);

export default injectIntlHOC()(ConfirmSidebarCloseModal);
