import React, {SVGProps} from 'react';

const Trash = (props: SVGProps<SVGSVGElement>) => (

  <svg width={20} height={24} viewBox="0 0 20 24" {...props}>

    <path
      d="M3.75 5v16.25c0 .002-.002 0-.002 0h12.504-.002V5H3.75zm14.999 0h.001v16.25a2.5 2.5 0 0 1-2.498 2.5H3.748a2.497 2.497 0 0 1-2.498-2.5V5h.001A1.247 1.247 0 0 1 0 3.75C0 3.06.556 2.5 1.251 2.5H7.5a2.5 2.5 0 1 1 5 0h6.249c.69 0 1.251.555 1.251 1.25C20 4.44 19.444 5 18.749 5zM11.25 8.747c0-.689.555-1.247 1.25-1.247.69 0 1.25.558 1.25 1.247v8.756c0 .689-.555 1.247-1.25 1.247-.69 0-1.25-.558-1.25-1.247V8.747zm-5 0c0-.689.555-1.247 1.25-1.247.69 0 1.25.558 1.25 1.247v8.756c0 .689-.555 1.247-1.25 1.247-.69 0-1.25-.558-1.25-1.247V8.747z"
      fill="#000B9D"
      fillRule="nonzero"
    />
  </svg>
);

export default Trash;
