// @ts-nocheck
import React from 'react';


import { SiteIcon, SiteTypes } from 'common-ui/Sites';


import { injectIntlHOC } from 'utils/HOC';
import styles from './styles.module.scss';


type LegendProps = {
    intl: any
};

const Legend = ({ intl }: LegendProps) => (

  <div className={styles.legend}>

    <div className={styles.legendEntry}>

      <SiteIcon type={SiteTypes.SOURCE} />
      {intl.formatMessage({
        id: 'site.legend.pickUp_text',
        defaultMessage: 'Pick-Up',
      })}
    </div>

    <div className={styles.legendEntry}>

      <SiteIcon type={SiteTypes.RECEIVING} />
      {intl.formatMessage({
        id: 'site.legend.dropOff_text',
        defaultMessage: 'Drop-Off',
      })}
    </div>

    <div className={styles.legendEntry}>

      <SiteIcon type={SiteTypes.BOTH} />
      {intl.formatMessage({
        id: 'site.legend.both_text',
        defaultMessage: 'Both',
      })}
    </div>
  </div>
);

export default injectIntlHOC(Legend);
