// @ts-nocheck
import { useMutation, gql } from '@apollo/client';
import { useCallback } from 'react';

import { useCompanyContext } from 'utils/Hooks';

const UPDATE_PROJECT_SHARING_LINK_SETTINGS_MUTATION = gql`
mutation updateProjectSharingLinkSettingsMutation($linkID: ID!, $enabled: Boolean!, $settings: ProjectSharingLinkSettingsInput!, $companyID: ID!) {
  updateProjectSharingLinkSettings(linkID:$linkID, enabled:$enabled, settings:$settings, companyID:$companyID) {
    id
    link
    settings {
      driverName
      phoneNumber
      loggingTimestamps
      loggingStatus
      licensePlate
    }
    enabled
  }
}
`;

const useUpdateProjectSharingLinkSettings = () => {
  const { selectedCompanyID } = useCompanyContext();
  const [updateProjectSharingLinkSettings] = useMutation(UPDATE_PROJECT_SHARING_LINK_SETTINGS_MUTATION);
  return useCallback((
    linkID,
    enabled,
    settings,
  ) => (
    updateProjectSharingLinkSettings({
      variables: {
        linkID,
        settings,
        enabled,
        companyID: selectedCompanyID,
      },
    })
  ), [updateProjectSharingLinkSettings]);
};

export default useUpdateProjectSharingLinkSettings;
