// @ts-nocheck
import React, { useState } from 'react';

import AddCollaboratorModal from '../AddCollaboratorModal';
import { Button } from 'common-ui/Button';
import ClickableDiv from 'common-ui/ClickableDiv';
import { Close } from 'common-ui/Icon';


import { CollaboratorInput } from 'components/Common/Form/CollaboratorInput';

import { useCollaboratorMap, useCollaborators } from 'components/Common/Form/CollaboratorInput/hooks';

import { useModal } from 'utils/Hooks';
import CollaboratorShareCard from './CollaboratorShareCard';
import styles from './styles.module.scss';
import useUpdateProjectGuests from './useUpdateProjectGuests';


type ShareWithCollaboratorsModalProps = {
  projectID: string,
  initialCollaborators: string[], // collaborator ids
  onClose: () => void,
};

const ShareWithCollaboratorsModal = ({
  projectID,
  initialCollaborators,
  onClose,
}: ShareWithCollaboratorsModalProps) => {
  const [collaboratorIds, setCollaboratorIds] = useState(initialCollaborators);

  const collaborators = useCollaborators();
  const collaboratorMap = useCollaboratorMap(collaborators);

  const { openModal } = useModal();

  const updateProjectGuests = useUpdateProjectGuests();

  return (

    <div className={styles.shareCollaboratorsModal}>

      <div className={styles.shareCollaboratorsHeader}>
        Share With Collaborators

        <ClickableDiv onClick={onClose} className={styles.closeButton}>
          <Close />
        </ClickableDiv>
      </div>

      <div className={styles.shareCollaboratorsContent}>

        <div className={styles.collaboratorInput}>
          <CollaboratorInput
            values={collaboratorIds}
            onChange={setCollaboratorIds}
            controlShouldRenderValue={false}
            isClearable={false}
            onCreateOption={() => {
              openModal(
                <AddCollaboratorModal
                  onClose={() => openModal(
                    <ShareWithCollaboratorsModal
                      projectID={projectID}
                      initialCollaborators={collaboratorIds}
                      onClose={onClose}
                    />,
                  )}
                  onComplete={(newCollaborator) => {
                    if (!newCollaborator) {
                      onClose();
                    } else {
                      openModal(
                        <ShareWithCollaboratorsModal
                          projectID={projectID}
                          initialCollaborators={[...collaboratorIds, newCollaborator.accountId]}
                          onClose={onClose}
                        />,
                      );
                    }
                  }}
                />,
              );
            }}
          />
        </div>

        <div className={styles.collaboratorList}>
          {collaboratorIds.map(cId => (
            <CollaboratorShareCard
              key={cId}
              collaborator={collaboratorMap[cId]}
              onEdit={() => openModal(
                <AddCollaboratorModal
                  collaborator={collaboratorMap[cId]}
                  onClose={() => openModal(
                    <ShareWithCollaboratorsModal
                      projectID={projectID}
                      initialCollaborators={collaboratorIds}
                      onClose={onClose}
                    />,
                  )}
                  onComplete={() => openModal(
                    <ShareWithCollaboratorsModal
                      projectID={projectID}
                      initialCollaborators={collaboratorIds}
                      onClose={onClose}
                    />,
                  )}
                />,
              )}
              onRemove={() => setCollaboratorIds(collaboratorIds.filter(c => c !== cId))}
            />
          ))}
        </div>
      </div>

      <div className={styles.shareCollaboratorsButtons}>

        <Button
          text="Save"
          className={styles.shareCollaboratorsSubmit}
          onClick={() => {
            updateProjectGuests(projectID, collaboratorIds);
            onClose();
          }}
        />
      </div>
    </div>
  );
};

export default ShareWithCollaboratorsModal;
