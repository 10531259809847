// @ts-nocheck

import _ from 'lodash';
import { ReactNode } from 'react';
import { timezones } from 'react-timezone';

const stringifyZone = (zone: any, offset: any) => {
  const ensure2Digits = (num: any) => num > 9 ? `${num}` : `0${num}`;

  return `(${offset}${zone.offset < 0 ? '-' : '+'}${ensure2Digits(
    Math.floor(Math.abs(zone.offset)),
  )}:${ensure2Digits(Math.abs((zone.offset % 1) * 60))}) ${zone.label}`;
};

type ZoneType = {
  offset: number,
  label: string,
  name: string
};

type ZoneListType = {
  label: ReactNode,
  value: string
};


const formattedTimezones = _.map<ZoneType, ZoneListType>(timezones, (tz: any) => ({
  label: stringifyZone(tz, 'GMT'),
  value: tz.name
}));

export default formattedTimezones;
