// @ts-nocheck
import classNames from 'classnames';
import React from 'react';
import styles from './styles.module.scss';

const HireTooltip = () => (

  <div className={styles.hireTooltipContainer}>

    <div className={classNames(styles.hireTooltip)}>

      <>
      This hire is booked for the day.
      </>
    </div>
  </div>
);

export default HireTooltip;
