// @ts-nocheck
import React, { ReactNode } from 'react';
import {
  compose,
  withStateHandlers,
  withProps,
} from 'recompose';

import TreadMap from './TreadMap';

export const ConnectMapControls = compose(

  withProps(({ calculatedBounds }) => ({ calcBounds: calculatedBounds })),
  withStateHandlers(
    {
      animationCount: 0,
      bounds: null,
      autoZoom: false,
    },
    {
      setBounds: () => bounds => (
        {
          bounds,
        }
      ),

      setAutoZoom: ({ bounds }, { calcBounds }) => autoZoom => (
        {
          autoZoom,
          bounds: autoZoom ? null : (bounds || calcBounds),
        }
      ),
    },
  ),

  withProps(({
    bounds,
    autoZoom,
    calcBounds,
  }) => (
    {
      bounds: (!autoZoom && bounds) ? bounds : calcBounds,
    }
  )),
);

type MapContainerPropTypes = {
  scrollWheelZoom?: boolean,
  includeTraffic?: boolean,
  includeDriverIdentifier?: boolean,
  noRefreshButton?: boolean,
  noZoomButtons?: boolean,
  noLayerControl?: boolean,
  onTouchDevice?: boolean,
  onClickRefresh?: () => void,

  setAutoZoom:(param:boolean) => void,
  children?: ReactNode,
  openPopup: {lat: number, lng: number},
  bounds: {lat: number, lng: number}[],
  height?: string,
  width?: string,
  onClickMap: () => void,
  forceFocusToPoint: {lat: number, lng: number},
  getCursor: () => string,
};

export const BaseMap = (props: MapContainerPropTypes) => {
  const {
    scrollWheelZoom,
    onTouchDevice,
    bounds,
    openPopup,
    children,
    height,
    width,
    onClickMap,
    forceFocusToPoint,
    getCursor,
  } = props;

  return (

    <TreadMap
      {...props}
      height={height}
      width={width}

      dragging={!onTouchDevice}
      tap={!onTouchDevice}
      zoomControl={false}
      preferCanvas
      maxZoom={20}
      minZoom={2}
      {...{
        bounds,
        scrollWheelZoom,
        openPopup,
        onClickMap,
        forceFocusToPoint,
        getCursor,
      }}
    >
      {children}
    </TreadMap>
  );
};

BaseMap.defaultProps = {
  includeTraffic: true,
  includeDriverIdentifier: true,
  scrollWheelZoom: true,
  onTouchDevice: false,
  onClickRefresh: null,
  noRefreshButton: false,
  noZoomButtons: false,
  noLayerControl: false,
  children: null,
  height: '100%',
  width: '100%',
};

export const MapWithControls = compose(
  ConnectMapControls,

)(BaseMap);

export default BaseMap;
