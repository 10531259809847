// @ts-nocheck

import { connect } from 'formik';
import React from 'react';

import { FormField, CalendarInput } from 'components/Common/Form';


const FormikCalendarInput = ({
  formik,
  label,
  required,
  name,
  ...inputProps
}: any) => {
  return (

    <FormField
      label={label}
      required={required}
      error={formik.touched[name] && formik.errors[name]}
    >
      {!inputProps.readOnly && (

        <CalendarInput
          value={formik.values[name]}
          onChange={(value: any) => {
            formik.setFieldTouched(name);
            formik.setFieldValue(name, value);
          }}
          {...inputProps}
        />
      )}
      {inputProps.readOnly && (

        <>
          {(!!inputProps.readOnlyValue && inputProps.readOnlyValue) || formik.values[name] || '-'}
        </>
      )}
    </FormField>
  );
};

export default connect(FormikCalendarInput);
