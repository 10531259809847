import React, {SVGProps} from 'react';

const Declined = (props: SVGProps<SVGSVGElement>) => (

  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >

    <circle cx="6" cy="6.23438" r="6" fill="#383838" />

    <path
      d="M4.75877 8.60233H5.87914C6.65075 8.60233 7.23408 8.37384 7.62914 7.91687C8.0242 7.45396 8.22173 6.87829 8.22173 6.18986C8.22173 5.53705 7.99951 4.98808 7.55507 4.54298C7.1168 4.09788 6.54581 3.87532 5.8421 3.87532H4.75877V8.60233ZM3.99951 3.23438H5.85136C6.77112 3.23438 7.5242 3.51034 8.11062 4.06227C8.70322 4.60826 8.99951 5.31746 8.99951 6.18986C8.99951 7.05633 8.72482 7.78037 8.17544 8.36197C7.63223 8.94357 6.86988 9.23438 5.8884 9.23438H3.99951V3.23438Z"
      fill="white"
      stroke="white"
      strokeWidth="0.5"
    />
  </svg>
);

export default Declined;
