// @ts-nocheck
import React from 'react';
import { Marker } from 'react-map-gl';


import { useEditableMap, editModes } from '../utils/useEditableMap';

import { SiteIcon, getColourForSiteType } from 'common-ui/Sites';
import Circle from './Circle';

const EditSiteMarker = () => {
  const {
    isEditingMap,

    selectedLocation,
    editMode,
  } = useEditableMap();

  return (

    <>
      {isEditingMap
        && editMode === editModes.CIRCLE
        && selectedLocation
        && selectedLocation.radius
        && selectedLocation.lat
        && (
           <Circle
            lng={selectedLocation.lng}
            lat={selectedLocation.lat}
            radius={selectedLocation.radius}
            color={getColourForSiteType(selectedLocation.siteType)}
          />
        )
      }
      {isEditingMap && selectedLocation && selectedLocation.lng && (

        <Marker
          longitude={selectedLocation.lng}
          latitude={selectedLocation.lat}
          offsetLeft={-13}
          offsetTop={-13}
        >
           <SiteIcon type={selectedLocation.siteType} />
        </Marker>
      )}
    </>
  );
};

export default EditSiteMarker;
