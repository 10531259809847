// @ts-nocheck
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { CheckmarkGreen, Close, Warning } from 'common-ui/Icon';
import styles from './styles.module.scss';

const Styles = {
  SUCCESS: 'SUCCESS',
  INFORMATION: 'INFORMATION',
  WARNING: 'WARNING',
  CRITICAL: 'CRITICAL',
};

const classForStyle = (style: any) => {
  switch (style) {
    case Styles.INFORMATION:
      return styles.flagInformation;
    case Styles.WARNING:
      return styles.flagWarning;
    case Styles.CRITICAL:
      return styles.flagCritical;
    case Styles.SUCCESS:
    default:
      return styles.flagSuccess;
  }
};

const iconForStyle = (style: any) => {
  switch (style) {
    case Styles.SUCCESS:

      return <CheckmarkGreen />;
    case Styles.CRITICAL:

      return <Warning exclamationColor="#E2164A"/>;
    default:
      return null;
  }
};

type FlagProps = {

  style?: $Keys<typeof Styles>,
  title: string,
  body?: string,
  icon?: ReactNode,
  linkText?: string,
  linkRef?: string,
};

const Flag = ({
  style,
  title,
  icon,
  body,
  linkText,
  linkRef,
}: FlagProps) => (

  <div className={classForStyle(style)}>

    <div className={styles.stripe} />

    <div className={styles.contentContainer}>

      <div className={styles.icon}>
        {icon || iconForStyle(style)}
      </div>

      <div className={styles.content}>

        <div className={styles.title}>
          {title}
        </div>
        {body && (
          <div className={styles.textBody}>
            {body}
          </div>
        )}
        {linkRef && linkText && (
          <Link to={linkRef}>
            <div className={styles.action}>{linkText}</div>
          </Link>
        )}
      </div>

      <div className={styles.closeIcon}>

        <Close />
      </div>
    </div>
  </div>
);

Flag.Styles = Styles;

Flag.defaultProps = {
  style: Styles.SUCCESS,
  icon: null,
  body: '',
  linkText: '',
  linkRef: '',
};

export default Flag;
