// @ts-nocheck
import classNames from 'classnames';
import React from 'react';

import CompanyInitials from './CompanyInitials';
import styles from './styles.module.scss';

type CompanyLogoProps = {
  value: string,
  companyName: string,
  large?: boolean,
  priority?: string,
  noPlaceholder?: boolean,
};

const CompanyLogo = ({
  value,
  large,
  companyName,
  priority,
  noPlaceholder,
}: CompanyLogoProps) => {
  const classNameForLogoPriority = () => {
    switch (priority) {
      case 'HIGH':
        return styles.imagePreviewHigh;
      case 'MEDIUM':
        return styles.imagePreviewMedium;
      case 'LOW':
        return styles.imagePreviewLow;
      default:
        return styles.imagePreview;
    }
  };
  return (

    <>
      {value ? (

        <div className={classNames(classNameForLogoPriority(priority), { [styles.large]: !!large })}>
          <img className={styles.circleLogo} src={value} alt="Company logo" />
        </div>
      ) : (

        <>
          { !noPlaceholder && (
            <CompanyInitials
              companyName={companyName}
              large={large}
              priority={priority}
            />
          )}
        </>
      )}
    </>
  );
};

CompanyLogo.defaultProps = {
  large: false,
  priority: '',
  noPlaceholder: false,
};

export default CompanyLogo;
