// @ts-nocheck
import { graphql } from '@apollo/client/react/hoc';
import { compose, withHandlers, HOC } from 'recompose';
import { CompressIfImage } from '../ImageManipulation';
import UPLOAD_ATTACHMENT_URL from './graphql';

type UploadImagePropsType = {
  mutate: any,
  files: Array<any>,
  uploadId: string | number,
  domain: string,
};

const UploadImage = ({
  mutate,
  files,
  uploadId,
  domain,
}: UploadImagePropsType) => {
  const filesArray = Array.from(files);

  let uploadPromises = Promise.resolve();

  const filesUploaded = [];
  const filesFailed = [];
  const input = {
    domain,

    id: uploadId ? parseInt(uploadId, 10) : 0,
  };

  // upload files 1 by 1.
  filesArray.forEach((file) => {
    uploadPromises = uploadPromises.then(() => (
      CompressIfImage(file)
    )).then((compressedFile) => {
      const variables = {
        file: compressedFile,
        title: file.name,
        input,
      };

      return mutate({
        variables,
      });
    }).then(({ data }) => {
      const { uploadAttachment: { getURL, mimeType } } = data;
      filesUploaded.push({
        file,
        filename: file.name,
        result: data,
        getURL,
        mimeType,
        input,
      });

      return data;
    }).catch((e) => {
      // if failed, continue to trying to upload files.
      filesFailed.push({
        file, filename: file.name, error: e, input,
      });
    });
  });

  return uploadPromises.then(() => {
    if (filesFailed.length > 0) {
      const errorMsg = filesFailed.length > 0 ? `${filesFailed.length} uploads failed` : 'Upload failed';
      const newError = new Error(errorMsg);

      return Promise.reject(newError);
    }


    const newValues = filesUploaded.map(uploaded => ({
      id: uploaded.getURL,
      mimeType: uploaded.mimeType,
      filename: uploaded.filename,
      getURL: uploaded.getURL,
      input: uploaded.input,
    }));

    return {
      uploadedFileValues: newValues,
    };
  }).catch((err) => {
    throw err;
  });
};

type UploadToServerHocProps = {
  uploadId: string | number,
  domain: string,

  onUploaded: (param:any) => any,
  multiple: boolean
};

const UploadToServerHoc: HOC<any, UploadToServerHocProps> = compose(
  graphql(UPLOAD_ATTACHMENT_URL),
  withHandlers({

    UploadFile: ({
      mutate,
      domain,
      uploadId,
      onUploaded,
    }) => ({

      files,
    }) => UploadImage({
      mutate,
      files,
      domain,
      uploadId,

      onUploaded,
    }),
  }),
);

export default UploadToServerHoc;
