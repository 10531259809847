// @ts-nocheck
import _ from 'lodash';
import {compose, HOC, withHandlers, withProps, withStateHandlers,} from 'recompose';


import Analytics from 'common-ui/Analytics/SegmentAnalytics';
import {Events, Actions} from 'common-ui/Analytics/types';

import SearchUI from './Search.ui';
import {createFilterOption} from './util';
import type {SearchProps} from './types';

export const projectSortOptions = [
  { id: 'Company', name: 'Company', sortProp: 'assignedBy' },
  { id: 'Customer', name: 'Customer', sortProp: 'client' },
  { id: 'Foreman', name: 'Foreman', sortProp: 'foreman' },
  { id: 'JobNum', name: 'Job #', sortProp: 'jobNumber' },
];

export const requestSortOptions = [
  { id: 'Company', name: 'Company', sortProp: 'project.companyName' },
  { id: 'Customer', name: 'Customer', sortProp: 'project.client' },
  { id: 'Foreman', name: 'Foreman', sortProp: 'project.foreman' },
  { id: 'JobNum', name: 'Job #', sortProp: 'project.jobNumber' },
];

const SearchHOC: HOC<any, SearchProps> = compose(
  withHandlers({

    onFiltersChange: ({ onFiltersChange }) => (selectedOptions) => {
      if (onFiltersChange) {
        onFiltersChange(selectedOptions);
      }
    },

    setStatusSelected: ({ setStatusSelected }) => (status) => {
      if (setStatusSelected) {
        setStatusSelected(status);
      }
    },
  }),
  withStateHandlers(
    ({ sortOptions }) => ({
      sortOption: null,
      sortOptions,
      sortIsOpen: false,
      internalSelectedTerms: [],
    }),
    {
      setSelectedTerms: (__, { onFiltersChange }) => (newFilterItems) => {
        onFiltersChange(newFilterItems);

        return { internalSelectedTerms: newFilterItems };
      },
      setSortBox: () => newState => ({ sortIsOpen: newState }),
      onSortClick: ({ sortIsOpen }) => () => ({ sortIsOpen: !sortIsOpen }),

      onSelectSort: (state, { onSort }) => (selectedOption) => {
        // On Select Sort
        if (onSort) {
          onSort(selectedOption);
        }
        Analytics.pushEvent(Events.filterUpdate, Actions.sortChange, selectedOption.sortProp);

        return {
          sortIsOpen: false,
          sortOption: selectedOption,
        };
      },
    },
  ),

  withProps(({
    filterItems,
    internalSelectedTerms,
  }) => ({
    selectedTerms: filterItems || internalSelectedTerms,
  })),
  withHandlers(
    {

      onClickClear: (
        { selectedTerms, setSelectedTerms },

      ) => (id) => {
        const filteredTerms = _.filter(selectedTerms, st => st.value !== id);

        setSelectedTerms(filteredTerms);
      },

      clearAll: ({ setSelectedTerms, setStatusSelected }) => () => {
        setSelectedTerms([]);
        setStatusSelected(null);
      },

      onSelectTerm: ({ selectedTerms, setSelectedTerms }) => (newSelectedTerms) => {
        const newTerms = _.differenceBy(newSelectedTerms, selectedTerms, 'value');
        newTerms.forEach(term => (
          Analytics.pushEvent(Events.filterUpdate, Actions.filterChange, term.filterType.value)
        ));
        setSelectedTerms(newSelectedTerms || []);
      },

      onCreateOption: (
        { selectedTerms, setSelectedTerms },

      ) => (selectedOption) => {
        if (selectedOption.length > 0) {
          const newOption = createFilterOption(selectedOption);
          const newTerms = _.concat(selectedTerms, newOption);

          setSelectedTerms(newTerms);
          Analytics.pushEvent(Events.filterUpdate, Actions.filterChange, 'Free form value');
        }
      },
    },
  ),

  withProps(({ currentOptions }) => ({
    possibleOptions: currentOptions,
  })),
);

export default SearchHOC(SearchUI);
