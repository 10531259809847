// @ts-nocheck
// NOTE: Deprecated - use AssignmentStatusTag
import moment, {  Moment } from 'moment';
import React from 'react';
import { Check } from 'common-ui/Icon';
import { Tag } from 'common-ui/Labels';
import type { AssignedStatus } from 'pages/Projects/types';
import styles from './styles.module.scss';

const textForStatus = (status: AssignedStatus, sentTime?: Moment ) => {
  switch (status) {
    case 'MISSING':
      return 'Missing';
    case 'DECLINED':
      return 'Declined';
    case 'DRAFT':
      return 'Draft';
    case 'SENT':
      return moment(sentTime).isValid()
        ? moment(sentTime).format('h:mm A')
        : 'Sent';
    case 'CANCELLED':
      return 'Cancelled';
    case 'AUTO':
      return 'Auto';
    case 'UNASSIGNED':
      return 'Unassigned';
    case 'REASSIGNED':
      return 'Reassigned';
    default:
      return '';
  }
};

const classNameForStatus = (status: AssignedStatus) => {
  switch (status) {
    case 'MISSING':
      return styles.missing;
    case 'DECLINED':
      return styles.declined;
    case 'DRAFT':
      return styles.draft;
    case 'SENT':
      return styles.sent;
    case 'CANCELLED':
      return styles.cancelled;
    case 'AUTO':
      return styles.done;
    case 'UNASSIGNED':
      return styles.unassigned;
    case 'REASSIGNED':
      return styles.reassigned;
    default:
      return '';
  }
};

type AssignedStatusTagProps = {
  status: AssignedStatus,
  sentTime?: Moment
};

const AssignedStatusTag = ({ status, sentTime }: AssignedStatusTagProps) => (
  <Tag
    text={textForStatus(status, sentTime)}
    className={classNameForStatus(status)}
    leftIcon={!!status && status.toLowerCase() === 'sent' && <Check />}
  />
);

AssignedStatusTag.defaultProps = {
  sentTime: '',
};

export default AssignedStatusTag;
