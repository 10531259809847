import React, {SVGProps} from 'react';

const Edit = (props: SVGProps<SVGSVGElement>) => (

  <svg width={21} height={21} viewBox="0 0 21 21" {...props}>

    <title>Edit</title>

    <path
      d="M18.326.78l1.893 1.892a2.67 2.67 0 0 1-.004 3.778L6.995 19.67l-4.972 1.247c-1.432.36-2.3-.514-1.942-1.942l1.247-4.972L14.548.783A2.675 2.675 0 0 1 18.326.78zM6.523 16.363l-1.889-1.888-.893.894-.627 2.514 2.503-.614.906-.906zm1.889-1.889l7.08-7.08c.004-.004-1.888-1.894-1.888-1.894a5904.7 5904.7 0 0 1-7.08 7.086l1.888 1.888z"
      fill="#162093"
      fillRule="evenodd"
    />
  </svg>
);

export default Edit;
