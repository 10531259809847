// @ts-nocheck
import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { LIVE_DATA_QUERY } from 'pages/Live/graphql';
import { useCompanyContext } from 'utils/Hooks';


const SHARE_LIVE_MUTATION = gql`
  mutation ShareLiveProject($message: String!, $projectID: ID!, $link: String!) {
    shareClientProjectLink(projectID: $projectID, message: $message, link: $link)
  }
`;

const useShareLiveProject = () => {
  const { selectedCompanyID } = useCompanyContext();
  const [shareLiveProject] = useMutation(SHARE_LIVE_MUTATION);
  return useCallback((projectID, link, message) => (
    shareLiveProject({
      variables: {
        projectID,
        link,
        message,
      },
      awaitRefetchQueries: true,
      refetchQueries: [{ query: LIVE_DATA_QUERY, variables: { companyID: selectedCompanyID } }],
    })
  ), [shareLiveProject]);
};

export default useShareLiveProject;
