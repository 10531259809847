import React, {SVGProps} from 'react';

const BothSite = (props: SVGProps<SVGSVGElement>) => (

  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="#002FB0"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >

    <rect x="1" y="1" width="24" height="24" rx="13" />

    <path
      d="M17.7507 10.6875L17.0519 11.3715C16.8074 11.6108 16.4103 11.6122 16.1663 11.3733C15.9206 11.1328 15.9231 10.7463 16.1682 10.5065C16.1682 10.5065 17.9324 8.77949 17.9337 8.77826C18.0562 8.6583 18.2158 8.59879 18.376 8.59987C18.5359 8.59977 18.6956 8.65913 18.8192 8.78011L20.5828 10.5065C20.8273 10.7458 20.8287 11.1345 20.5847 11.3733C20.339 11.6139 19.9442 11.6114 19.6991 11.3715L19.0004 10.6875V18.3768C19.0004 18.7148 18.7206 18.9887 18.3755 18.9887C18.028 18.9887 17.7507 18.7147 17.7507 18.3768V10.6875ZM14.2174 17.5162V9.82162C14.2174 9.48209 13.9376 9.21069 13.5925 9.21069C13.245 9.21069 12.9676 9.48421 12.9676 9.82162V17.5162L12.2689 16.8336C12.0244 16.5948 11.6273 16.5934 11.3833 16.8318C11.1376 17.0718 11.1401 17.4575 11.3852 17.6968C11.3852 17.6968 13.1494 19.4202 13.1507 19.4215C13.2732 19.5412 13.4328 19.6006 13.593 19.5995C13.7529 19.5996 13.9126 19.5403 14.0362 19.4196L15.7998 17.6968C16.0443 17.458 16.0457 17.0701 15.8017 16.8318C15.556 16.5917 15.1612 16.5942 14.9161 16.8336L14.2174 17.5162Z"
      fill="white"
      transform="translate(-3, -1)"
    />
  </svg>
);

export default BothSite;
