// @ts-nocheck
import { addMetersToLngLat } from '@math.gl/web-mercator';
import React from 'react';
import { SVGOverlay } from 'react-map-gl';

type Props = {
  lng: number,
  lat: number,
  radius: number,
  color: string
}

const Circle = ({
  lng, lat, radius, color,
}: Props) => (

  <SVGOverlay
    style={{ zIndex: -1 }}
    redraw={(opts) => {
      const center = [lng, lat];
      const [cx, cy] = opts.project(center);

      // Calculate radius in pixels by projecting a point on the circle onto the
      // viewport.
      const edge = addMetersToLngLat(center, [radius, 0, 0]);
      const [ex, ey] = opts.project(edge);

      const x = cx - ex;
      const y = cy - ey;


      const pixelRadius = Math.sqrt(x ** 2, y ** 2);

      return (

        <circle
          cx={cx}
          cy={cy}
          r={pixelRadius}
          fill={color}
          fillOpacity={0.25}
        />
      );
    }}
  />
);

export default Circle;
