// @ts-nocheck

import WebMercatorViewport from '@math.gl/web-mercator';
import React, {
  ReactNode, useEffect, useMemo,
  useState,
} from 'react';
import ReactMapGL, { LinearInterpolator } from 'react-map-gl';

import { useWhoAmI } from 'common-ui/Hooks';
import { useUpdateLiveMapSettings } from 'pages/Settings/graphql';
import CustomLayerControl, { getMapStyle } from './CustomLayerControl';
import CustomZoomControl from './CustomZoomControl';
import { driverIdentifiers } from './utils/map.utils';

type TreadMapPropsType = {

  setSize: (any) => any,

  onMapResize:(any) => any,
  children?: ReactNode,
  bounds: any,
  openPopup: any,

  includeTraffic: bool,

  noLayerControl: bool,
  height?: string,
  width?: string,

  includeDriverIdentifier: bool,
  onClickMap: () => void,
  forceFocusToPoint: {lat: number, lng: number},
};

const TreadMap = (props:TreadMapPropsType) => {
  const {
    bounds,
    openPopup,
    children,
    includeTraffic,
    includeDriverIdentifier,
    noLayerControl,
    height,
    width,
    onClickMap,
    forceFocusToPoint,
    // eslint-disable-next-line react/prop-types
    getCursor,
  } = props;

  const transitionInterpolator = useMemo(() => new LinearInterpolator(), [LinearInterpolator]);
  const [hasBounds, setHasBounds] = useState(false);

  // Start the map at the top-left of the bounds. The `.fitBounds` function will
  // adjust this immediately on load.
  const [viewport, setViewport] = useState({
    latitude: bounds[0][1],
    longitude: bounds[0][0],
  });

  const updateViewport = (fn: any) => setViewport(vp => ({
    ...fn(vp),
    transitionDuration: 300,
    transitionInterpolator,
  }));

  const fitBounds = () => {
    if (bounds?.length) {
      setViewport((vp) => {
        // Fit all markers within the viewport
        // Unsure why, but having a non-zero padding for the bounds will break the initial load of the map

        const {longitude, latitude, zoom} = new WebMercatorViewport(vp)
            .fitBounds(bounds, {padding: 0});

        return {
          ...vp,
          longitude,
          latitude,
          zoom,
          bearing: 0,
          pitch: 0,
          transitionDuration: 300,
          transitionInterpolator,
        };
      });
    }
  };

  // Fit bounds on load once bounds are available
  useEffect(fitBounds, [hasBounds]);
  useEffect(() => {
    if (bounds?.length) {
      setHasBounds(true);
    }
  }, [bounds]);

  // Provide an object to forceFocusToPoint to have the map
  // slide the viewport to this object when it changes
  // without affecting the rest of the map
  // This object must have lng/lat properties, and the viewport
  // will change whenever these properties change
  useEffect(() => {
    if (forceFocusToPoint && forceFocusToPoint.lat) {
      setViewport(vp => ({
        ...vp,
        longitude: forceFocusToPoint.lng,
        latitude: forceFocusToPoint.lat,
        zoom: 15,
        bearing: 0,
        pitch: 0,
        transitionDuration: 300,
        transitionInterpolator,
      }));
    }
  }, [forceFocusToPoint]);

  // Zoom to popup when opened
  useEffect(() => {
    if (!openPopup) {
      return;
    }

    // Center the selected marker with an offset to fit the popup on screen
    setViewport(vp => {

      const wmvp = new WebMercatorViewport(vp);
      const [longitude, latitude] = wmvp.getMapCenterByLngLatPosition({
        lngLat: [openPopup.lng, openPopup.lat],
        pos: [wmvp.width / 2, wmvp.height / 2 + 100],
      });

      return {
        ...vp,
        longitude,
        latitude,
        bearing: 0,
        pitch: 0,
        transitionDuration: 300,
        transitionInterpolator,
      };
    });
  }, [openPopup]);

  const [mapStyle, setMapStyle] = useState('mapbox://styles/treadinc/cjsc7m75102991fo3lr4mev0v');
  const [whoami, loading] = useWhoAmI();
  const [driverIdentifier, setDriverIDState] = useState(
    (whoami && whoami.liveMapSettings && whoami.liveMapSettings.driverIdentifier)
    || driverIdentifiers.truckNumbers,
  );

  useEffect(() => {
    if (!loading && whoami && whoami.liveMapSettings) {
      setDriverIDState(whoami.liveMapSettings.driverIdentifier);
    }
  }, [whoami, loading]);

  const updateLiveMapSettings = useUpdateLiveMapSettings();
  const setDriverIdentifier = (v: any) => {
    updateLiveMapSettings({ driverIdentifier: v })
      .then(() => setDriverIDState(v));
  };

  return (

    <ReactMapGL
      {...viewport}
      height={height}
      width={width}
      onViewportChange={vp => setViewport(vp)}
      mapStyle={mapStyle}
      onClick={(e) => {

        if (onClickMap) onClickMap(e);
      }}
      getCursor={getCursor}
    >
      {React.Children.map(children, child => (
        React.cloneElement(child, {
          driverIdentifier,
          satelliteView: mapStyle === getMapStyle({ showSatellite: true, showTraffic: false })
            || mapStyle === getMapStyle({ showSatellite: true, showTraffic: true }),
        })
      ))}

      <CustomZoomControl
        onZoomIn={() => updateViewport((vp: any) => ({
          ...vp,
          zoom: vp.zoom + 1
        }))}
        onZoomOut={() => updateViewport((vp: any) => ({
          ...vp,
          zoom: vp.zoom - 1
        }))}
        onClickRefresh={fitBounds}
      />

      <CustomLayerControl
        {...{
          setMapStyle,
          includeTraffic,
          includeDriverIdentifier,
          noLayerControl,
          driverIdentifier,
          setDriverIdentifier,
        }}
      />
    </ReactMapGL>
  );
};

TreadMap.defaultProps = {
  children: null,
  height: '100%',
  width: '100%',
};

export default TreadMap;
