// @ts-nocheck
import classNames from 'classnames';
import moment from 'moment-timezone';
import React, { ReactNode } from 'react';

import ID from 'common-ui/ConsistentIDs';
import { useTimezone } from 'common-ui/Hooks';


import MultiProgressBar from 'pages/Projects/components/MultiProgressBar';
import type { ModifiedProjectType } from 'pages/Projects/types';


import { useCompany } from 'pages/Settings/CompanySettings/graphql';

import { useCompanyContext } from 'utils/Hooks';
import { ProjectCardActionDropdown } from './ProjectCardActionDropdown';
import getProjectQuantityProgress, { emptyProgressHoverText } from './getProjectQuantityProgress';
import styles from './styles.module.scss';



type ProjectCardHeaderProps = {
  startTime: string | null,
  info: ReactNode,
  tagSource: ReactNode,
  controls: ReactNode,
  mobile?: boolean,
  history?: boolean,
  disableAllProjectActions?: boolean,
  projectID?: string,
  project: ModifiedProjectType,
};

const ProjectCardHeader = ({
  startTime,
  info,
  tagSource,
  controls,
  mobile,
  history,
  disableAllProjectActions,
  projectID,
  project,
}: ProjectCardHeaderProps) => {
  const { selectedCompanyID } = useCompanyContext();
  const company = useCompany(selectedCompanyID);

  const canViewQuantityProgress = !mobile && !history && !project.parentId;
  const quantityProgress = canViewQuantityProgress
    && getProjectQuantityProgress(
      canViewQuantityProgress ? project.filterableAssignments : [],
      project.quantityUnit,
      true,
      company,
      true,
      project.quantity,
      project.id,
    );

  const timezone = useTimezone();
  return (

    <div className={classNames(styles.projectCardHeader, { [styles.mobile]: mobile })} style={{ gridArea: 'header' }}>

      <div className={styles.projectCardHeaderLeft}>
        {startTime && (
          <div className={styles.dateDisplay}>
            <div className={styles.dateMonth}>

              {moment.tz(startTime, timezone).format('MMM')}
            </div>
            <div className={styles.dateNumber}>

              {moment.tz(startTime, timezone).format('D')}
            </div>
            <div className={styles.dateWeekday}>

              {moment.tz(startTime, timezone).format('ddd')}
            </div>
          </div>
        )}

        <div className={classNames(styles.projectCardHeaderInfo, { [styles.mobile]: mobile })}>
          {info}
        </div>
      </div>

      <div className={classNames(styles.projectCardHeaderControls, { [styles.mobile]: mobile, [styles.history]: history })}>
        {canViewQuantityProgress && (

          <div className={styles.projectCardHeaderProgressBar}>
            <MultiProgressBar
              data={quantityProgress}
              total={project.quantity || 0}
              emptyHoverText={(remainder: any) => emptyProgressHoverText(remainder, project.quantityUnit, project.quantity, 'Unassigned')}
            />
          </div>
        )}
        { (mobile || history) ? (
          <>
            {controls}
          </>
        ) : (
          <>
            <div>
              {tagSource}
            </div>
            <div>
              <ProjectCardActionDropdown

                disableAllProjectActions={disableAllProjectActions}
                id={ID.create(ID.Areas.Dispatch, ID.Types.Button, `${ID.Labels.ProjectActions}-${projectID}`)}
              >
                {controls}
              </ProjectCardActionDropdown>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

ProjectCardHeader.defaultProps = {
  mobile: false,
  history: false,
  disableAllProjectActions: false,
  projectID: undefined,
};

export default ProjectCardHeader;
