// @ts-nocheck
import { connect, FormikProps } from 'formik';
import React, { ReactNode } from 'react';


import { DispatchProductInput } from '../ProductInput';
import { FormField } from 'common-ui/Form';

type FormikDispatchProductInputProps = {
  formik: FormikProps,
  label: string,
  embedded:boolean,
  required?: boolean,
  name: string,
  readOnly?: boolean,
  readOnlyValue?: ReactNode,
  id?: string,
};

// This product input is for selecting a single product or inputting freeform text
const FormikDispatchProductInput = ({
  formik,
  label,
  required,
  name,
  embedded,
  readOnly,
  readOnlyValue,
  id,
  ...inputProps
}: FormikDispatchProductInputProps) => (

  <FormField
    label={label}
    required={required}
    error={formik.touched[name] && formik.errors[name]}
    embedded={embedded}
    id={id}
  >
    {!readOnly ? (

      <DispatchProductInput
        value={formik.values[name]}
        onChange={value => formik.setFieldValue(name, value)}

        onBlur={() => formik.setFieldTouched(name)}
        name={name}
        embedded={embedded}
        {...inputProps}
      />
    ) : (

      <>
        {readOnlyValue || (formik.values[name] && formik.values[name].name) || '-'}
      </>
    )
    }
  </FormField>
);


FormikDispatchProductInput.defaultProps = {
  required: false,
  readOnly: false,
  readOnlyValue: '',
  id: undefined,
};

export default connect(FormikDispatchProductInput);
