import React, {SVGProps} from 'react';

const Page = (props: SVGProps<SVGSVGElement>) => (

  <svg width="22" height="22" viewBox="0 0 22 22" {...props}>

    <title>Page</title>

    <rect
      x="4.40002"
      y="3.48333"
      width="13.2"
      height="15.0333"
      rx="1.1"
      stroke="#4D4D4D"
      fill="none"
      strokeWidth="1.46667"
    />

    <line
      x1="8.06665"
      y1="6.60009"
      x2="13.9333"
      y2="6.60009"
      stroke="#4D4D4D"
      strokeWidth="1.46667"
      strokeLinecap="round"
    />

    <line
      x1="8.06665"
      y1="9.35009"
      x2="13.9333"
      y2="9.35009"
      stroke="#4D4D4D"
      strokeWidth="1.46667"
      strokeLinecap="round"
    />

    <line
      x1="8.06665"
      y1="12.1001"
      x2="13.9333"
      y2="12.1001"
      stroke="#4D4D4D"
      strokeWidth="1.46667"
      strokeLinecap="round"
    />

    <line
      x1="8.06665"
      y1="14.8501"
      x2="13.9333"
      y2="14.8501"
      stroke="#4D4D4D"
      strokeWidth="1.46667"
      strokeLinecap="round"
    />
  </svg>
);

export default Page;
