import React, {SVGProps} from 'react';

const OneDot = (props: SVGProps<SVGSVGElement>) => {
  return (

    <svg
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="#106FEE"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >

      <circle cx="4" cy="4" r="3.5" />
    </svg>
  );
};

export default OneDot;
