// @ts-nocheck
import React from 'react';
import ReactDOMServer from 'react-dom/server';

import progressColors from 'pages/Projects/components/MultiProgressBar/colors.module.scss';
import type { ModifiedAssignmentType } from 'pages/Projects/types';

import { loadTracking, assignedStatuses, undefinedOrNull } from 'pages/Projects/utils/projects.utils';

import { readableRateUnits, quantityUnits } from 'utils/format/rateUnits';

const getLegendSuffix = (inProjectView: any, isManualUploads: any, loadTrackingType: any, isLoadQuantity: any) => {
  if (inProjectView || !isLoadQuantity) {
    return '';
  }

  if (isManualUploads) {
    return '(Manual Uploads)';
  }

  switch (loadTrackingType) {
    case loadTracking.GPS:
      return '(Cycles)';
    case loadTracking.TICKET:
      return '(Tickets)';
    default:
      return '';
  }
};

export const emptyProgressHoverText = (
  remainder: number,
  unitText: string,
  total: number,
  prefixText: string,
) => (
  // Need to convert this element to HTML since react-tooltip doesn't support JSX for its messages
  ReactDOMServer.renderToStaticMarkup(

    <div style={{ textAlign: 'center' }}>

      <b>{prefixText}</b>

      <div>
        {`${remainder} ${readableRateUnits[unitText]}s (${Math.trunc(((remainder / total) || 0) * 100)}%)`}
      </div>
    </div>,
  )
);

const getProjectQuantityProgress = (
  assignments: Array<ModifiedAssignmentType>,
  quantityUnit: string,
  includeAssigned = true,
  company: any,
  inProjectView: boolean,
  total: number,
  progressId: string,
) => {
  if (!company) {
    return [];
  }

  let assignedValue = 0;
  let deliveredValue = 0;

  let isManualUploads = false;
  assignments.forEach((a) => {
    isManualUploads = company.loadTracking === loadTracking.GPS && !undefinedOrNull(a.quantityDeliveredOverride);

    if (a.assignedStatus !== assignedStatuses.MISSING && a.quantityUnit === quantityUnit) {
      assignedValue += a.quantity;
    }

    deliveredValue += isManualUploads ? a.quantityDeliveredOverride : (a.quantityDelivered || 0);
  });

  const isLoadQuantity = quantityUnit === quantityUnits.LOAD;
  const deliveredProgress = {
    id: `delivered_progress_${progressId}`,
    value: deliveredValue,
    name: `${readableRateUnits[quantityUnit]}s Delivered ${getLegendSuffix(inProjectView, isManualUploads, company.loadTracking, isLoadQuantity)}`,
    color: progressColors.green,
    hoverText: emptyProgressHoverText(deliveredValue, quantityUnit, total, 'Delivered'),
    priority: 1,
  };

  const assignedProgress = {
    id: `assigned_progress_${progressId}`,
    value: assignedValue,
    name: `${readableRateUnits[quantityUnit]}s Assigned ${getLegendSuffix(inProjectView, isManualUploads, company.loadTracking, isLoadQuantity)}`,
    color: progressColors.lightGreen,
    hoverText: emptyProgressHoverText(assignedValue, quantityUnit, total, 'Assigned'),
    priority: 0,
  };

  return [
    ...(deliveredValue > 0 || !includeAssigned ? [deliveredProgress] : []),
    ...(includeAssigned ? [assignedProgress] : [])];
};

export default getProjectQuantityProgress;
