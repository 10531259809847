// @ts-nocheck
import classNames from 'classnames';
import React from 'react';
import styles from './styles.module.scss';

type CompanyInitialsProps = {
  companyName: string,
  large?: boolean,
  priority?: string,
};

const CompanyInitials = ({ companyName, large, priority }: CompanyInitialsProps) => {
  const initials = companyName && companyName.length > 0 && companyName.charAt(0).toUpperCase();
  const classNameForInitialPriority = () => {
    switch (priority) {
      case 'HIGH':
        return styles.initialsHigh;
      case 'MEDIUM':
        return styles.initialsMedium;
      case 'LOW':
      default:
        return styles.initials;
    }
  };
  return (

    <div className={classNames(classNameForInitialPriority(priority), { [styles.large]: !!large })}>
      {initials || null}
    </div>
  );
};

CompanyInitials.defaultProps = {
  large: false,
  priority: 'LOW',
};

export default CompanyInitials;
