// @ts-nocheck
import classNames from 'classnames';
import React from 'react';

import ClickableDiv from 'common-ui/ClickableDiv';
import { ChevronLeft } from 'common-ui/Icon';


import { TooltipWrapper } from 'components/Common/Tooltip';
import styles from './styles.module.scss';

export type ProjectCardExpandProps = {
  collapsed?: boolean,
  onCollapse: () => void,
};

export const ProjectCardExpand = ({
  collapsed,
  onCollapse,
}: ProjectCardExpandProps) => (

  <TooltipWrapper message={collapsed ? 'Expand Assignments' : 'Collapse Assignments'}>

    <ClickableDiv

      className={classNames(styles.collapseButton, {
        [styles.collapsed]: collapsed,
      })}
      onClick={onCollapse}
    >

      <ChevronLeft width={13} height={13} />
    </ClickableDiv>
  </TooltipWrapper>
);

ProjectCardExpand.defaultProps = {
  collapsed: false,
};

export default ProjectCardExpand;
