// @ts-nocheck
import React from 'react';

const useScrollYHandler = (ref: any, onScrollUp: any, onScrollDown: any, minDeltaY = 20) => {
  // useRef since we need this to be mutable
  // and useState will only give the event handlers the value of the state
  // at the time of handler instantiation (closure funkiness)
  const scrollY = React.useRef({
    start: 0,
    end: 0,
    delta: 0,
  });

  React.useEffect(() => {
    const onMove = (e: any) => {
      const end = e.touches[0].screenY;
      const delta = end - scrollY.current.start;
      scrollY.current = {
        ...scrollY.current,
        delta,
        end,
      };
      // Positive delta means touch movement upwards (scrolling down),
      // Negative means downwards (scrolling up)
      if (Math.abs(delta) > minDeltaY) {
        if (typeof onScrollUp === 'function' && delta > 0) {
          onScrollDown();
        } else if (typeof onScrollDown === 'function' && delta < 0) {
          onScrollUp();
        }
      }
    };
    const onScrollStart = (e: any) => {
      scrollY.current = { start: e.touches[0].screenY, end: 0, delta: 0 };
    };
    const onScrollEnd = () => {
      scrollY.current = {
        start: 0,
        end: 0,
        delta: 0,
      };
    };
    if (ref && ref.current) {
      ref.current.addEventListener('touchstart', onScrollStart);
      ref.current.addEventListener('touchmove', onMove);
      ref.current.addEventListener('touchend', onScrollEnd);
    }

    return () => {
      if (ref && ref.current) {
        ref.current.removeEventListener('touchstart', onScrollStart);
        ref.current.removeEventListener('touchmove', onMove);
        ref.current.removeEventListener('touchend', onScrollEnd);
      }
    };
  }, [ref]);
};

export default useScrollYHandler;
