// @ts-nocheck
import React from 'react';

import { FormikTextInput, FormikRadio } from 'common-ui/Form';
import styles from './styles.module.scss';

const AddEditCollaboratorsFormFields = () => {
  return (

    <>

      <FormikTextInput label="First Name" name="firstName" />

      <FormikTextInput label="Last Name" name="lastName" />

      <FormikTextInput label="Phone Number" placeholder="ex. 647-555-4321" name="phoneNumber" />

      <FormikTextInput label="Company" name="companyName" />

      <FormikRadio
        label="Role"
        name="role"
        horizontal
        hint={(
          <a
            href="https://intercom.help/tread/en/articles/4222671-permissions-access-or-personas"
            target="_blank"
            className={styles.rolesLink}
            rel="noopener noreferrer"
          >
            Learn the Roles
          </a>
        )}
        options={[{
          label: 'DOT',
          value: 'DOT',
        }, {
          label: 'External Supervisor',
          value: 'External Supervisor',
        }]}
      />

      <FormikTextInput label="Email (optional)" name="email" />
    </>
  );
};

export default AddEditCollaboratorsFormFields;
