// @ts-nocheck
import { useState } from 'react';

type Step = string;

// This hook includes a function for opening the page display (meant to be used
// by the parent component), and the other props used by the component itself
// (and any of its children that need them)
//
// Typical usage is as follows:
// const [openPageDisplay, pageDisplayProps] = usePageDisplay();
// ...
// <Button onClick={() => openPageDisplay(step)} />
// <PageDisplayComponent {...pageDisplayProps} />
const usePageDisplay = () => {
  const [currentStep, setStep] = useState/* ::<Step | null> */(null);
  const [visible, setVisible] = useState/* ::<boolean> */(false);
  const [history, setHistory] = useState/* ::<Step[]> */([]);

  const goToStep = (newStep: Step) => {
    if (newStep !== history[0]) {

      setStep(newStep);

      setHistory((h) => [newStep].concat(h));
    }
  };

  const goBack = () => {
    history.shift();
    const prevStep = history[0];
    if (prevStep) {
      setStep(prevStep);
      setHistory(history);
    } else {
      setVisible(false);
    }
  };

  const openPageDisplay = (step: Step) => {
    setVisible(true);

    setStep(step);

    setHistory([step]);
  };

  return [openPageDisplay, {
    currentStep,
    goToStep,
    goBack,
    visible,
    setVisible,
  }];
};

export default usePageDisplay;
