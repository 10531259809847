// @ts-nocheck
export const quantityUnits = {
  LOAD: 'LOAD',
  TONNE: 'TONNE',
  TON: 'TON',
  YARD: 'YARD',
  METER: 'METER',
  FOOT: 'FOOT',
  LITER: 'LITER',
  HOUR: 'HOUR',
  BUSHEL: 'BUSHEL',
  GALLON: 'GALLON',
  CUBICMETER: 'CUBICMETER',
};

export const rateUnits = {
  ...quantityUnits,
  LB: 'LB',
  KG: 'KG',
};

export const ticketRateUnits = {
  ...rateUnits,
  WAIT: 'WAIT',
};

const capacityUnits = {
  TON: 'TON',
  TONNE: 'TONNE',
  LOAD: 'LOAD',
  YARD: 'YARD',
  LITER: 'LITER',
  BUSHEL: 'BUSHEL',
  GALLON: 'GALLON',
  CUBICMETER: 'CUBICMETER',
};

export const readableRateUnits = {
  LOAD: 'Load',
  TONNE: 'Tonne',
  TON: 'Ton',
  YARD: 'Yard',
  METER: 'Meter',
  FOOT: 'Foot',
  LB: 'Pound',
  KG: 'Kilogram',
  LITER: 'Liter',
  HOUR: 'Hour',
  WAIT: 'Wait Hour',
  BUSHEL: 'Bushel',
  GALLON: 'Gallon',
  CUBICMETER: 'Cubic Meter',
};

export const quantityUnitOptions = Object.keys(quantityUnits).map(unit => ({

  label: readableRateUnits[unit],
  value: unit,
}));

export const rateUnitOptions = Object.keys(rateUnits).map(unit => ({

  label: readableRateUnits[unit],
  value: unit,
}));

export const ticketRateUnitOptions = Object.keys(ticketRateUnits).map(unit => ({

  label: readableRateUnits[unit],
  value: unit,
}));

export const capacityUnitOptions = Object.keys(capacityUnits).map(unit => ({

  label: readableRateUnits[unit],
  value: unit,
}));


export const formatRateUnit = (unit: any) => readableRateUnits[unit];
