// this is our base split.io config
//
// in SplitWrapper we take this config and set core.key to our current userId. technically you could target based on userid but
// that's probably not super useful.
//
// in SplitTreatment we pass in an array of companyIds as attributes which are used for targeting and are probably what you want.
//
// to create a split useable in this codebase:
//   1. set "Traffic type" to user
//   2. under "Targeting rules", use "IF user companies has any of" and add the numeric company id you want to target.

const SPLIT_CONFIG = {
  core: {
    authorizationKey: process.env.REACT_APP_SPLIT_IO_CLIENT_KEY,
    trafficType: 'user',
  },
};

export default SPLIT_CONFIG;
