// @ts-nocheck
import classNames from 'classnames';
import React from 'react';
import {Chat} from '../../Icon';
import styles from './styles.module.scss';
//
const IntercomLauncher = ({mobile}: {mobile?: boolean}) => {
  const [messageCount, setMessageCount] = React.useState(0);
  const [hidden, setHidden] = React.useState(true);
  React.useEffect(() => {
    window.Intercom('onUnreadCountChange', (unreadCount: any) => {
      setMessageCount(unreadCount);
    });
    window.Intercom('onShow', () => setHidden(false));
    window.Intercom('onHide', () => setHidden(true));
  }, []);
  return (

    <div

      className={classNames(styles.intercomButton, {[styles.open]: !hidden})}
      id="intercom_launcher"
      role="button"
      tabIndex={0}
      // onKeyPress={() => {
      //   if (hidden) window.Intercom('show');
      //   else window.Intercom('hide');
      //   GA.trackClick(GAActions.help);
      // }}
      // onClick={() => {
      //   if (hidden) window.Intercom('show');
      //   else window.Intercom('hide');
      //   GA.trackClick(GAActions.help);
      // }}
    >
      {!!messageCount && hidden && (

        <div

          className={classNames(styles.intercomIcon, styles.notificationCount, {
            [styles.mobile]: mobile,
          })}
        >
          {messageCount}
        </div>
      )}
      {!hidden && (

        <div

          className={classNames(styles.intercomIcon, {
            [styles.mobile]: mobile,
          })}
        >
          &times;
        </div>
      )}

      <Chat />
    </div>
  );
};

IntercomLauncher.defaultProps = {
  mobile: false,
};

export default IntercomLauncher;
