// @ts-nocheck
import { fromRenderProps } from 'recompose';

import { TimezoneContext } from 'common-ui/Hooks';

const withUserTimezone = (
  fromRenderProps(TimezoneContext.Consumer, timezone => ({ timezone }))
);

export default withUserTimezone;
