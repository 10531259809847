// @ts-nocheck
import React from 'react';

import type { Site } from 'common-ui/types';
import { EmptyMap, LiveMarkers, MapWithControls } from 'components/Common/Map';

import { useLiveMarkerBounds } from 'components/Common/Map/LiveMarkers/LiveMarkers';
import { SiteMarker } from 'components/Common/Map/SiteMarkers';



import { makeMarkers } from 'components/Common/Map/utils/marker.utils';
import type { Marker } from 'pages/Live/types';
import useSiteIdUrl from 'pages/Sites/Hooks/useSiteIdUrl';
import styles from './styles.module.scss';

// eslint-disable-next-line import/namespace

// eslint-disable-next-line import/namespace

type SharedMapProps = {
  markers: Array<Marker>,
  sites: Array<Site>,
  openedMarkerID: string,
  toggleMarkerOpen: () => void,
  liveMapLoading: boolean,
  validLink: boolean,
}

const SharedMap = ({
  markers,
  sites,
  openedMarkerID,
  toggleMarkerOpen,
  liveMapLoading,
  validLink,
}: SharedMapProps) => {
  // TODO calcMarkers could be removed and we could just use the prop markers?
  // I think they have all the same info
  // calcMarkers is just generated from a useEffect so it may be doubling effort
  // since we effectively generate markers twice
  // on data update
  const { calculatedBounds, calcMarkers, openPopup } = useLiveMarkerBounds(markers, openedMarkerID);
  const [selectedSiteId, setSelectedSiteId] = useSiteIdUrl();
  const siteMarkers = makeMarkers(sites);

  if (liveMapLoading) {
    return (

      <div className={styles.emptyMap}>

        <EmptyMap
          text="Loading"
          isLoading={liveMapLoading}
        />
      </div>
    );
  }

  if (!liveMapLoading && (markers.length === 0 || markers.filter(m => m.type !== 'badData').length === 0)) {
    const text = validLink
      ? 'You aren’t able to view live projects at this time.'
      : 'Your project link has expired. Contact your Tread Admin for another link.';

    return (

      <div className={styles.emptyMap}>

        <EmptyMap
          text={text}
          isLoading={liveMapLoading}
        />
      </div>
    );
  }

  if (!calcMarkers || calcMarkers.length === 0) {
    return (

      <div className={styles.emptyMap}>

        <EmptyMap
          text="Loading"
          isLoading
        />
      </div>
    );
  }

  return (

    <div className={styles.mapContainer}>

      <MapWithControls calculatedBounds={calculatedBounds} openPopup={openPopup} mobile>

        <LiveMarkers
          openMarkerId={openedMarkerID}
          markers={calcMarkers}
          selectedSiteId={null}
          setSelectedSiteId={setSelectedSiteId}
          toggleMarkerOpen={toggleMarkerOpen}
          sites={sites}
        />

        {siteMarkers.map((m: any) => <SiteMarker
          key={`sitemarker-${m.id}`}
          marker={m}
          onClick={(e: any) => {
            e.stopPropagation();
            if (m.id !== selectedSiteId) {

              toggleMarkerOpen(null);
              setSelectedSiteId(m.id);
            } else {
              setSelectedSiteId(null);
            }
          }}
          showGeofence
          popupCanOpen
          popupIsOpen={m.id === selectedSiteId}
        />)}
      </MapWithControls>
    </div>
  );
};

export default SharedMap;
