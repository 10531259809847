import React, {SVGProps} from 'react';

const Rotate = (props: SVGProps<SVGSVGElement>) => (

  <svg width={16} height={19} viewBox="0 0 16 19" {...props}>

    <title>Rotate</title>

    <path
      d="M11.89 4.934H7.141a5.1 5.1 0 0 0-5.1 5.104v3.1A1.992 1.992 0 0 1 .05 15.14h1.94C.902 15.14 0 14.244 0 13.138v-3.1a7.142 7.142 0 0 1 7.142-7.145h4.762l-1.149-1.149A1.02 1.02 0 1 1 12.2.301l2.88 2.88c.202.202.301.463.3.724.003.262-.097.522-.297.723L12.2 7.51a1.02 1.02 0 1 1-1.444-1.443l1.134-1.134zm-5.767 6.075c0-1.1.898-1.993 1.994-1.993h5.199c1.1 0 1.993.898 1.993 1.993v5.2c0 1.1-.898 1.993-1.993 1.993h-5.2c-1.1 0-1.993-.898-1.993-1.994V11.01zm2.042.048v5.103h5.102v-5.103H8.165z"
      fill="#2493FF"
      fillRule="evenodd"
    />
  </svg>
);

export default Rotate;
