import classNames from 'classnames';
import React, {SVGProps} from 'react';

import Check from './Check';
import styles from './styles.module.scss';

const CircleCheck = (props: SVGProps<SVGSVGElement>) => (

  <Check className={classNames(props?.className || '', styles.circleCheck)} />
);

export default CircleCheck;
