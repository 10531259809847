// @ts-nocheck
import classNames from 'classnames';
import React from 'react';

import { Draggable, Droppable } from 'react-beautiful-dnd';

import cannotAssignHireToAssignment from '../../utils';
import { HireChip } from '../HireChip';
import type { InternalHire, Subcontractor } from 'pages/Hires/types';

import type { AssignmentType } from 'pages/Projects/types';

import { useHover } from 'utils/Hooks';
import styles from './styles.module.scss';

export interface HireDropProps  {
  assignments: Array<AssignmentType>,
  // eslint-disable-next-line react/require-default-props
  assignmentInstance: any,
  companyId: number,
  // eslint-disable-next-line react/require-default-props
  hire?: InternalHire | Subcontractor,
  cancelled?: boolean,
  children: ReactNode,
};

const HireDrop = ({
  assignments,
  assignmentInstance,
  hire,
  cancelled,
  children,
}: HireDropProps) => (

  <Droppable
    droppableId={JSON.stringify({
      id: assignments,
      isAssignment: true,
      assignmentContainer: true,
    })}
    direction="horizontal"
  >

    {(assignmentContainerProvided, assignmentContainerSnapshot) => {
      const draggedHire = JSON.parse(assignmentContainerSnapshot.draggingOverWith);

      // internal hires cannot be assigned to both
      // smart and classic assignments at the same time
      const disabled = cannotAssignHireToAssignment(draggedHire, assignments);

      // Only calculate draggableId if there's a hire.

      let draggableId;

      if (hire) {
        draggableId = JSON.stringify({
          id: hire.__typename === 'Hire' ? hire.accountId : hire.id,
          caID: hire.__typename === 'Hire' ? hire.id : null,
          companyId: hire.__typename === 'Hire' ? hire.companyId : null,
          isInternal: hire.__typename === 'Hire',
          copy: hire.__typename !== 'Hire' ? hire.copy : null,
          assignments,
          isAssignedToSmartAssignment: hire.isAssignedToSmartAssignment,
          isNotAssignedToAnyAssignment: hire.isNotAssignedToAnyAssignment,
        });
      }

      return (

        <div
          ref={assignmentContainerProvided.innerRef}
          {...assignmentContainerProvided.droppableProps}
          className={classNames(styles.hireDrop, {
            [styles.dropping]: assignmentContainerSnapshot.isDraggingOver,
            [styles.missing]: !cancelled && !hire,
            [styles.withHire]: !cancelled && !!hire,
            [styles.cancelled]: !!cancelled,
            [styles.disabled]: disabled,
          })}
        >
          <Droppable
            droppableId={JSON.stringify({
              id: assignments,
              isAssignment: true,
              assignmentContainer: false,
            })}
            isDropDisabled
            mode="virtual"

            renderClone={(provided, snapshot) => (
              <HireChip
                hire={hire}
                assignmentInstance={assignmentInstance}
                provided={provided}
                snapshot={snapshot}
              />
            )}
          >

            {assignmentProvided => (
              <div
                ref={assignmentProvided.innerRef}
                {...assignmentProvided.droppableProps}
                className={styles.innerContainer}
              >
                {hire ? (
                  <Draggable
                    key={draggableId}
                    draggableId={draggableId}
                    hire={{
                      ...hire,
                      assignedCount: 0,
                      isInternal: hire.__typename === 'Hire',
                    }}
                    assignmentInstance={assignmentInstance}
                    assignments={assignments}
                    index={0}
                  >

                    {(provided, snapshot) => {
                      const [ref] = useHover();
                      const isAssigned = assignments && assignments.length > 0;
                      const isUnassigning = false;

                      if (snapshot.isDropAnimating && snapshot.draggingOver) {
                        try {
                          const container = JSON.parse(snapshot.draggingOver).id;
                          if (container === 'unassignContainer') {
                            return true;
                          }
                        } catch (e) {
                          // Defaults to false above
                        }
                      }

                      return (
                        <div ref={ref} className={styles.draggableHireChip}>
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}

                            className={classNames(styles.assignmentHireChip, {
                              [styles.assigned]: isAssigned,
                              [styles.unassigning]: isUnassigning,
                              [styles.dragging]: snapshot.isDragging,
                            })}
                          >
                            {children}
                          </div>
                        </div>
                      );
                    }}
                  </Draggable>
                ) : (
                  children
                )}
              </div>
            )}
          </Droppable>
        </div>
      );
    }}
  </Droppable>
);

HireDrop.defaultProps = {
  assignmentInstance: null,
  cancelled: false,
};

export default HireDrop;
