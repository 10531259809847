// @ts-nocheck
import { useMutation, gql } from '@apollo/client';
import { useCallback } from 'react';

import { useCompanyContext } from 'utils/Hooks';

const ADD_HIRE_TAG_MUTATION = gql`
  mutation addTags($companyID: ID!, $names: [String!]!) {
    addTags(companyID: $companyID, names: $names) {
      id
      name
      deleted
    }
  }
`;

const COMPANY_TAGS_FRAGMENT = gql`
  fragment tags on Company {
    __typename
    id
    hireTags {
      __typename
      id
      name
      deleted
    }
  }
`;

const useAddTag = () => {
  const [addTag] = useMutation(ADD_HIRE_TAG_MUTATION);
  const { selectedCompanyID } = useCompanyContext();
  return useCallback((name: string) => addTag({
    variables: {
      companyID: selectedCompanyID,
      names: [name],
    },
    update: (store, { data: { addTags: newTags } }) => {
      const oldCompany = store.readFragment({
        id: `Company:${selectedCompanyID}`,
        fragment: COMPANY_TAGS_FRAGMENT,
      });
      store.writeFragment({
        id: `Company:${selectedCompanyID}`,
        fragment: COMPANY_TAGS_FRAGMENT,
        data: {

          ...oldCompany,

          hireTags: [...oldCompany.hireTags, ...newTags],
        },
      });
    },
  }).then(({ data }) => {
    if (data.addTags.length) return data.addTags[0];
    return null;
  }), [addTag, selectedCompanyID]);
};

export default useAddTag;
