// @ts-nocheck
import _ from 'lodash';
import React, { useRef } from 'react';

import { TooltipWrapper } from 'components/Common/Tooltip';
import styles from './styles.module.scss';

type MultiProgressBarProps = {
  data: Array<{
    id: string,
    name: string,
    value: number,
    color: string,
    hoverText?: string,
    priority?: number,
  }>,
  total: number,
  backgroundColor?: string,

  emptyHoverText?: (any) => string,
};

const getWidthPercentage = (data: any, index: any, total: any) => {
  if (index >= data.length) {
    return 0;
  }

  const preValue = index > 0 ? data[index - 1].value : total;
  return (data[index].value / preValue);
};

const MAX_PERCENT = 100;
const MultiProgressBar = ({
  data, total, backgroundColor, emptyHoverText,
}: MultiProgressBarProps) => {
  // Make orderby compare priority values if values begin to exceed total
  const valueComparartor = (data: any) => data.value > total ? total : data.value;
  const orderedData = _.orderBy(data, [valueComparartor, 'priority'], ['desc', 'asc']);

  const bars = (index: number, parentWidth: number) => {
    if (index === orderedData.length) {
      return null;
    }

    const barData = orderedData[index];
    const widthPercentage = getWidthPercentage(orderedData, index, total);
    // Progress bars are overlapped,
    // so we need to offset the bars so that the tool tip is over the correct bar
    const barWidth = parentWidth * widthPercentage;
    const widthOffset = (getWidthPercentage(orderedData, index + 1, total) * barWidth) / 2;

    return (

      <TooltipWrapper
        message={barData.hoverText}
        className={styles.multiProgressBarData}
        style={{ width: `${widthPercentage * MAX_PERCENT}%`, '--baseProgressColor': barData.color }}
        data-html
        data-offset={`{'right': ${widthOffset}}`}
      >
        { bars(index + 1, barWidth) }
      </TooltipWrapper>
    );
  };

  const ref = useRef(null);
  const baseBarWidth = (ref && ref.current) ? ref.current.clientWidth : 0;
  const baseBarWidthOffset = (getWidthPercentage(orderedData, 0, total) * baseBarWidth) / 2;


  return (

    <div className={styles.multiProgressBar}>

      <div className={styles.multiProgressBarDisplay} ref={ref}>

        <TooltipWrapper

          message={emptyHoverText(total - (orderedData[0] && orderedData[0].value))}
          className={styles.multiProgressBackground}
          style={{ '--backgroundColor': backgroundColor }}
          data-html
          data-offset={`{'right': ${baseBarWidthOffset}}`}
        >
          { !!total && bars(0, baseBarWidth)}
        </TooltipWrapper>
      </div>

      <div className={styles.multiProgressLegend}>

        {orderedData.map((d: any) => <div className={styles.multiProgressLegendText} key={d.id}>
          <span className={styles.multiProgressLegendValueText} style={{ '--textColor': d.color }}>
            { `${d.value} / ${total}` }
          </span>
          &nbsp;
          {d.name}
        </div>)}
      </div>
    </div>
  );
};

MultiProgressBar.defaultProps = {
  backgroundColor: '',
  emptyHoverText: () => '',
};

export default MultiProgressBar;
