// @ts-nocheck
import React, { ReactNode, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';

type TooltipWrapperProps = {
  message?: string;
  children?: ReactNode;
};

const TooltipWrapper = ({
  message,
  children,
  ...tooltipProps
}: TooltipWrapperProps) => {

  useEffect(() => {
    if(message?.length){
      ReactTooltip.rebuild();
    }

  },[message]);

  return (
    <div
      data-tip={message}
      data-for="tooltip"
      {...tooltipProps}
    >
      {children}
    </div>
  );
};

TooltipWrapper.defaultProps = {
  message: '',
  children: null,
};

export default TooltipWrapper;
