// @ts-nocheck
import { connect } from 'formik';
import React from 'react';


import { FormField, Radio } from '..';
import Analytics from 'common-ui/Analytics/SegmentAnalytics';
import {Events} from 'common-ui/Analytics/types';

type FormikRadioProps = {
  formik: any,
  label: string,
  required?: boolean,
  options: { label: string, value: string }[],
  name: string,
  analyticsAction?: string,
  readOnly?: boolean,
  onChange?: () => void,
  id?: string,
  hint?: string,
};

const FormikRadio = ({
  formik,
  label,
  required,
  options,
  name,
  analyticsAction,
  readOnly,
  onChange,
  id,
  hint,
  ...inputProps
}: FormikRadioProps) => (

  <FormField
    label={label}
    required={required}
    error={formik.touched[name] && formik.errors[name]}
    hint={hint}
  >

    <Radio
      id={id}
      options={options}
      value={formik.values[name]}
      onChange={(value) => {
        formik.setFieldValue(name, value);
        if (analyticsAction) {
          Analytics.pushEvent(Events.formChange, analyticsAction, value);
        }

        if (onChange) onChange(value);
      }}
      onBlur={() => formik.setFieldTouched(name)}
      name={name}

      readOnly={readOnly}
      {...inputProps}
    />
  </FormField>
);

FormikRadio.defaultProps = {
  required: false,
  analyticsAction: '',
  readOnly: false,
  id: undefined,
  onChange: () => {},
  hint: null,
};

export default connect(FormikRadio);
