// @ts-nocheck
import { connect } from 'formik';
import _ from 'lodash';
import React, { ReactNode } from 'react';

import { Dropdown, FormField } from '..';

type NestedName = {
  arrayName: string,
  index: number,
  name: string,
};

type FormikDropdownProps = {
  formik: any,
  label?: string,
  required?: boolean,
  locked?: boolean,
  options: { label: ReactNode, value: string }[],
  name: string,
  embedded: boolean,
  readOnly?: boolean,
  readOnlyValue?: ReactNode,
  id?: string,
  hideError?: boolean,
  nestedName?: NestedName,
  onMenuScrollToBottom?: () => void,
  onInputChange?: (inputText: string) => void,
  disableBuiltInFilter?: boolean,
  shouldOverlay?: boolean,
};

const fieldArrayValidatorHelper = (touched: any, errors: any, nestedName: any) => {
  touched = touched[nestedName.arrayName];

  if (!Array.isArray(touched) || !Array.isArray(errors)) {
    return '';
  }

  if (!touched[nestedName.index] || !errors[nestedName.index]) {
    return '';
  }

  if (nestedName.name in touched[nestedName.index]) {
    if (touched[nestedName.index][nestedName.name]) {
      if (nestedName.name in errors[nestedName.index]) {
        return errors[nestedName.index][nestedName.name];
      }
    }
  }

  return '';
};

const FormikDropdown = ({
  formik,
  label,
  required,
  locked,
  options,
  name,
  embedded,
  readOnly,
  readOnlyValue,
  id,
  hideError,
  nestedName,
  onMenuScrollToBottom,
  onInputChange,
  disableBuiltInFilter,
  shouldOverlay,
  ...inputProps
}: FormikDropdownProps) => (

  <FormField
    label={label}
    required={required}
    locked={locked}
    error={
      !nestedName
        ? formik.touched[name] && formik.errors[name]
        : fieldArrayValidatorHelper(formik.touched, formik.errors, nestedName)
    }
    embedded={embedded}
    hideError={hideError}
  >
    {!readOnly && (

      <Dropdown
        id={id}
        options={options}
        value={
          !nestedName
            ? formik.values[name]
            : formik.values[nestedName.arrayName][nestedName.index][
              nestedName.name
            ]
        }

        inputId={name}
        onChange={(value) => formik.setFieldValue(name, value)}
        onBlur={() => formik.setFieldTouched(name)}
        name={name}
        embedded={embedded}
        onMenuScrollToBottom={onMenuScrollToBottom}
        onInputChange={onInputChange}
        disableBuiltInFilter={disableBuiltInFilter}
        shouldOverlay={shouldOverlay}
        {...inputProps}
      />
    )}
    {readOnly && (

      <>
        {readOnlyValue ||
              _.get(
                options.find((o) => o.value === formik.values[name]),
                'label',
                '-'
              )}
      </>
    )}
  </FormField>
);

FormikDropdown.defaultProps = {
  required: false,
  locked: false,
  readOnly: false,
  readOnlyValue: '',
  id: undefined,
  label: undefined,
  hideError: false,
  nestedName: null,
  onMenuScrollToBottom: () => {},
  onInputChange: () => {},
  disableBuiltInFilter: false,
  shouldOverlay: false,
};

export default connect(FormikDropdown);
