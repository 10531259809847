// @ts-nocheck
import React from 'react';
import { compose } from 'recompose';

import { BaseMap, ConnectMapControls } from '../BaseMap';

import { Path, ConnectPathControls } from './Path';

import TicketPoints, {  TicketPointProps } from './TicketPoints';

type PathMapProps = {
  onRefresh: () => void,
  intl: any,
  bounds: Array<number>,

  setAutoZoom: (param:boolean) => void,
  scrollWheelZoom?: boolean,
  includeTraffic?: boolean,
  path: Array<any>,
  signoffPoints?: TicketPointProps[],
  uploadPoints?: TicketPointProps[],
};

const PathMap = ({
  onRefresh,
  intl,
  bounds,
  setAutoZoom,
  scrollWheelZoom,
  includeTraffic,
  path,
  signoffPoints,
  uploadPoints,
}: PathMapProps) => (

  <BaseMap
    {...{
      onRefresh,
      intl,
      bounds,
      setAutoZoom,
      scrollWheelZoom,
      includeTraffic,
    }}
  >

    <Path path={path} />

    <TicketPoints signoffPoints={signoffPoints} uploadPoints={uploadPoints} />
  </BaseMap>
);

PathMap.defaultProps = {
  scrollWheelZoom: true,
  includeTraffic: false,
  signoffPoints: [],
  uploadPoints: [],
  height: '100%',
  width: '100%',
};

export const MapWithControls = compose(
  ConnectPathControls,
  ConnectMapControls,

)(PathMap);

export default MapWithControls;
