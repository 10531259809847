import * as React from 'react';


const IdleIcon = (props: any) => <svg
  width={47}
  height={47}
  xmlns="http://www.w3.org/2000/svg"
  xmlnsXlink="http://www.w3.org/1999/xlink"
  {...props}
>

  <defs>

    <ellipse id="b" cx={11.831} cy={11.5} rx={11} ry={11.5} />

    <filter
      x="-52.2%"
      y="-52.2%"
      width="204.3%"
      height="204.3%"
      filterUnits="objectBoundingBox"
      id="a"
    >

      <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />

      <feGaussianBlur
        stdDeviation={3}
        in="shadowOffsetOuter1"
        result="shadowBlurOuter1"
      />

      <feColorMatrix
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
        in="shadowBlurOuter1"
        result="shadowMatrixOuter1"
      />

      <feMerge>

        <feMergeNode in="shadowMatrixOuter1" />

        <feMergeNode in="SourceGraphic" />
      </feMerge>
    </filter>
  </defs>

  <g
    filter="url(#a)"
    transform="translate(12 12)"
    fill="none"
    fillRule="evenodd"
  >

    <mask id="c" fill="#fff">

      <use xlinkHref="#b" />
    </mask>

    <use fill="#46A4FF" xlinkHref="#b" />

    <g mask="url(#c)" fill="#FFA300">

      <path d="M-12-11.5h48v45h-48z" />
    </g>
  </g>
</svg>;

export default IdleIcon;
