// @ts-nocheck
import {
  compose,
  withStateHandlers,
  HOC,
} from 'recompose';

import SortUI from './Sort.ui';
import type { SearchProps } from '../types';

export const projectSortOptions = [
  { id: 'CreatedAt', name: 'Created At', sortProp: 'createdAt' },
  { id: 'Company', name: 'Company', sortProp: 'assignedBy' },
  { id: 'Customer', name: 'Customer', sortProp: 'client' },
  { id: 'Foreman', name: 'Foreman', sortProp: 'foreman' },
  { id: 'JobNum', name: 'Job #', sortProp: 'jobNumber' },
];

export const driverSortOptions = [
  { id: 'Driver', name: 'Driver', sortProp: 'driver' },
  { id: 'Company', name: 'Company', sortProp: 'company' },
  { id: 'Project', name: 'Project', sortProp: 'project' },
  { id: 'Time', name: 'Start Time', sortProp: 'startTime' },
  { id: 'PickUp', name: 'Pick Up', sortProp: 'pickUp' },
  { id: 'Availability', name: 'Availability', sortProp: 'availability' },
];

export const requestSortOptions = [
  { id: 'Company', name: 'Company', sortProp: 'project.companyName' },
  { id: 'Customer', name: 'Customer', sortProp: 'project.client' },
  { id: 'Foreman', name: 'Foreman', sortProp: 'project.foreman' },
  { id: 'JobNum', name: 'Job #', sortProp: 'project.jobNumber' },
];

const SortHOC: HOC<any, SearchProps> = compose(
  withStateHandlers(
    () => ({
      sortOption: null,
      sortIsOpen: false,
    }),
    {
      setSortBox: () => newState => ({ sortIsOpen: newState }),
      onSortClick: ({ sortIsOpen }) => () => ({ sortIsOpen: !sortIsOpen }),

      onSelectSort: (state, { onSort }) => (selectedOption) => {
        // On Select Sort
        if (onSort) {
          onSort(selectedOption);
        }

        return {
          sortIsOpen: false,
          sortOption: selectedOption,
        };
      },
    },
  ),
);

export default SortHOC(SortUI);
