// @ts-nocheck
import { useMutation, gql } from '@apollo/client';
import PhoneNumber from 'awesome-phonenumber';
import { useCallback } from 'react';

import { useCompanyContext } from 'utils/Hooks';
import type { ExternalGuestInput } from './useAddCollaborator';

const EDIT_COLLABORATOR_MUTATION = gql`
  mutation editExternalGuest($companyID: ID!, $guestID: ID!, $input: ExternalGuestInput!) {
    editExternalGuest(companyID: $companyID, guestID: $guestID, input: $input) {
      id
      accountId
      firstName
      lastName
      roles
      companyName : externalCompanyName
      phoneNumber
      email
      roles
    }
  }
`;

const useEditCollaborator = () => {
  const [editCollaborator] = useMutation(EDIT_COLLABORATOR_MUTATION);
  const { selectedCompanyID } = useCompanyContext();
  return useCallback((id: string, values: ExternalGuestInput) => {
    const phoneNumber = new PhoneNumber(values.phoneNumber, 'CA').getNumber('e164');
    return editCollaborator({
      variables: {
        companyID: selectedCompanyID,
        guestID: id,
        input: {
          ...values,
          phoneNumber,
        },
      },
    }).then(({ data }) => {
      return data.editExternalGuest;
    });
  }, [editCollaborator, selectedCompanyID]);
};

export default useEditCollaborator;
