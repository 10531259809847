// @ts-nocheck
import classNames from 'classnames';
import React, { useState, useRef, ReactNode } from 'react';
import { intlShape } from 'react-intl';
import { Button } from 'common-ui/Button';

import { ChevronLeft } from 'common-ui/Icon';

import { injectIntlHOC } from 'utils/HOC';
import { useOutsideClickHandler } from 'utils/Hooks';
import styles from './styles.module.scss';


type PopupProps = {

  button: ({ setMenuOpen: (param:boolean) => void, menuOpen: boolean }),

  body: ({ setMenuOpen: (param:boolean) => void, menuOpen: boolean }),
  leftAligned?: boolean,
  id?: string,
  className?: any,
};

const Popup = ({
  button, body, leftAligned, id, className,
}: PopupProps) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const popupRef = useRef();
  useOutsideClickHandler(popupRef, () => setMenuOpen(false));

  return (

    <div id={id} className={styles.popupContainer} ref={popupRef}>

      {button({ setMenuOpen, menuOpen })}

      <div

        className={classNames(styles.popupBox, className, {
          [styles.open]: menuOpen,
          [styles.left]: leftAligned,
        })}
      >

        {body({ setMenuOpen, menuOpen })}
      </div>
    </div>
  );
};

Popup.defaultProps = {
  leftAligned: false,
  id: undefined,
  className: undefined,
};

type ConfirmationPopupProps = {
  children: ReactNode,

  intl: intlShape,
  shouldPrompt: boolean,
  onConfirm: () => void,

  buttonComponent: React$Element<typeof Button>,
  openClassName: string,
  onNext?: () => void | null,
  onCustomBack?: () => void | null,
  customBackButtonLabel: string,
  headerText?: string,
};

const ConfirmationPopupBase = ({
  children,
  intl,
  shouldPrompt,
  onConfirm,
  buttonComponent,
  openClassName,
  onNext,
  onCustomBack,
  customBackButtonLabel,
  headerText,
}: ConfirmationPopupProps) => (

  <Popup

    button={({ setMenuOpen, menuOpen }) => (
      React.cloneElement(buttonComponent, {

        className: classNames({ [openClassName]: menuOpen }, buttonComponent.props.className),
        onClick: () => {
          if (shouldPrompt) setMenuOpen(!menuOpen);
          else onConfirm();
        },
      })
    )}

    body={({ setMenuOpen }) => (

      <>

        <div className={styles.textRegularLabel}>
          {headerText || intl.formatMessage({ id: 'modals.confirm_header', defaultMessage: 'Are you sure?' })}
        </div>
        {children}

        <div className={styles.confirmationPopupControls}>
          <Button
            text={onCustomBack ? customBackButtonLabel : intl.formatMessage({ id: 'modals.cancel_button', defaultMessage: 'Cancel' })}
            className={styles.confirmationPopupBackButton}
            icon={<ChevronLeft />}
            onClick={() => {
              if (onCustomBack) {
                onCustomBack();
              }
              setMenuOpen(false);
            }}
          />
          {React.cloneElement(buttonComponent, {
            onClick: () => {
              if (onNext) {
                onNext();
              } else {
                onConfirm();
                setMenuOpen(false);
              }
            },
          })}
        </div>
      </>
    )}
  />
);

ConfirmationPopupBase.defaultProps = {
  onNext: null,
  onCustomBack: null,
  headerText: '',
};

export const ConfirmationPopup = injectIntlHOC()(ConfirmationPopupBase);

export default Popup;
