// @ts-nocheck
import React from 'react';
import { Button } from 'common-ui/Button';

import { usePermissions } from 'common-ui/Hooks';

import { Edit, Send, Truck, View } from 'common-ui/Icon';
import type { ModifiedProjectType } from 'pages/Projects/types';
import { useCompanyContext } from 'utils/Hooks';
import styles from './styles.module.scss';


type MobileProjectActionProps = {
  project: ModifiedProjectType,
  onEdit?: (project:ModifiedProjectType) => void,
  onRequest?: (project:ModifiedProjectType) => void,
  onAssignHires?: (project:ModifiedProjectType) => void,
  setVisible?: (param:boolean) => void,
};

const MobileProjectActions = ({
  project, onEdit, onRequest, onAssignHires, setVisible,
} : MobileProjectActionProps) => {
  const { selectedCompanyID } = useCompanyContext();
  const {
    EditAllProjects,
    EditOwnProjects,
    CreateInternalRequests: canCreateInternalRequests,
  } = usePermissions(selectedCompanyID);

  const canEditProjects = EditAllProjects || EditOwnProjects;

  return (

    <>

      <div className={styles.textSubheader}> Project Actions </div>

      {canCreateInternalRequests && (

        <Button
          text="Request Project"
          icon={<Send />}
          className={styles.mobileActionButton}
          onClick={() => {

            onRequest(project);

            setVisible(false);
          }}
        />
      )}


      <Button
        text={(!project.hasEnded && canEditProjects) ? 'Edit Project' : ' View Project'}

        icon={(!project.hasEnded && canEditProjects) ? <Edit /> : <View />}
        className={styles.mobileActionButton}
        onClick={() => {

          onEdit(project);

          setVisible(false);
        }}
      />

      {(!project.hasEnded && canEditProjects) && (

        <Button
          text="Assign Hires"
          icon={<Truck />}
          className={styles.mobileActionButton}
          onClick={() => {

            onAssignHires(project);

            setVisible(false);
          }}
        />
      )}
    </>
  );
};

MobileProjectActions.defaultProps = {
  onEdit: () => {},
  onRequest: () => {},
  onAssignHires: () => {},
  setVisible: () => {},
};

export default MobileProjectActions;
