// @ts-nocheck
import { useState, useEffect, useRef } from 'react';

/**
 * This hook allows for executing a certain callback after a rerender of the page
 * You shouldn't need to use this unless you're doing really weird stuff with promises
 * If you are, you can use this to update some values in one step of a promise, then
 * trigger a rerender of the page, then execute the next step of a promise with updated state
 *
 * Usage:
 * const someCallback = useCallback((someparams) => doSomeStuff(someparams), [dependencies]);
 * const execute = useExecuteAfterRender(someCallback);
 * ...
 * doSomethingToUpdateThePageState()
 *   .then(execute);
 *
 * The above code will cause the callback to be executed with the data that has been updated after
 * the changes made by the first function in the promise chain
*/

const useExecuteAfterRender = (callback: (any) => void) => {
  const params = useRef([]);
  const [shouldExecute, setShouldExecute] = useState(false);
  useEffect(() => {
    if (shouldExecute) {

      callback(...params.current);
      setShouldExecute(false);
      params.current = [];
    }
  }, [shouldExecute, callback]);
  return (...calledParams: any) => {
    params.current = calledParams;
    setShouldExecute(true);
  };
};

export default useExecuteAfterRender;
