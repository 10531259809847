// @ts-nocheck
import { fromRenderProps } from 'recompose';

import { CompanyContext } from 'utils/Hooks';

const withCompanyContext = (

  fromRenderProps(CompanyContext.Consumer, ctx => ({ selectedCompanyID: ctx.selectedCompanyID }))
);

export default withCompanyContext;
