import React, {SVGProps} from 'react';

const ZoomIn = (props: SVGProps<SVGSVGElement>) => (

  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >

    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.03716 14.0743C8.64211 14.0743 10.1216 13.537 11.3055 12.6325L16.3993 17.7263C16.7647 18.0917 17.3569 18.0898 17.7233 17.7234C18.0923 17.3544 18.0911 16.7642 17.7262 16.3994L12.6325 11.3056C13.537 10.1217 14.0743 8.64215 14.0743 7.03716C14.0743 3.15064 10.9237 0 7.03716 0C3.15064 0 0 3.15064 0 7.03716C0 10.9237 3.15064 14.0743 7.03716 14.0743ZM1.87668 7.03698C1.87668 9.88709 4.18716 12.1976 7.03727 12.1976C9.88738 12.1976 12.1979 9.88709 12.1979 7.03698C12.1979 4.18687 9.88738 1.87639 7.03727 1.87639C4.18716 1.87639 1.87668 4.18687 1.87668 7.03698Z"
      fill="#4A4A4A"
    />

    <rect
      x="6.26099"
      y="3.9126"
      width="1.56522"
      height="6.26087"
      rx="0.782609"
      fill="#4A4A4A"
    />

    <rect
      x="3.91309"
      y="7.82617"
      width="1.56522"
      height="6.26087"
      rx="0.782609"
      transform="rotate(-90 3.91309 7.82617)"
      fill="#4A4A4A"
    />
  </svg>
);

export default ZoomIn;
