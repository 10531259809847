// @ts-nocheck
import { useFormikContext } from 'formik';
import React from 'react';


import { FormField } from 'common-ui/Form';
import CollaboratorInput from './CollaboratorInput';


type FormikCollaboratorInputProps = {
  label: string,
  name: string,
  id?: string,
  readOnly?: boolean,
};

const FormikCollaboratorInput = ({
  label,
  name,
  id,
  readOnly,
}: FormikCollaboratorInputProps) => {
  const { values, setFieldValue } = useFormikContext();

  return (

    <FormField label={label} id={id}>
      {!readOnly ? (

        <CollaboratorInput
          values={values[name] || []}
          onChange={value => setFieldValue(name, value)}
        />
      ) : (
        values?.collaborators?.map((c: any) => `${c.firstName} ${c.lastName}`)
          .join(', ')
      )}
    </FormField>
  );
};

FormikCollaboratorInput.defaultProps = {
  id: '',
  readOnly: false,
};

export default FormikCollaboratorInput;
