import React, {SVGProps} from 'react';

const CyclePlay = (props: SVGProps<SVGSVGElement>) => (

  <svg
    width="51"
    height="50"
    viewBox="0 0 51 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >

    <circle cx="25.6155" cy="24.9231" r="24.9231" fill="#F6CC46" />

    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.3076 13.8462V35.8892L37.179 24.8677L17.3076 13.8462Z"
      fill="#4A4A4A"
    />
  </svg>
);

export default CyclePlay;
