// @ts-nocheck
import { graphql } from '@apollo/client/react/hoc';
import { compose, withHandlers, HOC } from 'recompose';
import { CompressIfImage } from '../ImageManipulation';
import FETCH_UPLOAD_URL from './graphql';

type UploadImagePropsType = {
  mutate: any,

  files: Array<any>,
  companyID: string | number,
  domain: string,
  type: string
};

const UploadImage = ({
  mutate,
  files,
  companyID,
  domain,
  type,
}: UploadImagePropsType) => {
  const fileNames = Array.from(files).map((file) => {
    if (!file) {
      return '';
    }

    return file.name ? file.name : '';
  });


  let finalFetchUploadUrl;

  // STEP 1: fetch presigned upload urls
  return mutate({
    variables: {
      input: {
        companyID,
        domain,
        type,
        fileNames,
        quantity: files.length,
      },
    },
  })

    .then(({ data: { fetchUploadURL } }) => {
      // check requested quantities match
      if (fetchUploadURL.length !== files.length) {
        const error = 'Error requesting upload urls';

        throw error;
      }
      finalFetchUploadUrl = fetchUploadURL;

      // STEP 2: upload files to urls
      return Promise.all(

        fetchUploadURL.map((url, idx) => (
          CompressIfImage(files[idx]).then(file => (
            fetch(url.putURL, {
              method: 'PUT',

              body: file,
            })))
        )),
      );
    })
    .then(() => {
      // STEP 3: set this components value

      const newValue = finalFetchUploadUrl.map(url => ({
        id: url.id,
        url: url.getURL,
      }));

      return { uploadedFileValues: newValue };
    })

    .catch((err) => {
      throw err;
    });
};

type UploadToAwsHocProps = {
  companyID: string,
  domain: string,
  type: string,

  onUploaded: (param:any) => any,
  multiple: boolean
};

const UploadToAwsHoc: HOC<any, UploadToAwsHocProps> = compose(
  graphql(FETCH_UPLOAD_URL),
  withHandlers({

    UploadFile: ({
      mutate, companyID, domain, type, onUploaded,
    }) => ({

      files,
    }) => UploadImage({
      mutate,
      files,
      companyID,
      domain,
      type,

      onUploaded,
    }),
  }),
);

export default UploadToAwsHoc;
