// @ts-nocheck

import { withApollo } from '@apollo/client/react/hoc';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { compose, withHandlers } from 'recompose';

import { post } from 'utils/axios';

class Logout extends Component {
  constructor(props: any) {
    super(props);
    this.state = { didLogout: false };
  }

  componentDidMount() {
    // eslint-disable-next-line react/prop-types
    const { client, logout } = this.props;
    logout();
    window.Intercom('shutdown');
    // eslint-disable-next-line react/prop-types
    client.resetStore();
    this.setState({ didLogout: true });
  }

  render() {
    const { didLogout } = this.state;

    return didLogout ? <Redirect to="/login" /> : null;
  }
}

export default compose(
  withApollo,
  withHandlers({
    logout: () => () => post('token/clear'),
  }),
)(Logout);
