// @ts-nocheck
import React, { ReactNode } from 'react';
import { intlShape } from 'react-intl';
import { Button } from 'common-ui/Button';

import ID from 'common-ui/ConsistentIDs';
import { Download } from 'common-ui/Icon';

import { LoadingSpinner } from 'components/Common/LoadingSpinner';
import { injectIntlHOC } from 'utils/HOC';
import styles from './styles.module.scss';




type DownloadModalProps = {
  onDownload: () => void,
  onClose: () => void,

  intl: intlShape,
  textHeader: ReactNode,
  textBody: ReactNode,
  children: ReactNode,
  loading?: boolean,
  downloadButtonID?: string,
};

const DownloadModal = ({
  onDownload,
  onClose,
  textHeader,
  textBody,
  intl,
  children,
  loading,
}: DownloadModalProps) => {
  const downloadText = loading
    ? intl.formatMessage({ id: 'modal_templates.download.loading_button', defaultMessage: 'Downloading...' })
    : intl.formatMessage({ id: 'modal_templates.download.download_button', defaultMessage: 'Download' });
  return (

    <div className={styles.baseModal}>

      <div className={styles.baseModalHeader}>

        <div className={styles.textHeader}>
          {textHeader}
        </div>
      </div>

      <div className={styles.textBody}>
        {textBody}
      </div>
      {children}

      <div className={styles.baseModalButtons}>

        <Button
          id={ID.create(
            ID.Areas.Tickets,
            ID.Types.Button,
            ID.Labels.CancelDownloadButton,
          )}
          text={intl.formatMessage({ id: 'modals.cancel_button', defaultMessage: 'Cancel' })}
          onClick={onClose}
        />
        {loading ? (
          <div className={styles.loading}>
            <LoadingSpinner small className={styles.loadingSpinner} />
            {downloadText}
          </div>
        ) : (
          <Button
            id={ID.create(
              ID.Areas.Tickets,
              ID.Types.Button,
              ID.Labels.ConfirmDownloadButton,
            )}
            text={downloadText}
            styleName="primary"
            icon={<Download />}
            onClick={onDownload}
          />
        )}
      </div>
    </div>
  );
};

DownloadModal.defaultProps = {
  loading: false,
  downloadButtonID: undefined,
};

export default injectIntlHOC()(DownloadModal);
