import React, {SVGProps} from 'react';

const Warning = (props: SVGProps<SVGSVGElement>) => {
  const exclamationColor = props?.stroke || '#4A4A4A';

  return (

    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >

      <circle
        cx="12"
        cy="12"
        r="9"
        fill="white"
        stroke={exclamationColor}
        strokeWidth="2"
      />

      <circle cx="12" cy="16" r="1" fill={exclamationColor} />

      <rect x="11" y="7" width="2" height="7" rx="1" fill={exclamationColor} />
    </svg>
  );
};

export default Warning;
