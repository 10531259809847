// @ts-nocheck

import { withStateField } from 'utils/HOC';

/*
  This HOC is a helper HOC to be used with any component
  that requires setting visibilty
  It's a simple Hooks-like implementation where we
  return a stateful object containing the applicable state field
  and stateful logic, keyed by the name of the component

  An example state field:
  {
    projectDetailsSidebar: {
      visible: false,
      setVisible:  (param:boolean) => void,
    }
  }
*/

export type withVisibilityType = {
  visible: boolean,
  setVisible:  (param:boolean) => void,
};

const withVisible = (
  componentName: string,
  defaultValue = false,
) => withStateField(componentName, 'visible', defaultValue);

export default withVisible;
