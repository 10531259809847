// @ts-nocheck
import React from 'react';
import { fromRenderProps } from 'recompose';
import { useOpenableContent, TreadModal } from 'utils/Hooks';

const OverlaidModal = ({
  children
}: any) => {
  const [visible, modalContent, openModal, closeModal] = useOpenableContent();
  return (

    <>
      <TreadModal visible={visible} closeModal={closeModal}>
        {modalContent}
      </TreadModal>
      {children({ openModal, closeModal })}
    </>
  );
};

export default fromRenderProps(
  OverlaidModal,

  ({ openModal, closeModal }) => ({ openModal, closeModal }),
);
