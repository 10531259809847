// @ts-nocheck
import React, {Fragment} from 'react';

import {EmptyMap, BaseMap, LiveMarkers} from 'components/Common/Map';

import {SiteMarker, useLimitedSites} from 'components/Common/Map/SiteMarkers';
import {makeMarkers} from 'components/Common/Map/utils/marker.utils';
import {MapEditContextProvider} from 'components/Common/Map/utils/useEditableMap';

import useSiteIdUrl from 'pages/Sites/Hooks/useSiteIdUrl';
import Legend from './Legend';
import styles from './styles.module.scss';
import type {LiveMapProps} from '../types';



//

const LiveMap = ({
  liveMapLoading,
  filters,
  onRefresh,
  mobile,
  intl,
  calcMarkers,
  sites,
  openMarkerId,
  openPopup,
  bounds,

  toggleMarkerOpen,
  showTraffic,
  showSatellite,
  noRefreshButton,
  noZoomButtons,
}: LiveMapProps) => {
  const [selectedSiteId, setSelectedSiteId] = useSiteIdUrl();

  // Limit Live map to show 200 sites
  const limitedSites = useLimitedSites(sites, selectedSiteId, 200);
  const siteMarkers = makeMarkers(limitedSites);

  if (!calcMarkers || calcMarkers.length === 0) {
    let text = intl.formatMessage({
      id: 'live.live_map.empty_map_text',
      defaultMessage: 'You aren’t able to view live projects at this time.',
    });
    if (liveMapLoading) {
      text = intl.formatMessage({
        id: 'live.live_map.loading_text',
        defaultMessage: 'Loading',
      });
    } else if (filters && filters.length > 0) {
      text = intl.formatMessage({
        id: 'live.live_map.no_drivers_text',
        defaultMessage: 'No drivers found. Please try another search.',
      });
    }
    return (

      <Fragment>

        <div className={styles.emptyMap}>
          <div className={styles.topShadow} />
          <EmptyMap text={text} isLoading={liveMapLoading} />
        </div>
      </Fragment>
    );
  }

  return (

    <MapEditContextProvider>

      <div className={styles.liveMap}>

        <div className={styles.topShadow} />

        <div className={styles.mapContainer}>
          <BaseMap
            onClickRefresh={onRefresh}
            onClickMap={() => {
              toggleMarkerOpen(0);
              setSelectedSiteId(null);
            }}
            {...{
              openPopup,
              bounds,
              showTraffic,
              showSatellite,
              noRefreshButton,
              noZoomButtons,
            }}
            noLayerControl={mobile}
          >
            <LiveMarkers
              {...{
                markers: calcMarkers,
                sites,
                openMarkerId,
                toggleMarkerOpen,
                selectedSiteId,
                setSelectedSiteId,
                intl,
              }}
            />
            {siteMarkers.map((m: any) => <SiteMarker
              key={`sitemarker-${m.id}`}
              marker={m}
              onClick={(e: any) => {
                e.stopPropagation();
                if (m.id !== selectedSiteId) {
                  toggleMarkerOpen(null);
                  setSelectedSiteId(m.id);
                } else {
                  setSelectedSiteId(null);
                }
              }}
              showGeofence
              popupCanOpen
              popupIsOpen={m.id === selectedSiteId}
            />)}
          </BaseMap>
        </div>

        {!mobile && <Legend intl={intl} />}
      </div>
    </MapEditContextProvider>
  );
};

LiveMap.defaultProps = {
  calcMarkers: [],
  openMarkerId: null,
  toggleMarkerOpen: null,
  onRefresh: null,
};

export default LiveMap;
