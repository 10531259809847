// @ts-nocheck
import { connect } from 'formik';
import React from 'react';


import { FormField, AddressInput } from 'components/Common/Form';

type FormikTextInputProps = {
  formik: any,
  label: string,
  required?: boolean,
  name: string,
  readOnly?: boolean,
};

const FormikTextInput = ({
  formik,
  label,
  required,
  name,
  readOnly,
  ...inputProps
}: FormikTextInputProps) => (

  <FormField
    label={label}
    required={required}
    error={formik.touched[name] && formik.errors[name]}
  >
    {!readOnly ? (

      <AddressInput
        value={formik.values[name]}
        onChange={(value: any) => formik.setFieldValue(name, value)}
        onBlur={() => formik.setFieldTouched(name)}
        name={name}
        {...inputProps}
      />
    ) : (

      <>
        {formik.values[name] || '-'}
      </>
    )}
  </FormField>
);

FormikTextInput.defaultProps = {
  required: false,
  readOnly: false,
};

export default connect(FormikTextInput);
