// @ts-nocheck
import { useQuery, gql } from '@apollo/client';
import { useMemo } from 'react';

import { useWhoAmI } from 'common-ui/Hooks/useWhoAmI';
import useCompanyContext from './useCompanyContext';

const HIRE_ROLES_QUERY = gql`
  query HireRolesQuery($accountID: ID!, $companyID: ID!) {
    hire(accountID: $accountID, companyID: $companyID) {
      id
      accountId
      companyId
      roles
    }
  }
`;

const useUserRoles = () => {
  const { selectedCompanyID } = useCompanyContext();
  const [{ id: accountID }] = useWhoAmI();
  const { data, ...rest } = useQuery(HIRE_ROLES_QUERY, {
    variables: {
      accountID,
      companyID: selectedCompanyID,
    },

    skip: !selectedCompanyID || selectedCompanyID === '0',
  });

  return useMemo(() => {
    const roles = (data && data.hire && data.hire.roles) || [];
    return [roles, rest];
  }, [data, rest]);
};

export default useUserRoles;
