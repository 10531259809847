import { useState, useCallback, createContext, ReactNode } from 'react';

const useOpenableContent = () => {
  const [visible, setVisible] = useState(false);
  const [component, setComponent] = useState<ReactNode>(undefined);

  const openContent = useCallback((displayComponent: ReactNode) => {
    setVisible(true);

    setComponent(displayComponent);
  }, [setVisible, setComponent]);

  const closeContent = useCallback(() => setVisible(false), [setVisible]);

  return [visible, component, openContent, closeContent];
};

export default useOpenableContent;

export const OpenableContext = createContext({
  openSidebar: () => {},
  closeSidebar: () => {},
  inListView: false,
});
