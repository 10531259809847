import React, {SVGProps} from 'react';

const Add = (props: SVGProps<SVGSVGElement>) => (
   
  <svg width={22} height={22} viewBox="0 0 22 22" {...props}>
     
    <title>Add</title>
     
    <path
      d="M9.9 9.9H6.594a1.1 1.1 0 0 0 0 2.2H9.9v3.306a1.1 1.1 0 0 0 2.2 0V12.1h3.306a1.1 1.1 0 0 0 0-2.2H12.1V6.594a1.1 1.1 0 0 0-2.2 0V9.9zM11 22c6.075 0 11-4.925 11-11S17.075 0 11 0 0 4.925 0 11s4.925 11 11 11zm0-2.2A8.8 8.8 0 0 1 2.2 11 8.8 8.8 0 0 1 11 2.2a8.8 8.8 0 0 1 8.8 8.8 8.8 8.8 0 0 1-8.8 8.8z"
      fill="#162093"
      fillRule="evenodd"
    />
  </svg>
);

export default Add;
