// @ts-nocheck
import classNames from 'classnames';

import { Form, useFormikContext } from 'formik';
import { isEqual } from 'lodash';
import React, { useState, useEffect } from 'react';







import { Steps } from '../utils';
import { Button, TextOnlyButton } from 'common-ui/Button';
import { MultiDoc } from 'common-ui/Icon';
import {  ProjectSharingLink,  ProjectSharingLinkSettings } from 'common-ui/types';
import { BasicFlag, useFlag, FLAG_POSITIONS } from 'components/Common/Flag';
import { Toggle } from 'components/Common/Form';
import { LoadingSpinner } from 'components/Common/LoadingSpinner';
import Popup from 'components/Common/Popup';
import { useDetectMobile } from 'utils/Hooks';
import EditPermissionsPopup from './EditPermissionsPopup';
import styles from './styles.module.scss';

const copyToastID = 'copyToastID';

const CopyToast = () => (

  <BasicFlag text="Link Copied" />
);

const transformSettings = (values: any) => {
  return (
    {
      driverName: values.driverName,
      phoneNumber: values.phoneNumber,
      loggingStatus: values.loggingStatus,
      loggingTimestamps: values.loggingTimestamps,
      licensePlate: values.licensePlate,
    }
  );
};

const settingsChanged = (link: any, values: any) => {
  delete link.settings.__typename;
  return !isEqual(link.settings, transformSettings(values));
};

type LinkShareModalContentProps = {
  projectSharingLink: ProjectSharingLink,

  updateProjectSharingLinkSettings: (string, boolean, ProjectSharingLinkSettings) => void,
  currentStep: string,

  openPageDisplay: (param:string) => void,
}

const LinkShareModalContent = ({
  projectSharingLink,
  updateProjectSharingLinkSettings,
  currentStep,
  openPageDisplay,
}: LinkShareModalContentProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [shouldUpdateValues, setShouldUpdateValues] = useState(false);
  const isMobile = useDetectMobile();
  const { addFlag } = useFlag();

  const { values } = useFormikContext();
  const projectSharingLinkDisabled = !projectSharingLink || (projectSharingLink && !projectSharingLink.enabled);

  useEffect(() => {
    if (!isMenuOpen && projectSharingLink && settingsChanged(projectSharingLink, values)) {
      setShouldUpdateValues(true);
    }
  }, [isMenuOpen]);

  if (shouldUpdateValues && projectSharingLink) {
    updateProjectSharingLinkSettings(
      projectSharingLink.id,
      projectSharingLink.enabled,
      transformSettings(values),
    );
    setShouldUpdateValues(false);
  }

  return (

    <Form>
      {currentStep === Steps.editPermissions && (

        <EditPermissionsPopup onSave={() => {
          updateProjectSharingLinkSettings(
            projectSharingLink.id,
            projectSharingLink.enabled,
            transformSettings(values),
          );
          openPageDisplay(Steps.shareProject);
        }}
        />
      )}
      {(currentStep === Steps.shareProject || !isMobile) && (

        <>
          {isMobile && (
            <div className={styles.linkHeader}>
              <span>Link Sharing</span>
              <Toggle
                checked={projectSharingLink && projectSharingLink.enabled}
                onChange={() => projectSharingLink && updateProjectSharingLinkSettings(
                  projectSharingLink.id,
                  !projectSharingLink.enabled,
                  projectSharingLink.settings,
                )}
                slim
              />
            </div>
          )}
          <div className={styles.description}>
            Share a link to any non-Tread users and edit their viewing permissions.
          </div>
          <div className={styles.linkContainer}>
            <input

              className={classNames(styles.linkBox, {
                [styles.disabled]: projectSharingLinkDisabled,
              })}
              value={projectSharingLink && projectSharingLink.link}
              disabled={projectSharingLinkDisabled}
            />
            <Button
              text="Copy Link"
              icon={<MultiDoc />}
              className={styles.headerButton}
              disabled={!projectSharingLink || (projectSharingLink && !projectSharingLink.enabled)}
              onClick={() => {
                addFlag(copyToastID, CopyToast, FLAG_POSITIONS.bottomCenter);
                // Note that we cannot access clipboard locally for security reasons
                return navigator.clipboard
                  && navigator.clipboard.writeText(projectSharingLink && projectSharingLink.link);
              }}
            />
          </div>
          {isMobile && (
            <div className={styles.editButtons}>
              <TextOnlyButton
                disabled={!projectSharingLink
                  || (projectSharingLink && !projectSharingLink.enabled)}
                text="Edit Link Permissions"
                className={styles.manageButton}
                onClick={() => openPageDisplay(Steps.editPermissions)}
              />
            </div>
          )}
          {!isMobile && (
            <Popup
              leftAligned
              button={({
                setMenuOpen,
                menuOpen
              }: any) => (
                <div className={styles.editButtons}>
                  <TextOnlyButton
                    text="Edit Link Permissions"
                    className={styles.manageButton}
                    onClick={() => setMenuOpen(!menuOpen)}
                    disabled={!projectSharingLink
                      || (projectSharingLink && !projectSharingLink.enabled)}
                  />
                </div>
              )}
              body={({
                menuOpen
              }: any) => {
                setIsMenuOpen(menuOpen);
                return (
                  <EditPermissionsPopup />
                );
              }}
            />
          )}
        </>
      )}
    </Form>
  );
};

export default LinkShareModalContent;
