// @ts-nocheck

import { Areas } from './Areas';
import create from './Create';
import { Labels } from './Labels';
import { Types } from './Types';

const ID = {
  Areas,
  Types,
  Labels,
  create,
};

export default ID;
