// @ts-nocheck
import classNames from 'classnames';
import React, { ReactNode, useRef, useState } from 'react';
import {
  useOutsideClickHandler,
  usePreselectValue,
  useInputFilteredOptions,

} from 'utils/Hooks';
import styles from './styles.module.scss';

type Option = {
  name: string,
  value: string,
  label: string,
}

type ObjectOrTextInputPropTypes = {
  formik: FormikProps,
  idField: string,
  textField: string,
  options: Array<Option>,
  embedded?: boolean,
  readOnly?: boolean,
  placeholder: string,
  Icon: any,
  id?: string,
  CustomOnSelectLayout?: ()=>Record<string, unknown>,
  CustomOnSelect?: ()=>Record<string, unknown>,
  CustomFilter?: ()=>Record<string, unknown>,
  CustomLayout?: ReactNode,
};

// ObjectOrTextInput is a generic input form that displays objects
// but also allows typing freeform text
// If freeform text is provided, the textField field is populated with the text
// otherwise if an object is selected, the idField is populated with the selected object's ID
const ObjectOrTextInput = ({
  formik,
  idField,
  textField,
  options,
  embedded,
  Icon,
  placeholder,
  id,
  CustomOnSelectLayout, // custom layout for the selected input value
  CustomOnSelect, // custom behaviour for selecting a value on dropdown
  CustomFilter, // custom search filter
  CustomLayout, // custom layout for the dropdown window
}: ObjectOrTextInputPropTypes) => {
  const inputRef = useRef();
  const outerRef = useRef();
  const [autocompleteHidden, setAutocompleteHidden] = useState(true);
  // HoveredIndex refers to the index of the options list that is
  // being hovered over by the user either using arrowkeys or the mouse
  // This is defaulted to -1 because we cannot set a valid option index default
  // (e.g. 0) unless the autocompleted window is triggered,
  // otherwise hitting Enter while in this input will immediately select
  // the first option available instead of just submitting the form
  const [hoveredIndex, setHoveredIndex] = useState(-1);
  const [selectedValue, setSelectedValue] = useState(null);

  // focus the input if the icon was pressed
  const setFocus = () => {
    setAutocompleteHidden(false);

    return inputRef.current && inputRef.current.focus();
  };

  useOutsideClickHandler(outerRef, () => {
    setHoveredIndex(-1);
    setAutocompleteHidden(true);
  }, []);

  usePreselectValue(options, formik.values[idField], selectedValue, setSelectedValue);
  const filteredOptions = CustomFilter

    ? CustomFilter(formik.values[textField], selectedValue, options)
    : useInputFilteredOptions(formik.values[textField], selectedValue, options);


  const onChangeText = (e) => {
    formik.setFieldValue(textField, e.target.value);
  };


  const onSelectOption = (o) => {
    if (CustomOnSelect) {

      CustomOnSelect(formik, idField, textField, o);
    } else {
      formik.setFieldValue(textField, o.label);
      if (o && o.value) {
        formik.setFieldValue(idField, o.value);
      }
    }
    setSelectedValue(o);
    setAutocompleteHidden(true);
  };

  const displayValue = () => {
    if (selectedValue) {
      if (CustomOnSelectLayout) return '';

      return selectedValue.label;
    }
    return formik.values[textField];
  };

  return (

    <div
      role="button"
      tabIndex={-1} // removes this out of focus to avoid double tabbing

      className={classNames(styles.objectTextField)}
      onKeyDown={(e) => {
        switch (e.key) {
          case 'ArrowDown':
            e.preventDefault();
            setAutocompleteHidden(false);
            setHoveredIndex(
              options && options.length > 0 && hoveredIndex < options.length - 1
                ? hoveredIndex + 1
                : 0,
            );
            break;
          case 'ArrowUp':
            e.preventDefault();
            setAutocompleteHidden(false);
            setHoveredIndex(
              options && options.length > 0 && hoveredIndex > 0
                ? hoveredIndex - 1
                : (options.length && options.length - 1) || 0,
            );
            break;
          case 'Enter':
            if (!autocompleteHidden && hoveredIndex >= 0) {
              e.preventDefault();
              onSelectOption(filteredOptions[hoveredIndex]);
              setAutocompleteHidden(true);
            }
            break;
          case 'Escape':
            e.preventDefault();
            setAutocompleteHidden(true);
            setHoveredIndex(-1);
            break;
          default:
            break;
        }
      }}

      ref={outerRef}
    >

      <div aria-hidden="true" onClick={setFocus} onKeyDown={setFocus}>
        {selectedValue && Icon && (!CustomOnSelectLayout
          ? <Icon className={styles.objectSelectedIcon} />
          : <CustomOnSelectLayout selectedValue={selectedValue} />
        )}
      </div>

      <input
        id={id}

        className={classNames(styles.objectTextInputField, {
          [styles.embedded]: embedded,
          [styles.focused]: !autocompleteHidden,
          [styles.autocompleted]: selectedValue && !CustomOnSelectLayout,
          [styles.setcolor]: embedded,
          [styles.customOnSelect]: selectedValue && CustomOnSelectLayout,
        })}
        placeholder={placeholder}

        ref={inputRef}
        autoComplete="off"
        value={displayValue()}
        onClick={() => {
          setAutocompleteHidden(false);
        }}
        onChange={(e) => {
          setAutocompleteHidden(false);
          onChangeText(e);
        }}
        onBlur={() => {
          formik.setFieldTouched(textField);
        }}
        onKeyDown={(e) => {
          switch (e.key) {
            case 'Home':
              e.preventDefault();
              e.currentTarget.scrollLeft = 0;
              e.currentTarget.setSelectionRange(0, 0);
              break;
            case 'End':
              e.preventDefault();
              e.currentTarget.scrollLeft = e.currentTarget.scrollWidth;
              e.currentTarget.setSelectionRange(
                e.currentTarget.value.length,
                e.currentTarget.value.length,
              );
              break;
            case 'Backspace':
              // If a site is selected, just clean out the input
              if (selectedValue) {
                e.preventDefault();
                setSelectedValue(null);
                formik.setFieldValue(idField, '');
                formik.setFieldValue(textField, '');
              }
              break;
            default:
              // clears the value if something was already selected
              if (selectedValue) {
                setSelectedValue(null);
                formik.setFieldValue(idField, '');
                formik.setFieldValue(textField, '');
              }
              break;
          }
        }}
      />
      {filteredOptions && filteredOptions.length > 0
        && (
          <ObjectDropdown
            {...{
              autocompleteHidden,
              hoveredIndex,
              setHoveredIndex,
            }}
            options={filteredOptions}

            onSelect={onSelectOption}
            Icon={Icon}

            CustomLayout={CustomLayout}
          />
        )
      }
    </div>
  );
};

type DefaultLayoutPropTypes = {
  Icon: any,
  label: string,
};
const DefaultLayout = ({ Icon, label }:DefaultLayoutPropTypes) => (

  <>

    <Icon />

    <div className={styles.optionLabel}>{label}</div>
  </>
);

type ObjectOptionProps = {
  option: Option,
  index: number,
  hoveredIndex: number,

  setHoveredIndex: (param:number) => void,
  onSelect: () => void,
  Icon: ReactNode,
  CustomLayout: ReactNode,
};

const ObjectOption = (props: ObjectOptionProps) => {
  const {
    option,
    index,
    hoveredIndex,
    setHoveredIndex,
    onSelect,
    Icon,
    CustomLayout,
  } = props;
  return (

    <div
      role="button"
      tabIndex={0}

      className={classNames(styles.option, {
        [styles.hovered]: index === hoveredIndex,
      })}
      onMouseDown={(e) => {
        e.preventDefault(); // Prevent input from losing focus on click

        onSelect(option);
      }}
      onMouseEnter={() => setHoveredIndex(index)}
      onMouseLeave={() => setHoveredIndex(-1)}

      value={option.value}
    >
      {!CustomLayout

        ? (<DefaultLayout Icon={Icon} label={option.label} />)

        : (<CustomLayout {...props} />)}
    </div>
  );
};

type ObjectDropdownPropTypes = {
  autocompleteHidden: boolean,
  onSelect: ()=> void,
  options: [],
  hoveredIndex: number,
  setHoveredIndex: ()=>void,
  Icon: any,
  CustomLayout: ReactNode,
};
const ObjectDropdown = ({
  autocompleteHidden,
  onSelect,
  options,
  hoveredIndex,
  setHoveredIndex,
  Icon,
  CustomLayout,
}: ObjectDropdownPropTypes) => (

  <div className={classNames(styles.objectDropdownMenu, {
    [styles.hidden]: autocompleteHidden,
  })}
  >
    {options.map((o, i) => (

      <ObjectOption

        key={`${o.label}-${o.value}`}
        option={o}
        index={i}
        setHoveredIndex={setHoveredIndex}
        hoveredIndex={hoveredIndex}
        onSelect={onSelect}
        Icon={Icon}
        CustomLayout={CustomLayout}
      />
    ))}
  </div>
);

ObjectOrTextInput.defaultProps = {
  id: undefined,
  CustomOnSelectLayout: undefined,
  CustomOnSelect: undefined,
  CustomFilter: undefined,
  CustomLayout: undefined,
};

export default ObjectOrTextInput;
