// @ts-nocheck
import React from 'react';

import { BasicFlag } from 'components/Common/Flag';

export const DrawFenceToastID = 'DrawFenceToastID';

const DrawFenceToast = () => (

  <BasicFlag text="Click on map to draw site" />
);

export default DrawFenceToast;
