// @ts-nocheck
import { connect,  Formik } from 'formik';
import _ from 'lodash';
import React, { ReactNode } from 'react';

import { FormField } from '..';

import DropdownCreatable from '../Dropdown/DropdownCreatable';

type FormikCreatableDropdownProps = {
  formik: Formik,
  label?: string,
  required?: boolean,
  locked?: boolean,
  options: { label: ReactNode, value: string }[],
  name: string,
  embedded: boolean,
  tooltip: any,
  readOnly?: boolean,
  readOnlyValue?: ReactNode,
  id?: string,
  canCreateOption?: boolean,
  isTimeInput?: boolean,
  hideError?: boolean,
};

/**
 * Similar to its cousin FormikDropdown expect that it uses DropdownCreatable as its base.
 */
const FormikCreatableDropdown = ({
  formik,
  label,
  required,
  locked,
  options,
  name,
  embedded,
  readOnly,
  readOnlyValue,
  id,
  isTimeInput,
  hideError,
  tooltip,
  ...inputProps
}: FormikCreatableDropdownProps) => (

  <FormField
    label={label}
    required={required}
    locked={locked}
    error={formik.touched[name] && formik.errors[name]}
    embedded={embedded}
    hideError={hideError}
    tooltip={tooltip}
  >
    {
      !readOnly && (
        <DropdownCreatable
          id={id}
          options={options}
          value={formik.values[name]}

          inputId={name}
          onChange={(value) => formik.setFieldValue(name, value)}
          onBlur={() => formik.setFieldTouched(name)}
          name={name}
          embedded={embedded}
          canCreateOption
          isTimeInput={isTimeInput}
          {...inputProps}
        />
      )
    }
    { readOnly && (

      <>
        {readOnlyValue || _.get(options.find((o) => o.value === formik.values[name]), 'label', '-')}
      </>
    )}
  </FormField>
);

FormikCreatableDropdown.defaultProps = {
  required: false,
  locked: false,
  readOnly: false,
  readOnlyValue: '',
  id: undefined,
  label: undefined,
  isTimeInput: false,
  hideError: false,
};

export default connect(FormikCreatableDropdown);
