// @ts-nocheck

import classNames from 'classnames';
import { Formik } from 'formik';
import React from 'react';




import { useGetProjectSharingLink, useUpdateProjectSharingLinkSettings } from '../hooks';
import { Steps } from '../utils';

// eslint-disable-next-line import/namespace,import/default
import {  Project, Company } from 'common-ui/types';
import { Toggle } from 'components/Common/Form';
import { SidebarForm } from 'components/Sidebar';
import { useDetectMobile } from 'utils/Hooks';
import LinkShareModalContent from './LinkShareModalContent';
import styles from './styles.module.scss';

type LinkShareModalProps = {
  handleClose: () => void,
  project: Project,
  companyClient: Company,
  currentStep: string,

  openPageDisplay: (param:string) => void,
}

const LinkShareModal = ({
  handleClose,
  project,
  companyClient,
  currentStep,
  openPageDisplay,
}: LinkShareModalProps) => {
  const defaultSettings = {
    driverName: true,
    phoneNumber: false,
    loggingStatus: false,
    loggingTimestamps: false,
    licensePlate: false,
  };

  const projectSharingLink = useGetProjectSharingLink(project && project.data.id);
  const updateProjectSharingLinkSettings = useUpdateProjectSharingLinkSettings();
  const initialSettings = projectSharingLink ? projectSharingLink.settings : defaultSettings;
  const isMobile = useDetectMobile();

  return (

    <div className={classNames(styles.linkShareModal, { [styles.border]: companyClient })}>
      {isMobile ? (

        <Formik
          enableReinitialize
          initialValues={{
            projectID: projectSharingLink ? projectSharingLink.projectID : null,
            ...initialSettings,
          }}
        >
          <LinkShareModalContent
            projectSharingLink={projectSharingLink}
            updateProjectSharingLinkSettings={updateProjectSharingLinkSettings}
            currentStep={currentStep}
            openPageDisplay={openPageDisplay}
          />
        </Formik>
      ) : (

        <SidebarForm
          title={(
            <div className={styles.modalHeader}>
              <span>Link Sharing</span>
              <Toggle
                checked={projectSharingLink ? projectSharingLink.enabled : false}
                onChange={() => projectSharingLink && updateProjectSharingLinkSettings(
                  projectSharingLink.id,
                  !projectSharingLink.enabled,
                  projectSharingLink.settings,
                )}
                slim
              />
            </div>
          )
          }
          onClose={handleClose}
        >
          <Formik
            enableReinitialize
            initialValues={{
              projectID: projectSharingLink ? projectSharingLink.projectID : null,
              ...initialSettings,
            }}
          >
            <LinkShareModalContent
              projectSharingLink={projectSharingLink}
              updateProjectSharingLinkSettings={updateProjectSharingLinkSettings}
              currentStep={currentStep}
              openPageDisplay={openPageDisplay}
            />
          </Formik>
        </SidebarForm>
      )}
    </div>
  );
};

export default LinkShareModal;
