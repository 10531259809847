// @ts-nocheck
import _ from 'lodash';
import {  intlShape } from 'react-intl';
import {  PositionType } from '../types';
import {
  makeBounds,
} from '../utils/map.utils';
import {
  measurementTypes,

} from 'utils/format';

// eslint-disable-next-line no-underscore-dangle
export const defaultPosition = [
  [-85.473633, 45.322461],
  [-73.322754, 41.98527],
];

export const siteMarkerTooltipID = 'siteMarkerTooltip';

// Search for a marker that doesn't have bad data

export const hasValidMarker = (markers: Array<$Subtype<PositionType>>) => (
  _.some(markers, (m: any) => m && !m.badData)
);


export const calculateMarkerBounds = (markers: Array<$Subtype<PositionType>>, companyCoordinates: any) => {
  if (!!markers && markers.length && hasValidMarker(markers)) {
    return makeBounds(markers);
  }
  if (!!companyCoordinates.longitude || !!companyCoordinates.latitude) {
    return makeBounds([{
      lng: companyCoordinates.longitude,
      lat: companyCoordinates.latitude,
    }]);
  }
  return defaultPosition;
};


const getSingularUnitText = (measurementType: string, intl: intlShape) => {
  switch (measurementType) {
    case measurementTypes.IMPERIAL:
      return intl.formatMessage({
        id: 'sites.measurement.foot',
        defaultMessage: 'foot',
      });
    case measurementTypes.METRIC:
    default:
      return intl.formatMessage({
        id: 'sites.measurement.metre',
        defaultMessage: 'metre',
      });
  }
};

export const getRadiusText = (
  { radius, measurementType }: { radius: number, measurementType: string},

  intl: intlShape,
) => (
  intl.formatMessage({
    id: 'sites.popup.radius_text',
    defaultMessage: '{radius} {unit} radius',

  },
  {
    radius: radius.toFixed(0),
    unit: getSingularUnitText(measurementType, intl),
  })
);
