// @ts-nocheck
import classNames from 'classnames';
import React from 'react';

import { FormattedMessage } from 'react-intl';
import { BlackGhost, Truck } from 'common-ui/Icon';

import { Tag } from 'common-ui/Labels';


import {  NotificationTypeType } from 'components/NotificationsSidebar/types';
import styles from './styles.module.scss';

const textForStatus = (status: NotificationTypeType, subdispatched: boolean) => {
  switch (status) {
    case 'request':
      return (

        <FormattedMessage
          id="notifications.tags.request"
          description="Notifications Tag Request"
          defaultMessage="Request"
        />
      );
    case 'declined':
      return (

        <FormattedMessage
          id="notifications.tags.assignment.declined"
          description="Notifications Tag Assignment Declined"
          defaultMessage="Declined"
        />
      );
    case 'requestdeclined':
      return (

        <FormattedMessage
          id="notifications.tags.request.declined"
          description="Notifications Tag Request Declined"
          defaultMessage="Declined"
        />
      );
    case 'idle':
      return (

        <FormattedMessage
          id="notifications.tags.idle"
          description="Notifications Tag Idle"
          defaultMessage="Idle"
        />
      );
    case 'online':
      return (

        <FormattedMessage
          id="notifications.tags.online"
          description="Notifications Tag Online"
          defaultMessage="Online"
        />
      );
    case 'lost':
      return (

        <FormattedMessage
          id="notifications.tags.lost"
          description="Notifications Tag Lost"
          defaultMessage="Lost"
        />
      );
    case 'accepted':
      return subdispatched ? (

        <FormattedMessage
          id="notifications.tags.request.added"
          description="Notifications Tag Added"
          defaultMessage="Added"
        />
      ) : (

        <FormattedMessage
          id="notifications.tags.assignment.accepted"
          description="Notifications Tag Assignment Accepted"
          defaultMessage="Accepted"
        />
      );
    case 'requestaccepted':
      return (

        <FormattedMessage
          id="notifications.tags.request.accepted"
          description="Notifications Tag Request Accepted"
          defaultMessage="Accepted"
        />
      );
    case 'info':
      return (

        <FormattedMessage
          id="notifications.tags.info"
          description="Notifications Tag Info"
          defaultMessage="Info"
        />
      );
    default:
      return '';
  }
};

const classNameForStatus = (status: NotificationTypeType, subdispatched: boolean) => {
  switch (status) {
    case 'request':
      return styles.request;
    case 'declined':
      return styles.declined;
    case 'requestdeclined':
      return styles.declinedRequest;
    case 'idle':
      return styles.idle;
    case 'online':
      return styles.online;
    case 'lost':
      return styles.lost;
    case 'accepted':
      return subdispatched ? styles.added : styles.accepted;
    case 'requestaccepted':
      return styles.acceptedRequest;
    case 'info':
      return styles.info;
    default:
      return '';
  }
};

const iconForStatus = (status: NotificationTypeType) => {
  switch (status) {
    case 'request':
      return (

        <div className={styles.subdispatchIconYellow}>
          <Truck />
        </div>
      );
    case 'requestdeclined':
      return (

        <div className={styles.subdispatchIconRed}>
          <Truck />
        </div>
      );
    case 'requestaccepted':
      return (

        <div className={styles.subdispatchIconGreen}>
          <Truck />
        </div>
      );
    case 'lost':

      return <BlackGhost />;
    case 'declined':
    case 'idle':
    case 'online':
    case 'accepted':
    case 'info':
    default:
      return null;
  }
};

const NotificationTag = ({
  type,
  subdispatched,
}: { type: NotificationTypeType, subdispatched: boolean }) => {
  switch (type) {
    case 'request':
    case 'declined':
    case 'requestdeclined':
    case 'idle':
    case 'lost':
    case 'online':
    case 'accepted':
    case 'requestaccepted':
      return (

        <Tag
          text={textForStatus(type, subdispatched)}

          className={classNames(styles.notifications, classNameForStatus(type, subdispatched))}
          leftIcon={iconForStatus(type)}
        />
      );
    case 'info':
    default:
      return null;
  }
};

export default NotificationTag;
