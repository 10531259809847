// @ts-nocheck
import React from 'react';
import { FormattedMessage } from 'react-intl';


import SearchOnly from '../SearchOnly';

import { Radio } from 'components/Common/Form';
import styles from './styles.module.scss';


type ProjectFilterPropTypes = {
  filterItems: any[],
  currentOptions: () => any[],
  mobile?: boolean,
  filterByAndOrOptions: {value:string, label: string}[],
  filterByAndOrValue: string,
  setAndOrValue: () => void,
  setFilterValues: () => void,
};

const SearchWithAndOr = ({
  filterItems,
  currentOptions,
  mobile,
  filterByAndOrOptions,
  filterByAndOrValue,
  setAndOrValue,
  setFilterValues,
}: ProjectFilterPropTypes) => (

  <div className={!mobile ? styles.searchDialog : styles.mobileSearchDialog}>

    <SearchOnly
      onFiltersChange={setFilterValues}
      selectedTerms={filterItems}
      filterItems={filterItems}
      currentOptions={currentOptions}
      className={styles.searchOnly}
      sortBoxText={(

        <FormattedMessage
          id="projects.tools_sidebar.sort_projects_by_text"
          description="Sort projects by sort box text"
          defaultMessage="Sort Projects By"
        />
      )}
    />

    <div className={mobile ? styles.mobileAndOrButton : styles.andOrButton}>

      <Radio
        options={filterByAndOrOptions}
        value={filterByAndOrValue}
        onChange={setAndOrValue}
      />
    </div>

  </div>
);

SearchWithAndOr.defaultProps = {
  mobile: false,
};

export default SearchWithAndOr;
