// @ts-nocheck
import moment from 'moment-timezone';

export const formatLogTime = (value: string, timezone: string, defaultString = '') => {
  return moment.tz(value, timezone).isValid() ? `${moment.tz(value, timezone).format('MMM D, h:mma')} ` : defaultString;
};

export const formatDatetime = (value: string, timezone: string, defaultString = '') => {
  return moment.tz(value, timezone).isValid()
    ? `${moment.tz(value, timezone).format('ddd MMM D, YYYY, h:mma')} `
    : defaultString;
};

export const formatDuration = (start?: string, stop?: string, hoursUnit = 'h', minutesUnit = 'm'): string => {

  if (!moment(start).isValid()) return '-';

  const endTime = moment(stop).isValid() ? moment(stop) : moment();
  const duration = moment.duration(endTime.diff(start));

  return `${moment
    .duration(Math.floor(duration.asMinutes()), 'minutes')
    
    ?.format(`h[${hoursUnit}] mm[${minutesUnit}]`)}`;
};

// This function is specifically for load dump time duration
// Returns hour:minute Duration as decimal number (ex: 2 hr 45 m =  2.75 hr)
// Defaults to 0 if there is an invalid date
// Assumes stop time is 24hours in the future if it is before start
// Normalizes dates to January 1st, 2020 for time comparisons
export const formatLoadDumpDuration = (start?: string, stop?: string): string => {

  if (!moment(start).isValid()) return '0';

  if (!moment(stop).isValid()) return '0';

  let validStartTime = moment(start);

  let validEndTime = moment(stop);
  // Normalize to same day for correct time comparison

  validStartTime = moment({
    year: 2020,
    month: 1,
    day: 1,
    hour: validStartTime.hours(),
    minute: validStartTime.minutes(),
    second: validStartTime.seconds(),

    millisecond: moment(start).milliseconds(),
  });

  validEndTime = moment({
    year: 2020,
    month: 1,
    day: 1,
    hour: validEndTime.hours(),
    minute: validEndTime.minutes(),
    second: validEndTime.seconds(),
    millisecond: validEndTime.milliseconds(),
  });
  // If stop time before start time assume end time was a day in the future

  if (moment(validEndTime).isBefore(validStartTime)) {

    validEndTime = moment(validEndTime).add(24, 'hours');
  }
  const duration = moment.duration(validEndTime.diff(validStartTime));
  const hoursDecimalDuration = (duration.asMinutes() / 60).toFixed(2);
  return hoursDecimalDuration;
};
