// @ts-nocheck
import classNames from 'classnames';
import React from 'react';

import { Dispatcher, Bulb } from 'common-ui/Icon';
import { Tag } from 'common-ui/Labels';


import { isProjectTypeSmart } from 'pages/Projects/utils/projects.utils';
import styles from './styles.module.scss';

const ClassicProjectTag = () => (

  <Tag
    text="Classic"

    leftIcon={<Dispatcher />}

    className={classNames(styles.projectCardClassicTag, styles.projectCardTag)}
  />
);

const SmartProjectTag = () => (

  <Tag
    text="Smart"

    leftIcon={<Bulb />}

    className={classNames(styles.projectCardSmartTag, styles.projectCardTag)}
  />
);

const ProjectTypeTag = ({ type }: ({ type: string })) => {
  if (isProjectTypeSmart(type)) {

    return <SmartProjectTag />;
  }

  return <ClassicProjectTag />;
};

export default ProjectTypeTag;
