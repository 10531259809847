// @ts-nocheck
import classNames from 'classnames';
import React, { Fragment } from 'react';
// Components

import Attachment from '../../Attachment';
import { Button } from 'common-ui/Button';
import { Add, Switch, Ellipsis } from 'common-ui/Icon';
import ReadOnlyUI from './ReadOnlyFile.ui';
import Thumbnail from './components/Thumbnail';
import styles from './styles.module.scss';



type UploadUIPropTypes = {
  multiple?: boolean,
  isUploading?: boolean,
  readOnly?: boolean,
  orientation?: string | number,
  value: Array<any> | any,
  error: string,
  onUploadButtonClicked: () => any,
  handleSelectedImages: () => any,
  setInputRef: (param:any) => any,
  className?: string,
  thumbnail?: string,
  includeThumbnail?: boolean,
  includeButton?: boolean,
  includeFileList?: boolean,
  uploadCount?: string | number,
  uploadingText?: string,
  uploadText?: string,
  uploadAgainText?: string,
  attachmentButton?: boolean,
  icon?: any,
  disabled?: boolean
};

const UploadUI = ({
  multiple,
  isUploading,
  readOnly,
  orientation,
  value,
  error,
  onUploadButtonClicked,
  handleSelectedImages,
  setInputRef,
  className,
  thumbnail,
  includeThumbnail,
  includeButton,
  uploadCount,
  uploadingText,
  uploadText,
  uploadAgainText,
  includeFileList,
  disabled,
  attachmentButton,
  icon,
}: UploadUIPropTypes) => {
  if (readOnly) {
    return (

      <ReadOnlyUI
        {...{
          includeThumbnail,
          thumbnail,
          orientation,
          uploadCount,
          includeFileList,
          value,
          multiple,
        }}
      />
    );
  }

  // get button text

  let addIcon = <Add />;
  let buttonText = uploadText;
  const hasValue = (value instanceof Array && value.length > 0) || (value && !!value.getURL);
  if (isUploading) {
    buttonText = uploadingText;

    if (!multiple && hasValue) {

      addIcon = <Ellipsis />;
    }
  } else if (hasValue) {
    buttonText = uploadAgainText;

    addIcon = <Switch />;
  } else if (value) {
    buttonText = uploadText;
  }

  if (icon) {
    addIcon = icon;
  }

  return (

    <Fragment>

      <div className={`${styles.inputFileUpload} ${className || ''}`}>

        <div className={styles.fileUpload}>
          { includeThumbnail
            ? (
              <div className={classNames(includeButton && styles.uploadThumbnail)}>
                <Thumbnail
                  {...{
                    thumbnail,
                    orientation,
                    uploadCount,
                    multiple,
                    isUploading,
                  }}
                  onClick={onUploadButtonClicked}
                />
              </div>
            )
            : null
          }
          {includeButton && (
            <div className={styles.uploadButton}>
              <Button
                icon={addIcon}
                onClick={onUploadButtonClicked}
                text={buttonText}
                styleName= {attachmentButton ? 'attachmentButton' : 'neutral'}
                disabled={disabled}
              >
                {' '}
                {buttonText}
              </Button>
            </div>
          )}
        </div>

        <input
          type="file"
          multiple={multiple}
          accept="image/*, .pdf"
          onChange={handleSelectedImages}
          style={{ display: 'none' }}
          ref={setInputRef}
        />
      </div>

      {!!error && <div className={styles.genericErrorMessage}>{error.toString()}</div>}
      { includeFileList
        ? (
          <div className={styles.currentUploaded}>
            {

              value.map(ul => (
                <Attachment key={ul.getURL} filename={ul.filename ? ul.filename : ''} src={ul.getURL} />
              ))
            }
          </div>
        )
        : null
      }
    </Fragment>
  );
};

UploadUI.defaultProps = {
  multiple: true,
  isUploading: false,
  readOnly: false,
  orientation: 1,
  className: '',
  thumbnail: undefined,
  uploadCount: 0,
  includeThumbnail: false,
  includeButton: true,
  uploadText: 'Add',
  uploadingText: 'Adding',
  uploadAgainText: 'Replace Photo',
  includeFileList: false,
  attachmentButton: false,
  icon: undefined,
};

export default UploadUI;
