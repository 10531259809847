// @ts-nocheck
import classNames from 'classnames';
import React from 'react';

import { TooltipWrapper } from 'components/Common/Tooltip';
import styles from './styles.module.scss';

type AssignedCounterProps = {
  assignedCount: number,
};

const styleForCount = (count: any) => {
  switch (count) {
    case 1:
      return styles.oneAssigned;
    case 2:
      return styles.twoAssigned;
    case 3:
      return styles.threeAssigned;
    default:
      return null;
  }
};

const AssignedCounter = ({ assignedCount }: AssignedCounterProps) => (

  <TooltipWrapper
    message={`This driver has been added to ${assignedCount} assignment${assignedCount > 1 ? 's' : ''}`}
  >

    <div className={classNames(styles.assignedCounter, styleForCount(assignedCount))}>
      {assignedCount}
      x
    </div>
  </TooltipWrapper>
);

export default AssignedCounter;
