// @ts-nocheck
import classNames from 'classnames';
import React from 'react';
import {InfoCardHover, SubcontractorInfoCard} from '../InfoCard';
import {Add} from 'common-ui/Icon';
import type {InternalHire, Subcontractor} from 'pages/Hires/types';
import {HireTypes} from 'pages/Hires/utils';
import {useHover} from 'utils/Hooks';
import styles from './styles.module.scss';

type HireCellPropTypes = {
  hire?: (InternalHire | Subcontractor),
  assignmentInstance?: any,
  assignment?: any,
  mobile?: boolean,
  selected?: boolean,
  cancelled?: boolean,
  hoverOnMobile?: boolean,
};

const HireCell = ({
  hire,
  assignmentInstance,
  assignment,
  selected,
  cancelled,
  mobile,
  hoverOnMobile,
}: HireCellPropTypes) => {
  const declinedDriversName =
    assignment &&
    assignment.lastDeclinedEvent &&
    (assignment.lastDeclinedEvent.hireType.toLowerCase() ===
    HireTypes.SUBCONTRACTOR.toLowerCase()
      ? assignment.lastDeclinedEvent.company.name
      : `${assignment.lastDeclinedEvent.createdBy.firstName} ${assignment.lastDeclinedEvent.createdBy.lastName}`);
  const [ref, isHovered] = useHover(hoverOnMobile);

  let displayItem = (
    <div
      className={classNames(styles.noHire, {
        [styles.cancelled]: cancelled && !hire,
      })}
    >
      <Add />
      <div className={styles.addDriverSection}>
        Add Driver
        <span className={styles.declinedBy}>
          {assignment &&
            assignment.lastDeclinedEvent &&
            `Declined by ${declinedDriversName}`}
        </span>
      </div>
    </div>
  );
  if (hire) {
    if (hire.__typename === 'Hire') {
      const truckNumber =
        (assignmentInstance &&
          assignmentInstance.truckNumber &&
          assignmentInstance.truckNumber !== '' &&
          assignmentInstance.truckNumber) ||
        hire.truckNumber;

      displayItem = (
        <InfoCardHover
          firstName={hire.firstName}
          lastName={hire.lastName}
          company={hire.companyName}
          phoneNumber={hire.phoneNumber}
          truckNumber={truckNumber}
          accountId={hire.accountId}
          isHovered={isHovered}
        />
      );
    } else {
      displayItem = (
        <SubcontractorInfoCard name={hire.name} priority={hire.priority} />
      );
    }
  }
  return (
    <div
      className={classNames(styles.hireCell, {
        [styles.missing]: !cancelled && !hire,
        [styles.selected]: selected,
        [styles.mobile]: mobile,
        [styles.declinedBorder]: assignment && assignment.lastDeclinedEvent,
      })}
      ref={ref}
    >
      {displayItem}
    </div>
  );
};

HireCell.defaultProps = {
  mobile: false,
  assignmentInstance: null,
  assignment: null,
  selected: false,
  cancelled: false,
  hoverOnMobile: false,
};

export default HireCell;
