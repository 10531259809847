// @ts-nocheck
import React from 'react';

import { Tag } from 'common-ui/Labels';
import styles from './styles.module.scss';

type SelectedTagPropTypes = {
  count: number,
};

const SelectedTag = ({ count }: SelectedTagPropTypes) => (

  <Tag text={`${count} Selected`} className={styles.selected} />
);

export default SelectedTag;
