// @ts-nocheck
import classNames from 'classnames';
import React from 'react';

import AssignedCounter from './AssignedCounter';
import { CompanyClientStatusIcon, HireCardContainer } from './HireChip';

import styles from './styles.module.scss';
import type { DisplayHire, DisplaySubcontractor } from '../types';

type HireChipMobileProps = {
  hire: DisplayHire | DisplaySubcontractor,
  assignmentInstance?: any,
  selected: boolean,
  onSelect: () => void,
};

const HireChipMobile = ({
  hire,
  assignmentInstance,
  selected,
  onSelect }: HireChipMobileProps) => {
  const truckNumber = ((
    assignmentInstance
    && assignmentInstance.truckNumber
    && assignmentInstance.truckNumber !== ''
    && assignmentInstance.truckNumber)
    || hire.truckNumber);
  return (

    <div

      className={classNames(styles.hireChipMobile, { [styles.selected]: selected })}
      onClick={onSelect}
      onKeyPress={onSelect}
      role="button"
      tabIndex={0}
    >

      <HireCardContainer
        hire={hire}

        truckNumber={truckNumber}
      />

      {hire.companyClientPermissionType && <CompanyClientStatusIcon permissionType={hire.companyClientPermissionType} />}

      {hire.assignedCount > 0 && <AssignedCounter assignedCount={hire.assignedCount} />}
    </div>
  );
};

HireChipMobile.defaultProps = {
  assignmentInstance: null,
};

export default HireChipMobile;
