import React, {SVGProps} from 'react';

const Truck = (props: SVGProps<SVGSVGElement>) => (

  <svg
    width="25px"
    height="25px"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    {...props}
  >

    <path d="M17.85,4.66H10L8,7.22H.5l2.82,8.37H15.74V6.21h2.11a.75.75,0,0,0,.73-.77A.76.76,0,0,0,17.85,4.66ZM2.57,8.78h2L6.35,14h-2ZM7.9,14,6.12,8.78H8L9.73,14Zm6.39,0h-3l-2-6,1.4-1.8h3.65Z" />

    <path d="M20.32,7.27H16.2l0,4.42s0,.06,0,.09,0,.06,0,.09v4.07H11.34a2.5,2.5,0,0,0-4.66,0H5.11a.73.73,0,1,0,0,1.45H6.56a2.51,2.51,0,0,0,4.9,0h5.05a2.51,2.51,0,0,0,4.91,0h1.95V11.15ZM9,17.92a1.06,1.06,0,1,1,1.06-1.06A1.06,1.06,0,0,1,9,17.92Zm8.64-9.19h2l1.84,2.32H17.64ZM19,17.92a1.06,1.06,0,1,1,0-2.12,1.06,1.06,0,0,1,0,2.12Zm3-2H21.3A2.53,2.53,0,0,0,19,14.34a2.42,2.42,0,0,0-1.33.4V12.5h4.28Z" />
  </svg>
);

export default Truck;
