import React, {SVGProps} from 'react';

const Data = (props: SVGProps<SVGSVGElement>) => (

  <svg width={20} height={19} viewBox="0 0 20 19" {...props}>

    <title>Data</title>

    <path
      d="M8.824 15.442L6.63 13.86l-2.33 4.5a.976.976 0 0 1-1.323.419.994.994 0 0 1-.415-1.336L8.166 6.62a1.983 1.983 0 0 1-.323-1.088 1.97 1.97 0 0 1 1.96-1.98 1.97 1.97 0 0 1 1.962 1.98c0 .395-.115.763-.313 1.072l5.594 10.866a.994.994 0 0 1-.417 1.335.976.976 0 0 1-1.322-.422l-2.329-4.523-2.194 1.582v2.472a.98.98 0 1 1-1.96 0v-2.472zm.989-7.73L7.546 12.09l2.258 1.63 2.261-1.633-2.252-4.375zM2.147.29A.974.974 0 0 1 3.533.289a.996.996 0 0 1 .002 1.4A5.447 5.447 0 0 0 1.96 5.531c0 1.474.582 2.852 1.6 3.869a.996.996 0 0 1 .007 1.4.974.974 0 0 1-1.387.007A7.436 7.436 0 0 1 0 5.532C0 3.54.781 1.672 2.147.29zM17.43 10.803a.974.974 0 0 1-1.387-.006.996.996 0 0 1 .007-1.4 5.447 5.447 0 0 0 1.596-3.865 5.447 5.447 0 0 0-1.56-3.83.996.996 0 0 1 .007-1.399.974.974 0 0 1 1.387.007 7.436 7.436 0 0 1 2.127 5.222 7.436 7.436 0 0 1-2.177 5.271zM4.23 2.386a.974.974 0 0 1 1.386-.001.996.996 0 0 1 .002 1.4 2.474 2.474 0 0 0-.716 1.747c0 .676.269 1.306.738 1.77A.996.996 0 0 1 5.657 8.7a.974.974 0 0 1-1.387.016 4.462 4.462 0 0 1-1.329-3.185c0-1.195.47-2.317 1.289-3.146zM15.376 8.68a.974.974 0 0 1-1.386 0 .996.996 0 0 1-.001-1.399 2.474 2.474 0 0 0 .717-1.748c0-.661-.257-1.279-.708-1.74a.996.996 0 0 1 .008-1.399.974.974 0 0 1 1.386.008 4.463 4.463 0 0 1 1.275 3.13c0 1.196-.47 2.319-1.29 3.148z"
      fill="#162093"
      fillRule="evenodd"
    />
  </svg>
);

export default Data;
