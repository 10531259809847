// @ts-nocheck
import { connect } from 'formik';
import moment from 'moment-timezone';
import React, { ReactNode } from 'react';


import { useTimezone } from 'common-ui/Hooks';

import { FormField, TimePicker } from 'components/Common/Form';

type FormikTimePickerProps = {
  formik: any,
  label: string,
  required?: boolean,
  name: string,
  embedded: boolean,
  readOnly?: boolean,
  readOnlyValue?: ReactNode,
  displayErrorIcon?: boolean,
  id?: string,
  hideError?: boolean,
  warnings?: any,
  showWarning?: boolean,
};

const FormikTimePicker = ({
  formik,
  label,
  required,
  name,
  embedded,
  readOnly,
  readOnlyValue,
  displayErrorIcon,
  id,
  hideError,
  showWarning,
  warnings,
  ...inputProps
}: FormikTimePickerProps) => {
  const timezone = useTimezone();
  const readOnlyTime = formik.values[name] && formik.values[name] !== ''

    ? moment.tz(formik.values[name], timezone).format('h:mma')
    : '-';
  return (

    <FormField
      label={label}
      required={required}
      error={!displayErrorIcon && formik.touched[name] && formik.errors[name]}
      embedded={embedded}
      hideError={hideError}
      warning={warnings && (warnings[name])}
      showWarning={showWarning}
    >
      {!readOnly ? (

        <TimePicker
          id={id}
          value={formik.values[name]}
          onChange={(value: any) => {
            formik.setFieldValue(name, value);
            if (!showWarning && !formik.touched[name]) {
              formik.setFieldTouched(name);
            }
          }}
          name={name}
          embedded={embedded}
          error={displayErrorIcon && formik.errors[name]}
          {...inputProps}
        />
      ) : (

        <>
          {readOnlyValue || readOnlyTime}
        </>
      )}
    </FormField>
  );
};

FormikTimePicker.defaultProps = {
  required: false,
  readOnly: false,
  readOnlyValue: '',
  displayErrorIcon: false,
  id: undefined,
  hideError: false,
  showWarning: false,
  warnings: {},
};

export default connect(FormikTimePicker);
