import React, {SVGProps} from 'react';

const Clock = (props: SVGProps<SVGSVGElement>) => (

  <svg viewBox="0 0 25 25" {...props}>

    <g id="History">

      <path d="M12,22.32A10.339,10.339,0,1,1,22.339,11.981,10.35,10.35,0,0,1,12,22.32ZM12,3.242a8.74,8.74,0,1,0,8.739,8.739A8.749,8.749,0,0,0,12,3.242Z" />

      <path d="M15.43,15.688a.8.8,0,0,1-.517-.189l-3.179-2.694a1.507,1.507,0,0,1-.534-1.154V6.126a.8.8,0,0,1,1.6,0v5.525l3.148,2.627a.8.8,0,0,1-.518,1.41Z" />
    </g>
  </svg>
);

export default Clock;
