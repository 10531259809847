// @ts-nocheck
import classNames from 'classnames';
import React, { ReactNode } from 'react';
import styles from './styles.module.scss';

type ContextSwitcherItemProps = {
  label: string,
  isActive: boolean,
  onClick?: () => void,
  icon?: ReactNode,
}

const ContextSwitcherItem = ({
  label, isActive, onClick, icon,
}: ContextSwitcherItemProps) => (

  <button
    id={label}

    className={classNames(styles.unselectedItem, {
      [styles.selectedItem]: isActive,
    })}
    type="button"
    onClick={onClick}
  >
    {icon && (

      <div className={classNames(styles.buttonIcon)}>
        {icon}
      </div>
    )}

    <span className={styles.buttonLabel}>{label}</span>
  </button>
);

ContextSwitcherItem.defaultProps = {
  onClick: () => {},
  icon: null,
};

export default ContextSwitcherItem;
