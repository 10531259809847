// @ts-nocheck
import React from 'react';
import { Marker } from 'react-map-gl';

import { TooltipWrapper } from 'components/Common/Tooltip';

export type TicketPointProps = {
  ticketIndex: number,
  lat: number,
  lng: number,
  isUpload?: boolean,
};

const TicketPoint = ({
  lat,
  lng,
  ticketIndex,
  isUpload,
}: TicketPointProps) => {
  return (

    <Marker
      longitude={lng}
      latitude={lat}
      offsetLeft={-8}
      offsetTop={-8}
    >

      <TooltipWrapper message={isUpload ? 'Ticket uploaded here' : 'Sign-off completed here'}>

        <svg width={24} height={24} viewBox="0 0 24 24">
           <g>
               <circle cx="12" cy="12" r="12" fill="black" />
               <text x="50%" y="50%" textAnchor="middle" stroke="white" strokeWidth="1px" dy=".3em">{ticketIndex}</text>
          </g>
        </svg>
      </TooltipWrapper>
    </Marker>
  );
};

TicketPoint.defaultProps = {
  isUpload: false,
};

type TicketPointsProps = {
  signoffPoints: TicketPointProps[],
  uploadPoints: TicketPointProps[],
};

const TicketPoints = ({ signoffPoints, uploadPoints }: TicketPointsProps) => (

  <>
    {uploadPoints.map(p => (

      <TicketPoint
        lat={p.lat}
        lng={p.lng}
        ticketIndex={p.ticketIndex}
        isUpload
        key={`upload-point-${p.ticketIndex}`}
      />
    ))}
    {signoffPoints.map(p => (

      <TicketPoint
        lat={p.lat}
        lng={p.lng}
        ticketIndex={p.ticketIndex}
        key={`signoff-point-${p.ticketIndex}`}
      />
    ))}
  </>
);

export default TicketPoints;
