// @ts-nocheck
import classNames from 'classnames';
import React, { useState } from 'react';
import SharedMap from '../components/SharedMap';




import { ContextSwitcher } from 'common-ui/ContextSwitcher';
import type { Project, Site } from 'common-ui/types';

import { MAP_OPTION, PROJECTS_OPTION, getToggleOptions } from 'pages/Live/Mobile/liveMobile';

import styles from 'pages/Live/Mobile/styles.module.scss';
import { LiveProjects } from 'pages/Live/components/LiveSidebar';
import type { Marker } from 'pages/Live/types';
import type { intlShape } from 'react-intl';
import { injectIntlHOC } from 'utils/HOC';
import shareLiveProjectStyles from './styles.module.scss';


type ShareLiveProjectMobileProps = {

  intl: typeof intlShape,
  projects: Array<Project>,
  markers: Array<Marker>,
  sites: Array<Site>,
  openedMarkerID: string,
  toggleMarkerOpen: () => void,
  toggleAssignmentOpen: () => void,
  onRefresh: () => void,
  validLink: boolean,
  liveMapLoading: boolean,
}

const ShareLiveProjectMobile = ({
  intl,
  projects,
  markers,
  sites,
  openedMarkerID,
  toggleMarkerOpen,
  toggleAssignmentOpen,
  onRefresh,
  validLink,
  liveMapLoading,
}: ShareLiveProjectMobileProps) => {
  const [togglePageValue, setTogglePage] = useState(MAP_OPTION);
  const toggleOptions = getToggleOptions(intl);

  return (

    <div className={styles.overviewContent}>

      <span className={shareLiveProjectStyles.mobileTreadLogo}>TREAD</span>

      <div

        className={classNames(
          styles.mainContent,
          togglePageValue !== MAP_OPTION && styles.hideMap,
        )}
      >

        <SharedMap

          mobile
          markers={markers}
          sites={sites}
          openedMarkerID={openedMarkerID}
          toggleMarkerOpen={toggleMarkerOpen}
          validLink={validLink}
          liveMapLoading={liveMapLoading}
        />
      </div>
      {togglePageValue === PROJECTS_OPTION && (

        <div className={styles.sidebarContent}>
          <LiveProjects
            intl={intl}
            projects={projects}
            onRefresh={onRefresh}
            chosenMarkerId={openedMarkerID}
            openMarker={(props: any) => {
              setTogglePage(MAP_OPTION);

              toggleMarkerOpen(props);
            }}
            toggleAssignmentOpen={toggleAssignmentOpen}
            disableSearch
            hideShareButton
          />
        </div>
      )}

      <ContextSwitcher
        onChange={setTogglePage}
        selectedValue={togglePageValue}
        options={toggleOptions}
      />
    </div>
  );
};

export default injectIntlHOC(ShareLiveProjectMobile);
