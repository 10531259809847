// @ts-nocheck
import React from 'react';

import SitesMap from 'pages/Sites/components/SitesMap';
import styles from './styles.module.scss';

const CreateSiteModalMap = () => {
  return (

    <div className={styles.createSiteMap}>

      <SitesMap sites={[]} hideLegend />
    </div>
  );
};

export default CreateSiteModalMap;
