// @ts-nocheck
import React, { ReactNode } from 'react';
import styles from './styles.module.scss';

type DetailsTableFlexProps = {
  children: ReactNode,
};

/**
 * This component mirrors the functionality of DetailsTable but instead of using
 * grid and background color to draw the borders in the table this uses
 * flex and a border element
 *
 * This was implemented because sometimes the borders don't draw properly
 * in the original implementation
 */
const DetailsTableFlex = ({ children }: DetailsTableFlexProps) => (

  <div className={styles.detailsTable}>
    {children}
  </div>
);

export default DetailsTableFlex;
