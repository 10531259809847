// @ts-nocheck
import { Formik, Form } from 'formik';
import React from 'react';
import { Button } from 'common-ui/Button';
import ClickableDiv from 'common-ui/ClickableDiv';
import { Close } from 'common-ui/Icon';
import type { EquipmentType } from 'pages/Projects/types';
import CreateEquipmentFormFields from './CreateEquipmentFormFields';
import styles from './styles.module.scss';
import useEquipmentFormikConfig from './useEquipmentFormikConfig';





type CreateEquipmentModalProps = {
  equipment: EquipmentType,

  onComplete: (any) => void,
  onClose: () => void,
}

const CreateEquipmentModal = ({
  equipment,
  onComplete,
  onClose,
} : CreateEquipmentModalProps) => {
  const config = useEquipmentFormikConfig(equipment, onComplete);

  return (

    <Formik {...config}>
      {({
        isValid
      }: any) => (

        <Form>
          <div className={styles.createEquipmentHeader}>
            Create Equipment
            <ClickableDiv onClick={onClose} className={styles.closeButton}>
              <Close />
            </ClickableDiv>
          </div>
          <CreateEquipmentFormFields />
          <Button
            text="Save"
            type="submit"
            className={styles.createEquipmentSubmit}
            disabled={!isValid}
          />
        </Form>
      )}
    </Formik>
  );
};

export default CreateEquipmentModal;
