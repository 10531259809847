// @ts-nocheck
import { connect } from 'formik';
import React, { ReactNode, useMemo } from 'react';


import { FormField, TextInput } from '..';
import Analytics from 'common-ui/Analytics/SegmentAnalytics';
import {Events} from 'common-ui/Analytics/types';

type NestedName = {
  arrayName: string,
  index: number,
  name: string,
};

type FormikTextInputProps = {
  formik: any,
  label: string,
  required?: boolean,
  name: string,
  hint: string,
  embedded: boolean,
  readOnly?: boolean,
  readOnlyValue?: ReactNode,
  analyticsAction?: string,
  step?: number,
  id?: string,
  disableIncrements?: boolean,
  nestedName?: NestedName,
  resetInput?: () => void,
  setUserMadeChangeFlag?: () => void, // fields should be locked if value already populated
};

const fieldArrayValidatorHelper = (touched: any, errors: any, nestedName: any) => {
  touched = touched[nestedName.arrayName];

  if (!Array.isArray(touched) || !Array.isArray(errors)) {
    return '';
  }

  if (!touched[nestedName.index] || !errors[nestedName.index]) {
    return '';
  }

  if (nestedName.name in touched[nestedName.index]) {
    if (touched[nestedName.index][nestedName.name]) {
      if (nestedName.name in errors[nestedName.index]) {
        return errors[nestedName.index][nestedName.name];
      }
    }
  }

  return '';
};

const FormikTextInput = ({
  formik,
  label,
  required,
  name,
  hint,
  embedded,
  readOnly,
  readOnlyValue,
  analyticsAction,
  step,
  id,
  disableIncrements,
  nestedName,
  setUserMadeChangeFlag,
  resetInput,
  ...inputProps
}: FormikTextInputProps) => useMemo(() => (

  <FormField
    label={label}
    required={required}
    error={
      !nestedName
        ? formik.touched[name] && formik.errors[name]
        : fieldArrayValidatorHelper(formik.touched, formik.errors, nestedName)
    }
    embedded={embedded}
    hint={hint}

    hideError={inputProps.hideError || false}
  >
    {!readOnly ? (

      <TextInput
        disableIncrements={disableIncrements}

        value={
          !nestedName
            ? formik.values[name]
            : formik.values[nestedName.arrayName][nestedName.index][nestedName.name]
        }
        id={id}
        onChange={(value) => {
          if (setUserMadeChangeFlag !== null) {

            setUserMadeChangeFlag(true);
          }
          formik.setFieldValue(name, value);
        }}
        onBlur={() => formik.setFieldTouched(name)}
        step={step}
        onFocus={(value: any) => {
          if (analyticsAction) {

            Analytics.pushEvent(Events.formChange, analyticsAction, value);
          }
        }}
        name={name}
        embedded={embedded}
        resetInput={resetInput}
        {...inputProps}
      />
    )
      : (

        <div id={id}>
          {readOnlyValue || formik.values[name] || '-'}
        </div>
      )}
  </FormField>
), [
  formik.values[name],
  formik.errors[name],
  formik.touched[name],
  label,
  required,
  name,
  hint,
  embedded,
  readOnly,
  readOnlyValue,
  analyticsAction,
  step,
  id,
  disableIncrements,
  nestedName,
]);

FormikTextInput.defaultProps = {
  required: false,
  readOnly: false,
  readOnlyValue: '',
  analyticsAction: '',
  step: undefined,
  id: undefined,
  disableIncrements: true,
  setUserMadeChangeFlag: null,
  resetInput: null,
};

FormikTextInput.styleNames = TextInput.styleNames;

export default connect(FormikTextInput);
