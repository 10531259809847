// @ts-nocheck
import { useQuery, gql } from '@apollo/client';
import { useMemo } from 'react';

import { usePermissions } from 'common-ui/Hooks';

import { useCompanyContext } from 'utils/Hooks';

export const COLLABORATORS_QUERY = gql`
  query externalGuests($companyID: ID!) {
    externalGuests(companyID: $companyID) {
      id
      accountId
      firstName
      lastName
      roles
      companyName : externalCompanyName
      phoneNumber
      email
      hireType
    }
  }
`;

const useCollaboratorAccounts = (skipQuery = false) => {
  const { selectedCompanyID } = useCompanyContext();
  const { ViewInternalHires } = usePermissions(selectedCompanyID);

  const { data, loading, error } = useQuery(COLLABORATORS_QUERY, {
    variables: {
      companyID: selectedCompanyID,
    },
    skip: !ViewInternalHires || skipQuery,
    fetchPolicy: 'cache-and-network',
  });

  return useMemo(() => {
    if (loading || error || !data) return [];
    const tempGuests = [...data.externalGuests];
    const externalGuests = tempGuests.map((g) => {
      return ({
        ...g,
        role: g.roles[0],
      }
      );
    });
    return externalGuests;
  }, [data, loading, error]);
};

export default useCollaboratorAccounts;
