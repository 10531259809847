// @ts-nocheck
import React, {ReactNode} from 'react';
import { compose, withHandlers, HOC } from 'recompose';
import { Toggle } from '../../../components/Common/Form';
import { Button } from 'common-ui/Button';
import { usePageDisplay } from 'common-ui/Hooks';
import { Assign } from 'common-ui/Icon';
import { Count } from 'common-ui/Labels';
import CompanyLogo from 'components/Common/CompanyLogo';
import PageDisplay from 'components/Common/PageDisplay';
import { useIsExternalSupervisor } from 'pages/Hires/Collaborators';
import { useDetectMobile, useCompanyContext } from 'utils/Hooks';
import ShareLiveModal from './ProjectSharing/ShareLiveModal';
import { Steps } from './ProjectSharing/utils';
import styles from './styles.module.scss';
import type { AssignmentJobCounts, CompanyWithMetaData, CompanyWithData } from '../types';

type CompanySideBarUIProps = {
  company: CompanyWithMetaData,
  counts: AssignmentJobCounts,
  toggleAssignmentOpen: (param:any) => any,
  children: ReactNode,
  openModal: (param:any) => any,
  closeModal: (param:any) => any,
  project: CompanyWithData,
  disableShare: boolean,
  hideShareButton: boolean,
};

const titleForStep = (step: string | null) => {
  switch (step) {
    case Steps.shareProject:
      return 'Share Project';
    case Steps.editPermissions:
      return 'Edit Link Permissions';
    default:
      return '';
  }
};

const CompanySideBarUI = ({
  project,
  counts,
  toggleAssignmentOpen,
  children,
  openModal,
  closeModal,
  disableShare,
  hideShareButton,
}: CompanySideBarUIProps) => {
  const classNames = `${styles.assignment} ${
    project.isClosed ? `${styles.assignmentIsClosed}` : ''
  }`;
  const {
    showStatus, companyClient, lastSharedAt, company,
  } = project.data;
  const companyLogo = company && company.logo && company.logo.getURL;

  const isMobile = useDetectMobile();

  const [openPageDisplay, pageDisplayProps] = usePageDisplay();
  const { currentStep, goBack } = pageDisplayProps;
  const backFn = () => {
    if (currentStep === Steps.shareProject) {
      goBack();
    } else {
      openPageDisplay(Steps.shareProject);
    }
  };

  const { selectedCompanyID } = useCompanyContext();
  const isClientOfProject = companyClient && companyClient.id && selectedCompanyID === companyClient.id;
  const isExternalSupervisor = useIsExternalSupervisor();

  return (

    <>

      <div className={classNames}>

        <PageDisplay title={titleForStep(currentStep)} {...pageDisplayProps} goBack={backFn}>
          <ShareLiveModal
            project={project}
            handleClose={closeModal}
            companyClient={companyClient}
            openPageDisplay={openPageDisplay}
            currentStep={currentStep}

            disableShare={disableShare}
          />
        </PageDisplay>

        <div className={styles.projectDetailSection}>
          <div className={styles.toggleButton}>
            <Toggle
              checked={project.isClosed}
              onChange={toggleAssignmentOpen}
              slim
            />
          </div>
          <div className={styles.detailHeader}>
            <span>{project.name || ''}</span>
            {!hideShareButton && !isClientOfProject && !isExternalSupervisor && (
              <Button
                text={lastSharedAt ? 'Shared' : 'Share'}
                icon={<Assign />}
                className={styles.shareButton}
                onClick={() => {
                  if (!isMobile) {
                    openModal(
                      <ShareLiveModal
                        project={project}
                        handleClose={closeModal}
                        companyClient={companyClient}

                        disableShare={disableShare}
                      />,
                    );
                  } else {
                    openPageDisplay(Steps.shareProject);
                  }
                }}
              />
            )}
          </div>
          <div>
            <CompanyLogo
              value={companyLogo}
              companyName={project.companyName}
              noPlaceholder
            />
          </div>
          <div className={styles.projectDetails}>
            {project && project.companyName && (
              <div className={styles.projectDetailsRow}>
                <div className={styles.label}>Company:</div>
                <div className={styles.value}>{project.companyName}</div>
              </div>
            )}
            {project && project.data && project.data?.client && (
              <div className={styles.projectDetailsRow}>
                <div className={styles.label}>Client:</div>
                <div className={styles.value}>{project.data.client}</div>
              </div>
            )}
            {project && project.data && project.data.jobNumber && (
              <div className={styles.projectDetailsRow}>
                <div className={styles.label}>Job No.</div>
                <div className={styles.value}>{`#${project.data.jobNumber}`}</div>
              </div>
            )}
            {project && project.foreman && (
              <div className={styles.projectDetailsRow}>
                <div className={styles.label}>Foreman:</div>
                <div className={styles.value}>{project.foreman}</div>
              </div>
            )}
            {project && project.data && project.data.pickupAddress
              && project.data.pickupAddress.fullAddress && (
              <div className={styles.projectDetailsRow}>
                <div className={styles.label}>Pick Up:</div>
                <div className={styles.value}>{`#${project.data.pickUpAddress.fullAddress}`}</div>
              </div>
            )}
          </div>
          <div />
          {showStatus && (
            <div className={styles.countSection}>
              {counts.online > 0 && (
                <Count
                  value={counts.online}
                  className={`${styles.countOnline} ${styles.count}`}
                />
              )}
              {counts.idle > 0 && (
                <Count
                  value={counts.idle}
                  className={`${styles.countIdle} ${styles.count}`}
                />
              )}
              {counts.badData > 0 && (
                <Count
                  value={counts.badData}
                  className={`${styles.countBadData} ${styles.count}`}
                />
              )}
              {counts.disconnected > 0 && (
                <Count
                  value={counts.disconnected}
                  className={`${styles.countDisconnected} ${styles.count}`}
                />
              )}
            </div>
          )}
        </div>
      </div>
      {children}
    </>
  );
};

const CompanySideBarHOC: HOC<any, CompanySideBarUIProps> = compose(
  withHandlers({

    toggleAssignmentOpen: ({ project, toggleAssignmentOpen }) => () => {
      toggleAssignmentOpen(project.data.id);
    },
  }),
);

export default CompanySideBarHOC(CompanySideBarUI);
