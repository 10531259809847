import React, {SVGProps} from 'react';

const Skip = (props: SVGProps<SVGSVGElement>) => (

  <svg width={20} height={17} viewBox="0 0 20 17" {...props}>

    <title>Skip</title>

    <path
      d="M16.222 4H6.167C2.76 4 0 6.911 0 10.5v4.497C0 16.097.898 17 2.006 17H.051c1.11 0 2.006-.897 2.006-2.003V10.5c0-2.394 1.84-4.334 4.11-4.334H16.98l-2.687 2.955a1.18 1.18 0 0 0 0 1.556.937.937 0 0 0 1.414 0l4-4.4a1.18 1.18 0 0 0 0-1.556l-4-4.4a.937.937 0 0 0-1.414 0 1.18 1.18 0 0 0 0 1.556L16.223 4z"
      fill="#162093"
      fillRule="nonzero"
    />
  </svg>
);

export default Skip;
