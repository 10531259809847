import * as React from 'react';


const OnlineDirectionIcon = (props: any) => <svg
  width={47}
  height={47}
  xmlns="http://www.w3.org/2000/svg"
  xmlnsXlink="http://www.w3.org/1999/xlink"
  {...props}
>

  <defs>

    <filter
      x="-52.2%"
      y="-52.2%"
      width="204.3%"
      height="204.3%"
      filterUnits="objectBoundingBox"
      id="a"
    >

      <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />

      <feGaussianBlur
        stdDeviation={3}
        in="shadowOffsetOuter1"
        result="shadowBlurOuter1"
      />

      <feColorMatrix
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
        in="shadowBlurOuter1"
        result="shadowMatrixOuter1"
      />

      <feMerge>

        <feMergeNode in="shadowMatrixOuter1" />

        <feMergeNode in="SourceGraphic" />
      </feMerge>
    </filter>

    <filter
      x="-84.4%"
      y="-122.7%"
      width="268.8%"
      height="345.5%"
      filterUnits="objectBoundingBox"
      id="d"
    >

      <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />

      <feGaussianBlur
        stdDeviation={2.5}
        in="shadowOffsetOuter1"
        result="shadowBlurOuter1"
      />

      <feColorMatrix
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.33984375 0"
        in="shadowBlurOuter1"
        result="shadowMatrixOuter1"
      />

      <feMerge>

        <feMergeNode in="shadowMatrixOuter1" />

        <feMergeNode in="SourceGraphic" />
      </feMerge>
    </filter>

    <ellipse id="b" cx={11.831} cy={11.5} rx={11} ry={11.5} />

    <path id="e" d="M7.626.571.241 10.417h14.77z" />
  </defs>

  <g fill="none" fillRule="evenodd">

    <g filter="url(#a)" transform="translate(12 12)">

      <mask id="c" fill="#fff">

        <use xlinkHref="#b" />
      </mask>

      <use fill="#46A4FF" xlinkHref="#b" />

      <g mask="url(#c)" fill="#1E9F76">

        <path d="M-12-11.5h48v45h-48z" />
      </g>
    </g>

    <g filter="url(#d)" transform="translate(16)">

      <mask id="f" fill="#fff">

        <use xlinkHref="#e" />
      </mask>

      <use fill="#000" xlinkHref="#e" />

      <g mask="url(#f)" fill="#1E9F76">

        <path d="M-1.23-2.462h19v16h-19z" />
      </g>
    </g>
  </g>
</svg>;

export default OnlineDirectionIcon;
