import React, {SVGProps} from 'react';

const Plus = (props: SVGProps<SVGSVGElement>) => (
   
  <svg width={22} height={22} viewBox="0 0 22 22" {...props}>
     
    <title>Add</title>
     
    <path d="M 10 5 V 17 H 12 V 5 H 12 z" fill="#162093" fillRule="evenodd" />
     
    <path d="M 5 10 H 17 V 12 H 5 V 12 z" fill="#162093" fillRule="evenodd" />
  </svg>
);

export default Plus;
