import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

export const WHO_AM_I_QUERY = gql`
  query WhoAmIQuery {
    whoami {
      id
      firstName
      lastName
      phoneNumber
      timezone
      email
      isClientOnly
      intercomHash
      avatar {
        getURL
        filename
      }
      notificationSettings {
        acceptAssignment
        acceptProject
        declineProject
        requestNewProject
        timesheetSignoffReminder
        assignmentDeclined
        addedAsProjectCollaborator
      }
      liveMapSettings {
        driverIdentifier
      }
    }
  }
`;

export const UPDATE_USER_MUTATION = gql`
  mutation UpdateProfileMutation($id: ID!, $input: AccountInput!) {
    updateAccount(id: $id, input: $input) {
      id
      firstName
      lastName
      phoneNumber
      email
    }
  }
`;

export const UPDATE_USER_NOTIFICATION_SETTINGS_MUTATION = gql`
  mutation updateNotificationSettingsMutation($input: NotificationSettingsInput!) {
    updateNotificationSettings(input: $input) {
        acceptAssignment
        acceptProject
        declineProject
        requestNewProject
        timesheetSignoffReminder
        assignmentDeclined
        addedAsProjectCollaborator
    }
  }
`;

const UPDATE_USER_LIVE_MAP_SETTINGS_MUTATION = gql`
  mutation updateUserLiveMapSettings($input: LiveMapSettingsInput!) {
    updateLiveMapSettings(input: $input) {
      driverIdentifier
    }
  }
`;

export const useUpdateLiveMapSettings = () => {
  const [updateLiveMapSettings] = useMutation(UPDATE_USER_LIVE_MAP_SETTINGS_MUTATION);
  return useCallback((input:any) => (
    updateLiveMapSettings({
      variables: { input },
      refetchQueries: ['WhoAmIQuery'],
    })
  ), [updateLiveMapSettings]);
};
