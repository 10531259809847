// @ts-nocheck
import { useMemo } from 'react';

import { useUserRoles } from 'utils/Hooks';

const useIsExternalSupervisor = () => {
  const [roles] = useUserRoles();

  return useMemo(() => roles.includes('External Supervisor'), [roles]);
};

export default useIsExternalSupervisor;
