// @ts-nocheck

import { connect } from 'formik';
import React from 'react';

import { UploadToServer } from '../Upload';
import styles from '../Upload/styles.module.scss';
import { SmallButton } from 'common-ui/Button';

import { Trash } from 'common-ui/Icon';
import { FormField } from 'components/Common/Form';

type FormikUploadToServerProps = {
  formik: any,
  label: string,
  required?: boolean,
  name: string,
  clearable?: boolean,
  id?: string,
};

const FormikUploadToServer = ({
  formik,
  label,
  required,
  name,
  clearable,
  id,
  ...inputProps
}: FormikUploadToServerProps) => (

  <FormField
    label={label}
    required={required}
    error={formik.touched[name] && formik.errors[name]}
  >

    <div className={styles.uploadControlsContainer}>

      <div id={`${id}-ToServer`}>

        <UploadToServer
          value={formik.values[name]}
          onUploaded={(value: any) => formik.setFieldValue(name, value)}
          onBlur={() => formik.setFieldTouched(name)}
          name={name}
          {...inputProps}
        />
      </div>
      {clearable && formik.values[name] && (

        <SmallButton
          id={`${id}-RemoveButton`}
          styleName="destructive"
          icon={<Trash />}
          onClick={() => formik.setFieldValue(name, null)}
          onKeyPress={() => formik.setFieldValue(name, null)}
        />
      )}
    </div>
  </FormField>
);

FormikUploadToServer.defaultProps = {
  required: false,
  clearable: false,
  id: undefined,
};

export default connect(FormikUploadToServer);
