// @ts-nocheck
import React, { ReactNode } from 'react';
import Modal from 'react-modal';
import styles from './styles.module.scss';

type TreadModalProps = {
  visible: boolean,
  children: ReactNode,
  closeModal: () => void,
};

const TreadModal = ({ visible, children, closeModal }: TreadModalProps) => {
  // Adding this to avoid warning of not having element
  if (typeof window !== 'undefined') {
    Modal.setAppElement('body');
  } else {
    Modal.setAppElement('#app');
  }
  return (
    <Modal
      isOpen={visible}
      onRequestClose={closeModal}
      className={styles.modalContent}
      overlayClassName={styles.modalOverlay}
    >
      {children}
    </Modal>
  );
};

export default TreadModal;
