// @ts-nocheck
import React, { ReactNode } from 'react';

import ClickableDiv from 'common-ui/ClickableDiv';
import ID from 'common-ui/ConsistentIDs';
import { Close } from 'common-ui/Icon';
import styles from './styles.module.scss';



type SidebarFormProps = {
  title: string | ReactNode,
  onClose?: () => void,
  hideCloseButton?: boolean,
  children: ReactNode,
};

const SidebarForm = ({
  title, onClose, hideCloseButton, children,
}: SidebarFormProps) => (

  <>

    <div className={styles.formHeader}>

      <span className={styles.textHeader}>
        {title}
      </span>
      { !hideCloseButton && (

        <ClickableDiv
          id={ID.create(
            ID.Areas.General,
            ID.Types.Button,
            ID.Labels.CloseSidebarButton,
          )}
          onClick={onClose}
          className={styles.closeIcon}>
          <Close />
        </ClickableDiv>
      )}
    </div>
    {children}
  </>
);

SidebarForm.defaultProps = {
  onClose: () => {},
  hideCloseButton: false,
};

export default SidebarForm;
