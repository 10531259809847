// @ts-nocheck
import { graphql } from '@apollo/client/react/hoc';
import _ from 'lodash';
import moment from 'moment-timezone';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import {
  compose,
  mapProps,
  withProps,
  HOC,
} from 'recompose';

import { CloseButton, Button } from 'common-ui/Button';
import { useTimezone } from 'common-ui/Hooks';

import { Switch } from 'common-ui/Icon';
import { Notification , useNotificationsAndRequests } from 'components/NotificationsSidebar';
import { assignedStatuses } from 'pages/Projects/utils/projects.utils';
import { withUserTimezone, withCompanyContext } from 'utils/HOC';

import { useCompanyContext } from 'utils/Hooks';
import {  CLEAR_NOTIFICATIONS_MUTATION, ALL_NOTIFICATIONS_AND_REQUESTS_QUERY } from './gql';
import styles from './styles.module.scss';
import {
  NotificationsType,
  NotificationsListPropTypes,
  NotificationsSidebarPropTypes,
} from './types';







const NOTIFICATIONS_GROUP_MOMENT_FORMAT = 'YYYY-MM-DD-HH:mm';
const DATE_FORMAT = 'YYYY-MM-DD';

type getNotificationPropsType = {

  notification: NotificationType,
  closeNotificationSidebar: () => void,
  timezone: string,
};

const CustomNotification = ({
  notification,
  closeNotificationSidebar,
  timezone,
}: getNotificationPropsType) => {
  let projectDate = '';
  const { selectedCompanyID } = useCompanyContext();
  switch (notification.type) {
    case 'request':
      return (

        <Link
          to={{
            pathname: '/requests',
            search: `company=${selectedCompanyID}&requestType=Inbound&status=Pending`,
          }}
          key={notification.id}
          onClick={closeNotificationSidebar}
        >
          <Notification
            key={notification.id}
            notification={notification}
          />
        </Link>
      );
    case 'accepted':
      projectDate = !!notification.extraInfo
        && notification.extraInfo.ProjectDate

        && moment(notification.extraInfo.ProjectDate).tz(timezone).format(DATE_FORMAT);
      return (

        <Link
          to={{
            pathname: '/dispatch',
            search: `company=${selectedCompanyID}&date=${projectDate || ''}`,
          }}
          key={notification.id}
          onClick={closeNotificationSidebar}
        >
          <Notification
            key={notification.id}
            notification={notification}
          />
        </Link>
      );
    case 'requestaccepted':
      projectDate = !!notification.extraInfo
        && notification.extraInfo.ProjectDate

        && moment(notification.extraInfo.ProjectDate).tz(timezone).format(DATE_FORMAT);
      return (

        <Link
          to={{
            pathname: '/dispatch',
            search: `company=${selectedCompanyID}`,
          }}
          key={notification.id}
          onClick={closeNotificationSidebar}
        >
          <Notification
            key={notification.id}
            notification={notification}
          />
        </Link>
      );
    case 'declined':
      projectDate = !!notification.extraInfo
        && notification.extraInfo.ProjectDate

        && moment(notification.extraInfo.ProjectDate).tz(timezone).format(DATE_FORMAT);
      return (

        <Link
          to={{
            pathname: '/dispatch',
            search: `company=${selectedCompanyID}&date=${projectDate || ''}`,
          }}
          key={notification.id}
          onClick={closeNotificationSidebar}
        >
          <Notification
            key={notification.id}
            notification={notification}
          />
        </Link>
      );
    case 'requestdeclined':
      projectDate = !!notification.extraInfo
          && notification.extraInfo.ProjectDate

          && moment(notification.extraInfo.ProjectDate).tz(timezone).format(DATE_FORMAT);
      return (

        <Link
          to={{
            pathname: '/requests',
            search: `company=${selectedCompanyID}&requestType=Inbound&status=Declined`,
          }}
          key={notification.id}
          onClick={closeNotificationSidebar}
        >
          <Notification
            key={notification.id}
            notification={notification}
          />
        </Link>
      );
    case 'idle':
    case 'lost':
      return (

        <Link
          to={{
            pathname: '/live',
            search: `company=${selectedCompanyID}`,
          }}
          key={notification.id}
          onClick={closeNotificationSidebar}
        >
          <Notification
            key={notification.id}
            notification={notification}
          />
        </Link>
      );
    case 'SmartSchedulerJobComplete':
      return (

        <Link
          to={`/dispatch?date=${notification.extraInfo.Date}`}
          key={notification.id}
          onClick={closeNotificationSidebar}
        >
          <Notification
            key={notification.id}
            notification={notification}
          />
        </Link>
      );
    case 'UploadProjectsCSV':
      return (

        <Notification
          key={notification.id}
          notification={notification}
        />
      );
    default:
      return (

        <Notification
          key={notification.id}
          notification={notification}
        />
      );
  }
};

const NotificationList = ({
  list,
  closeNotificationSidebar,
}: NotificationsListPropTypes) => {
  const timezone = useTimezone();
  return (

    [...list].map(([groupDateKey, notifications]) => {
      if (notifications.length === 0) {
        return null;
      }

      const formattedDate = moment(groupDateKey, NOTIFICATIONS_GROUP_MOMENT_FORMAT).tz(timezone).format('MMM DD h:mma');
      return (

        <div key={groupDateKey}>
          <div className={styles.notificationDateHeader}>
            {formattedDate}
          </div>
          {notifications.map(

            (notification, i) => (
              <CustomNotification
                notification={notification}
                closeNotificationSidebar={closeNotificationSidebar}
                timezone={timezone}
                key={`CustomNotification-${i}`}
              />
            ),
          )
          }
        </div>
      );
    })
  );
};

export const removeDeclinedNotifications = (notifications: NotificationsType[]) => {

  const requestNotificationNoDeclined = [];
  notifications.map((notification) => {
    if (notification.hasDeclined === false) {
      requestNotificationNoDeclined.push(notification);

      return requestNotificationNoDeclined;
    }
    return null;
  });

  return requestNotificationNoDeclined;
};

export const groupNotificationsByDate = (notifications: NotificationsType[]) => {
  const sortedNotifications = _.reverse(_.sortBy(
    notifications,

    a => (moment(a.date)),
  ));

  const sortResult = _.reduce(
    sortedNotifications,

    (result, value) => {

      const groupKey = moment(value.date).format(NOTIFICATIONS_GROUP_MOMENT_FORMAT);
      const mapEntry = result.get(groupKey) || [];

      mapEntry.push(value);
      result.set(groupKey, mapEntry);

      return result;
    },
    new Map(),
  );

  return sortResult;
};


const getNotificationTypeName = (notificationType) => {
  switch (notificationType) {
    case 'AcceptAssignment':
      return 'accepted';
    case 'AcceptSubdispatch':
      return 'requestaccepted';
    case 'AssignmentDeclined':
      return 'declined';
    case 'SubdispatchDeclined':
      return 'requestdeclined';
    case 'SmartSchedulerJobComplete':
      return 'SmartSchedulerJobComplete';
    case 'HireIdle':
      return 'idle';
    case 'HireDisconnected':
      return 'lost';
    case 'UploadProjectsCSV':
      return 'UploadProjectsCSV';
    default:
      return '';
  }
};


const parseNotificationExtraInfo = (extraInfo) => {
  try {
    return JSON.parse(extraInfo);
  } catch {
    return {};
  }
};

export const NotificationsSidebarBase = (
  {
    notificationsSidebar,
    requestNotificationsGroupedByDate,
    notificationsGroupedByDate,
    clearAllNotifications,
  }: NotificationsSidebarPropTypes,
) => (

  <div>

    <div className={styles.sidebarHeading}>

      <div className={styles.textHeader}>

        <FormattedMessage
          id="notifications.viewRequests"
          description="Requests Header"
          defaultMessage="Requests"
        />
      </div>

      <CloseButton
        onClick={() => notificationsSidebar.setVisible(!notificationsSidebar.visible)}
      />
    </div>
    {requestNotificationsGroupedByDate.size <= 0
      && (

        <div>
          <FormattedMessage
            id="notifications.noNewRequests"
            defaultMessage="No new requests"
          />
        </div>
      )
    }

    <NotificationList
      list={requestNotificationsGroupedByDate}
      closeNotificationSidebar={() => notificationsSidebar.setVisible(false)}
    />

    <div className={styles.sidebarHeading}>

      <div className={styles.textHeader}>

        <FormattedMessage
          id="notifications.viewNotifications"
          description="Notifications Header"
          defaultMessage="Notifications"
        />
      </div>
    </div>

    <NotificationList
      list={notificationsGroupedByDate}
      closeNotificationSidebar={() => notificationsSidebar.setVisible(false)}
    />
    {notificationsGroupedByDate.size > 0
      ? (

        <Button
          fullWidth
          icon={<Switch />}
          text={(
            <FormattedMessage
              id="notifications.clearAllNotifications"
              defaultMessage="Clear all notifications"
            />
          )}
          onClick={clearAllNotifications}
        />
      ) : (

        <div>
          <FormattedMessage
            id="notifications.noNewNotifications"
            defaultMessage="No new notifications"
          />
        </div>
      )
    }
  </div>
);

export const NotificationsHOC: HOC<any, any> = compose(
  withUserTimezone,
  withCompanyContext,
  graphql(CLEAR_NOTIFICATIONS_MUTATION, {
    name: 'clearAllNotifications',
    options: {
      refetchQueries: [{
        query: ALL_NOTIFICATIONS_AND_REQUESTS_QUERY,
        variables: {
          companyID: () => {
            const { selectedCompanyID } = useCompanyContext();
            return selectedCompanyID;
          },
        },
      }],
    },
  }),

  withProps(({ ...props }) => {
    const data = useNotificationsAndRequests();
    return { ...props, data };
  }),

  mapProps(({ data, ...restProps }) => {
    const requests = (!!data.loading || !(data.requests)) ? []

      : data.requests.map((request) => {
        const { project, schedule } = request;
        if (project) {
          return (
            {
              id: project.id,
              date: project.startTime,
              type: 'request',
              hire: request.requestedBy,
              parentCompany: {
                companyName: project.companyName,
                companyNumber: project.jobNumber,
              },
              hasDeclined: project.assignments.some(

                a => a.assignedStatus === assignedStatuses.DECLINED,
              ),
            }
          );
        }

        const scheduleProject = schedule.projects[0];
        return (
          {
            id: schedule.scheduleId,
            date: schedule.startTime,
            type: 'request',
            hire: request.requestedBy,
            parentCompany: {
              companyName: scheduleProject.companyName,
              companyNumber: scheduleProject.jobNumber,
            },
            hasDeclined: schedule.assignments.some(

              a => a.assignedStatus === assignedStatuses.DECLINED,
            ),
          }
        );
      });

    const notificationsGroupedByDate = (
      !!data.loading || !(data.notifications)
    )
      ? []

      : groupNotificationsByDate(data.notifications.map(n => (
        {
          id: n.id,
          date: n.createdAt,
          type: getNotificationTypeName(n.notificationType),
          hire: n.aboutHire,
          extraInfo: parseNotificationExtraInfo(n.notificationExtraInfo),
        }
      )));
    const requestNotificationsPendingOnly = removeDeclinedNotifications(requests);
    const requestNotificationsGroupedByDate = groupNotificationsByDate(requestNotificationsPendingOnly);
    return {
      requestNotificationsGroupedByDate,
      notificationsGroupedByDate,
      ...restProps,
    };
  }),
);

export default NotificationsHOC(NotificationsSidebarBase);
