import PhoneNumber from 'awesome-phonenumber';

const formatPhoneNumber = (phoneNumber: string) => {
  let pn = new PhoneNumber(phoneNumber);
  if (!pn.getRegionCode()) {
    pn = new PhoneNumber(phoneNumber, 'US');
  }

  return pn.isValid() ? pn.getNumber('e164') : phoneNumber;
};

export default formatPhoneNumber;
