const APIURL = process.env.REACT_APP_API_URL;
const APIAUTHURL = process.env.REACT_APP_API_AUTH_URL;
const APIWSURL = process.env.REACT_APP_API_WS_URL;
export const PUBLIC_API_URL = process.env.REACT_APP_PUBLIC_API_URL;

export function apiURL() {
  return APIURL;
}

export function apiAuthURL() {
  return APIAUTHURL;
}

export function apiWSURL() {
  return APIWSURL;
}
