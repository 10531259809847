import _ from 'lodash';
import { useMemo } from 'react';
// This hook takes in the text input value,
// a list of options, the currently selected value
// and returns the list filtered againt the input value
const useInputFilteredOptions = (inputValue: any, selectedValue: any, options: any) => useMemo(() => {
  if (selectedValue === null) {
    const newOptions = options.filter((o: any) => {
      const labelLower = _.trim(_.toLower(o.label));
      const lowerInput = _.trim(_.toLower(inputValue));
      return lowerInput === '' || labelLower.includes(lowerInput);
    });
    return newOptions;
  }
  return options;
}, [inputValue, selectedValue, options]);

export default useInputFilteredOptions;
