import React, {SVGProps} from 'react';

const Filter = (props: SVGProps<SVGSVGElement>) => (

  <svg
    width="25px"
    height="25px"
    viewBox="0 0 25 25"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >

    <title>Icon/Filter Secondary</title>

    <desc>Created with Sketch.</desc>

    <g transform="translate(2.000000, 3.000000)">

      <path
        fill="none"
        fillRule="evenodd"
        d="M11.55,14.4444444 L11.55,13.285578 C11.55,12.0846531 12.5238527,11.1111111 13.7293782,11.1111111 L15.9706218,11.1111111 C17.1742591,11.1111111 18.15,12.0792992 18.15,13.285578 L18.15,14.4444444 L20.9090166,14.4444444 C21.5115501,14.4444444 22,14.9376276 22,15.529516 L22,15.5815951 C22,16.1808636 21.5200388,16.6666667 20.9090166,16.6666667 L18.15,16.6666667 L18.15,17.8255332 C18.15,19.026458 17.1761473,20 15.9706218,20 L13.7293782,20 C12.5257409,20 11.55,19.031812 11.55,17.8255332 L11.55,16.6666667 L1.09098342,16.6666667 C0.488449915,16.6666667 0,16.1734835 0,15.5815951 L0,15.529516 C0,14.9302475 0.479961187,14.4444444 1.09098342,14.4444444 L11.55,14.4444444 Z M3.85,3.33333333 L3.85,2.17446685 C3.85,0.973541969 4.82385273,0 6.02937823,0 L8.27062177,0 C9.47425913,0 10.45,0.968188047 10.45,2.17446685 L10.45,3.33333333 L20.9090166,3.33333333 C21.5115501,3.33333333 22,3.82651647 22,4.4184049 L22,4.47048399 C22,5.06975247 21.5200388,5.55555556 20.9090166,5.55555556 L10.45,5.55555556 L10.45,6.71442204 C10.45,7.91534692 9.47614727,8.88888889 8.27062177,8.88888889 L6.02937823,8.88888889 C4.82574087,8.88888889 3.85,7.92070084 3.85,6.71442204 L3.85,5.55555556 L1.09098342,5.55555556 C0.488449915,5.55555556 0,5.06237242 0,4.47048399 L0,4.4184049 C0,3.81913642 0.479961187,3.33333333 1.09098342,3.33333333 L3.85,3.33333333 Z M6.05,2.22222222 L6.05,6.66666667 L8.25,6.66666667 L8.25,2.22222222 L6.05,2.22222222 Z M13.75,13.3333333 L13.75,17.7777778 L15.95,17.7777778 L15.95,13.3333333 L13.75,13.3333333 Z"
      />
    </g>
  </svg>
);

export default Filter;
