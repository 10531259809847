// @ts-nocheck
import moment from 'moment-timezone';
import React from 'react';
import { FormattedMessage } from 'react-intl';


import { useTimezone } from 'common-ui/Hooks';
import { HireInitials } from 'components/Common/Hires/HireInitials';
import type { SiteActivityItemType } from 'pages/Sites/types';
import styles from './styles.module.scss';



// Separate firstname/lastname with projects name to get initals for avatar
const getSplitNames = (name: string) => {
  const splitName = name.split(' ');

  // Case where it's just one project name
  if (splitName.length === 1) {
    return [name, ''];
  }
  return [splitName[0], splitName[1]];
};


const formatDateTime = (timestamp: string, timezone: string) => moment.tz(timestamp, timezone).format('MMM DD, YYYY LT');

const SiteActivityItem = ({
  action,
  details,
  time,
  name,
}: SiteActivityItemType) => {
  const initals = getSplitNames(name);
  const timezone = useTimezone();

  return (

    <div className={styles.siteActivityItemWrapper}>

      <HireInitials
        firstName={initals[0]}
        lastName={initals[1]}
        className={styles.siteActivityItemAvatar}
      />

      <div>

        <div className={styles.siteActivityItemDescription}>
          <span className={styles.siteActivityItemAction}><FormattedMessage id={action} /></span>
          &nbsp;
          {details}
        </div>

        <div className={styles.siteActivityItemTime}>{formatDateTime(time, timezone)}</div>
      </div>
    </div>
  );
};

export default SiteActivityItem;
