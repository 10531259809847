// @ts-nocheck
import React from 'react';

import { Button } from 'common-ui/Button';
import { Empty } from 'common-ui/Empty';

import { Request } from 'common-ui/Icon';

import OverviewContent from 'components/OverviewContent';
import styles from './styles.module.scss';

const RequestDemo = () => (

  <div className={styles.requestDemoContainer}>

    <OverviewContent>

      <Empty
        title="Request a Free Demo Today"
        text="Get the full benefit of using Tread. Save 30% on dispatch time and costs."
        button={(
          <a href="https://tread.io/request-demo">
            <Button
              icon={<Request />}
              styleName="primary"
              text="Request Free Demo"
            />
          </a>
        )}
      />
    </OverviewContent>
  </div>
);

export default RequestDemo;
