// @ts-nocheck
import { useMemo } from 'react';

// TODO: change type to array of Collaborators
const useCollaboratorMap = (collaborators: any) => useMemo(() => (

  collaborators.reduce((acc, curr) => ({
    ...acc,
    [curr.accountId]: curr
  }), {})
), [collaborators.length]);

export default useCollaboratorMap;
