/* eslint-disable no-unused-expressions */
// @ts-nocheck
import { useRef } from 'react';


/**
 * Async function sets a slight delay below the focus occurs which
 * is useful in situations when the element needs a bit of time to
 * render like for example the filters modal
 */
async function setFocusAsync(ref: any, delayMs: any) {
  setTimeout(() => {
    if (ref && ref.current) {
      ref.current.focus();
    }
  }, delayMs);
}

/**
 * use this hook to programmatically set the focus of an element
 * const FocusDemo = () => {
    const [inputRef, setInputFocus] = useFocus()
    return (
        <>
            <button onClick={setInputFocus} >
               FOCUS
            </button>
            <input ref={inputRef} />
        </>
    )
}
 */
const useFocus = (delayMs = 0) => {
  const ref = useRef();
  const setFocus = () => { setFocusAsync(ref, delayMs); };

  return [ref, setFocus];
};

export default useFocus;
