// @ts-nocheck
import { connect } from 'formik';
import React from 'react';


import { Toggle } from '..';
import Analytics from 'common-ui/Analytics/SegmentAnalytics';
import {Events} from 'common-ui/Analytics/types';

type FormikToggleProps = {
  formik: any,
  name: string,
  analyticsAction?: string,
};

const FormikToggle = ({
  formik,
  name,
  analyticsAction,
  ...inputProps
}: FormikToggleProps) => (

  <Toggle
    checked={formik.values[name]}
    onChange={(value) => {
      formik.setFieldValue(name, value);
      if (analyticsAction) {

        Analytics.pushEvent(Events.formChange, analyticsAction, value);
      }
    }}

    onBlur={() => formik.setFieldTouched(name)}
    name={name}
    {...inputProps}
  />
);

FormikToggle.defaultProps = {
  analyticsAction: '',
};

export default connect(FormikToggle);
