import React, {SVGProps} from 'react';

const PDFPlaceholder = (props: SVGProps<SVGSVGElement>) => (
   
  <svg
    width="100"
    height="161"
    viewBox="0 0 133 161"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
     
    <mask id="path-1-inside-1" fill="white">
       
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M96.1385 0H5C2.23858 0 0 2.23857 0 5V155.93C0 158.691 2.23857 160.93 5 160.93H128C130.761 160.93 133 158.691 133 155.93V36.8615L96.1385 0Z"
      />
    </mask>
     
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M96.1385 0H5C2.23858 0 0 2.23857 0 5V155.93C0 158.691 2.23857 160.93 5 160.93H128C130.761 160.93 133 158.691 133 155.93V36.8615L96.1385 0Z"
      fill="white"
    />
     
    <path
      d="M96.1385 0L98.2599 -2.12132L97.3812 -3H96.1385V0ZM133 36.8615H136V35.6188L135.121 34.7401L133 36.8615ZM5 3H96.1385V-3H5V3ZM3 5C3 3.89543 3.89543 3 5 3V-3C0.581723 -3 -3 0.581719 -3 5H3ZM3 155.93V5H-3V155.93H3ZM5 157.93C3.89543 157.93 3 157.035 3 155.93H-3C-3 160.348 0.581718 163.93 5 163.93V157.93ZM128 157.93H5V163.93H128V157.93ZM130 155.93C130 157.035 129.105 157.93 128 157.93V163.93C132.418 163.93 136 160.348 136 155.93H130ZM130 36.8615V155.93H136V36.8615H130ZM135.121 34.7401L98.2599 -2.12132L94.0172 2.12132L130.879 38.9828L135.121 34.7401Z"
      fill="#D8D8D8"
      mask="url(#path-1-inside-1)"
    />
     
    <path
      d="M96 0.5V33C96 35.2091 97.7909 37 100 37H132"
      stroke="#D8D8D8"
      strokeWidth="3"
    />
     
    <path
      d="M24.908 94.544C24.908 93.968 25.016 93.428 25.232 92.924C25.448 92.396 25.748 91.94 26.132 91.556C26.516 91.172 26.96 90.872 27.464 90.656C27.992 90.44 28.544 90.332 29.12 90.332C29.696 90.332 30.236 90.44 30.74 90.656C31.268 90.872 31.724 91.172 32.108 91.556C32.492 91.94 32.792 92.396 33.008 92.924C33.224 93.428 33.332 93.968 33.332 94.544C33.332 95.12 33.224 95.672 33.008 96.2C32.792 96.704 32.492 97.148 32.108 97.532C31.724 97.916 31.268 98.216 30.74 98.432C30.236 98.648 29.696 98.756 29.12 98.756C28.544 98.756 27.992 98.648 27.464 98.432C26.96 98.216 26.516 97.916 26.132 97.532C25.748 97.148 25.448 96.704 25.232 96.2C25.016 95.672 24.908 95.12 24.908 94.544ZM45.1344 83.312H47.4744C50.0664 83.312 51.3624 82.184 51.3624 79.928C51.3624 77.672 50.0664 76.544 47.4744 76.544H45.1344V83.312ZM45.1344 98H38.0784V70.856H49.3104C52.3584 70.856 54.6864 71.648 56.2944 73.232C57.9264 74.816 58.7424 77.048 58.7424 79.928C58.7424 82.808 57.9264 85.04 56.2944 86.624C54.6864 88.208 52.3584 89 49.3104 89H45.1344V98ZM69.5328 92.024H71.1528C72.3768 92.024 73.4688 91.832 74.4288 91.448C75.3888 91.064 76.1928 90.536 76.8408 89.864C77.5128 89.192 78.0168 88.4 78.3528 87.488C78.7128 86.552 78.8928 85.532 78.8928 84.428C78.8928 83.348 78.7128 82.34 78.3528 81.404C77.9928 80.468 77.4768 79.664 76.8048 78.992C76.1568 78.32 75.3528 77.792 74.3928 77.408C73.4328 77.024 72.3528 76.832 71.1528 76.832H69.5328V92.024ZM62.4768 70.856H72.9168C74.7648 70.856 76.4928 71.228 78.1008 71.972C79.7328 72.716 81.1488 73.712 82.3488 74.96C83.5728 76.184 84.5328 77.624 85.2288 79.28C85.9248 80.912 86.2728 82.628 86.2728 84.428C86.2728 86.204 85.9248 87.92 85.2288 89.576C84.5568 91.208 83.6088 92.648 82.3848 93.896C81.1848 95.144 79.7688 96.14 78.1368 96.884C76.5288 97.628 74.7888 98 72.9168 98H62.4768V70.856ZM106.33 76.832H97.8336V81.368H105.574V87.344H97.8336V98H90.7776V70.856H106.33V76.832Z"
      fill="#9B9B9B"
    />
  </svg>
);

export default PDFPlaceholder;
