// @ts-nocheck
import { useMemo } from 'react';

import type { HireTag } from 'common-ui/types';

// Returns a mapping of a tag's id to its name
const useTagsMap = (tags: HireTag[]) => useMemo(() => (
  tags.reduce((acc, curr) => ({ ...acc, [curr.id]: curr.name }), {})
), [tags.length]);

export default useTagsMap;
