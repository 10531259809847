// @ts-nocheck
import classNames from 'classnames';
import React, { ReactNode } from 'react';

import { CardDivider } from 'common-ui/Cards';
import styles from './styles.module.scss';

type DetailsRowFlexProps = {
  label: string,
  labelIcon?: ReactNode,
  children: ReactNode,
  labelStyle?: string,
};

/**
 * This component is to be used as a child for DetailsTableFlex
 */
const DetailsRowFlex = ({
  label,
  labelIcon,
  children,
  labelStyle,
}: DetailsRowFlexProps) => (

  <>

    <div className={styles.detailsRowContainer}>

      <div className={styles.detailsRowLabel}>

        <div>{label}</div>
        { labelIcon }
      </div>

      <div className={

        classNames(styles.detailsRowValue, labelStyle)}
      >
        {children || '-'}
      </div>
    </div>

    <CardDivider />
  </>
);

DetailsRowFlex.defaultProps = {
  labelIcon: undefined,
  labelStyle: undefined,
};

export default DetailsRowFlex;
