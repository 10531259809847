// @ts-nocheck
import React from 'react';

import { Truck } from 'common-ui/Icon';
import styles from './styles.module.scss';

const SubdispatchIcon = () => (

  <div className={styles.subdispatchIcon}>

    <Truck />
  </div>
);

export default SubdispatchIcon;
