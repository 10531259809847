// @ts-nocheck
import _ from 'lodash';
import React from 'react';


import { components } from 'react-select';
import ReactSelect from 'react-select/creatable';

import  { Filter } from '../types';
import Analytics from 'common-ui/Analytics/SegmentAnalytics';
import {Actions} from 'common-ui/Analytics/types';

import styles from './styles.module.scss';

const EmptyContainer = () => null;

// Update Input to have placeholder, after each entry is added
const Input = (props: any) => {
  const {
    selectProps: { placeholderText },
  } = props;
  return (

    <components.Input
      className={styles.searchSelect}
      placeholder={placeholderText}
      {...props}
    />
  );
};

type MultiSelectWithoutValuesProps = {

  onSelectTerm: (param:any) => any,

  onCreateOption: (param:string) => any,
  possibleOptions: Array<Filter>,
  selectedTerms: Array<Filter>,
  placeholderText: string,
  formatCreateLabel: (param:string) => string,
  disabled: boolean
};

const MultiSelectWithoutValues = ({
  disabled,
  onSelectTerm,
  onCreateOption,
  possibleOptions,
  selectedTerms,
  placeholderText,
  formatCreateLabel,
}: MultiSelectWithoutValuesProps) => (

  <ReactSelect
    isDisabled={disabled}
    classNamePrefix="react-select"
    components={{
      DropdownIndicator: null,
      IndicatorSeparator: null,
      MultiValueContainer: EmptyContainer,
      Input,
      IndicatorsContainer: EmptyContainer,
    }}

    theme={theme => ({
      ...theme,
      colors: {
        ...theme.colors,
        primary: styles.colorSelected,
        primary25: styles.colorSelectedLight,
        primary50: styles.colorSelectedMedium,
      },
    })}
    isMulti
    isSearchable
    placeholderText={placeholderText}
    className={styles.searchInput}
    onChange={onSelectTerm}
    onCreateOption={onCreateOption}
    createOptionPosition="first"

    isValidNewOption={(inputVal, selectVal, selectOptions) => {
      if (_.find(selectOptions, so => so.label === inputVal)) {
        return false;
      }
      return true;
    }}
    onFocus={() => Analytics.startTiming(Actions.filterUpdate)}
    onBlur={() => Analytics.stopTiming('Filtering', Actions.filterUpdate)}
    options={possibleOptions}
    value={selectedTerms}
    styles={{
      container: () => ({ paddingLeft: 0 }),
    }}
    placeholder=""
    formatCreateLabel={formatCreateLabel}
  />
);

export default MultiSelectWithoutValues;
