// @ts-nocheck
import classNames from 'classnames';
import React from 'react';

import { Plug } from 'common-ui/Icon';
import { Tag } from 'common-ui/Labels';
import styles from './styles.module.scss';


const ProjectSourceTag = () => (

  <Tag
    text="Apex generated"

    leftIcon={<Plug />}

    className={classNames(styles.projectGeneratedTag, styles.projectCardTag)}
  />
);

export default ProjectSourceTag;
