// @ts-nocheck
import { useMutation, gql } from '@apollo/client';
import PhoneNumber from 'awesome-phonenumber';
import { useCallback } from 'react';

import { COLLABORATORS_QUERY } from 'components/Common/Form/CollaboratorInput/hooks';
import { useCompanyContext } from 'utils/Hooks';


const ADD_COLLABORATOR_MUTATION = gql`
  mutation addExternalGuest($companyID: ID!, $input: ExternalGuestInput!) {
    addExternalGuest(companyID: $companyID, input: $input) {
      id
      accountId
      firstName
      lastName
      roles
      companyName : externalCompanyName
      phoneNumber
      email
      roles
    }
  }
`;

export type ExternalGuestInput = {
  firstName: string,
  lastName: string,
  phoneNumber: string,
  companyName: string,
  role: string,
  email: string,
};

const useAddCollaborator = () => {
  const [addCollaborator] = useMutation(ADD_COLLABORATOR_MUTATION);
  const { selectedCompanyID } = useCompanyContext();
  return useCallback((values: ExternalGuestInput) => {
    const phoneNumber = new PhoneNumber(values.phoneNumber, 'CA').getNumber('e164');
    return addCollaborator({
      variables: {
        companyID: selectedCompanyID,
        input: {
          ...values,
          phoneNumber,
        },
      },
      update: (store, { data: { addExternalGuest: newGuest } }) => {
        store.modify({
          fields: {
            collaborators: (existing = []) => {
              const newCollaboratorRef = store.writeFragment({
                data: newGuest,
                fragment: gql`
                  fragment newCollaborator on Collaborator {
                    id
                    accountId
                    firstName
                    lastName
                    roles
                    companyName : externalCompanyName
                    phoneNumber
                    email
                    roles
                  }
                `,
              });
              return [...existing, newCollaboratorRef];
            },
          },
        });
      },
    }).then(({ data }) => {
      return data.addExternalGuest;
    });
  }, [addCollaborator, selectedCompanyID]);
};

export default useAddCollaborator;
