// @ts-nocheck
import classNames from 'classnames';
import React from 'react';
import { Button } from 'common-ui/Button';

import { Radio } from 'common-ui/Form';

import { driverStatus } from 'components/Common/Search/util/filters';
import styles from './styles.module.scss';

const FilterDetail = ({
  label, detail,

}: FilterDetailProps) => (

  <div className={styles.popupSection}>

    <div className={styles.popupDetails}>

      <div className={styles.popupHeader}>{label}</div>
      {detail}
    </div>
  </div>
);

type DriverStatusFilterProps = {

  currentStatusFilter: any,

  statusSelected: any,
  setStatusSelected: (any: any) => void,
  options: [],
  filterItems: [],
  onFilterChange: (any: any) => void,
  setMenuOpen: (isOpen: boolean) => void,
};

const DriverStatusFilter = ({
  currentStatusFilter,
  statusSelected,
  setStatusSelected,
  options,
  filterItems,
  onFilterChange,
  setMenuOpen,
}: DriverStatusFilterProps) => (

  <div>

    <div className={classNames(styles.popupMain, styles.bottomBorder)}>

      <Radio
        value={statusSelected}
        onChange={(value: any) => {
          setStatusSelected(value);
        }}
        options={[
          {
            label: <FilterDetail label="Pick-up" detail="See drivers who are at their pick-up site." />,
            value: driverStatus.PICKUP,
          },
          {
            label: <FilterDetail label="Driving" detail="See drivers who are on route to a pick-up or drop-off site." />,
            value: driverStatus.DRIVING,
          },
          {
            label: <FilterDetail label="Drop-off" detail="See drivers who are at their drop-off site." />,
            value: driverStatus.DROPOFF,
          },
        ]}
      />
    </div>

    <div className={styles.buttons}>

      <Button
        text="Clear"
        disabled={!statusSelected}
        onClick={() => {
          setStatusSelected(null);
        }}
      />

      <Button
        text="Save"
        styleName="primary"
        onClick={() => {
          let newFilterItems = filterItems;
          const filterValue = options.find(option => option.filterValue === statusSelected);
          if (filterValue !== currentStatusFilter) {

            newFilterItems = [...filterItems.filter(item => item !== currentStatusFilter)];
          }
          if (filterValue) {

            newFilterItems = [...newFilterItems, filterValue];
          }
          onFilterChange(newFilterItems);
          setMenuOpen(false);
        }}
      />
    </div>
  </div>
);


export default DriverStatusFilter;
