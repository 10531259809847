import React, {SVGProps} from 'react';

const ChevronDown = (props: SVGProps<SVGSVGElement>) => (

  <svg
    width="12"
    height="6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >

    <path d="M6 6L.804.75h10.392L6 6z" fill="#fff" />
  </svg>
);

export default ChevronDown;
