// @ts-nocheck
import { withFormik, Form } from 'formik';
import queryString from 'query-string';
import React, { useEffect } from 'react';


// Higher Order Components
import { withRouter } from 'react-router-dom';
import { compose, withHandlers } from 'recompose';



import { sendErrorAlert } from '../../apolloClient/client';
import { post } from '../../utils/axios';
import Analytics from 'common-ui/Analytics/SegmentAnalytics';
import {Events, Actions} from 'common-ui/Analytics/types';

// Components - Quark
import { Button, BackButton } from 'common-ui/Button';

import ID from 'common-ui/ConsistentIDs';

import { ChevronRight } from 'common-ui/Icon';
import { FormikTextInput } from 'components/Common/Form';
import styles from './styles.module.scss';





const VerifyCodeForm = ({ handleBack }: { handleBack: () => void }) => {
  useEffect(() => {
    Analytics.pushEvent(Events.login, Actions.startVerifyCode);
  }, []);

  return (

    <Form>

      <div className={styles.verifyCodeFormWrapper}>

        <BackButton onClick={() => handleBack()} text="" />

        <div className={styles.verifyCodeFormInputWrapper}>
          <FormikTextInput
            label="Verification Code"
            name="code"
            placeholder="ex. 12345"
            type="tel"
            autoFocus
            id={ID.create(ID.Areas.Login, ID.Types.Input, ID.Labels.VerificationCode)}
          />
        </div>
      </div>

      <Button
        type="submit"
        styleName="primary"
        text="Sign In"

        icon={<ChevronRight />}
        rightIcon
        fullWidth
        id={ID.create(ID.Areas.Login, ID.Types.Button, ID.Labels.SignIn)}
      />
    </Form>
  );
};

const redirectURL = () => {
  const q = queryString.parse(window.location.search);
  // Make sure its an internal path i.e starts with "/"
  return q.des && q.des.startsWith('/') ? q.des : false;
};

const formConfig = {
  mapPropsToValues: () => ({ code: '' }),
  validate: (values: any) => {
    const errors = {};
    if (values.code.length !== 5) {

      errors.code = true;
      errors.serverError = 'This is a required field';
    }
    return errors;
  },
  handleSubmit: ({
    code
  }: any, {
    props,
    setErrors,
    setSubmitting
  }: any) => {
    setSubmitting(true);
    props
      .verifyLoginCode(code)
      .then((res: any) => {
        if (res.data) {
          Analytics.pushEvent(Events.login, Actions.loginSuccess);
          Analytics.stopTiming(Events.login, Actions.startLogin);
          const redirect = redirectURL();
          if (redirect) {
            props.history.push(redirect);
            return;
          }
          props.history.push('/');
        } else {
          Analytics.pushEvent(Events.login, Actions.loginFailure, 'Login failed!');
          throw new Error('Login failed!');
        }
      })
      .catch((error: any) => {
        setSubmitting(false);

        if (error.response && error.response.data) {
          const {
            response: {
              status,
              data,
            },
          } = error;

          sendErrorAlert({
            statusCode: status,
            result: data,
          }, {
            operationName: 'setClientAlert',
          });

          if (error.response.status >= 400 && error.response.status < 500) {
            Analytics.pushEvent(Events.login, Actions.loginFailure, error.response.status);
            setErrors({ serverError: 'Login failed!' });
          } else {
            Analytics.pushEvent(Events.login, Actions.loginFailure, error.response.data.toString());
            setErrors({ serverError: error.response.data.toString() });
          }
        } else {
          Analytics.pushEvent(Events.login, Actions.loginFailure, error.toString());
          setErrors({ serverError: error.toString() });
        }
      });
  },
};

export default compose(
  withRouter,
  withHandlers({
    verifyLoginCode: () => (code: any) => {
      const params = new URLSearchParams();
      params.append('code', code);

      return post('token/auth', params, { withCredentials: true });
    },
  }),
  withFormik(formConfig),

)(VerifyCodeForm);
