// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { SVGOverlay } from 'react-map-gl';

import {
  compose,
  withProps,
} from 'recompose';

import {
  START_COLOR,
  END_COLOR,
  makeBounds,
} from '../utils/map.utils';


import IconMarker from './IconMarker';

const defaultPosition = [
  [-85.473633, 45.322461],
  [-73.322754, 41.98527],
];

const calculateBounds = (path: any, signoffPoints: any, uploadPoints: any) => {
  if ((path && path.length)
    || (signoffPoints && signoffPoints.length)
    || (uploadPoints && uploadPoints.length)) {
    const pathArray = path || [];
    const signoffArray = signoffPoints || [];
    const uploadArray = uploadPoints || [];
    return makeBounds([...pathArray, ...signoffArray, ...uploadArray]);
  }

  return defaultPosition;
};

export const ConnectPathControls = compose(

  withProps(({ path, signoffPoints, uploadPoints }) => (
    {
      calculatedBounds: calculateBounds(path, signoffPoints, uploadPoints),
    }
  )),
);

type MapPointType = {
  lat: number,
  lng: number
};

type PathPropTypes = {
  path?: Array<MapPointType>,
};

export const Path = (props: PathPropTypes) => {
  const { path } = props;

  const [animationCount, setAnimationCount] = useState(path.length);
  const [animating, setAnimating] = useState(false);

  // Animate the path by increasing the size of the slice we take every 40ms
  useEffect(() => {
    if (!animating) {
      return undefined;
    }

    setAnimationCount(0);

    const id = setInterval(() => setAnimationCount((count) => {

      if (count >= path.length) {
        setAnimating(false);
      }

      return count + 1;
    }), 40);

    return () => {
      clearInterval(id);

      setAnimationCount(path.length);
    };
  }, [animating]);

  const startReplay = () => {
    setAnimationCount(0);
    setAnimating(true);
  };

  return (

    <>
      {!!path && path.length > 0 && (

        <>
           <SVGOverlay
            redraw={(opts) => {
              const points = path.map((coords) => {
                const projected = opts.project([coords.lng, coords.lat]);
                return projected.join(' ');
              });

                   return <polyline points={points.slice(0, animationCount)} stroke="#B538DE" strokeWidth={2} fill="none" />;
            }}
          />
           <IconMarker position={path[0]} color={START_COLOR} />
           <IconMarker
            position={path[path.length - 1]}
            color={END_COLOR}
          />
        </>
      )}

      <button
        type="button"
        style={{
          position: 'absolute',
          right: 5,
          bottom: 20,
          zIndex: 2,
          background: '#FF4646',
          padding: '5px 10px',
          fontSize: '.9rem',
          fontWeight: 600,
          fontFamily: 'Hind',
          borderRadius: 20,
          borderWidth: 0,

          borderType: 'solid',
          color: '#fff',
        }}
        onClick={startReplay}
      >
        Replay
      </button>
    </>
  );
};

Path.defaultProps = {
  path: null,
};
