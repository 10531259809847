import React, {SVGProps} from 'react';

const ChevronLeft = (props: SVGProps<SVGSVGElement>) => (

  <svg
    width={20}
    height={20}
    viewBox="0 0 100 100"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >

    <circle cx="50" cy="50" r="32" fill="rgb(29, 23, 2)" />

    <g transform="matrix(1.667169, 0, 0, 1.667169, -17.630611, 3.637103)">

      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        transform="matrix(1.284074, 0, 0, 1.284074, 16.026693, -14.36525)"
      >

        <g id="Icons/Ghost" transform="translate(7.200000, 12.20000)">
          <g id="Rectangle-2">
            <path
              d="M 1.347 7.738 C 3.655 2.041 8.123 0 11.761 0 C 15.4 0 19.338 0.735 22.175 7.738 C 25.013 14.741 24.431 24.557 22.175 25.702 C 19.92 26.848 21.852 22.31 19.825 22.164 C 17.798 22.018 18.436 25.32 16.842 25.32 C 15.685 25.32 15.686 22.241 13.341 22.164 C 10.996 22.087 10.381 25.453 8.386 25.32 C 6.391 25.187 7.603 22.164 5.335 22.164 C 3.067 22.164 2.547 27.037 1.347 25.702 C 0.147 24.368 -0.961 13.435 1.347 7.738 Z M 15.331 10.806 C 16.541 10.806 17.521 9.838 17.521 8.645 C 17.521 7.451 16.541 6.483 15.331 6.483 C 14.122 6.483 13.141 7.451 13.141 8.645 C 13.141 9.838 14.122 10.806 15.331 10.806 Z M 12.046 15.128 C 12.651 15.128 13.141 14.644 13.141 14.047 C 13.141 13.451 12.651 12.967 12.046 12.967 C 11.441 12.967 10.951 13.451 10.951 14.047 C 10.951 14.644 11.441 15.128 12.046 15.128 Z M 6.57 10.806 C 7.78 10.806 8.761 9.838 8.761 8.645 C 8.761 7.451 7.78 6.483 6.57 6.483 C 5.361 6.483 4.38 7.451 4.38 8.645 C 4.38 9.838 5.361 10.806 6.57 10.806 Z"
              fill="rgb(244, 244, 244)"
            />
            <path
              d="M1.34708803,7.73828731 C3.65543992,2.04125305 8.12275844,0 11.761287,0 C15.3998156,0 19.3379275,0.735166172 22.175486,7.73828731 C25.0130445,14.7414084 24.4314201,24.5571565 22.175486,25.7023414 C19.9195518,26.8475263 21.8520257,22.309681 19.8248837,22.163877 C17.7977417,22.0180729 18.4359648,25.3201757 16.8422462,25.3201757 C15.6847489,25.3201757 15.6856178,22.2410248 13.34105,22.163877 C10.9964822,22.0867292 10.3813127,25.4529717 8.38634312,25.3201757 C6.39137354,25.1873798 7.6029597,22.163877 5.33514704,22.163877 C3.06733438,22.163877 2.54743127,27.0370309 1.34708803,25.7023414 C0.146744782,24.3676519 -0.961263866,13.4353216 1.34708803,7.73828731 Z M15.3311023,10.8057507 C16.5406929,10.8057507 17.5212598,9.83817082 17.5212598,8.64460056 C17.5212598,7.45103029 16.5406929,6.48345042 15.3311023,6.48345042 C14.1215117,6.48345042 13.1409448,7.45103029 13.1409448,8.64460056 C13.1409448,9.83817082 14.1215117,10.8057507 15.3311023,10.8057507 Z M12.0458661,15.128051 C12.6506614,15.128051 13.1409448,14.644261 13.1409448,14.0474759 C13.1409448,13.4506908 12.6506614,12.9669008 12.0458661,12.9669008 C11.4410708,12.9669008 10.9507874,13.4506908 10.9507874,14.0474759 C10.9507874,14.644261 11.4410708,15.128051 12.0458661,15.128051 Z M6.57047241,10.8057507 C7.78006298,10.8057507 8.76062988,9.83817082 8.76062988,8.64460056 C8.76062988,7.45103029 7.78006298,6.48345042 6.57047241,6.48345042 C5.36088184,6.48345042 4.38031494,7.45103029 4.38031494,8.64460056 C4.38031494,9.83817082 5.36088184,10.8057507 6.57047241,10.8057507 Z"
              fill="rgb(244, 244, 244)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default ChevronLeft;
