// @ts-nocheck
import moment from 'moment';
import React from 'react';

import {
  Switch, Play, BlackGhost,

} from 'common-ui/Icon';
import styles from './styles.module.scss';


type TimeCardProps = {
  info: {
    startTime?: string,

    lastPingTime?: moment,
    type?: string,
  };
};

const TimeCard = ({ info }:TimeCardProps) => {
  let disconnectedTimeText = '? mins';

  let disconnectedDuration = null;
  if (info.lastPingTime && info.type === 'lostConnection') {
    disconnectedDuration = moment.duration(info.lastPingTime.diff(moment()));
  }
  if (disconnectedDuration) {
    disconnectedTimeText = (Math.abs(disconnectedDuration.asMinutes()) > 60 * 2)
      ? `${Math.abs(Math.round(disconnectedDuration.asHours()))} hrs`
      : `${Math.abs(Math.round(disconnectedDuration.asMinutes()))} mins`;
  }

  return (

    <div className={styles.timeCard}>
      {info.startTime ? (

        <div className={styles.timeCardLine}>
           <div className={`${styles.icon} ${styles.play}`}>
               <Play />
            Started
          </div>
          {info.startTime}
        </div>
      ) : null}
      {info.lastPingTime ? (

        <div className={styles.timeCardLine}>
           <div className={`${styles.icon} ${styles.switch}`}>
               <Switch />
            Last Ping
          </div>
          {info.lastPingTime.format('h:mm a')}
        </div>
      ) : null}
      {info.type === 'lostConnection' ? (

        <div className={styles.timeCardLine}>
           <div className={`${styles.icon} ${styles.disconnected}`}>
               <BlackGhost />
            Disconnected
          </div>
          {disconnectedTimeText}
        </div>
      ) : null}
    </div>
  );
};

export default TimeCard;
