import { Account } from 'common-ui/types';

export const formatName = (hire?: Account, defaultString = '') => {
  if (!hire) return defaultString;
  
  if (hire.__typename === 'Subcontractor') return `${hire.name || ''}`;
  return `${hire.firstName || ''} ${hire.lastName || ''}`;
};

export default formatName;
