// @ts-nocheck
import classNames from 'classnames';
import moment from 'moment-timezone';
import React from 'react';

import { Calendar as RCalendar } from 'react-calendar/dist/entry.nostyle';
import { compose, mapProps,  HOC } from 'recompose';

import { useTimezone } from 'common-ui/Hooks';
import { ChevronLeft, ChevronRight } from 'common-ui/Icon';
import { injectIntlHOC, withUserTimezone } from 'utils/HOC';
import styles from './styles.module.scss';



type CalendarPropTypes = {

  intl: intlShape,

  selectedDate: moment,
  setSelectedDate: (moment) => void,
  selectableDates?: 'all' | 'past' | 'future' | 'pastWithoutCurrent',
  highlightedDates: string[],
  isOpen?: boolean,
  isShowOpenStyle?: boolean,
};

const Calendar = ({
  intl,
  selectedDate,
  setSelectedDate,
  selectableDates,
  highlightedDates,
  isOpen,
  isShowOpenStyle,
  ...calendarProps
}: CalendarPropTypes) => {
  const timezone = useTimezone();

  const currentDate = moment(moment().tz(timezone).format('YYYY-MM-DD'));

  const userDate = moment(selectedDate.format('YYYY-MM-DD'));
  const datesSet = new Set(highlightedDates);
  let maxDate = null;
  if (selectableDates === 'past') {
    maxDate = currentDate.toDate();
  } else if (selectableDates === 'pastWithoutCurrent') {
    maxDate = currentDate.subtract(1, 'days').toDate();
  }
  return (

    <div>

      <RCalendar

        className={classNames(styles.reactCalendarOverrides, {
          [styles.isOpen]: isShowOpenStyle && isOpen,
        })}

        onChange={value => setSelectedDate(moment(value))}
        value={userDate.toDate()}
        calendarType="US"
        minDetail="month"

        prevLabel={<ChevronLeft />}

        nextLabel={<ChevronRight />}
        prev2Label={null}
        next2Label={null}

        formatShortWeekday={(locale, date) => (moment(date).locale(intl.locale).format('dd'))}
        minDate={selectableDates === 'future' ? currentDate.toDate() : null}
        maxDate={maxDate}

        tileClassName={({ date, view }) => {

          const tileDate = moment(date);

          if (view === 'month' && datesSet.has(tileDate.format('YYYY-MM-DD'))) {
            return styles.highlighted;
          }
          return null;
        }}

        tileContent={({ date, view }) => {

          const tileDate = moment(date);

          if (
            view === 'month'
            && tileDate.isSame(currentDate, 'day')
            && !tileDate.isSame(userDate, 'day')
          ) {
            return <div className={styles.todayIndicator} />;
          }
          if (
            view === 'month'
            && userDate.isSame(tileDate, 'day')
          ) {
            return <div className={styles.selectedIndicator} />;
          }
          return null;
        }}
        {...calendarProps}
      />
    </div>
  );
};

Calendar.defaultProps = {
  selectableDates: 'all',
  isOpen: false,
  isShowOpenStyle: false,
};

const enhance: HOC<any, CalendarPropTypes> = compose(
  injectIntlHOC(),
  withUserTimezone,

  mapProps(({
    intl,
    selectedDate,
    setSelectedDate,
    selectableDates,
    timezone,
    ...calendarProps
  }) => {

    const currDate = selectedDate || moment().tz(timezone);
    return {
      intl,
      selectedDate: currDate,
      setSelectedDate,
      selectableDates,
      ...calendarProps,
    };
  }),
);

export default enhance(Calendar);
