import { useContext, createContext } from 'react';

export const CompanyContext = createContext({
  selectedCompany: null,
  selectedCompanyID: 0,
  isClient: false,
  isExternalDOT: false,
  setSelectedCompanyID: () => null,
});

const useCompanyContext = () => useContext(CompanyContext);

export default useCompanyContext;
