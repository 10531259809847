// @ts-nocheck
import classNames from 'classnames';
import moment from 'moment';
import React, { useState } from 'react';
import { Popup } from 'react-map-gl';





import { Link } from 'react-router-dom';




import CycleCard from '../CycleCard';

import timeCardStyles from '../TimeCard/styles.module.scss';
import Analytics from 'common-ui/Analytics/SegmentAnalytics';
import {Actions} from 'common-ui/Analytics/types';
import { TextOnlyButton } from 'common-ui/Button';
import ClickableDiv from 'common-ui/ClickableDiv';
import ID from 'common-ui/ConsistentIDs';
import { usePermissions } from 'common-ui/Hooks';
import { ChevronLeft, Export, Stop, CircleMarker } from 'common-ui/Icon';
import type { Site } from 'common-ui/types';
import { InfoCard } from 'components/Common/Hires';
import TimeCard from 'components/Common/Map/LiveMarkers/TimeCard';
import { useCompanyContext, useDetectMobile } from 'utils/Hooks';
import styles from './styles.module.scss';
import type { MarkerType } from '../../types';

export const getStyleType = (type: any, showStatus = false) => {
  if (!showStatus) {
    return 'notavailable';
  }
  switch (type) {
    case 'onlineDirection':
    case 'online':
      return 'online';
    case 'lostConnection':
      return 'lost';
    default:
    case 'idle':
    case 'idleDirection':
      return 'idle';
  }
};

export const getImageBorderStyle = (type: any) => {
  switch (type) {
    case 'onlineDirection':
    case 'online':
    case 'onlineIdle':
      return 'online';
    case 'lostConnection':
      return 'lost';
    case 'idleDirection':
    case 'idle':
    default:
      return 'idle';
  }
};

export const getIsOnline = (type: any) => {
  switch (type) {
    case 'onlineDirection':
    case 'online':
    case 'onlineIdle':
      return true;
    default:
      return false;
  }
};

type InfoPopupCardProps = {
  info: MarkerType,
  isHovered?: boolean,
  showName: boolean,
  showPhoneNumber: boolean,
  showStatus: boolean,
  showTimecard: boolean,
  showLicensePlate: boolean,
  sites: Site[]
};

const InfoPopupCard = ({
  info,
  isHovered,
  sites,
  showName,
  showPhoneNumber,
  showStatus,
  showTimecard,
  showLicensePlate,
}: InfoPopupCardProps) => {
  const isMobile = useDetectMobile();
  const { selectedCompanyID } = useCompanyContext();
  const {
    ViewLiveCycleInsights: showCycles,
    ViewDriverRoute: showDriverRoute,
  } = usePermissions(selectedCompanyID);

  let pickupSite;
  let dropoffSite;

  if (sites && info.activeCycle) {
    pickupSite = sites.find(site => site.id === info.activeCycle.pickupSiteID);
    dropoffSite = sites.find(site => site.id === info.activeCycle.dropoffSiteID);
  }

  let idleDuration = null;

  if (info.idleSince && info.type !== 'lostConnection') {
    idleDuration = moment.duration(moment().diff(moment(info.idleSince))).asMinutes().toFixed(0);
  }

  let lostSignalDuration = null;

  if (info.lastPingTime && info.type === 'lostConnection') {
    lostSignalDuration = moment.duration(moment().diff(moment(info.lastPingTime))).asMinutes().toFixed(0);
  }

  let loadInfo;

  if (!!info.activeCycle && info.quantityUnit === 'LOAD' && info.quantity > 0) {
    loadInfo = `${info.activeCycle.cycleNumber} / ${parseInt(info.quantity, 10)}`;
  } else if (info.activeCycle) {
    loadInfo = `${info.activeCycle.cycleNumber}`;
  }

  const [expandTimecard, setExpandTimecard] = useState(false);
  const toggleTimecard = (e: any) => {
    setExpandTimecard(bool => !bool);
    e.stopPropagation();
  };

  return (

    <Popup

      className={classNames(styles.infoPopup, { [styles.hovered]: isHovered, [styles.mobile]: isMobile })}
      tipSize={5}
      anchor="bottom"
      dynamicPosition={false}
      offsetLeft={24}
      offsetTop={10}
      longitude={info.lng}
      latitude={info.lat}
      closeButton={false}
    >

      <div className={classNames(styles.infoPopup, { [styles.mobile]: isMobile })}>

        <InfoCard
          firstName={showName && info.firstName}
          lastName={showName && info.lastName}
              truckNumber={info.truckNumber}
          phoneNumber={showPhoneNumber && info.phoneNumber}
          styleName={getStyleType(info.type, showStatus)}
          hideName={!showName}
          hideIcon
        />

        <div className={classNames(styles.infoSection, {
          [styles.bottomBorder]: !isHovered,
        })}
        >
           <div className={styles.projectInfoRow}>
               <div className={styles.projectInfoTitle}>Company</div>
               <div>{info.companyName}</div>
          </div>
              {!isHovered && showLicensePlate && info.licensePlate && (
               <div className={styles.projectInfoRow}>
                   <div className={styles.projectInfoTitle}>License Plate</div>
                   <div>{info.licensePlate}</div>
            </div>
          )}
          {!isHovered && showTimecard && idleDuration !== null && (
               <div className={styles.projectInfoRow}>
                   <div className={styles.projectInfoTitle}>
                       <div className={timeCardStyles.timeCardLine}>
                           <div className={`${timeCardStyles.icon} ${timeCardStyles.stop}`}>
                               <div className={styles.idleIcon}><Stop /></div>
                    Idle
                  </div>
                </div>
              </div>
                   <div>
                {`${idleDuration}m`}
              </div>
            </div>
          )}
          {!isHovered && showTimecard && lostSignalDuration !== null && (
               <div className={styles.projectInfoRow}>
                   <div className={styles.projectInfoTitle}>
                       <div className={timeCardStyles.timeCardLine}>
                           <div className={`${timeCardStyles.icon}`}>
                               <div className={styles.lostIcon}><CircleMarker /></div>
                    Lost
                  </div>
                </div>
              </div>
              {`${lostSignalDuration}m`}
            </div>
          )}
        </div>
        {!isHovered && (
           <div className={classNames(styles.infoSection, {
            [styles.bottomBorder]: showTimecard,
          })}
          >
               <div className={styles.projectInfoRow}>
                   <div className={styles.projectInfoTitle}>Project</div>
                   <div>{info.projectName}</div>
            </div>
            {showCycles && !!info.activeCycle && (
                   <div className={styles.projectInfoRow}>
                       <div className={styles.projectInfoTitle}>Load</div>
                       <div>{loadInfo}</div>
              </div>
            )}
            {showCycles && info.activeCycle && (
                   <CycleCard
                cycle={info.activeCycle}
                pickupSite={pickupSite}
                dropoffSite={dropoffSite}
              />
            )}
          </div>
        )}
        {!showDriverRoute && !isHovered && showTimecard && (
        // this should be removed once driver route feature is complete
           <div className={styles.infoSection}>
               <ClickableDiv onClick={toggleTimecard} className={styles.dropdown}>
                  Signal Information
                   <span className={classNames(styles.icon, {
                [styles.opened]: expandTimecard,
                [styles.closed]: !expandTimecard,
              })}
              >
                       <ChevronLeft />
              </span>
            </ClickableDiv>
               {expandTimecard && <TimeCard info={info} />}
          </div>
        )}
        {showDriverRoute && !isHovered && !isMobile && (
           <div className={styles.viewRoutesInfoContainer}>
               <Link to={{ pathname: `/live/history/${info.accountId}`, search: `?company=${selectedCompanyID}` }}>
                   <TextOnlyButton
                className={styles.viewRouteInfoFont}
                       buttonIcon={<Export viewBox="2 1 16 16" />}
                text="View Route Info"
                id={ID.create(ID.Areas.Live, ID.Types.Button, ID.Labels.PopupCardViewRouteInfo)}
                onClick={() => Analytics.trackClick(Actions.viewDriverRoute)}
              />
            </Link>
          </div>
        )}
      </div>
    </Popup>
  );
};

InfoPopupCard.defaultProps = {
  isHovered: false,
};
export default InfoPopupCard;
