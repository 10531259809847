// @ts-nocheck
import _ from 'lodash';
import {  AbstractComponent } from 'react';
import { injectIntl,  IntlShape } from 'react-intl';

type intlEnhanceType = {
  intl: IntlShape,
}


type injectIntlHOCType = (component:AbstractComponent<BaseProps>) =>

  AbstractComponent<$Diff<BaseProps, intlEnhanceType>>

const injectIntlHOC: injectIntlHOCType = _.curryRight(injectIntl);

export default injectIntlHOC;
