// @ts-nocheck
import moment from 'moment';
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { useCompanyContext } from 'utils/Hooks';
import styles from './styles.module.scss';

export type ProjectCardLinkValueProps = {
  value?: string | null,
  field: string,
  searchEnd?: string | null,
  children: ReactNode,
};

export const ProjectCardLinkValue = ({
  children,
  value,
  field,
  searchEnd,
}: ProjectCardLinkValueProps) => {
  if (!value || !field) {
    return children || '-';
  }

  const searchEndDate = moment(searchEnd).isValid() ? moment(searchEnd).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');

  const searchStartDate = moment(searchEndDate).subtract(7, 'd').format('YYYY-MM-DD');
  const { selectedCompanyID } = useCompanyContext();
  const params = `?company=${selectedCompanyID || ''}&searchEnd=${searchEndDate}&searchStart=${searchStartDate}&searchTerms=${value}&selectedSearchFields=${field}`;
  return (

    <Link
      to={{
        pathname: '/search',
        search: params,
      }}
      className={styles.blueUnderline}
    >
      {children}
    </Link>
  );
};

ProjectCardLinkValue.defaultProps = {
  value: null,
  searchEnd: null,
};

export default ProjectCardLinkValue;
