import axios from 'axios';

import { apiAuthURL } from '../api';

export const post = (url: any, data: any) => (
  axios.post(url, data, {
    baseURL: apiAuthURL(),
    withCredentials: true,
  })
);

export const get = (url: any) => axios.get(url, {
  baseURL: apiAuthURL(),
});
