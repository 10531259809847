// @ts-nocheck
import classNames from 'classnames';
import moment from 'moment';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { Bulb } from 'common-ui/Icon';
import { InfoCard, SubcontractorInfoCard } from 'components/Common/Hires/InfoCard';
import { NotificationTag } from 'components/Common/Labels';

import styles from './styles.module.scss';
import {  NotificationsType,  NotificationTypeType } from './types';

const classForNotificationType = (status: NotificationTypeType) => {
  switch (status) {
    case 'request':
      return styles.notificationRequest;
    case 'declined':
    case 'requestdeclined':
    case 'idle':
    case 'lost':
    case 'online':
    case 'accepted':
    case 'requestaccepted':
    case 'info':
    default:
      return '';
  }
};

const Notification = ({
  notification: {
    type,
    hire,
    note,
    extraInfo,
  },
}: { notification: NotificationsType }) => {
  let notification = null;

  if (type === 'info') {
    notification = note;
  } else if (type === 'SmartSchedulerJobComplete') {
    notification = (
      <div className={styles.smartSchedulerNotification}>
        <Bulb className={styles.smartSchedulerBulb} />
        <FormattedMessage
          id="notifications.smartSchedulerJobComplete"
          defaultMessage="Your Smart Schedules for {date} are ready!"
          values={{
            date: moment(extraInfo.Date).format('MMM Do'),
          }}
        />
      </div>
    );
  } else if (type === 'UploadProjectsCSV') {
    const title = (extraInfo.body && extraInfo.body.title) || 'Upload Projects CSV';
    const data = (extraInfo.body && extraInfo.body.data) || '';

    return (
      <div className={styles.notificationTag}>
        <div>
          <b>{title}</b>
        </div>
        <div>
          {data}
        </div>
      </div>
    );
  } else if (hire) {
    const {
      lastName,
      firstName,
      truckNumber,
      companyName,
      accountId,
    } = hire;

    const subdispatched = type === ('requestaccepted' || 'requestdeclined');

    notification = (
      <>
        {subdispatched ? (
          <SubcontractorInfoCard
            name={companyName}
          />
        ) : (
          <InfoCard
            truckNumber={type !== 'request' ? truckNumber : undefined}
            accountId={accountId}
            {...{
              firstName,
              lastName,
              company: companyName,
            }}
          />
        )}
        <div className={styles.notificationTag}>
          <NotificationTag type={type} subdispatched={subdispatched} />
        </div>
      </>
    );
  }

  if (type === 'declined' || type === 'accepted' || type === 'requestdeclined' || type === 'requestaccepted') {
    return (
      <div className={classNames(styles.notificationWithFooter, classForNotificationType(type))}>
        <div className={styles.notificationBody}>
          { notification }
        </div>
        { extraInfo && extraInfo.Client && (
          <div className={styles.notificationFooter}>
            { extraInfo.Client && (
              <div className={styles.notificationCompanyNumber}>
                Client:
                &nbsp;
                <div className={styles.notificationMetadataValue}>
                  {extraInfo.Client}
                </div>
              </div>
            )}
            { extraInfo.ProjectName && (
              <div className={styles.notificationCompanyNumber}>
                Project Name:
                &nbsp;
                <div className={styles.notificationMetadataValue}>
                  {extraInfo.ProjectName}
                </div>
              </div>
            )}
            { extraInfo.JobNumber && (
              <div className={styles.notificationCompanyNumber}>
                Job No.:
                &nbsp;
                <div className={styles.notificationMetadataValue}>
                  {extraInfo.JobNumber}
                </div>
              </div>
            )}
            { extraInfo.HireCount && (
              <div className={styles.notificationCompanyNumber}>
                Hires:
                &nbsp;
                <div className={styles.notificationMetadataValue}>
                  {extraInfo.HireCount}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={classNames(styles.notification, classForNotificationType(type))}>
      { notification }
    </div>
  );
};

export default Notification;
