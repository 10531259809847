// @ts-nocheck
import { Formik, Form } from 'formik';
import React from 'react';

import { Button } from 'common-ui/Button';
import { FormikTextArea } from 'components/Common/Form';

import { SidebarForm } from 'components/Sidebar';
import styles from './styles.module.scss';

const ClientShareModal = ({
  handleClose,
  shareLiveProject,
  project,
  disableShare
}: any) => (

  <div className={styles.clientShareModal}>

    <SidebarForm title="Share to Client" hideCloseButton>

      <Formik
        enableReinitialize
        initialValues={{
          projectID: (project && project.data && project.data.id) || '',
          link: 'live/',
          fullLink: `${window.location.protocol}://${window.location.host}/live/`,
          message: '',
        }}
        validate={() => {
          const errors = {};
          return errors;
        }}
        onSubmit={(values: any, formik: any) => {
          const { projectID, link, message } = values;
          shareLiveProject(projectID, link, message)
            .finally(() => {
              formik.resetForm();
              handleClose();
            });
        }}
      >
        {({
          dirty,
          isValid
        }: any) => (
          <Form>
            <div className={styles.description}>
              Send a link via SMS text message to the admins at
              {' '}
              <span className={styles.bold}>
                {(project && project.client) || ''}
              </span>
              .
              The link will let them log in and view the live map for this project.
            </div>
            <span className={styles.clientBox}>
              {project && project.client}
            </span>
            <FormikTextArea name="message" placeholder="Add a message (optional)" />
            <Button
              type="submit"
              styleName="primary"
              fullWidth
              text="Send Link"
              disabled={disableShare || (dirty && !isValid)}
            />
          </Form>
        )}
      </Formik>
    </SidebarForm>
  </div>

);

export default ClientShareModal;
