// @ts-nocheck
import React from 'react';
import styles from './styles.module.scss';

type CollaboratorInitialsProps = {
  firstName: string,
  lastName: string,
};

const CollaboratorInitials = ({
  firstName,
  lastName,
}: CollaboratorInitialsProps) => {
  return (

    <div className={styles.border}>

      <div className={styles.initials}>
        {firstName ? firstName.charAt(0).toUpperCase() : ''}
        {lastName ? lastName.charAt(0).toUpperCase() : ''}
      </div>
    </div>
  );
};

export default CollaboratorInitials;
