import classNames from 'classnames';
import React from 'react';
import styles from './styles.module.scss';

type DividerProps = {
  className?: string;
};

const Divider = ({className}: DividerProps) => (

  <div className={classNames(styles.dividerStyle, className)} />
);

Divider.defaultProps = {
  className: undefined,
};

export default Divider;
