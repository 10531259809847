// @ts-nocheck
import { Formik, Form } from 'formik';
import React from 'react';
import {
  AddEditCollaboratorsFormFields,
  useCollaboratorsFormikConfig,
} from '../AddEditCollaboratorsForm';
import { Button } from 'common-ui/Button';

import ClickableDiv from 'common-ui/ClickableDiv';
import { Close } from 'common-ui/Icon';
import styles from './styles.module.scss';


type AddCollaboratorModalProps = {
  collaborator?: any, // TODO: type

  onComplete: (any) => void, // TODO: type
  onClose: () => void,
};

const AddCollaboratorModal = ({
  collaborator,
  onComplete,
  onClose,
}: AddCollaboratorModalProps) => {
  const config = useCollaboratorsFormikConfig(collaborator, onComplete);

  const isCreating = !collaborator;

  return (

    <Formik {...config}>
      {({
        dirty,
        isValid
      }: any) => (

        <Form>
          <div className={styles.addCollaboratorModal}>
            <div className={styles.addCollaboratorHeader}>
              {isCreating ? 'Create External Guest' : 'Edit External Guest'}
              {isCreating && (
                <ClickableDiv onClick={onClose} className={styles.closeButton}>
                  <Close />
                </ClickableDiv>
              )}
            </div>
            <div className={styles.addCollaboratorContent}>
              {isCreating && (
                <div className={styles.addCollaboratorDescription}>
                  Intended for external guests that are not part of your company.
                  Guests can only access documents and spaces they are invited to.
                </div>
              )}
              <AddEditCollaboratorsFormFields />
            </div>
            <div className={styles.addCollaboratorButtons}>
              <Button text="Cancel" onClick={onClose} />
              <Button
                text="Submit"
                type="submit"
                className={styles.addCollaboratorSubmit}
                disabled={!dirty || !isValid}
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

AddCollaboratorModal.defaultProps = {
  collaborator: null,
};

export default AddCollaboratorModal;
