// @ts-nocheck
import { useRef, useState, useCallback } from 'react';

// useElementSize is used to get the height and width of an element using JS
// This is useful when certain elements must be sized relative to another element
// Usage is:
// const [elementRef, { width, height }]
const useElementSize = () => {
  const sizeRef = useRef();
  const [size, setSize] = useState({});

  const updateSize = () => {

    if (sizeRef.current) setSize(sizeRef.current.getBoundingClientRect());
  };

  const callbackRef = useCallback((node: Element) => {
    if (sizeRef.current) {
      window.removeEventListener('resize', updateSize);
    }


    sizeRef.current = node;

    if (node) {
      updateSize();
      window.addEventListener('resize', updateSize);
    }
  }, []);

  return [callbackRef, size];
};

export default useElementSize;
