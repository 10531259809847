// @ts-nocheck
import React from 'react';

import { BasicFlag } from 'components/Common/Flag';

export const dropPinToastID = 'dropPinToastID';

const DropPinToast = () => (

  <BasicFlag text="Click on map to drop a pin" />
);

export default DropPinToast;
