// @ts-nocheck
import { connect } from 'formik';
import _ from 'lodash';
import React from 'react';

import { Checkbox } from '..';

type FormikCheckboxProps = {
  formik: any,
  name: string,
  id: string,
};

const FormikCheckbox = ({
  formik,
  name,
  id,
  ...inputProps
}: FormikCheckboxProps) => (

  <Checkbox
    id={id}
    checked={_.get(formik.values, name)}
    onChange={(value) => formik.setFieldValue(name, value)}

    onBlur={() => formik.setFieldTouched(name)}
    name={name}
    {...inputProps}
  />
);

FormikCheckbox.defaultProp = {
  id: undefined,
};

export default connect(FormikCheckbox);
