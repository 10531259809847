// @ts-nocheck
import React from 'react';

import { BasicFlag } from 'components/Common/Flag';

export const DisplayLimitToastID = 'DisplayLimitToast';

type DisplayLimitToastProps = {
  displayedCount: number,
  total: number,
};

const DisplayLimitToast = ({ displayedCount, total } : DisplayLimitToastProps) => (

  <BasicFlag text={`Showing ${displayedCount} out of ${total} sites.
    Use the filter to narrow down sites.`}
  />
);

export default DisplayLimitToast;
