// @ts-nocheck
import React from 'react';
import styles from './styles.module.scss';

type BasicFlagProps = {
  text: string,
};

const BasicFlag = ({ text }: BasicFlagProps) => {
  return (

    <div className={styles.basicFlag}>{text}</div>
  );
};

export default BasicFlag;
