import classNames from 'classnames';
import React from 'react';
import styles from './styles.module.scss';

type LoadingSpinnerProps = {
  small?: boolean,
  className?: string,
}

const LoadingSpinner = ({ small, className }: LoadingSpinnerProps) => (

  <div

    className={classNames(styles.loadingSpinner, className, {
      [styles.smallLoadingSpinner]: small,
    })}
  />
);

LoadingSpinner.defaultProps = {
  small: false,
  className: undefined,
};

export default LoadingSpinner;
