// @ts-nocheck
import { useQuery, gql } from '@apollo/client';
import { useMemo } from 'react';

const COMPANY_QUERY = gql`
  query company($id: ID!) {
    company(id: $id) {
      id
      measurementType
    }
  }
`;

const useCompanyMeasurementType = (selectedCompanyID: any) => {
  const { data, loading, error } = useQuery(COMPANY_QUERY, {
    variables: {
      id: selectedCompanyID,
    },
    skip: !selectedCompanyID || selectedCompanyID === '0',
  });
  return useMemo(() => {
    if (loading || error || !data) return 'metric';
    return data.company.measurementType;
  }, [data, loading, error]);
};

export default useCompanyMeasurementType;
