import React, {SVGProps} from 'react';

const Request = (props: SVGProps<SVGSVGElement>) => (

  <svg
    width={12}
    height={12}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >

    <path
      d="M9.81818 3.59595V1.09091H2.18181V3.59593L6.00001 7.42847L9.81818 3.59595ZM1.09092 10.1377L4.1377 7.09092L1.09085 4.04407C1.09075 5.50973 1.09059 8.67411 1.09092 10.1377ZM4.90909 7.86231L1.86229 10.9091C3.6969 10.9092 8.30581 10.9096 10.1393 10.9107L7.09092 7.86231L6.3857 8.56753C6.17269 8.78054 5.82733 8.78054 5.61431 8.56753L4.90909 7.86231ZM10.9091 4.04414V10.1377L7.86231 7.09092L10.9091 4.04414ZM10.9091 0.547052V1.83469L10.9602 1.85097C11.5345 2.03368 12 2.66869 12 3.27003V10.9118C12 11.5128 11.511 12 10.9086 12H1.0914C0.488638 12 0 11.5131 0 10.9118V3.27003C0 2.66902 0.465823 2.03359 1.0387 1.85131L1.09091 1.8347V0.547052C1.09091 0.244924 1.3336 0 1.63628 0H10.3637C10.6649 0 10.9091 0.244653 10.9091 0.547052ZM5.99662 4.34401L6.74053 4.7351C6.93876 4.83932 7.13005 4.70053 7.09216 4.47963L6.95008 3.65127C6.95147 3.65933 6.95387 3.65194 6.94801 3.65766L7.54985 3.07101C7.71022 2.91469 7.63733 2.68988 7.41554 2.65765L6.58382 2.53679C6.5919 2.53797 6.58563 2.5334 6.58925 2.54074L6.21729 1.78707C6.11818 1.58625 5.88184 1.58609 5.78265 1.78707L5.4107 2.54074C5.41432 2.53341 5.40803 2.53797 5.41613 2.53679L4.58441 2.65765C4.36279 2.68985 4.28961 2.91457 4.4501 3.07101L5.05194 3.65766C5.04609 3.65195 5.04848 3.65934 5.04986 3.65127L4.90779 4.47963C4.86993 4.70036 5.06104 4.8394 5.25942 4.7351L6.00333 4.34401C5.9961 4.34781 6.00386 4.34782 5.99662 4.34401Z"
      fill="white"
      fillRule="evenodd"
      clipRule="evenodd"
    />
  </svg>
);

export default Request;
