// @ts-nocheck
import classNames from 'classnames';
import React, { ReactNode } from 'react';
import styles from './styles.module.scss';

type ProjectCardDetailProps = {
  name: string,
  label: string,
  children: ReactNode,
  mobile?: boolean,
  className?: string,
  id?: string,
};

const ProjectCardDetail = ({
  children,
  name,
  label,
  mobile,
  className,
  id,
}: ProjectCardDetailProps) => (

  <div

    className={classNames(styles.projectCardDetail, className, { [styles.mobile]: mobile })}
    style={{ gridArea: name }}
    id={id}
  >

    <div className={classNames(styles.projectCardDetailLabel, { [styles.mobile]: mobile })}>
      {label}
    </div>

    <div className={classNames(styles.projectCardDetailValue, { [styles.mobile]: mobile })}>
      {children}
    </div>
  </div>
);

ProjectCardDetail.defaultProps = {
  className: '',
  mobile: false,
  id: undefined,
};

export default ProjectCardDetail;
