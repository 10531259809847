// @ts-nocheck
import React, { ReactNode } from 'react';

import ContextSwitcherItem from './ContextSwitcherItem';
import styles from './styles.module.scss';

type Option = {
  value: string,
  label: string,
  icon?: Node,
}

type ContextSwitcherProps = {
  options: Option[],
  selectedValue: string,
  onChange: (value: string) => void,
}

const ContextSwitcher = ({ options, onChange, selectedValue }: ContextSwitcherProps) => (

  <div>

    <div className={styles.contextSwitcherContainer}>
      {options.map((option) => (

        <ContextSwitcherItem
          key={option.label}
          onClick={() => { onChange(option.value); }}
          isActive={option.value === selectedValue}
          label={option.label}
          icon={option.icon}
        />
      ))}
    </div>
  </div>
);

export default ContextSwitcher;
