// @ts-nocheck
import React from 'react';
import ReactTooltip from 'react-tooltip';

import { toolTipID } from './CollaboratorChip';
import CollaboratorHoverCard from './CollaboratorHoverCard';

import styles from './styles.module.scss';

const CollaboratorHoverTooltip = () => {
  return (

    <ReactTooltip
      className={styles.tooltip}
      id={toolTipID}
      type="light"
      place="top"
      effect="solid"
      arrowColor="transparent"
      getContent={(dataTip) => {
        if (!dataTip) {
          return null;
        }
        const hoverData = JSON.parse(dataTip);
        return (
          <CollaboratorHoverCard collaborator={hoverData} />
        );
      }}
    />
  );
};

export default CollaboratorHoverTooltip;
