// @ts-nocheck
import React, { ReactNode } from 'react';

import ClickableDiv from 'common-ui/ClickableDiv';
import styles from './styles.module.scss';

type TagListItemProps = {
  label: ReactNode,
  onClick: () => void,
};

const TagListItem = ({ label, onClick}: TagListItemProps) => {
  return (

    <ClickableDiv onClick={onClick} className={styles.tagListItem}>
      {label}
    </ClickableDiv>
  );
};

export default TagListItem;
