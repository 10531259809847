// @ts-nocheck
import React from 'react';
import { FormattedMessage } from 'react-intl';


import { FormField } from 'components/Common/Form';
import type { SiteType } from 'pages/Sites/types';
import SiteActivityItem from './SiteActivityItem';
import buildSiteActivities from './siteActivity.utils';



export type SiteActivityPropTypes = {
  site: SiteType,
}

const SiteActivity = ({
  site,
}: SiteActivityPropTypes) => {
  if (!site) return null;

  const activities = buildSiteActivities(site);
  return (

    <FormField
      label={(

        <FormattedMessage
          id="sites.add.activity_label"
          defaultMessage="Activity"
        />
      )}
    >

      <div>

        {activities.map((activity, i) => <SiteActivityItem {...activity} key={`${site.id}_${i}`} />)}
      </div>
    </FormField>
  );
};

export default SiteActivity;
