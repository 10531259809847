// @ts-nocheck
import classNames from 'classnames';
import React from 'react';

import CreatableSelect from 'react-select/creatable';

import { ChevronLeft } from 'common-ui/Icon';
import { CreateEquipmentModal } from 'pages/Projects/components/CreateEquipmentModal';

import { useGetEquipmentByCompanyId } from 'pages/Sites/graphql';


import { useCompanyContext } from 'utils/Hooks';
import styles from './styles.module.scss';

type EquipmentInputProps = {
  value: {
    name: string,
    id: string,
  },

  onChange: ({[string]: any }) => void,

  openModal: (node:ReactNode) => void,
  closeModal: () => void,
  embedded:boolean,
  disabled:boolean,
  canCreateEquipment:boolean
};

const EquipmentInput = ({
  value,
  onChange,
  embedded,
  disabled,
  canCreateEquipment,
  openModal,
  closeModal,
  ...inputProps
}: EquipmentInputProps) => {
  const { selectedCompanyID } = useCompanyContext();

  const selectEquipment = (inputValue: any) => {
    if (inputValue) {
      onChange({ name: inputValue.label, id: inputValue.value });
    } else onChange('Clear');// Using 'Clear' instead of null to distinguish between when equipmentInfo is undefined (we want to use the exisiting assignment.equipmenttInfo) and explicitly wanting to clear the equipment
  };

  const createEquipment = (eq: any) => {
    openModal(

      <CreateEquipmentModal
        equipment={{ name: eq }}
        onClose={closeModal}
        onComplete={(newEquipment: any) => {
          onChange({ ...newEquipment, id: 0 });
          closeModal();
        }}
      />,
    );
  };

  const [companyEquipment] = useGetEquipmentByCompanyId(selectedCompanyID);
  const equipmentDropdownOptions = companyEquipment.map(
    ({
      name,
      id
    }: any) => ({ label: name, value: id }),
  );


  const selectedEquipment = value && value !== 'Clear' ? { value: value.id, label: value.name } : null;

  return (

    <CreatableSelect
      isMulti={false}
      isClearable
      closeMenuOnSelect
      classNamePrefix="react-select"
      className={disabled && styles.disabled}
      placeholder="ex. Triaxle"
      noOptionsMessage={() => (canCreateEquipment ? 'No equipment. Create an equipment by typing in a name.' : 'No equipment.')}
      createOptionPosition="first"
      onChange={selectEquipment}
      options={equipmentDropdownOptions}
      onCreateOption={createEquipment}
      value={selectedEquipment}
      isDisabled={disabled}
      components={{
        IndicatorSeparator: null,
        DropdownIndicator: ({
          // innerProps and selectProps come from react-select library
          // eslint-disable-line
          innerProps,

          // eslint-disable-line
          selectProps
        }: any) => (
          (!disabled
          && (
            <div

              className={classNames(styles.dropdownIndicator, {
                [styles.open]: selectProps.menuIsOpen,
              })}
              {...innerProps}
            >
              <ChevronLeft />
            </div>
          )
          )
        ),
      }}
      styles={{

        control: (provided, state) => ({
          ...provided,
          border: embedded && !state.isFocused ? '0 !important' : 'inherit',
          height: embedded ? '100%' : 'inherit',
          backgroundColor: embedded && !state.isFocused ? 'transparent' : provided.backgroundColor
        }),
        container: (provided: any) => ({
          ...provided,
          height: embedded ? '100%' : 'inherit'
        }),
      }}
      theme={(theme: any) => ({
        ...theme,

        colors: {
          ...theme.colors,
          primary: styles.colorSelected,
          primary25: styles.colorSelectedLight,
          primary50: styles.colorSelectedMedium,
        }
      })}

      formatCreateLabel={(inputVal: any) => <div className={styles.createLabel}>
        Create equipment
        &quot;
        {inputVal}
        &quot;
      </div>}
      isValidNewOption={(inputValue: any, selectValue: any, selectOptions: any) => {
        return canCreateEquipment && inputValue !== '' && !selectOptions.some((o: any) => o.label === inputValue);
      }}
      {...inputProps}
    />
  );
};

export default EquipmentInput;
