// @ts-nocheck
import React from 'react';
import { compose, withHandlers,  HOC } from 'recompose';
import  { SortOptionType } from '../types';
import styles from './styles.module.scss';

type onClickProps = {
  option: any,
  onClick: any
};

type SortItemUIProps = {
  isSelected?: boolean,
  label: string,
  onClick: (param:any) => any,
  isLeft?: boolean
};

type SortItemHOCProps = {
  option: SortOptionType,
  onClick: () => any,
  isSelected?: boolean,
  label: string,
  isLeft?: boolean
};

const SortItemUI = ({
  isSelected,
  label,
  onClick,
  isLeft,
}: SortItemUIProps) => (

  <div
    className={`${styles.inputRadio}  ${isLeft ? styles.left : ''} ${
      isSelected ? styles.selected : ''
    }`}
    onClick={onClick}
    onKeyPress={onClick}
    role="button"
    tabIndex={0}
  >
    {!!label && (

      <span className={styles.item}>

        <span>{label}</span>
      </span>
    )}
  </div>
);

SortItemUI.defaultProps = {
  isLeft: false,
  isSelected: false,
};

const SortItemHOC: HOC<any, SortItemHOCProps> = compose(
  withHandlers({
    onClick: ({ option, onClick }: onClickProps) => () => {
      onClick(option);
    },
  }),
);

export default SortItemHOC(SortItemUI);
