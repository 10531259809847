// @ts-nocheck
import React, { useEffect, useState } from 'react';
import {
  AutoSizer, CellMeasurer, List, WindowScroller,

} from 'react-virtualized';
import { DraggableHireChip } from '../HireChip';
import styles from './styles.module.scss';

const MemoizedHiresList = React.memo<CellMeasurer>(({
  hires,
  cache,
  parentRef
}: any) => {
  const [parentNode, setParentNode] = useState();
  const getRowRender = (hires: any) => ({
    index,
    key,
    parent,
    style
  }: any) => {
    const hire = hires[index];

    const draggableId = JSON.stringify({
      id: hire.__typename === 'Hire' ? hire.accountId : hire.id,
      caID: hire.__typename === 'Hire' ? hire.id : null,
      companyId: hire.__typename === 'Hire' ? hire.companyId : null,
      isInternal: hire.__typename === 'Hire',
      copy: hire.__typename !== 'Hire' ? hire.copy : null,
      assignments: null,
      isAssignedToSmartAssignment: hire.isAssignedToSmartAssignment,
      isNotAssignedToAnyAssignment: hire.isNotAssignedToAnyAssignment,
    });

    return (

      <CellMeasurer
        cache={cache}
        columnIndex={0}
        key={draggableId}
        rowIndex={index}
        parent={parent}
      >
        {({
          measure,
          registerChild
        }: any) => (
          <div
            ref={registerChild}
            style={{
              ...style,
              width: '96%',
              left: '2%',
            }}
          >
            <DraggableHireChip
              className={styles.hireListItem}
              onLoad={measure}
              draggableId={draggableId}
              hire={hire}
              index={index}
            />
          </div>
        )}
      </CellMeasurer>
    );
  };

  useEffect(() => {
    if (parentRef) setParentNode(parentRef.current);
  }, [parentRef]);

  return (

    <WindowScroller scrollElement={parentNode}>
      {({
        height,
        isScrolling,
        scrollTop
      }: any) => (

        <AutoSizer disableHeight>
          {({
            width
          }: any) => (
            <List
              autoHeight
              estimatedRowSize={66}
              deferredMeasurementCache={cache}
              height={height}
              rowCount={hires.length}
              rowHeight={cache.rowHeight}
              width={width}
              rowRenderer={getRowRender(hires)}
              overscanRowCount={2}
              isScrolling={isScrolling}
              scrollTop={scrollTop}
            />
          )}
        </AutoSizer>
      )}
    </WindowScroller>
  );
});

export default MemoizedHiresList;
