// @ts-nocheck
import classNames from 'classnames';
import React from 'react';

import { InfoCardHover, SubcontractorInfoCard } from '../../InfoCard';
import { Bulb, Switch } from 'common-ui/Icon';

import { TooltipWrapper } from 'components/Common/Tooltip';

import { PermissionTypes } from 'pages/Hires/utils';
import { useHover } from 'utils/Hooks';
import AssignedCounter from './AssignedCounter';
import styles from './styles.module.scss';
import type { DisplayHire, DisplaySubcontractor } from '../types';



const isUnassigning = (snapshot: any): boolean => {
  if (snapshot.isDropAnimating && snapshot.draggingOver) {
    try {
      const container = JSON.parse(snapshot.draggingOver).id;
      if (container === 'unassignContainer') {
        return true;
      }
    } catch (e) {
      return false;
    }
  }
  return false;
};

const getTextFromPermissionType = (type: string) => {
  switch (type) {
    case PermissionTypes.ON_GOING:
      return 'to a new project';
    case PermissionTypes.PROJECT:
      return 'to their assigned project';
    case PermissionTypes.HOUR:
      return 'for the rest of the day';
    default:
      return '';
  }
};

type CompanyClientStatusIconProps = {
  permissionType: PermissionTypes,
};

export const CompanyClientStatusIcon = ({ permissionType }:CompanyClientStatusIconProps) => (

  <TooltipWrapper
    message={`You can reassign this hire ${getTextFromPermissionType(permissionType)}`}
    className={styles.companyClientHireSwitch}
  >

    <Switch />
  </TooltipWrapper>
);

type HireCardContainerProps = {
  hideIcon?: boolean,
  hire: DisplayHire | DisplaySubcontractor,
  assignmentInstance?: any,
  isHovered?: boolean,
  isAssigned?: boolean,
  enableMinutesScheduled?: boolean,
  scheduleView?: boolean,
};

export const HireCardContainer = ({
  hideIcon,
  hire,
  assignmentInstance,
  isHovered,
  isAssigned,
  enableMinutesScheduled,
  scheduleView,
}: HireCardContainerProps) => {
  const truckNumber = ((
    assignmentInstance
    && assignmentInstance.truckNumber
    && assignmentInstance.truckNumber !== ''
    && assignmentInstance.truckNumber)
    || hire.truckNumber);
  if (hire.__typename === 'Hire') {
    return (

      <InfoCardHover
        declinedEvent={hire.todayDeclinedEvent}
        hideIcon={hideIcon}
        firstName={hire.firstName}
        lastName={hire.lastName}
        truckNumber={truckNumber}
        licensePlate={hire.licensePlate}
        trailerNumber={hire.trailerNumber}
        company={hire.companyName}
        phoneNumber={hire.phoneNumber}
        accountId={hire.accountId}
        minutesScheduled={hire.minutesScheduled}

        isHovered={!!isAssigned && isHovered}
        enableMinutesScheduled={enableMinutesScheduled}
        minScheduledMinutes={hire.company && hire.company.driverMinimumScheduledMinutes}
        maxScheduledMinutes={hire.company && hire.company.driverMaximumScheduledMinutes}
        scheduleView={scheduleView}
      />
    );
  }
  if (hire.__typename === 'Subcontractor') {
    return (

      <SubcontractorInfoCard
        hideIcon={hideIcon}
        name={hire.name}
        priority={hire.priority}
        copy={hire.copy}
        numberHires={hire.numberHires}
      />
    );
  }
  return null;
};

HireCardContainer.defaultProps = {
  hideIcon: false,
  isHovered: false,
  assignmentInstance: null,
  isAssigned: false,
  enableMinutesScheduled: false,
  scheduleView: false,
};

type HireChipProps = {
  hire: DisplayHire | DisplaySubcontractor,
  assignmentInstance?: any,
  provided: any,
  snapshot: any,
  isAssigned?: boolean,
};

export const HireChip = ({
  hire,
  assignmentInstance,
  provided,
  snapshot,
  isAssigned,
}: HireChipProps) => {
  const [ref, isHovered] = useHover();
  return (

    <div ref={ref}>

      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}

        className={classNames(styles.hireChip, {
          [styles.assigned]: isAssigned,
          [styles.unassigning]: isUnassigning(snapshot),
          [styles.dragging]: snapshot.isDragging,
        })}
      >

        <HireCardContainer
          hire={hire}
          assignmentInstance={assignmentInstance}
          isHovered={isHovered}
          isAssigned={isAssigned}
        />

        {hire.companyClientPermissionType && <CompanyClientStatusIcon permissionType={hire.companyClientPermissionType} />}
        {hire.isAssignedToSmartAssignment
          ? (
            <div className={classNames(styles.assignedCounter, styles.assignedSmart)}>
              <Bulb />
            </div>
          )
          : (hire.assignedCount > 0 && <AssignedCounter assignedCount={hire.assignedCount} />)}
      </div>
    </div>
  );
};

HireChip.defaultProps = {
  isAssigned: false,
  assignmentInstance: null,
};
