import React, {SVGProps} from 'react';

const Assign = (props: SVGProps<SVGSVGElement>) => (

  <svg width={25} height={25} viewBox="0 0 25 25" {...props}>

    <title>Assign</title>

    <path
      d="M20.888 5.88l-7.541 7.54 2.778 6.748L20.888 5.88zm-9.309 5.773l7.541-7.54L4.832 8.874l6.747 2.778zm3.514 12.573l-4.176-10.143L.774 9.907c-1.07-.44-1.018-1.975.08-2.341l22.499-7.5c.977-.325 1.906.604 1.58 1.581l-7.499 22.498c-.366 1.099-1.9 1.152-2.341.081z"
      fill="#4D4D4D"
      fillRule="evenodd"
    />
  </svg>
);

export default Assign;
