// @ts-nocheck
import classNames from 'classnames';
import React from 'react';
import { Marker } from 'react-map-gl';

import ClickableDiv from 'common-ui/ClickableDiv';
import { SiteIcon } from 'common-ui/Sites';
import { useHover } from 'utils/Hooks';
import SelectedSiteGeofence from './SelectedSiteGeofence';
import SiteInfoPopupCard from './SiteInfoPopupCard';
import styles from './styles.module.scss';



const SiteMarkerTypes = {
  UNFILTERED: 'UNFILTERED',
  INACTIVE: 'INACTIVE',
  ACTIVE: 'ACTIVE',
};

const SiteMarker = ({
  marker,
  onClick,
  showGeofence,
  popupCanOpen,
  popupIsOpen,
  status
}: any) => {
  const {
    data,
    id,
    lat,
    lng,
  } = marker;

  const [hoverRef, isHovered] = useHover();

  const offset = -13;
  if (!lat || !lng) {
    return null;
  }

  return (

    <>

      <Marker
        longitude={lng}
        latitude={lat}
        offsetLeft={offset}
        offsetTop={offset}
        key={`marker-${id}`}
      >

        <ClickableDiv
          onClick={onClick}

          className={classNames(styles.siteMarker, {
            [styles.unfiltered]: status === SiteMarkerTypes.UNFILTERED,
            [styles.inactive]: status === SiteMarkerTypes.INACTIVE && !isHovered,
          })}
          ref={hoverRef}
        >
           <SiteIcon type={data.siteType} />
        </ClickableDiv>
      </Marker>
      {(isHovered || (popupCanOpen && popupIsOpen)) && (

        <SiteInfoPopupCard
          info={data}
          isHovered={!popupIsOpen}

          popupCanOpen={!popupIsOpen && popupCanOpen}
        />
      )}
      {showGeofence && (

        <SelectedSiteGeofence openMarker={marker} unfiltered={status === SiteMarkerTypes.UNFILTERED} />
      )}
    </>
  );
};

SiteMarker.Types = SiteMarkerTypes;

export default SiteMarker;
