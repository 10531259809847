// @ts-nocheck
import classNames from 'classnames';
import { filter } from 'lodash';
import React, { Fragment } from 'react';
import { Popup } from 'react-map-gl';

import { usePermissions } from 'common-ui/Hooks';
import { ChevronRight } from 'common-ui/Icon';
import { SiteIcon } from 'common-ui/Sites';
import WarningHoverIcon from 'components/Common/WarningHoverIcon';
import type { SiteType } from 'pages/Sites/types';



import { getSiteTypeLabel, getMedianTimeOnSiteMinutes } from 'pages/Sites/utils/site.utils';
import { useCompanyContext } from 'utils/Hooks';
import styles from './styles.module.scss';






const DetailRow = ({
  label, value, tooltip, exceedsBenchmark,

}: DetailRowProps) => (

  <>

    <div className={styles.siteInfoRowLabel}>{label}</div>

    <div className={classNames(styles.siteInfoRowValue, {
      [styles.highlightRed]: exceedsBenchmark,
    })}
    >
      {value || 'N/A'}
      {tooltip}
    </div>
  </>
);

type InfoPopupCardProps = {
  info: SiteType,
  isHovered?: boolean,
  popupCanOpen?: boolean,
};

const InfoPopupCard = ({
  info,
  isHovered,
  popupCanOpen,
}: InfoPopupCardProps) => {
  const {
    id,
    name,
    location,
    lng,
    lat,
    siteType,
    activeCycles,
    completeCycles,
    company,
  } = info;

  const { selectedCompanyID } = useCompanyContext();
  const { ViewLiveCycleInsights: showCycles } = usePermissions(selectedCompanyID);

  const trucksOnSite = filter(activeCycles, ({
    pickupSiteID,
    pickupArrival,
    pickupDeparture,
    dropoffSiteID,
    dropoffArrival,
    dropoffDeparture
  }: any) => {
    return (pickupSiteID === id && pickupArrival !== null && pickupDeparture === null)
    || (dropoffSiteID === id && dropoffArrival !== null && dropoffDeparture === null);
  });

  const trucksOnRoute = filter(activeCycles, ({
    pickupSiteID,
    dropoffSiteID,
    pickupDeparture,
    dropoffArrival,
    dropoffDeparture
  }: any) => {
    return (pickupSiteID === id && dropoffDeparture !== null)
    || (dropoffSiteID === id && pickupDeparture !== null && dropoffArrival === null);
  });

  const allCycles = (completeCycles || []).concat(activeCycles || []);
  const medTimeOnSite = getMedianTimeOnSiteMinutes(id, allCycles);
  const exceedsBenchmark = company && company.timeOnSiteGoalMinutes && medTimeOnSite >= company.timeOnSiteGoalMinutes;

  return (

    <Fragment>

      <Popup

        className={classNames(styles.infoPopup, { [styles.hovered]: isHovered })}
        tipSize={5}
        anchor="bottom"
        dynamicPosition={false}
        offsetTop={-17}
        longitude={lng}
        latitude={lat}
        closeButton={false}
        closeOnClick={false}
      >

        <div className={styles.sitesInfoTable}>
           <div className={styles.sitesInfoHeader}>
               <div className={styles.siteIcon}>
                   <SiteIcon type={siteType} />
            </div>
               <div className={styles.sitesInfoHeaderText}>
                   <div className={styles.name}>
                { name }
                {isHovered && popupCanOpen && (
                           <div className={styles.arrowIcon}>
                               <ChevronRight />
                  </div>
                )}
              </div>
                   <span className={styles.address}>{ location }</span>
            </div>
          </div>
          {!isHovered && (
               <div>
                   <div className={classNames(styles.sitesInfoSection, {
                [styles.bottomBorder]: showCycles
              })}>
                       <DetailRow label="Type" value={getSiteTypeLabel(siteType)} />
              </div>
              {showCycles && (
                       <div className={styles.sitesInfoSection}>
                           <DetailRow label="On-Site" value={`${trucksOnSite.length} Trucks`} />
                           <DetailRow label="En Route" value={`${trucksOnRoute.length} Trucks`} />
                           <DetailRow
                    label="Time On-Site"
                    value={medTimeOnSite ? `${medTimeOnSite} min` : '-'}
                    exceedsBenchmark={exceedsBenchmark}
                    tooltip={(
                                   <WarningHoverIcon
                        exceedsBenchmark={exceedsBenchmark}
                        message="Based off of the median time it takes drivers to enter and exit the site."
                      />
                    )}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </Popup>
    </Fragment>
  );
};

export default InfoPopupCard;
