// @ts-nocheck
// NOTE: Deprecated - use AssignmentStatusTag
import React from 'react';

import { Pause } from 'common-ui/Icon';
import { Tag } from 'common-ui/Labels';
import type { SendStatus } from 'pages/Projects/types';
import styles from './styles.module.scss';



export const textForStatus = (status: SendStatus) => {
  switch (status) {
    case 'PENDING':
      return 'Pending';
    case 'ACCEPTED':
      return 'Accepted';
    case 'LOGGING':
      return 'Logging';
    case 'PAUSED':
      return 'Paused';
    case 'DONE':
      return 'Done';
    case 'TERMINATED':
      return 'Ended';
    default:
      return '';
  }
};

const classNameForStatus = (status: SendStatus) => {
  switch (status) {
    case 'PENDING':
      return styles.pending;
    case 'ACCEPTED':
      return styles.accepted;
    case 'LOGGING':
      return styles.logging;
    case 'PAUSED':
      return styles.paused;
    case 'DONE':
      return styles.done;
    case 'TERMINATED':
      return styles.ended;
    default:
      return '';
  }
};

const iconForStatus = (status: SendStatus) => {
  switch (status) {
    case 'PAUSED':

      return <Pause />;
    default:
      return '';
  }
};

type SendStatusTagProps = {
  status: SendStatus
};

const SendStatusTag = ({ status }: SendStatusTagProps) => (

  <Tag text={textForStatus(status)} className={classNameForStatus(status)} leftIcon={iconForStatus(status)} />
);

export default SendStatusTag;
