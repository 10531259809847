// @ts-nocheck
import React, {Fragment} from 'react';

import Analytics from 'common-ui/Analytics/SegmentAnalytics';
import {Actions} from 'common-ui/Analytics/types';

import {MinimalButton} from 'common-ui/Button';
import {ChevronLeft, ChevronRight} from 'common-ui/Icon';
import styles from './styles.module.scss';
import type {SidebarPropTypes} from './types';

type SidebarDividerProps = {
  visible: boolean,

  setVisible: (param:boolean) => void,
};

export const SidebarDivider = ({ visible, setVisible }: SidebarDividerProps) => (

  <div className={styles.sidebarDivider}>

    <MinimalButton
      onClick={() => {
        setVisible(!visible);
        Analytics.trackClick(Actions.sidebarToggleClick);
      }}
      className={styles.sidebarButton}
    >

      {visible ? <ChevronLeft /> : <ChevronRight />}
    </MinimalButton>
  </div>
);

/*
  This sidebar is the more simple, non-overlaying type
  It is meant to be used as a component by the side of a larger "overview"
  type container

  Specify the contents of the sidebar with the SidebarComponent prop

  Make sure to set flex-grow on the overview container so it can stretch
  itself to the remainder of the width
*/
const Sidebar = ({
  SidebarComponent,
  sidebarStateObject,
  className,
  ...rest
}: SidebarPropTypes) => (

  <Fragment>
    { sidebarStateObject.visible
      ? (

        <div className={`${styles.sidebar} ${className || ''}`}>
          <SidebarComponent {...rest} />
        </div>
      )
      : null
    }

    <SidebarDivider {...sidebarStateObject} />
  </Fragment>
);

export default Sidebar;
