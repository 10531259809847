// @ts-nocheck
import React from 'react';

import Flag from 'components/Common/Flag';

const EditSiteToast = ({
  companyID,
  site
}: any) => (

  <Flag
    style={Flag.Styles.SUCCESS}
    title={`${site.name} Created`}
    body="Add more details such as products on the Sites page."
    linkText="Edit Site Details"
    linkRef={`/sites/?company=${companyID}&siteId=${site.id}`}
  />
);

export default EditSiteToast;
