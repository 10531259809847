// @ts-nocheck
import classNames from 'classnames';
import moment from 'moment-timezone';
import React, { useState } from 'react';

import { useTimezone } from 'common-ui/Hooks';


import { Calendar } from 'common-ui/Icon';

import { Calendar as CalendarControl } from 'components/Common/Calendar';
import { useOutsideClickHandler } from 'utils/Hooks';
import styles from './styles.module.scss';

const CalendarInput = ({
  value,
  onChange,
  isOverlaid,
  isShowOpen,
  ...inputProps
}: any) => {
  const timezone = useTimezone();
  const [visible, setVisible] = useState(false);

  const dateControlRef = React.useRef();
  useOutsideClickHandler(dateControlRef, () => setVisible(false));

  return (

    <div ref={dateControlRef}>

      <div

        className={classNames(styles.calendarInputField, {[styles.selected]: visible})}
        onKeyPress={() => setVisible(!visible)}
        onClick={() => setVisible(!visible)}
        role="button"
        tabIndex={0}
      >

        <div className={styles.dateCalendarIcon}>
          <Calendar />
        </div>

        <div className={styles.dateCalendarText}>

          {moment(value).format('ddd MMM D, YYYY')}
        </div>
      </div>

      <div className={classNames(styles.calendarFormFieldPopup, { [styles.hidden]: !visible, [styles.isOverlaid]: isOverlaid, [styles.selected]: visible })}>

        <CalendarControl

          selectedDate={moment(value).tz(timezone)}
          setSelectedDate={(d: any) => {
            setVisible(!visible);
            onChange(d);
          }}
          isOpen={visible}
          isShowOpen={isShowOpen}
          {...inputProps}
        />
      </div>
    </div>
  );
};

CalendarInput.defaultProps = {
  isOverlaid: false,
};

export default CalendarInput;
