// @ts-nocheck
import { compose, withStateHandlers, mapProps, HOC } from 'recompose';

const capFirstLetter = (s: string) =>
  `${s.charAt(0).toUpperCase()}${s.slice(1)}`;

/*
  This HOC is a simple Hooks-like implementation of shareable stateful logic
  It allows you to "name" components with a string value
  and reference state fields or handlers of a component
  by this name, stored as a state object

  An example state object:
  {
    projectDetailsSidebar: {
      visible: true,
      setVisible:  (param:boolean) => void,
    }
  }

  It works by using a Redux-y way to merge the named state object
  with new state field and handler

  This HOC is used by our other helper HOCs such as withMultiPageModal or withSidebar

  You can nest the calls to withStateField,
  but you must pass in the parent's state objects into the child
  if you want them to reference the same state object
  (otherwise, the child will have it's own separate "idea" of the state field)

  e.g
  const Child = withStateField('myName', 'myField')(childComp);
  const Parent = withStateField('myName', 'myField')((parentProps) => (
    <div>
      <Child {...parentProps} />  // Here Child gets the same myName.myField state
    </div>
  );

  TODO figure out how to make this drilling unnecessary using Provider/Context
  TODO allow passing in function to set stateFieldDefault e.g. (props) => defaultValue
*/

/*
  THE ORDER WE MERGE THE COMPONENTNAME FIELD IS VERY IMPORTANT HERE

  We must merge starting from the NEW named state (the ones generated
  in the above withStateHandlers() call)
  Followed by the ORIGINAL named state object in baseProps (if any)
  This is so we can use this stateField HOC in different places
  To have multiple components control it
  This way, if a child tries to use the same signature of withStateField
  that was already used in a parent,
  the ...state[componentName] spread for the "new" state
  will not override the state fields already set by the parent

  e.g.
  withStateField('test')(parent)(
    withStateField('test')(child)
  )

  or

  const Parent = withStateField('test')(parentComp);
  const Child = withStateField('test')(childComp);
  <Parent>
    <Child />
  </Parent>
*/
const withStateField = (
  componentName: string,
  stateField: string,
  stateFieldDefault: any,
  ...baseHOCs: any
): HOC<any, any> =>
  compose(
    // Apply all the previous HOCs passed into it
    // TODO If withStateField is called multiple times, do we apply all baseHOCs multiple times?
    ...baseHOCs,
    // Store base props for later
    mapProps((props) => ({ baseProps: props })),
    // Generate state field and handlers, mapped under componentName key
    withStateHandlers(
      {
        [componentName]: {
          [stateField]: stateFieldDefault,
        },
      },
      {
        tempSetField: (state) => (value) => ({
          [componentName]: {
            ...state[componentName],
            [stateField]: value,
          },
        }),
      },
    ),
    // Merge starting with the baseProps first,
    // then the componentName state object, if any

    mapProps(({ baseProps, tempSetField, ...state }) => {
      const stateHandlerName = `set${capFirstLetter(stateField)}`;
      const newProps = {
        ...baseProps,
        [componentName]: {
          ...state[componentName],
          [stateHandlerName]: tempSetField,
          ...baseProps[componentName],
        },
      };
      return newProps;
    }),
  );

export default withStateField;
