// @ts-nocheck

/**
 * These are the general Hey man are you able to join us? :slightly_smiling_face: s for what the element is showing or modifying.
 * Please add to this and help keep it clean.
 *
 * If there are any significant changes please update ReadMe.md in this directory
 */

const Filter = {
  ClearAllFilters: 'ClearAllFilters',
};

const Target = {
  NewTarget: 'NewTarget',
  TargetCopyFrom: 'TargetCopyFrom',
  TargetIDField: 'TargetIDField',
  ClientField: 'ClientField',
  CreateTargetSubmit: 'CreateTargetSubmit',
  TargetProjectNameField: 'TargetProjectNameField',
  JobIDField: 'JobIDField',
  SelectAllTargets: 'SelectAllTargets',
  DeleteTargets: 'DeleteTargets',
  TargetRowCard: 'TargetRowCard',
};

const Assignment = {
  TravelTime: 'travelTime',
  Filter: 'filterButton',
  CancelFilter: 'cancelFilter',
  ApplyFilter: 'applyFilter',
  AddNewFilter: 'addNewFilter',
  FilterType: 'filterType',
  FilterValue: 'filterValue',
  FilterButton: 'filterButton',
  EditAssignment: 'EditAssignment',
  CloseButton: 'CloseButton',
  AddToLoad: 'AddLoadTracking',
  RemoveFromLoad: 'RemoveFromLoad',
  UnassignHireButton: 'UnassignHireButton',
  InitalStatus: 'InitalStatus',
  HaulerTime: 'HaulerTime',
  InvoiceTime: 'InvoiceTime',
};
const Modal = {
  ConfirmModalButton: 'ConfirmModalButton',
  ConfirmModalCancelButton: 'ConfirmModalCancelButton',
};
const Company = {};
const User = {};
const Payment = {};
const Project = {
  CreateNewProject: 'CreateNewProject',
  ClassicNewProject: 'ClassicNewProject',
  SmartNewProject: 'SmartNewProject',
  ProjectName: 'ProjectName',
  ClientName: 'ClientName',
  CompletionDate: 'CompletionDate',
  CreateUpdateProject: 'CreateUpdateProject',
  CreateProjectPopUp: 'CreateProjectPopUp',
  TravelTimeInput: 'TravelTimeInput',
  ProjectAccordion: 'ProjectAccordion',
  ProjectAccordionCheckbox: 'ProjectAccordionCheckbox',
  EditProjectButton: 'EditProjectButton',
  TravelTimeHourMinuteSelection: 'TravelTimeHourMinuteSelection',
  CancelAssignmentButton: 'CancelAssignmentButton',
  BulkUpdateAssignmentButton: 'BulkUpdateAssignmentButton',
  RequestTimesheetToggle: 'RequestTimesheetToggle',
  EditAssignmentButton: 'EditAssignmentButton',
  JobID: 'JobID',
  TargetSelectionInput: 'TargetSelectionInput',
  DropOffSite: 'DropOffSite',
  Quantity: 'Quantity',
  QuantityType: 'QuantityType',
  ProjectType: 'ProjectType',
  ForwardEmail: 'ForwardEmail',
  ProjectAttachment: 'ProjectAttachment',
  ProjectForemanDropdown: 'ProjectForemanDropdown',
  SiteCreateAddress: 'SiteCreateAddress',
  SaveSite: 'SaveSite',
  Foreman: 'Foreman',
  ProjectActions: 'ProjectActions',
  DateSelectButton: 'DateSelectButton',
  PreviousDateButton: 'PreviousDateButton',
  NextDateButton: 'NextDateButton',
  HiresListSearch: 'HiresListSearch',
  SelectAllProjectsCheckbox: 'SelectAllProjectsCheckbox',
  ProjectSelectionCheckbox: 'ProjectSelectionCheckbox',
  SendProjectButton: 'SendProjectButton',
  CopyProjectButton: 'CopyProjectButton',
  IncludeAssignmentsCheckbox: 'IncludeAssignmentsCheckbox',
  IncludeCancelledCheckbox: 'IncludeCancelledCheckbox',
  ConfirmCopyButton: 'ConfirmCopyButton',
  AddAssignmentButton: 'AddAssignmentButton',
};

const AssignmentDefaults = {
  AssignmentPickUp: 'AssignmentPickUp',
  AssigmentNumberOfHires: 'AssigmentNumberOfHires',
  AssigmentProjectSiteAddress: 'AssigmentProjectSiteAddress',
  AssignmentPhase: 'AssignmentPhase',
  AssignmentProductInfo: 'AssignmentProductInfo',
  AssignmentEquipmentInfo: 'AssignmentEquipmentInfo',
  AssignmentQuantity: 'AssignmentQuantity',
  AssignmentQuantityUnit: 'AssignmentQuantityUnit',
  AssignmentStartTime: 'AssignmentStartTime',
  AssignmentDuration: 'AssignmentDuration',
  AssignmentDurationHint: 'AssignmentDurationHint',
  AssignmentStaggerStart: 'AssignmentStaggerStart',
  AssignmentGroupStagger: 'AssignmentGroupStagger',
  AssignmentInvoiceRate: 'AssignmentInvoiceRate',
  AssignmentInvoiceRateUnit: 'AssignmentInvoiceRateUnit',
  AssignmentHaulerRate: 'AssignmentHaulerRate',
  AssignmentHaulerRateUnit: 'AssignmentHaulerRateUnit',
  AssignmentHideHaulerRate: 'AssignmentHideHaulerRate',
  AssignmentNotes: 'AssignmentNotes',
};

const Request = {
  AcceptRequestButton: 'AcceptRequestButton',
  DeclineRequestButton: 'DeclineRequestButton',
  NewRequestButton: 'NewRequestButton',
  SendRequestButton: 'SendRequestButton',
  SelectAllRequestCheckbox: 'SelectAllRequestCheckbox',
  CancelRequestButton: 'CancelRequestButton',
  ConfirmRequestCancelButton: 'ConfirmRequestCancelButton',
  SelectRequestCheckbox: 'SelectRequestCheckbox',
  ChangeRequestTab: 'ChangeRequestTab',
  RequestCard: 'RequestCard',
};

const Job = {
  NewSelfLog: 'NewSelfLog',
  StopJob: 'StopJob',
  PauseJob: 'PauseJob',
  ConfirmStopJob: 'ConfirmStopJob',
  ConfirmPauseJob: 'ConfirmPauseJob',
  JobCard: 'JobCard',
  DeclineJob: 'DeclineJob',
  AcceptJob: 'AcceptJob',
  StartJob: 'StartJob',
  ReceiptCard: 'ReceiptCard',
};

const Ticket = {
  NewTicket: 'NewTicket',
  TicketNumber: 'TicketNumber',
  Quantity: 'Quantity',
  Gross: 'Gross',
  Tare: 'Tare',
  Notes: 'Notes',
  HaulerRateUnit: 'HaulerRateUnit',
  StartTime: 'StartTime',
  EndTime: 'EndTime',
  AddTicket: 'AddTicket',
  TicketRow: 'TicketRow',
  TicketItem: 'TicketItem',
  LoadDumpTime: 'LoadDumpTime',
  TimeAndAuthor: 'TimeAndAuthor',
  TicketCard: 'TicketCard',
  TicketCardTicketNumber: 'TicketCardTicketNumber',
  TicketCardJobID: 'TicketCardJobID',
  TicketCardTicketQuantity: 'TicketCardTicketQuantity',
  TicketMaterialType: 'TicketMaterialType',
  TicketSignedOffIndicator: 'TicketSignedOffIndicator',
  ExportTickets: 'ExportTickets',
  ExportInvoice: 'ExportInvoice',
  ExportInvoiceSuccess: 'ExportInvoiceSuccess',
  PickUpArrival: 'PickUpArrival',
  DropOffDeparture: 'DropOffDeparture',
  JobNumber: 'JobNumber',
  PickUpSite: 'PickUpSite',
  DropOffSite: 'DropOffSite',
  Product: 'Product',
  Phase: 'Phase',
  ConfirmRemoveAttachment: 'ConfirmRemoveAttachment',
  ConfirmRemoveCancel: 'ConfirmRemoveCancel',
  DownloadTicket: 'DownloadTicket',
  ConfirmDownloadButton: 'ConfirmDownloadButton',
  CancelDownloadButton: 'CancelDownloadButton',
  PastProjectButton: 'PastProjectButton',
};

const TicketDetails = {
  SaveChanges: 'SaveChanges',
  CancelChanges: 'CancelChanges',
};

const TicketModal = {
  CloseTicketModal: 'CloseTicketModal',
  ExpandBasicInfo: 'ExpandBasicInfo',
  ExpandRateInfo: 'ExpandRateInfo',
  ExpandTimesInfo: 'ExpandTimesInfo',
  ExpandDeliveryDetailsInfo: 'ExpandDeliveryDetailsInfo',
};

const Login = {
  PhoneNumber: 'PhoneNumber',
  VerificationCode: 'VerificationCode',
  SignIn: 'SignIn',
};
const Settings = {
  RequestTimesheet: 'RequestTimesheet',
  SaveChanges: 'SaveChanges',
};

const Insights = {
  ActionBar: 'ActionBar',
};

const Timesheets = {
  AddAdjustment: 'AddAdjustment',
  AdjustmentReason: 'AdjustmentReason',
  AdjustmentMinutes: 'AdjustmentMinutes',
  AdjustmentTiming: 'AdjustmentTiming',
  AdjustmentComments: 'AdjustmentComments',
  AdjustmentCancel: 'AdjustmentCancel',
  AdjustmentAffirmative: 'AdjustmentAffirmative',
  TicketsTimeline: 'TicketsTimeline',
  TicketsTimelineTotalMaterial: 'TicketsTimelineTotalMaterial',
  TicketsDashboard: 'TicketsDashboard',
  AddTicketToTicketsTimeline: 'AddTicketTOTicketsTimeline',
};

const AdjustmentModal = {
  ManualAdjustmentRemove: 'ManualAdjustmentRemove',
};

const Sites = {
  SiteInfoCard: 'SiteInfoCard',
  CreateNewSite: 'CreateNewSite',
  SiteName: 'SiteName',
  SiteLocation: 'SiteLocation',
  SiteType: 'SiteType',
  SiteProduct: 'SiteProduct',
  SiteID: 'SiteID',
  SiteGeofence: 'SiteGeofence',
  SiteStatus: 'SiteStatus',
  SiteNotes: 'SiteNotes',
  AddSite: 'AddSite',
};

const Alerts = {
  ErrorMessage: 'ErrorMessage',
};

const Live = {
  PopupCardViewRouteInfo: 'PopupCardViewRouteInfo',
  DriverChipViewRouteInfo: 'DriverChipViewRouteInfo',
};

const Equipment = {
  EquipmentName: 'EquipmentName',
  EquipmentCapacity: 'EquipmentCapacity',
  EquipmentCapacityUnit: 'EquipmentCapacityUnit',
  EquipmentRate: 'EquipmentRate',
  EquipmentRateUnit: 'EquipmentRateUnit',
  EquipmentDescription: 'EquipmentDescription',
  EquipmentCode: 'EquipmentCode',
};

const Accounts = {
  NewAccountDropdown: 'NewAccountDropdown',
  NewInternal: 'NewInternal',
  NewExternalGuest: 'NewExternalGuest',
  NewClient: 'NewClient',
};

const Menu = {
  DispatchMenuLink: 'DispatchMenuLink',
  LiveMenuLink: 'LiveMenuLink',
  AccountsMenuLink: 'AccountsMenuLink',
  TargetsMenuLink: 'TargetsMenuLink',
  SitesMenuLink: 'SitesMenuLink',
  PaymentsMenuLink: 'PaymentsMenuLink',
  HistoryMenuLink: 'HistoryMenuLink',
  RequestsMenuLink: 'RequestsMenuLink',
  InsightsMenuLink: 'InsightsMenuLink',
  SettingsLink: 'SettingsLink',
  GeneralSearchbar: 'GeneralSearchbar',
};

const Sidebar = {
  CloseSidebarButton: 'CloseSidebarButton',
  ActivityStreamSidebar: 'ActivityStreamSidebar',
};

// eslint-disable-next-line import/prefer-default-export
export const Labels = {
  ...Filter,
  ...Assignment,
  ...Company,
  ...User,
  ...Payment,
  ...Project,
  ...Modal,
  ...Target,
  ...Request,
  ...Job,
  ...Ticket,
  ...Login,
  ...Settings,
  ...TicketDetails,
  ...Insights,
  ...Timesheets,
  ...AssignmentDefaults,
  ...Sites,
  ...Alerts,
  ...Live,
  ...Equipment,
  ...Accounts,
  ...Menu,
  ...Sidebar,
  ...TicketModal,
  ...AdjustmentModal,
};


export type Label = $Keys<typeof Labels>;
