// @ts-nocheck
import React from 'react';

import { Dropdown } from 'components/Common/Form';
import timezones from './utils/timezones.list';
// eslint-disable-next-line import/namespace,import/default

type DropdownProps = {
  onChange: (newOption?: string) => void,
  value?: string
};

const TimeZonePickerUI = (props: DropdownProps) => (

  <Dropdown options={timezones} {...props} />
);

TimeZonePickerUI.defaultProps = {
  value: null,
};

export default TimeZonePickerUI;
