// @ts-nocheck
import { useQuery, gql } from '@apollo/client';
import { useMemo } from 'react';

import { useCompanyContext } from 'utils/Hooks';

export const PROJECT_SHARING_LINK_QUERY = gql`
query projectSharingLinkQuery($projectID: ID!, $companyID: ID!) {
  projectSharingLink(projectID: $projectID, companyID: $companyID) {
    id
    link
    settings {
      driverName
      phoneNumber
      loggingTimestamps
      loggingStatus
      licensePlate
    }
    enabled
  }
}
`;

const useGetProjectSharingLink = (projectID: string) => {
  const { selectedCompanyID } = useCompanyContext();
  const { loading, error, data } = useQuery(PROJECT_SHARING_LINK_QUERY, {
    variables: {
      projectID,
      companyID: selectedCompanyID,
    },
    skip: !projectID || projectID === '0' || selectedCompanyID === '0',
  });
  return useMemo(() => (
    (!loading && !error && data && data.projectSharingLink)
      ? (data.projectSharingLink)
      : null
  ), [loading, error, data]);
};

export default useGetProjectSharingLink;
