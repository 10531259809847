// @ts-nocheck
import React from 'react';

import CreatableSelect from 'react-select/creatable';

import { Add } from 'common-ui/Icon';
import { AddCollaboratorModal, useIsCollaborator } from 'pages/Hires/Collaborators';
import { useModal } from 'utils/Hooks';
import { CollaboratorOption, CollaboratorChip } from './components';
import { useCollaborators, useCollaboratorMap } from './hooks';
import styles from './styles.module.scss';



/*
  For proper collaborator hover card dipslay, to the root page (not the form)
  of any form you will be adding this input to add, the following component:

  <CollaboratorHoverTooltip />
*/

type CollaboratorInputProps = {
  values: string[], // list of collaborator ids

  onChange: (any) => void,
}

const CollaboratorInput = ({
  values,
  onChange,
  ...inputProps
}: CollaboratorInputProps) => {
  const collaborators =   useCollaborators();
  const collaboratorMap = useCollaboratorMap(collaborators);
  const isCollaborator = useIsCollaborator();

  const { openModal, closeModal } = useModal();
  return (

    <CreatableSelect
      isMulti
      isClearable
      closeMenuOnSelect
      classNamePrefix="collaborator-input"
      createOptionPosition="last"
      options={collaborators.map((c: any) => ({
        label: `${c.firstName} ${c.lastName}`,
        value: c.accountId
      }))}
      value={values.map(id => (
        collaboratorMap[id] ? {
          label: collaboratorMap[id]
            ? `${collaboratorMap[id].firstName} ${collaboratorMap[id].lastName}`
            : '',
          value: id,
        } : null))}
      onChange={(newVal: any) => {
        if (newVal === null) {
          onChange([]);
        } else if (newVal) {
          onChange(newVal.map((v: any) => v.value));
        } else {
          onChange(values);
        }
      }}
      onCreateOption={() => {
        openModal(
          <AddCollaboratorModal
            onClose={closeModal}
            onComplete={(newCollaborator: any) => {
              if (newCollaborator) {
                onChange([...values, newCollaborator.accountId]);
              }
              closeModal();
            }}
          />
        );
      }}
      placeholder="Give access to internal and external accounts."
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
        MultiValueContainer: ({
          data: {  value }
        }: any) => (
          <CollaboratorChip
            collaborator={collaboratorMap[value]}
            onMouseDown={() => onChange(values.filter(v => v !== value))}
          />
        ),
      }}
      formatOptionLabel={({
        __isNew__,
        label,
        value
      }: any) => (!__isNew__ && collaboratorMap[value] ? (

        <CollaboratorOption
          firstName={collaboratorMap[value].firstName}
          lastName={collaboratorMap[value].lastName}
          companyName={collaboratorMap[value].companyName}
          secondaryText={collaboratorMap[value].role}
        />
      ) : label)}

      formatCreateLabel={(inputVal: any) => <div className={styles.createLabel}>

        <Add />
        Add Guest:&nbsp;
        {inputVal}
      </div>}
      isValidNewOption={(input: any) => !!input && !isCollaborator}
      theme={(theme: any) => ({
        ...theme,

        colors: {
          ...theme.colors,
          primary: styles.selectHover,
          primary25: styles.selectHover,
          primary50: styles.selectHover,
        }
      })}
      {...inputProps}
    />
  );
};

export default CollaboratorInput;
