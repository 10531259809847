// @ts-nocheck
import React, { useState, useRef, ReactNode } from 'react';

import ClickableDiv from 'common-ui/ClickableDiv';
import { NineDots } from 'common-ui/Icon';
import BadgeHoverIcon from 'components/Common/BadgeHoverIcon';
import CompanyLogo from 'components/Common/CompanyLogo';




import { useOutsideClickHandler } from 'utils/Hooks';
import styles from './styles.module.scss';

type CompanyDropdownProps = {
  options: ReactNode,
  onChange?: () => void,
};

const CompanyDropdown = ({
  options,
  onChange,
}: CompanyDropdownProps) => {
  const [dropdownState, setDropdownState] = useState(false);
  const dropdownRef = useRef();
  useOutsideClickHandler(dropdownRef, () => setDropdownState(false));

  return (

    <div className={styles.companyDropdownWrapper}>

      <button
        id="companyDropdownId"
        type="button"
        onClick={() => { setDropdownState(!dropdownState); }}
        className={styles.nineDots}
      >

        <BadgeHoverIcon icon={<NineDots />} tooltipMessage="Switch Company" />
      </button>
      {!!dropdownState && (

        <div className={styles.dropdownContainer} ref={dropdownRef}>

          <div className={styles.header}> Switch to ... </div>

          {options.map((o: any) => <ClickableDiv
            key={`${o?.id}`}
            onClick={() => {

              onChange(o.id);
              setDropdownState(!dropdownState);
            }}
            className={styles.dropdownOptionContainer}
          >
            <div className={styles.companyOptionWrapper}>
              <CompanyLogo
                value={o.logo && o.logo.getURL}
                companyName={o.name}
              />
              <div className={styles.textBody}>{o.name}</div>
            </div>
          </ClickableDiv>)}
        </div>
      )}
    </div>
  );
};

CompanyDropdown.defaultProps = {
  onChange: () => {},
};

export default CompanyDropdown;
