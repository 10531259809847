// @ts-nocheck
import React from 'react';

import { Tag } from 'common-ui/Labels';
import styles from './styles.module.scss';

type Priority = 'HIGH' | 'MEDIUM' | 'LOW';

const textForPriority = (priority: Priority) => {
  switch (priority) {
    case 'HIGH':
      return 'High';
    case 'MEDIUM':
      return 'Medium';
    case 'LOW':
      return 'Low';
    default:
      return '';
  }
};

const classNameForPriority = (priority: Priority) => {
  switch (priority) {
    case 'HIGH':
      return styles.high;
    case 'MEDIUM':
      return styles.medium;
    case 'LOW':
      return styles.low;
    default:
      return '';
  }
};

type PriorityTagProps = {
  priority: Priority
};

const PriorityTag = ({ priority }: PriorityTagProps) => (

  <Tag
    text={textForPriority(priority)}
    className={classNameForPriority(priority)}
  />
);

export default PriorityTag;
