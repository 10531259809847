// @ts-nocheck
import classNames from 'classnames';
import React, { useRef } from 'react';

import CreatableSelect from 'react-select/creatable';

import { ChevronLeft } from 'common-ui/Icon';
import { useGetProductsByCompanyId } from 'pages/Sites/graphql';


import { useCompanyContext } from 'utils/Hooks';
import styles from './styles.module.scss';

type DispatchProductInputProps = {
  value : {
      name: string,
      id: string,
    },

  onChange: ({[string]: any}) => void,
  enableCreateProduct?: boolean,
  embedded:boolean | unknown
  disabled:boolean
};

const DispatchProductInput = ({
  value,
  onChange,
  embedded,
  disabled,
  enableCreateProduct,
  ...inputProps
}: DispatchProductInputProps) => {
  const { selectedCompanyID } = useCompanyContext();

  const selectProduct = (inputValue: any) => {
    if (inputValue) {
      onChange({ name: inputValue.label, id: inputValue.value });
    } else onChange('Clear');// Using 'Clear' instead of null to distinguish between when productInfo is undefined (we want to use the exisiting assignment.productInfo) and explicitly wanting to clear the product
  };

  const addProduct = (eq: any) => {
    onChange({ name: eq, id: 0 });
  };

  const [companyProduct] = useGetProductsByCompanyId(selectedCompanyID);
  const productDropdownOptions = companyProduct.map(
    ({
      name,
      id
    }: any) => ({ label: name, value: id }),
  );


  const selectedProduct = value && value !== 'Clear' ? { value: value.id, label: value.name } : null;
  const href = useRef();

  return (

    <CreatableSelect
      classNamePrefix="react-select"
      className={disabled && styles.disabled}
      placeholder="ex. Asphalt"
      noOptionsMessage={() => (enableCreateProduct ? 'No product options. Type to add product.' : 'No product options')}
      createOptionPosition="first"
      isClearable
      onChange={selectProduct}
      options={productDropdownOptions}
      onCreateOption={addProduct}
      value={selectedProduct}
      isDisabled={disabled}
      ref={href}
      onBlur={() => {

        if (href.current && href.current.state && href.current.state.inputValue) addProduct(href.current.state.inputValue);
      }}
      components={{
        IndicatorSeparator: null,
        DropdownIndicator: ({
          // innerProps and selectProps come from react-select library
          // eslint-disable-line
          innerProps,

          // eslint-disable-line
          selectProps
        }: any) => (
          (!disabled
            && (
              <div

                className={classNames(styles.dropdownIndicator, {
                  [styles.open]: selectProps.menuIsOpen,
                })}
                {...innerProps}
              >
                <ChevronLeft />
              </div>
            )
          )
        ),
      }}
      styles={{

        control: (provided, state) => ({
          ...provided,
          border: embedded && !state.isFocused ? '0 !important' : 'inherit',
          height: embedded ? '100%' : 'inherit',
          backgroundColor: embedded && !state.isFocused ? 'transparent' : provided.backgroundColor,
          minHeight: '40px'
        }),
        container: (provided: any) => ({
          ...provided,
          height: embedded ? '100%' : 'inherit'
        }),
      }}
      theme={(theme: any) => ({
        ...theme,

        colors: {
          ...theme.colors,
          primary: styles.colorSelected,
          primary25: styles.colorSelectedLight,
          primary50: styles.colorSelectedMedium,
        }
      })}
      isValidNewOption={(inputValue: any, selectValue: any, selectOptions: any) => {
        return enableCreateProduct && inputValue !== '' && !selectOptions.some((o: any) => o.label === inputValue);
      }}
      {...inputProps}
    />
  );
};

DispatchProductInput.defaultProps = {
  enableCreateProduct: true,
};

export default DispatchProductInput;
