// @ts-nocheck
import React from 'react';
import { SVGOverlay } from 'react-map-gl';

const Polygon = ({
  coordinates,
  color
}: any) => (

  <SVGOverlay
    style={{ zIndex: -1 }}
    redraw={({ project }) => {
      // Generate a list of points by projecting each geopoint
      const points = coordinates.reduce((acc: any, coord: any) => {
        const [x, y] = project([coord.longitude, coord.latitude]);
        return `${acc} ${x},${y}`;
      }, '');

      return (

        <polygon
          points={points}
          fill={color}
          fillOpacity={0.25}
        />
      );
    }}
  />
);

export default Polygon;
