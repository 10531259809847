// @ts-nocheck
import { Formik } from 'formik';
import React, { useEffect } from 'react';

import { Button } from 'common-ui/Button';

import ID from 'common-ui/ConsistentIDs';
import type { Site } from 'common-ui/types';

import { useFlag, FLAG_POSITIONS } from 'components/Common/Flag';
import { useEditableMap } from 'components/Common/Map/utils/useEditableMap';
import { SplitModal } from 'components/Common/Modal/ModalTemplates';




import { SidebarForm } from 'components/Sidebar';


import { validateSitesForm } from 'pages/Sites/components/Form/BaseForm';

import { useAddSite } from 'pages/Sites/graphql';
import {
  siteTypes,
  mapFormToSiteInputValues,
  geofenceTypes,
  siteStatuses,

} from 'pages/Sites/utils/site.utils';
import type { intlShape } from 'react-intl';
import { injectIntlHOC } from 'utils/HOC';
import { useCompanyContext } from 'utils/Hooks';
import CreateSiteModalForm from './CreateSiteModalForm';
import CreateSiteModalMap from './CreateSiteModalMap';
import EditSiteToast from './EditSiteToast';
import styles from './styles.module.scss';

type CreateSiteModalProps = {
  onClose: () => void,
  onCreateSiteOption: (site:Site) => void,

  intl: intlShape,
  siteName: string,
  mobile: boolean,
};

const CreateSiteModal = ({
  onClose,
  onCreateSiteOption,
  intl,
  siteName,
  mobile,
}: CreateSiteModalProps) => {
  const addSite = useAddSite();
  const { selectedCompanyID } = useCompanyContext();
  const { setIsEditingMap, stopEditingMap } = useEditableMap();
  const { addFlag } = useFlag();
  useEffect(() => {
    setIsEditingMap(true);
  }, []);
  return (

    <Formik
      initialValues={{
        companyId: selectedCompanyID,
        status: siteStatuses.ACTIVE,
        name: siteName,
        location: '',
        radius: 200,
        products: [],
        siteType: siteTypes.SOURCE_RECEIVING,
        notes: '',
        geofenceType: geofenceTypes.CIRCLE,
      }}

      initialTouched={{ geofenceType: true }}
      onSubmit={(values: any, formik: any) => addSite(mapFormToSiteInputValues(values))
        .then((r: any) => {
          const createdSite = r.data.createSite;
          if (onCreateSiteOption) {
            onCreateSiteOption(createdSite);
            const toastId = `editSiteToast-${createdSite.id}`;
            addFlag(
              toastId,
              <EditSiteToast companyID={selectedCompanyID} site={createdSite} />,
              FLAG_POSITIONS.bottomLeft,
              10000, // 10 seconds
            );
          }
          onClose();
          stopEditingMap();
          formik.resetForm();
        })
      }
      validate={(values: any) => validateSitesForm(intl, values)}
    >
      {({
        handleSubmit,
        isValid,
        dirty
      }: any) => (
        mobile ? (
          <SidebarForm title="Create New Site" onClose={onClose}>
            <CreateSiteModalForm />
            <CreateSiteModalMap />
            <div className={styles.createSiteButtons}>
              <Button
                onClick={onClose}
                styleName="neutral"
                text="Cancel"
              />
              <Button
                id={ID.create(ID.Areas.General, ID.Types.Button, ID.Labels.SaveSite)}
                onClick={handleSubmit}
                styleName="primary"
                text="Save Site"
                disabled={!isValid || !dirty}
              />
            </div>
          </SidebarForm>
        ) : (
          <SplitModal
            onClose={onClose}
            headerText="Create New Site"
            actionText="Create Site"
            rightChild={<CreateSiteModalMap />}
            leftChild={<CreateSiteModalForm />}
            leftColumn="1/5"
            rightColumn="5/13"
            disabled={!isValid || !dirty}
            onSubmit={handleSubmit}
          />
        )
      )}
    </Formik>
  );
};

export default injectIntlHOC(CreateSiteModal);
