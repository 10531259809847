// @ts-nocheck
import React, { ReactNode } from 'react';

import { Count } from 'common-ui/Labels';
import { TooltipWrapper } from 'components/Common/Tooltip';
import styles from './styles.module.scss';


type BadgeHoverIconProps = {
  icon: ReactNode,
  tooltipMessage?: string,
  badgeNumberCount?: string,
};

const BadgeHoverIcon = ({
  tooltipMessage,
  badgeNumberCount,
  icon,
}:BadgeHoverIconProps) => (

  <span className={styles.hoverIconContainer}>

    <TooltipWrapper
      message={tooltipMessage}
      className={styles.titleInfoIcon}
    >

      <div className={styles.iconContainer}>

        {badgeNumberCount > 0 && (
          <Count className={styles.notificationsCount} value={badgeNumberCount} />
        )}
        {icon}
      </div>
    </TooltipWrapper>
  </span>
);

BadgeHoverIcon.defaultProps = {
  tooltipMessage: null,
  badgeNumberCount: null,
};

export default BadgeHoverIcon;
