// @ts-nocheck
import classNames from 'classnames';
import {times} from 'lodash';
import React, {useContext, useState} from 'react';

import {Droppable} from 'react-beautiful-dnd';

import {CellMeasurerCache} from 'react-virtualized';
import {HireChip} from '../HireChip';
import {HiresContext} from '../HiresListContext';
import Analytics from 'common-ui/Analytics/SegmentAnalytics';
import {Actions} from 'common-ui/Analytics/types';
import ID from 'common-ui/ConsistentIDs';
import {ContextSwitcher} from 'common-ui/ContextSwitcher';

import {TextInput} from 'components/Common/Form';


import Popup from 'components/Common/Popup';

import HireTagFilter, {HireTagFilterButton} from 'pages/Hires/Tags/HireTagFilter';

import useTagFilterURL from 'pages/Hires/Tags/useTagFilterURL';
import MemoizedHiresList from './MemoizedHiresList';
import styles from './styles.module.scss';
import {getFilteredCompanyClientHires, getFilteredHires, getFilteredSubcontractors} from './utils';

// eslint-disable-next-line import/namespace

type HiresListControlsProps = {
  searchValue: string,
  setSearchValue: (param:string) => void,
  filtersSelected: [],
  setFiltersSelected: () => void,

  getAllFilteredHires: (string, []) => void,
};

export const HiresListControls = ({
  searchValue,
  setSearchValue,
  filtersSelected,
  setFiltersSelected,
  getAllFilteredHires,
}: HiresListControlsProps) => (

  <div className={styles.hiresListControls}>

    <TextInput
      id={ID.create(
        ID.Areas.Dispatch,
        ID.Types.Input,
        ID.Labels.HiresListSearch,
      )}
      value={searchValue}
      onChange={setSearchValue}
      placeholder="Search for drivers"
      styleName={TextInput.styleNames.search}
    />

    <Popup
      className={styles.popup}

      button={({ setMenuOpen, menuOpen }) => (

        <HireTagFilterButton
          onClick={() => setMenuOpen(!menuOpen)}
          isSelected={filtersSelected.length > 0}
        />
      )}

      body={({ setMenuOpen, menuOpen }) => (

        <HireTagFilter
          filters={filtersSelected}
          setFilters={setFiltersSelected}
          menuOpen={menuOpen}
          onClose={() => {
            setMenuOpen(false);
          }}
          getAllFilteredHires={getAllFilteredHires}
          searchValue={searchValue}
          simple
        />
      )}
    />
  </div>
);

type HireListProps = {
  parentRef: { current: null | HTMLDivElement },
}

const HiresList = ({ parentRef }: HireListProps) => {
  const cache = new CellMeasurerCache({
    fixedWidth: true,
    minHeight: 55,
  });

  const [searchValue, setSearchValue] = useState('');
  const [hiresView, setHiresView] = useState('unassigned');
  const {
    hires, subcontractors, companyClientHires, fromUnassign,
  } = useContext(HiresContext);
  const unassignedHiresCount = (
    hires.filter(hire => hire.assignedCount === 0).length
    + companyClientHires.filter(hire => hire.assignedCount === 0).length
    + subcontractors.reduce((acc, curr) => (
      acc + Math.max(curr.numberHires - curr.assignedCount, 0)
    ), 0)
  );

  const allHiresCount = (
    hires.length
    + companyClientHires.length
    + subcontractors.reduce((acc, curr) => acc + curr.numberHires, 0)
  );

  const [tagFiltersSelected, setTagFiltersSelected] = useTagFilterURL();


  const getAllFilteredHires = (search, filters) => {
    const filteredCompanyHires = getFilteredHires(hires, search, filters)
      .filter(hire => (hiresView === 'all' ? true : hire.assignedCount === 0));
    const subcontractorHires = getFilteredSubcontractors(subcontractors, search, filters)

      .map((hire) => {
        if (hiresView === 'unassigned') {

          return times(hire.numberHires - hire.assignedCount, i => ({
            ...hire,
            copy: i + 1,
            assignedCount: 0,
          }));
        }

        return hire;
      })
      .flat();
    const filteredClientHires = getFilteredCompanyClientHires(companyClientHires, subcontractors, search, filters)

      .filter(hire => (hiresView === 'all' ? true : hire.assignedCount === 0));

    return [
      ...filteredCompanyHires,
      ...filteredClientHires,
      ...subcontractorHires,
    ];
  };

  const filteredHires = getAllFilteredHires(searchValue, tagFiltersSelected);
  const toggleOptions = [
    { label: `Unassigned (${unassignedHiresCount})`, value: 'unassigned' },
    { label: `All (${allHiresCount})`, value: 'all' }];

  return (

    <div className={styles.hiresList}>

      <div className={styles.hiresToggle}>

        <ContextSwitcher

          onChange={(newVal) => {
            setHiresView(newVal);
            if (newVal === 'all') {
              Analytics.trackClick(Actions.hiresListAllClick, 'All');
            }
          }}
          selectedValue={hiresView}
          options={toggleOptions}
        />
      </div>

      <HiresListControls
        searchValue={searchValue}

        setSearchValue={setSearchValue}
        filtersSelected={tagFiltersSelected}
        setFiltersSelected={setTagFiltersSelected}
        getAllFilteredHires={getAllFilteredHires}
      />

      <Droppable
        droppableId={JSON.stringify({ id: 'unassignContainer', isAssignment: false })}
        isDropDisabled={fromUnassign}
      >

        {(unassignContainerProvided, unassignContainerSnapshot) => (
          <div
            ref={unassignContainerProvided.innerRef}
            {...unassignContainerProvided.droppableProps}

            className={classNames(styles.unassignContainer, {
              [styles.hovering]: unassignContainerSnapshot.isDraggingOver,
            })}
          >
            <Droppable
              droppableId={JSON.stringify({ id: 'unassignList', isAssignment: false })}
              isDropDisabled
              mode="virtual"

              renderClone={(provided, snapshot, rubric) => (
                <HireChip
                  hire={filteredHires[rubric.source.index]}
                  provided={provided}
                  snapshot={snapshot}
                />
              )}
            >

              {(unassignListProvided) => (
                <div
                  ref={unassignListProvided.innerRef}
                  {...unassignListProvided.droppableProps}
                  className={styles.unassignList}
                >
                  <MemoizedHiresList hires={filteredHires} cache={cache} parentRef={parentRef} />
                </div>
              )}
            </Droppable>
          </div>
        )}
      </Droppable>
    </div>
  );
};

export default HiresList;
