import { useEffect } from 'react';

// This hook preselects a value based on an id field
// NOTE The option object MUST HAVE an additional id field identifying it for us to match
// (we don't match on label/value for now)
const usePreselectValue = (options: any, selectedID: any, selectedValue: any, setSelectedValue: any) => {
  useEffect(() => {
    // Clear out selectedValue when we submit/cancel project editing/adding
    if (!selectedID) {
      setSelectedValue(null);
    }
    // If the options changed, make sure the selected value is still valid
    // Check our selectedID against any option's id field
    const validIDs = options.map((s: any) => s.id);
    if (selectedValue && selectedValue.id && validIDs.indexOf(selectedValue.id) < 0) {
      setSelectedValue(null);
    }
    // If we are editing something that has that ID field already populated, pre-select the value
    if (selectedID) {
      const val = options.find(
        (s: any) => parseInt(s.id, 10) === parseInt(selectedID, 10),
      );
      setSelectedValue(val || null);
    }
  }, [selectedID, options]);
};

export default usePreselectValue;
