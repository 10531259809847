import { useContext, createContext } from 'react';

export const ModalContext = createContext({
  openModal: (onCloseHandler?: any) => {},
  closeModal: () => {},
});

// Usage:
// const { openModal, closeModal } = useModal();
// () => openModal(<div>Modal Content</div>);
const useModal = () => useContext(ModalContext);

export default useModal;
