// @ts-nocheck
import { graphql } from '@apollo/client/react/hoc';
import { compose, branch, renderNothing } from 'recompose';

const queryWithLoader = (query: any, config: any) => (WrappedComponent: any) => {
  const finalConfig = Object.assign({}, config);
  let dataPropName = 'data';
  if (!!config && !!config.name) {
    dataPropName = config.name;
    finalConfig.name = config.name;
  }

  // FIXME The options object generation seems a little funky here.
  // temporary fix to apollo-client defaultOptions fetchPolicy not working so we explicitly set it in each query
  if (!!config && typeof config.options === 'object') {
    finalConfig.options = Object.assign(
      { fetchPolicy: 'cache-and-network' },
      config.options,
    );
  } else if (!!config && typeof config.options === 'function') {
    finalConfig.options = (props: any) => Object.assign(
      { fetchPolicy: 'cache-and-network' },
      config.options(props),
    );
  } else {
    finalConfig.options = { fetchPolicy: 'cache-and-network' };
  }

  return compose(
    graphql(query, finalConfig),
    branch(

      props => !!props[dataPropName] && props[dataPropName].loading,
      renderNothing,
    ),
  )(WrappedComponent);
};
export default queryWithLoader;
