import React, {SVGProps} from 'react';

const Add = (props: SVGProps<SVGSVGElement>) => (

  <svg width={22} height={22} viewBox="0 0 22 22" {...props}>

    <title>Add</title>

    <path d="M 5 10 H 17 V 12 H 5 V 12 z" fill="#162093" fillRule="evenodd" />
  </svg>
);

export default Add;
