import React, {SVGProps} from 'react';

const UpDownSort = (props: SVGProps<SVGSVGElement>) => (

  <svg
    width="25px"
    height="25px"
    viewBox="0 0 25 25"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >

    <title>Icon/UpDownSort</title>

    <desc>Created with Sketch.</desc>

    <g transform="translate(5.000000, 0.000000)">

      <path
        fill="#162093"
        fillRule="evenodd"
        d="M10.46411,4.36589059 L9.36855794,5.79578171 C8.98528274,6.29602431 8.36274157,6.29897168 7.98016267,5.79963788 C7.5949165,5.29682282 7.59890656,4.48899757 7.98311719,3.98753406 C7.98311719,3.98753406 10.7490753,0.377465942 10.7510442,0.374894937 C10.9431741,0.124131044 11.1934284,-0.000276622811 11.4444861,0.00197779529 C11.6952064,0.00177000252 11.945687,0.125869565 12.1394394,0.378751115 L14.9044119,3.98753406 C15.2876871,4.48777666 15.2899453,5.30030408 14.9073664,5.79963788 C14.5221202,6.30245295 13.9031818,6.29724522 13.5189711,5.79578171 L12.4234191,4.36589059 L12.4234191,20.4397738 C12.4234191,21.1463613 11.9848128,21.718824 11.4437645,21.718824 C10.8989442,21.718824 10.46411,21.1461737 10.46411,20.4397738 L10.46411,4.36589059 L10.46411,4.36589059 Z M4.92341908,18.6413953 L4.92341908,2.55649549 C4.92341908,1.84674766 4.4848128,1.27941101 3.94376454,1.27941101 C3.39894418,1.27941101 2.96410999,1.85118121 2.96410999,2.55649549 L2.96410999,18.6413953 L1.86855794,17.2144698 C1.48528274,16.7152647 0.86274157,16.7123235 0.480162672,17.2106217 C0.0949165042,17.7123939 0.0989065583,18.5185437 0.483117193,19.0189671 C0.483117193,19.0189671 3.24907534,22.6215479 3.25104416,22.6241135 C3.44317411,22.8743573 3.69342836,22.998507 3.94448608,22.9962572 C4.1952064,22.9964646 4.44568699,22.8726224 4.63943943,22.6202653 L7.40441188,19.0189671 C7.78768708,18.519762 7.7899453,17.7089198 7.4073664,17.2106217 C7.02212024,16.7088494 6.40318177,16.7140464 6.01897114,17.2144698 L4.92341908,18.6413953 L4.92341908,18.6413953 Z"
      />
    </g>
  </svg>
);

export default UpDownSort;
