// @ts-nocheck

import React, { Fragment } from 'react';

import { ImgOrientationCSS } from '../ImageManipulation';
import { Camera } from 'common-ui/Icon';
import styles from './styles.module.scss';

type ThumbnailProps = {
  thumbnail: string,
  multiple: boolean,
  orientation: string | number,
  uploadCount: string | number,
  isUploading: boolean,

  onClick: () => any
};

const Thumbnail = ({
  thumbnail,
  multiple,
  orientation,
  uploadCount,
  isUploading,
  onClick,
}: ThumbnailProps) => {

  const transform = ImgOrientationCSS[orientation];

  return (

    <Fragment>
      {!!thumbnail && uploadCount > 0 ? (

        <div
          className={styles.imageContainer}
          onClick={onClick}
          onKeyPress={onClick}
          role="button"

          tabIndex="0"
        >
          <div
            className={`${styles.image} ${styles.uploaded}`}
            style={{
              backgroundImage: `url(${thumbnail})`,
              transform: `${transform}`,
            }}
          />
          {multiple ? (
            <div className={styles.count}>
              <div className={styles.label}>{uploadCount}</div>
            </div>
          ) : (
            ''
          )}
        </div>
      ) : (

        <div
          className={styles.image}
          onClick={onClick}
          onKeyPress={onClick}
          role="button"

          tabIndex="0"
        >
          {isUploading ? <div className={styles.spinner} /> : <Camera />}
          {multiple && uploadCount > 0 ? (
            <div className={styles.count}>
              <div className={styles.label}>{uploadCount}</div>
            </div>
          ) : (
            ''
          )}
        </div>
      )}
    </Fragment>
  );
};

export default Thumbnail;
