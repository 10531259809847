// @ts-nocheck
import _ from 'lodash';
import {
  compose,
  withProps,
  withHandlers,
  withStateHandlers,
  HOC,
} from 'recompose';

import { createFilterOption } from '../util';
import SearchUI from './SearchOnly.ui';
import type { SearchProps } from '../types';

const SearchHOC: HOC<any, SearchProps> = compose(
  withHandlers({

    onFiltersChange: ({ onFiltersChange }) => (selectedOptions) => {
      if (onFiltersChange) {
        onFiltersChange(selectedOptions);
      }
    },
  }),
  withStateHandlers(
    ({ sortOptions }) => ({
      sortOption: null,
      sortOptions,
      sortIsOpen: false,
      internalSelectedTerms: [],
    }),
    {
      setSelectedTerms: (__, { onFiltersChange }) => (newFilterItems) => {
        onFiltersChange(newFilterItems);

        return { internalSelectedTerms: newFilterItems };
      },
      setSortBox: () => newState => ({ sortIsOpen: newState }),
      onSortClick: ({ sortIsOpen }) => () => ({ sortIsOpen: !sortIsOpen }),

      onSelectSort: (state, { onSort }) => (selectedOption) => {
        // On Select Sort
        if (onSort) {
          onSort(selectedOption);
        }

        return {
          sortIsOpen: false,
          sortOption: selectedOption,
        };
      },
    },
  ),

  withProps(({
    filterItems,
    internalSelectedTerms,
  }) => ({
    selectedTerms: filterItems || internalSelectedTerms,
  })),
  withHandlers(
    {

      onClickClear: (
        { selectedTerms, setSelectedTerms },

      ) => (id) => {
        const filteredTerms = _.filter(selectedTerms, st => st.value !== id);

        setSelectedTerms(filteredTerms);
      },

      clearAll: ({ setSelectedTerms }) => () => {
        setSelectedTerms([]);
      },

      onSelectTerm: ({ setSelectedTerms }) => (newSelectedTerms) => {
        setSelectedTerms(newSelectedTerms || []);
      },

      onCreateOption: (
        { selectedTerms, setSelectedTerms },

      ) => (selectedOption) => {
        if (selectedOption.length > 0) {
          const newOption = createFilterOption(selectedOption);

          const newTerms = _.concat(selectedTerms, newOption);

          setSelectedTerms(newTerms);
        }
      },
    },
  ),

  withProps(({ currentOptions }) => ({
    possibleOptions: currentOptions,
  })),
);

export default SearchHOC(SearchUI);
