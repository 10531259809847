// @ts-nocheck

import type { FormikValues, FormikProps } from 'formik';

import type { EquipmentType } from 'pages/Projects/types';

import { rateUnits } from 'utils/format/rateUnits';

const useEquipmentFormikConfig = (
  equipment: EquipmentType,

  onComplete: (any) => void = () => {},
) => {
  return {
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      id: equipment ? equipment.id : null,
      name: equipment ? equipment.name : '',
      capacity: equipment ? equipment.capacity : '',
      unit: equipment && equipment.unit ? equipment.unit : rateUnits.TON,
      rate: equipment ? equipment.rate : '',
      rateUnit: equipment && equipment.rateUnit ? equipment.rateUnit : rateUnits.HOUR,
      description: equipment ? equipment.description : '',
      code: equipment ? equipment.code : '',
    },
    validate: (values: FormikValues) => {
      const errors = {};

      if (!values.name.trim()) {

        errors.name = 'Please enter an equipment name';
      }
      return errors;
    },
    onSubmit: (values: FormikValues, formik: FormikProps) => {
      formik.resetForm();
      onComplete(values);
    },
  };
};

export default useEquipmentFormikConfig;
