// @ts-nocheck
import React, {
  useState,
  useEffect,
  useContext,
  createContext,
  ReactNode,
} from 'react';

const DetectMobileContext = createContext/* ::<boolean> */(false);

type DetectMobileContextWrapperProps = {
  children: ReactNode,
};

// Copied from https://github.com/bence-toth/react-hook-media-query
export const DetectMobileContextWrapper = ({ children }: DetectMobileContextWrapperProps) => {
  const [doesMatch, setDoesMatch] = useState(false);

  useEffect(() => {
    const onUpdateMatch = ({
      matches
    }: any) => setDoesMatch(matches);

    const matcher = window.matchMedia('(max-width: 768px)');

    try {
      matcher.addEventListener('change', onUpdateMatch);
    } catch (e) {
      // Safari
      matcher.addListener(onUpdateMatch);
    }

    onUpdateMatch(matcher);

    return () => {
      try {
        matcher.removeEventListener('change', onUpdateMatch);
      } catch (e) {
        // Safari
        matcher.removeListener(onUpdateMatch);
      }
    };
  }, [setDoesMatch]);

  return (

    <DetectMobileContext.Provider value={doesMatch}>
      {children}
    </DetectMobileContext.Provider>
  );
};

const useDetectMobile = () => useContext(DetectMobileContext);

export default useDetectMobile;
