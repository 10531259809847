// @ts-nocheck
import React from 'react';

import ID from 'common-ui/ConsistentIDs';
import { FormikTextInput, FormikDropdown, FormikTextArea } from 'common-ui/Form';


import { capacityUnitOptions, rateUnitOptions } from 'utils/format/rateUnits';
import styles from './styles.module.scss';

const CreateEquipmentFormFields = () => {
  return (

    <>

      <FormikTextInput
        label="Equipment"
        name="name"
        placeholder="Ex. Triaxle"
        required
        id={ID.create(ID.Areas.Dispatch, ID.Types.Input, ID.Labels.EquipmentName)}
      />


      <div className={styles.formInputRow}>

        <FormikTextInput
          label="Capacity"
          name="capacity"
          placeholder="Ex. 1000"
          min="0"
          id={ID.create(ID.Areas.Dispatch, ID.Types.Input, ID.Labels.EquipmentCapacity)}
        />


        <FormikDropdown
          label="Units"
          name="unit"
          options={capacityUnitOptions}
          id={ID.create(ID.Areas.Dispatch, ID.Types.Dropdown, ID.Labels.EquipmentCapacityUnit)}
        />
      </div>


      <div className={styles.formInputRow}>

        <FormikTextInput
          label="Rate"
          name="rate"
          placeholder="00.00"
          styleName={FormikTextInput.styleNames.currency}
          min="0"
          id={ID.create(ID.Areas.Dispatch, ID.Types.Input, ID.Labels.EquipmentRate)}
        />


        <FormikDropdown
          label="Units"
          name="rateUnit"
          options={rateUnitOptions}
          id={ID.create(ID.Areas.Dispatch, ID.Types.Dropdown, ID.Labels.EquipmentRateUnit)}
        />
      </div>


      <FormikTextInput
        label="Code"
        placeholder="Ex. 680"
        name="code"
        id={ID.create(ID.Areas.Dispatch, ID.Types.Input, ID.Labels.EquipmentCode)}
      />


      <FormikTextArea
        label="Description"
        placeholder="Add equipment notes here..."
        name="description"
        id={ID.create(ID.Areas.Dispatch, ID.Types.Input, ID.Labels.EquipmentDescription)}
      />
    </>
  );
};

export default CreateEquipmentFormFields;
