// @ts-nocheck
import _ from 'lodash';

import  { Filter, FilterType } from '../types';
import { projectTypesForFiltering } from 'pages/Projects/utils/projects.utils';

const projectFilterTypes = [
  { value: 'customer', label: 'Customer' },
  { value: 'foreman', label: 'Foreman' },
  { value: 'company', label: 'Company' },
  { value: 'jobNum', label: 'Job #' },
];

const liveAssignmentFilterTypes = [
  ...projectFilterTypes,
  { value: 'pickUpSiteName', label: 'Pick Up Site' },
  { value: 'dropOffSiteName', label: 'Drop Off Site' },
  { value: 'name', label: 'Project Name' },
  { value: 'hireName', label: 'Driver' },
  { value: 'truckNumber', label: 'Truck Number' },
  { value: 'phase', label: 'Phase' },
  { value: 'subcontractorCompanyName', label: 'Subcontractor' },
];

// only filter based on project type - Smart or Classic
// setting type property is necessary to differentiate
// between projectFilters and projectAssignmentFilters
// on Projects page where two types of filters are present
const projectTypeFilterTypes = [
  { value: 'type', label: 'Project Type', type: 'project' },
];

const smartProjectFilter = {
  filterType: { value: 'type', label: 'Project Type', type: 'project' },
  filterValue: projectTypesForFiltering.SMART,
  label: 'Project Type: Smart',
  value: 'type-Smart',
};

const classicProjectFilter = {
  filterType: { value: 'type', label: 'Project Type', type: 'project' },
  filterValue: projectTypesForFiltering.CLASSIC,
  label: 'Project Type: Classic',
  value: 'type-Classic',
};

const isFilterProjectSpecific = (filter: Filter) => (
  filter.filterType
  && filter.filterType.type
  && filter.filterType.type === 'project'
);

// separates filters with defined type = 'project' from the rest
const separateProjectAndProjectAssignmentFilters = (filters: Filter[]) => (
  filters.reduce((acc, filter) => {
    if (isFilterProjectSpecific(filter)) {

      acc.projectFilters.push(filter);
      return acc;
    }


    acc.projectAssignmentFilters.push(filter);
    return acc;
  }, { projectFilters: [], projectAssignmentFilters: [] })
);

const projectAssignmentFilterTypes = [
  { value: 'name', label: 'Project Name' },
  { value: 'client', label: 'Client' },
  { value: 'foreman', label: 'Foreman' },
  { value: 'company', label: 'Company' },
  { value: 'targetId', label: 'Target ID' },
  { value: 'jobNum', label: 'Job #' },
  { value: 'createdByName', label: 'Created By' },
  { value: 'hireName', label: 'Hire Name' },
  { value: 'startTime', label: 'Start Time' },
  { value: 'assignedStatus', label: 'Assignment Status' },
  { value: 'phase', label: 'Assignment Phase' },
  { value: 'product', label: 'Product' },
  { value: 'rateUnit', label: 'Rate Unit' },
  { value: 'hirePhase', label: 'Hire Phase' },
  { value: 'equipment', label: 'Equipment' },
  { value: 'pickUpSiteName', label: 'Pick Up Site' },
  { value: 'dropOffSiteName', label: 'Drop Off Site' },
  { value: 'subcontractingCompanyName', label: 'Subcontractor Name' },
  { value: 'truckNumber', label: 'Truck Number' },
  { value: 'licensePlate', label: 'License Plate' },
  { value: 'type', label: 'Project Type' },
];

const draftFilter = {
  filterType: { value: 'assignedStatus', label: 'Assignment Status' },
  filterValue: 'Draft',
  label: 'Assignment Status: Draft',
  value: 'assignedStatus-Draft',
};

const missingFilter = {
  filterType: { value: 'assignedStatus', label: 'Assignment Status' },
  filterValue: 'Missing',
  label: 'Assignment Status: Missing',
  value: 'assignedStatus-Missing',
};

const acceptedFilter = {
  filterType: { value: 'hirePhase', label: 'Hire Phase' },
  filterValue: 'Accepted',
  label: 'Hire Phase: Accepted',
  value: 'hirePhase-Accepted',
};

const liveFilter = {
  filterType: { value: 'hirePhase', label: 'Hire Phase' },
  filterValue: 'Logging',
  label: 'Hire Phase: Logging',
  value: 'hirePhase-Logging',
};

const pendingFilter = {
  filterType: { value: 'hirePhase', label: 'Hire Phase' },
  filterValue: 'Pending',
  label: 'Hire Phase: Pending',
  value: 'hirePhase-Pending',
};

const doneFilter = {
  filterType: { value: 'hirePhase', label: 'Hire Phase' },
  filterValue: 'Done',
  label: 'Hire Phase: Done',
  value: 'hirePhase-Done',
};

const cancelledFilter = {
  filterType: { value: 'assignedStatus', label: 'Assigned Status' },
  filterValue: 'Cancelled',
  label: 'Assigned Status: Cancelled',
  value: 'assignedStatus-Cancelled',
};

const driverStatus = {
  PICKUP: 'Pick-up',
  DRIVING: 'Driving',
  DROPOFF: 'Drop-off',
};

const driverPickupFilter = {
  filterType: { value: 'driverStatus', label: 'Driver Status' },
  filterValue: driverStatus.PICKUP,
  label: 'Driver Status: Pick-up',
  value: 'driverStatus-Pick-up',
};

const driverDropoffFilter = {
  filterType: { value: 'driverStatus', label: 'Driver Status' },
  filterValue: driverStatus.DROPOFF,
  label: 'Driver Status: Drop-off',
  value: 'driverStatus-Drop-off',
};

const driverDrivingFilter = {
  filterType: { value: 'driverStatus', label: 'Driver Status' },
  filterValue: driverStatus.DRIVING,
  label: 'Driver Status: Driving',
  value: 'driverStatus-Driving',
};

const getDriverStatusFilterOptions = () => (
  [driverPickupFilter, driverDropoffFilter, driverDrivingFilter]
);

// / Get Filter Options from items.
const getFilterOptions = (
  items: Array<any>,
  transform: (x: any) => any,
  filterTypes = projectFilterTypes,
): Array<Filter> => {
  const optionsList = _.map(filterTypes, (filterType) => {
    // get unique list of possible props from items.
    const uniqueOptions = _.uniq(
      _.flatten(_.map(items, (item) => {
        const nItem = transform ? transform(item) : item;

        if (_.isArray(nItem[filterType.value])) {

          return nItem[filterType.value].map(element => _.trim(element));
        }
        return _.trim(nItem[filterType.value]);
      })),
    );


    // convert unique props into filter Options for search.
    return _.filter(
      _.map(uniqueOptions, (uniqueOption) => {
        const value = `${filterType.value}-${uniqueOption}`;
        const label = `${filterType.label}: ${uniqueOption}`;
        return {
          value,
          label,
          filterType,
          filterValue: uniqueOption,
        };
      }),
      item => !!item.filterValue,
    );
  });

  return _.flatten(optionsList);
};

// / Create a new filter Option
// / string of type "Type:Name"
const createFilterOption = (text?: string): { filterValue: any; label: any; filterType: { label: string; value: string } | null; value: string } | null => {
  if (text === '' || !text) {
    return null;
  }

  const parseIndex = typeof text === 'string' ? text.indexOf(':') : -1;

  let textType = null;
  let textValue = null;

  if (parseIndex > 0) {
    textType = _.toLower(text.substr(0, parseIndex));
    textValue = _.trim(text.substr(parseIndex + 1));
  } else {
    textType = null;
    textValue = _.trim(text);
  }

  const filterType = _.find(projectFilterTypes, ft => _.toLower(ft.label) === textType);

  return {
    value: `searchTerm-${textValue}`,
    label: textValue,
    filterType: filterType || null,
    filterValue: textValue || null,
  };
};

// / Filter the filters based on text
const filterFilters = (text: string, filters: Array<Filter>): Array<Filter> => {
  // if no text, return all;
  if (text === '') {
    return filters;
  }

  const parseIndex = typeof text === 'string' ? text.indexOf(':') : -1;

  let textType = null;

  let textValue = null;

  if (parseIndex > 0) {
    textType = _.toLower(text.substr(0, parseIndex));
    textValue = _.trim(text.substr(parseIndex + 1));
  } else {
    textType = null;
    textValue = text;
  }

  return _.filter(filters, (f) => {
    const filterTypeLabel = f.filterType ? f.filterType.label : '';
    // if there is a type, but it doesn't match, false
    if (

      textType !== null
      && _.toLower(textType) !== _.toLower(filterTypeLabel)
    ) {
      return false;
    }

    if (textValue === '') {
      // if the textValue is empty, then match on everything.
      return true;
    }
    const lowerFilterValue = _.toLower(f.filterValue);
    const lowerTextValue = _.toLower(textValue);

    return (
      lowerFilterValue === lowerTextValue
      || lowerFilterValue.indexOf(lowerTextValue) >= 0
    );
  });
};

const valueMatchesFilter = (value?: string, filter?: string): boolean => {
  const lowerFilter = _.trim(_.toLower(filter));
  if (_.isString(value)) {
    const lowerValue = _.trim(_.toLower(value));

    return (
      !!lowerValue
      && (lowerValue === lowerFilter
        || (lowerValue.includes(lowerFilter) || lowerFilter.includes(lowerValue)))
    );
  }
  if (_.isArray(value)) {
    const loweredValues = value.map(valueElement => _.trim(_.toLower(valueElement)));

    return (
      !!loweredValues

      && loweredValues.some(lowerValue => (lowerValue === lowerFilter
        || (lowerValue.includes(lowerFilter) || lowerFilter.includes(lowerValue))))
    );
  }
  return false;
};

const filterMatchesItem = (
  value: any,
  filter: Filter,
  filterTypes: Array<FilterType> = projectFilterTypes,
): boolean => {
  if (filter.filterType) {
    const filterType = filter.filterType.value;


    return valueMatchesFilter(value[filterType], filter.filterValue);
  }

  // check all possible filter types to see if they match
  return (
    _.findIndex(filterTypes, ft => valueMatchesFilter(value[ft.value], filter.filterValue)) >= 0
  );
};

// Must match every filter
const itemPassesFilters = (
  item: any,
  filters: Array<Filter>,
  filterTypes: Array<FilterType>,
  filterByOr = false,
): boolean => {
  if (filters.length === 0) {
    return true;
  }

  if (!filterByOr) {
    return !(
      _.findIndex(
        filters, f => !filterMatchesItem(item, f, filterTypes),
      ) >= 0
    );
  }

  return _.find(filters, f => filterMatchesItem(item, f, filterTypes));
};

// / Filter the list of items
const filterItems = (
  items: Array<any>,
  filters: Array<Filter>,
  filterTypes: Array<FilterType>,
): Array<any> => (
  _.filter(items, item => itemPassesFilters(item, filters, filterTypes))
);

export {
  projectAssignmentFilterTypes,
  projectFilterTypes,
  liveAssignmentFilterTypes,
  filterFilters,
  getFilterOptions,
  filterMatchesItem,
  itemPassesFilters,
  filterItems,
  createFilterOption,
  missingFilter,
  draftFilter,
  acceptedFilter,
  liveFilter,
  pendingFilter,
  doneFilter,
  cancelledFilter,
  classicProjectFilter,
  smartProjectFilter,
  separateProjectAndProjectAssignmentFilters,
  projectTypeFilterTypes,
  getDriverStatusFilterOptions,
  driverStatus,
};
