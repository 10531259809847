// @ts-nocheck
import classNames from 'classnames';
import React from 'react';
import ClickableDiv from '../../ClickableDiv';
import { Close, Edit } from '../../Icon';
import { Tag } from '../../Labels';
import styles from './styles.module.scss';

type TagChipProps = {
  name: string,
  removable?: boolean,
  onClick?: () => void,
  onMouseDown?: () => void,
  onTouchEnd?: () => void,
};

const TagChip = ({
  name, removable, onClick, onMouseDown, onTouchEnd,
}: TagChipProps) => {
  const editable = !removable; // TODO: check for editing permissions

  return (

    <ClickableDiv
      // use onMouseDown so it works in the react-select component (onClick causes the menu to open)
      onClick={(e) => {
        e.stopPropagation();

        onClick();
      }}
      onMouseDown={(e: any) => {
        e.stopPropagation();

        onMouseDown();
      }}
      onTouchEnd={(e: any) => {
        e.stopPropagation();

        onTouchEnd();
      }}
    >

      <Tag
        className={styles.tagChip}
        text={(
          <div className={classNames(styles.tagChipText, { [styles.editable]: editable })}>
            {name}
            <div className={styles.editIcon}>
              <Edit />
            </div>
          </div>
        )}

        rightIcon={removable && <Close />}
      />
    </ClickableDiv>
  );
};

TagChip.defaultProps = {
  removable: false,
  onClick: () => {},
  onMouseDown: () => {},
  onTouchEnd: () => {},
};

export default TagChip;
