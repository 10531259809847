// @ts-nocheck
import classNames from 'classnames';
import * as React from 'react';

import useScrollYHandler from 'utils/Hooks/useScrollHandler';
import styles from './styles.module.scss';

type OverviewContentProps = {

  children: React.Node,
  mobile?: boolean,
  disableScroll?: boolean,
  onScrollDown?: () => void,
  onScrollUp?: () => void,
  id?: string,
};

const OverviewContent = ({
  onScrollDown,
  onScrollUp,
  children,
  mobile,
  id,
  disableScroll,
}: OverviewContentProps) => {
  const windowRef = React.useRef(null);
  useScrollYHandler(windowRef, onScrollDown, onScrollUp);
  return (

    <div
      id={id}
      ref={windowRef}

      className={classNames(styles.overviewContent, mobile && styles.mobileOverviewContent, disableScroll && styles.disableScroll)}
    >
      {children}
    </div>
  );
};

OverviewContent.defaultProps = {
  mobile: false,
  disableScroll: false,
  id: '',
  onScrollUp: () => { },
  onScrollDown: () => { },
};

// This is the container for all content that would fall to the right
// of the sidebar. Use in conjunction with OverviewHeader
export default OverviewContent;
