// @ts-nocheck

import _ from 'lodash';
import React, { useState,  Context,  ReactNode } from 'react';

import { DragDropContext } from 'react-beautiful-dnd';

// eslint-disable-next-line import/namespace
import cannotAssignHireToAssignment from '../utils';
import type { InternalHire, Subcontractor, CompanyClientHire } from 'pages/Hires/types';
import type { DisplayHire, DisplaySubcontractor } from './types';

type HiresContextTypes = {
  hires: DisplayHire[],
  subcontractors: DisplaySubcontractor[],
  companyClientHires: DisplayHire[],
  fromUnassign?: boolean,
  hireCompany?: string,

  onAssign: (assignmentId: string, hire?: any, isInternal: boolean) => void,
};


export const HiresContext: Context<HiresContextTypes> = React.createContext({
  hires: [],
  subcontractors: [],
  companyClientHires: [],
  fromUnassign: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onAssign: () => {},
  hireCompany: null,
});


export const setHiresAssigned = (
  hires: InternalHire[],
  assignmentHires: { param?:[accountId: number] },
): DisplayHire[] => (
  _.filter(hires, h => h.available).map(hire => ({
    ...hire,
    assignedCount: assignmentHires[hire.accountId] || 0,
  }))
);


export const setSubcontractorsAssigned = (
  subcontractors: Subcontractor[],
  assignmentSubcontractors: {param: [id: number]| number },
): DisplaySubcontractor[] => (
  _.filter(subcontractors, s => s.available).map(sub => ({
    ...sub,

    assignedCount: assignmentSubcontractors[parseInt(sub.id, 10)] || 0,
  }))
);


const onDragEnd = (dragResult, onAssign, onSwap) => {
  if (!dragResult.destination) {
    return;
  }

  try {
    const droppableSource = JSON.parse(dragResult.source.droppableId);
    const droppableDestination = JSON.parse(dragResult.destination.droppableId);
    const draggedHire = JSON.parse(dragResult.draggableId);
    const assignments = droppableDestination.id;

    // internal hires cannot be assigned to both smart and classic assignments at the same time
    if (cannotAssignHireToAssignment(draggedHire, assignments)) {
      return;
    }

    if (
      droppableSource.isAssignment
      && droppableDestination.isAssignment
      && droppableSource.id === droppableDestination.id
    ) {
      return;
    }

    // If dragging between two drafts, swap the hires
    if (droppableSource.isAssignment && droppableDestination.isAssignment) {
      const { isDraft: sourceIsDraft, id: sourceId } = droppableSource.id[0];
      const { isDraft: destinationIsDraft, id: destinationId } = droppableDestination.id[0];
      if (sourceIsDraft && destinationIsDraft) {
        onSwap(sourceId, destinationId);
        return;
      }
    }

    if (droppableSource.isAssignment) {
      onAssign(
        droppableSource.id,
        null,
        draggedHire.isInternal,
      );
    }

    if (droppableDestination.isAssignment) {
      onAssign(
        droppableDestination.id,
        draggedHire,
        draggedHire.isInternal,
      );
    }
  } catch (e) {
    // noop
  }
};

type HiresListContextProps = {
  hires: InternalHire[],
  subcontractors: Subcontractor[],
  companyClientHires: CompanyClientHire[],
  children: ReactNode,

  onAssign: (assignmentId: string, isDraft: boolean, hire?: any, isInternal: boolean) => void,
  onSwap: (assignmentId1: string, assignmentId2: string) => void,
  assignmentHires: {params?: [accountId: number]| number },
  assignmentSubcontractors: { [id: number]: number },
};

const HiresListContext = ({
  hires,
  subcontractors,
  companyClientHires,
  children,
  onAssign,
  onSwap,
  assignmentHires,
  assignmentSubcontractors,
}: HiresListContextProps) => {

  const hiresWithAssigned = setHiresAssigned(hires, assignmentHires);

  const subsWithAssigned = setSubcontractorsAssigned(subcontractors, assignmentSubcontractors);

  const companyClientWithAssigned = setHiresAssigned(companyClientHires, assignmentHires);

  const [fromUnassign, setFromUnassign] = useState();
  const [hireCompany, setHireCompany] = useState();


  return (

    <HiresContext.Provider
      value={{
        hires: hiresWithAssigned,
        subcontractors: subsWithAssigned,
        companyClientHires: companyClientWithAssigned,

        onAssign,
        fromUnassign,
        hireCompany,
      }}
    >

      <DragDropContext
        autoScroll={false}

        onDragEnd={(result) => {
          onDragEnd(result, onAssign, onSwap);
        }}

        onDragStart={(dragStart) => {
          try {
            const hire = JSON.parse(dragStart.draggableId);
            setHireCompany(hire.companyId || null);

            const sourceId = JSON.parse(dragStart.source.droppableId).id;
            if (sourceId === 'unassignList') {

              setFromUnassign(true);
            } else {

              setFromUnassign(false);
            }
            return;
          } catch (e) {
            // noop
          }

          setFromUnassign(false);
        }}
      >
        {children}
      </DragDropContext>
    </HiresContext.Provider>
  );
};

export const HiresContextConsumer = HiresContext.Consumer;

export default HiresListContext;
