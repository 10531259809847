import React, {SVGProps} from 'react';

const NineDots = (props: SVGProps<SVGSVGElement>) => (

  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >

    <circle cx="2.72727" cy="2.72727" r="2.72727" fill="#D8D8D8" />

    <circle cx="2.72727" cy="9.99998" r="2.72727" fill="#D8D8D8" />

    <circle cx="2.72727" cy="17.2727" r="2.72727" fill="#D8D8D8" />

    <circle cx="9.99998" cy="2.72727" r="2.72727" fill="#D8D8D8" />

    <circle cx="9.99998" cy="9.99998" r="2.72727" fill="#D8D8D8" />

    <circle cx="9.99998" cy="17.2727" r="2.72727" fill="#D8D8D8" />

    <circle cx="17.2727" cy="2.72727" r="2.72727" fill="#D8D8D8" />

    <circle cx="17.2727" cy="9.99998" r="2.72727" fill="#D8D8D8" />

    <circle cx="17.2727" cy="17.2727" r="2.72727" fill="#D8D8D8" />
  </svg>
);

export default NineDots;
