import React, {SVGProps} from 'react';

const Plug = (props: SVGProps<SVGSVGElement>) => (

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>

    <title>Plug</title>

    <path d="M12.53,13.08l-1,1L9.88,12.46l1-1a1,1,0,0,0,0-1.45,1,1,0,0,0-1.45,0l-1,1L7.11,9.69,3.87,12.93A4.64,4.64,0,0,0,3.37,19l-1.1,1.1a1,1,0,0,0,1.45,1.45L4.8,20.45A4.58,4.58,0,0,0,11,20.21l3.33-3.32L13,15.54l1-1a1,1,0,0,0-1.45-1.45Zm-1,3.81L9.6,18.82c-.19.2-1.94,1.81-4.13-.38a2.74,2.74,0,0,1-.21-4.12l1.85-1.86Z" />

    <path d="M20.28,2.47,19.2,3.55A4.58,4.58,0,0,0,13,3.79L9.69,7.11l7.2,7.2,3.24-3.24A4.64,4.64,0,0,0,20.63,5l1.1-1.1a1,1,0,1,0-1.45-1.45ZM18.74,9.68l-1.85,1.86L12.46,7.11,14.4,5.18c.19-.2,1.94-1.81,4.13.38A2.74,2.74,0,0,1,18.74,9.68Z" />
  </svg>
);

export default Plug;
