// @ts-nocheck

import React from 'react';
import Attachment from '../../Attachment';
import Thumbnail from './components/Thumbnail';

// Components
import styles from './styles.module.scss';

type ReadOnlyUIProps = {
  thumbnail: string,

  uploadCount: string | number,
  orientation?: string | number,
  includeThumbnail? : boolean,

  uploadCount?: string | number,
  includeFileList?: boolean,

  value: Array<any> | any,
  multiple?: boolean,
};

// UI for read only version of Upload
const ReadOnlyUI = ({
  thumbnail,
  includeThumbnail,
  includeFileList,
  multiple,
  orientation,
  uploadCount,
  value,
}: ReadOnlyUIProps) => (

  <div className={styles.inputFileUpload}>

    <div className={styles.fileUpload}>
      { includeThumbnail
        ? (
          <Thumbnail
            {...{
              thumbnail,
              orientation,
              uploadCount,
              multiple,
            }}
          />
        )
        : null
      }
    </div>
    { includeFileList
      ? (

        <div className={styles.currentUploaded}>
          { value.map((ul: any) => <Attachment key={ul.getURL} name={ul.name ? ul.name : 'file'} src={ul.getURL} />)}
        </div>
      )
      : null
    }
  </div>
);

ReadOnlyUI.defaultProps = {
  orientation: null,
  includeThumbnail: false,
  uploadCount: 0,
  includeFileList: false,
  multiple: false,
};

export default ReadOnlyUI;
