// @ts-nocheck
import classNames from 'classnames';
import React, { useEffect } from 'react';


import SortItem from '../components/SortItem';

import { Button } from 'common-ui/Button';
import styles from './styles.module.scss';
import type { SortUIProps } from '../types';

const SearchUI = ({
  sortOptions,
  sortOption,
  onSelectSort,
  mobile,
  className,
  vertical,
}: SortUIProps) => {
  // Select the first sort option by default
  useEffect(() => {
    if (!sortOption) {
      onSelectSort(sortOptions[0]);
    }
  }, []);
  return (

    <>
      {sortOptions.map((option, key) => {
        const isSelected = sortOption
          ? option.id === sortOption.id
          : false;

        return (!mobile
          ? (
            <Button
              key={option.id}
              className={

                classNames(
                  !vertical && styles.sortItem,
                  key === 0 && styles.isLeftMost,
                  isSelected && !vertical && styles.selectedButton,
                  vertical && key !== 0 && styles.verticalStyle,
                  className,
                )
              }
              onClick={() => (onSelectSort(option))}
              text={option.name}
              styleName={isSelected ? 'selectedDark' : 'default'}
            />
          ) : (
            <SortItem
              key={option.id}
              onClick={() => (onSelectSort(option))}
              id={option.id}
              label={option.name}
              isSelected={isSelected}
              isLeft={key === 0}
              option={option}
            />
          )
        );
      })}
    </>
  );
};

SearchUI.defaultProps = {
  mobile: false,
  className: null,
};

export default SearchUI;
