// @ts-nocheck
import React, { useEffect } from 'react';
import { compose } from 'recompose';

import { usePermissions, useWhoAmI } from 'common-ui/Hooks';

import { useFlag } from 'components/Common/Flag';
import { EmptyMap, MapWithControls } from 'components/Common/Map';

import { EditSiteMarker, SiteMarker, useLimitedSites } from 'components/Common/Map/SiteMarkers';

import { calculateMarkerBounds } from 'components/Common/Map/SiteMarkers/utils';

import { makeMarkers } from 'components/Common/Map/utils/marker.utils';
import { MapEditLayer, selectedLocationTypes, useEditableMap } from 'components/Common/Map/utils/useEditableMap';

import { DrawFenceToastID, dropPinToastID } from 'pages/Sites/components/Toasts';
import { injectIntlHOC } from 'utils/HOC';
import { useCompanyContext } from 'utils/Hooks';
import { EditForm } from './Form';
import Legend from './Legend';
import styles from './styles.module.scss';
import type { SitesMapProps } from '../types';

const SitesMap = ({
  openSidebar,
  closeSidebar,
  onRefresh,
  intl,
  sites,
  selectedSiteId,
  setSelectedSiteId,
  bounds,
  setAutoZoom,
  hideLegend,
  isEditing,
}: SitesMapProps) => {
  const focusSite = sites ? sites.find((s: any) => s.id === selectedSiteId) : null;
  const { isEditingMap, setIsEditingMap, selectedLocation, stopEditingMap, geoPoints } =
    useEditableMap();
  const isEditingGeofence = (selectedLocation && selectedLocation.lat) || geoPoints.length >= 1;

  const { selectedCompanyID } = useCompanyContext();
  const { EditAllSites: canEditAllSites, EditOwnSites: canEditOwnSites } =
    usePermissions(selectedCompanyID);
  const { id: myAccountID } = useWhoAmI();
  const canEditSiteMarker = (m: any) =>
    canEditAllSites ||
    (canEditOwnSites && m.data && m.data.createdBy && m.data.createdBy.id === myAccountID);

  const { removeFlag } = useFlag();
  const closeEditForm = () => {
    closeSidebar();
    setSelectedSiteId(null);
    removeFlag(DrawFenceToastID);
    removeFlag(dropPinToastID);
  };
  const openEditForm = (site: any) => {
    setIsEditingMap(true);
    openSidebar(<EditForm site={site} onClose={closeEditForm} />);
  };
  useEffect(() => {
    isEditing ? setIsEditingMap(true) : setIsEditingMap(false);
  }, [isEditing]);

  // Limit Sites map to show 200 sites
  const company = useCompanyContext();
  const limitedSites = useLimitedSites(sites, selectedSiteId, 200);
  const siteMarkers = makeMarkers(limitedSites);
  const calculatedBounds = calculateMarkerBounds(siteMarkers, company.selectedCompany.coordinates);

  if (limitedSites && limitedSites.length === 0 && !isEditingMap) {
    return (
      <>
        <div className={styles.emptyMap}>
          <div className={styles.topShadow} />
          <EmptyMap
            text={intl.formatMessage({
              id: 'sites.sites.empty_map_text',
              defaultMessage: 'There are no sites at this time.',
            })}
          />
        </div>
      </>
    );
  }

  // Only change focus when we populate the selectedLocation with a selected address
  // Do not refocus on regular point-n-click
  const selectedAddress = {
    lat:
      selectedLocation && selectedLocation.type === selectedLocationTypes.ADDRESS
        ? selectedLocation.lat
        : null,
    lng:
      selectedLocation && selectedLocation.type === selectedLocationTypes.ADDRESS
        ? selectedLocation.lng
        : null,
  };

  const getCursor = ({ isHovering, isDragging }: any) =>
    (isHovering && 'pointer') ||
    (isDragging && 'grabbing') ||
    (isEditingMap && 'default') ||
    'grab';

  return (
    <div className={styles.liveMap}>
      <div className={styles.topShadow} />

      <div className={styles.mapContainer}>
        <MapWithControls
          calculatedBounds={calculatedBounds}
          onClickRefresh={onRefresh}
          includeDriverIdentifier={false}
          forceFocusToPoint={selectedAddress}
          getCursor={getCursor}
          {...{
            openPopup: focusSite,
            bounds,
            setAutoZoom,
          }}
        >
          <MapEditLayer />
          <EditSiteMarker />
          {siteMarkers?.map((m: any) => (
            <SiteMarker
              key={`sitemarker-${m.id}`}
              marker={m}
              onClick={(e: any) => {
                const canEdit = canEditSiteMarker(m);
                if (canEdit) {
                  e.stopPropagation();
                  // Reset geofence and selected location
                  stopEditingMap();
                  if (m.id !== selectedSiteId) {
                    setSelectedSiteId(m.id);
                  } else {
                    setSelectedSiteId(null);
                  }
                  openEditForm(m.data);
                }
              }}
              showGeofence={m.id === selectedSiteId && !isEditingGeofence}
            />
          ))}
        </MapWithControls>
      </div>

      {!hideLegend && <Legend />}
    </div>
  );
};

SitesMap.defaultProps = {
  setSelectedSiteId: () => {},
  onRefresh: () => {},
};

const SitesMapHOC = compose(injectIntlHOC);

export default SitesMapHOC(SitesMap);
