// @ts-nocheck
import React, {SVGProps} from 'react';

const CheckmarkGreen = (props: SVGProps<SVGSVGElement>) => (

  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >

    <circle cx="9" cy="9" r="9" fill="#1E9F76" />

    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.0659 6.56594L7.00026 13.6316L3.43457 10.0659L4.56594 8.93457L7.00026 11.3689L12.9346 5.43457L14.0659 6.56594Z"
      fill="white"
    />
  </svg>
);

export default CheckmarkGreen;
