// These are only the internal roles options
export const AccountRoles = {
  ADMINISTRATOR: 'Administrator',
  DISPATCHER: 'Dispatcher',
  MANAGER: 'Manager',
  ACCOUNTANT: 'Accountant',
  FOREMAN: 'Foreman',
  DRIVER: 'Driver',
};

export const AllAccountRoles = {
  ADMINISTRATOR: 'Administrator',
  DISPATCHER: 'Dispatcher',
  MANAGER: 'Manager',
  ACCOUNTANT: 'Accountant',
  FOREMAN: 'Foreman',
  DRIVER: 'Driver',
  EXTERNALSUPERVISOR: 'External Supervisor',
  DOT: 'DOT',
};
