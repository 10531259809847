// @ts-nocheck
import classNames from 'classnames';
import React from 'react';
import {
  compose,
  withHandlers,
  withStateHandlers,
  defaultProps,
  mapProps,
  HOC,
} from 'recompose';
import styles from 'common-ui/Form/TextInput/styles.module.scss';
import {
  parseGoogleAddressComponent,
  createGoogleAutocomplete,

} from './GoogleAutocomplete';

type eventPropsType = {
  target: {
    value: string
  },
  preventDefault: () => void
};

type AddressInputProps = {
  bindInputRef: (param:any) => void,
  handleOnChange: (e: eventPropsType) => void,
  handleKeyPress: (e: eventPropsType) => void
};

const AddressInputUI = ({
  bindInputRef,
  handleOnChange,
  handleKeyPress,
  ...inputProps
}: AddressInputProps) => (

  <div className={styles.textInput}>

    <input
      ref={bindInputRef}
      type="text"

      className={classNames(styles.textInputField)}
      autoComplete="on"
      onChange={handleOnChange}

      onKeyPress={handleKeyPress}
      {...inputProps}
    />
  </div>
);

type AddressInputHOCProps = {
  onChange?: (address: string) => void,
  onKeyPress?: (e: eventPropsType) => void
};

const AddressInputHOC: HOC<any, AddressInputHOCProps> = compose(
  defaultProps({
    googleAutocompleteOptions: {
      types: ['address'],
      componentRestrictions: { country: 'ca' },
    },
  }),
  withHandlers({

    handlePlaceChange: ({ onChange }) => (place) => {
      const parsedAddress = parseGoogleAddressComponent(place);
      if (onChange) {
        if (parsedAddress.fullAddress) {
          onChange(parsedAddress.fullAddress);
        }
      }
    },
  }),
  withStateHandlers(
    {
      inputRef: null,
      autocomplete: null,
      lastKeyWasEnter: false,
      panningDropdown: false,
    },
    {

      bindInputRef: (props, { handlePlaceChange }) => (inputRef) => {
        const options = {
          types: ['address'],
          componentRestrictions: { country: 'ca' },
        };

        const autocomplete = createGoogleAutocomplete({
          inputRef,
          options,
          onPlaceChange: handlePlaceChange,
        });

        return { inputRef, autocomplete };
      },

      handleKeyPress: ({ lastKeyWasEnter }, { onKeyPress }) => (e) => {
        if (onKeyPress) {
          onKeyPress(e);
        }

        // Make them have to double tap enter to trigger parent event
        // This assumes the first enter is to choose the selection
        if (e.key === 'Enter' && !lastKeyWasEnter) {
          e.preventDefault();
          return { lastKeyWasEnter: true };
        }
        return { lastKeyWasEnter: false };
      },
    },
  ),
  withHandlers({

    handleOnChange: ({ onChange }) => (e) => {
      if (onChange) {
        onChange(e.target.value);
      }
    },
  }),
  mapProps(

    ({
      googleAutocompleteOptions,
      autocomplete,
      inputRef,
      onChange,
      handlePlaceChange,
      lastKeyWasEnter,
      panningDropdown,
      pressedDown,
      ...restProps
    }) => restProps,
  ),
);

export default AddressInputHOC(AddressInputUI);
