import React, {SVGProps} from 'react';

const Chat = (props: SVGProps<SVGSVGElement>) => (

  <svg
    viewBox="0 0 25 25"
    width="25"
    height="25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >

    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.162 23.615c1.01.755 1.838.343 1.838-.93V3.301A2.3 2.3 0 0021.7 1H3.3C2.033 1 1 2.03 1 3.302v13.796A2.3 2.3 0 003.3 19.4h13.225l5.637 4.215zM21.7 3.302C21.7 3.3 3.3 3.3 3.3 3.3c.002 0 0 13.798 0 13.798 0 .003 13.99.002 13.99.002l4.432 3.314L21.7 3.302zM14.225 10.2a1.725 1.725 0 11-3.45 0 1.725 1.725 0 013.45 0zM7.9 11.925a1.725 1.725 0 100-3.45 1.725 1.725 0 000 3.45zM18.825 10.2a1.725 1.725 0 11-3.45 0 1.725 1.725 0 013.45 0z"
      fill="#4D4D4D"
    />

    <mask
      id="a-chat"
      maskUnits="userSpaceOnUse"
      x="1"
      y="1"
      width="23"
      height="23"
    >

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.162 23.615c1.01.755 1.838.343 1.838-.93V3.301A2.3 2.3 0 0021.7 1H3.3C2.033 1 1 2.03 1 3.302v13.796A2.3 2.3 0 003.3 19.4h13.225l5.637 4.215zM21.7 3.302C21.7 3.3 3.3 3.3 3.3 3.3c.002 0 0 13.798 0 13.798 0 .003 13.99.002 13.99.002l4.432 3.314L21.7 3.302zM14.225 10.2a1.725 1.725 0 11-3.45 0 1.725 1.725 0 013.45 0zM7.9 11.925a1.725 1.725 0 100-3.45 1.725 1.725 0 000 3.45zM18.825 10.2a1.725 1.725 0 11-3.45 0 1.725 1.725 0 013.45 0z"
        fill="#fff"
      />
    </mask>

    <g mask="url(#a-chat)">

      <rect x="-3" y="-1" width="30" height="29" fill="#9B9B9B" />
    </g>
  </svg>
);

export default Chat;
