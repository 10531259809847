// @ts-nocheck
import { connect, Formik } from 'formik';
import React from 'react';


import TagInput from '../TagInput';
import { FormField } from 'common-ui/Form';

type FormikTagInputProps = {
  formik: FormikProps,
  label: string,
  required?: boolean,
  name: string,
};

const FormikTagInput = ({
  formik,
  label,
  required,
  name,
  ...inputProps
}: FormikTagInputProps) => (

  <FormField
    label={label}
    required={required}
    error={formik.touched[name] && formik.errors[name]}
  >

    <TagInput
      values={formik.values[name]}

      onChange={(value: any) => formik.setFieldValue(name, value)}
      onBlur={() => formik.setFieldTouched(name)}
      name={name}
      {...inputProps}
    />
  </FormField>
);

FormikTagInput.defaultProps = {
  required: false,
};

export default connect(FormikTagInput);
