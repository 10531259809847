// @ts-nocheck
import React, { useEffect } from 'react';

import ReactTooltip from 'react-tooltip';
import ClickableDiv from 'common-ui/ClickableDiv';
import { Close } from 'common-ui/Icon';
import { Tag } from 'common-ui/Labels';



import { useDetectMobile } from 'utils/Hooks';
import styles from './styles.module.scss';

type CollaboratorChipProps = {
  collaborator: any, // TODO proper type
  onMouseDown?: () => void,
};

export const toolTipID = 'collaboratorHoverCard';

const CollaboratorChip = ({ collaborator, onMouseDown }: CollaboratorChipProps) => {
  let toolTipMetadata = JSON.stringify(collaborator);
  useEffect(() => {
    toolTipMetadata = JSON.stringify(collaborator);
    ReactTooltip.rebuild();
  }, [collaborator]);

  const isMobile = useDetectMobile();

  if (!collaborator) return null;

  return (

    <ClickableDiv
      className={styles.hoverHandler}
      onMouseDown={(e: any) => {
        if (!isMobile) {
          e.stopPropagation();

          onMouseDown();
        }
      }}
      // Only execute one handler based on mobile/desktop to prevent multiple
      // items getting removed by one tap
      onTouchEnd={() => {

        if (isMobile) onMouseDown();
      }}
      data-tip={toolTipMetadata}
      data-for={toolTipID}
    >

      <Tag
        className={styles.tagChip}
        text={(
          <div className={styles.tagChipText}>
            {`${collaborator.firstName} ${collaborator.lastName}`}
          </div>
        )}

        rightIcon={<Close />}
      />
    </ClickableDiv>
  );
};

CollaboratorChip.defaultProps = {
  onMouseDown: () => {},
};

export default CollaboratorChip;
