import classNames from 'classnames';
import React from 'react';

import { Company, Project } from 'common-ui/types';
import { useDetectMobile } from 'utils/Hooks';
import ClientShareModal from './ClientShareModal';
import LinkShareModal from './LinkShareModal';
import { useShareLiveProject } from './hooks';
import styles from './styles.module.scss';
import { Steps } from './utils';

type ShareLiveModalProps = {
  handleClose: () => void,

  project: Project,

  companyClient: Company,
  currentStep: string,

  openPageDisplay: (param:string) => void,
  disableShare: boolean,
}

const ShareLiveModal = ({
  handleClose,
  project,
  companyClient,
  currentStep,
  openPageDisplay,
  disableShare,
}: ShareLiveModalProps) => {
  const shareLiveProject = useShareLiveProject();
  const isMobile = useDetectMobile();

  return (

    <div className={classNames(styles.shareLiveModal, {
      [styles.mobile]: isMobile,
    })}
    >

      <LinkShareModal
        handleClose={handleClose}
        project={project}
        companyClient={companyClient}
        currentStep={currentStep}
        openPageDisplay={openPageDisplay}
      />
      {companyClient && currentStep !== Steps.editPermissions && (

        <ClientShareModal
          project={project}
          handleClose={handleClose}
          shareLiveProject={shareLiveProject}
          disableShare={disableShare}
        />
      )}
    </div>
  );
};

export default ShareLiveModal;
