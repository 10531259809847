// @ts-nocheck
import React from 'react';

import { Attach } from 'common-ui/Icon';
import styles from './styles.module.scss';

export type AttachmentProps = {
  filename: string,
  src: string,
};

const Attachment = ({ filename, src }: AttachmentProps) => (

  <div className={styles.attachment}>

    <Attach />

    <a href={src || null} target="_blank" rel="noopener noreferrer">{filename}</a>
  </div>
);

export default Attachment;
