// @ts-nocheck
import classNames from 'classnames';
import React, { ReactNode, useState } from 'react';
import { Button } from 'common-ui/Button';

import { Close } from 'common-ui/Icon';
import styles from './styles.module.scss';

// SplitModalMultiPage content is a grid of 12 columns
// Need to pass gridColumn values (leftColumn and rightColumn)
// to tell the content which columns to take up
// The leftColumn is static whereas the rightColumn is multi page

type SplitModalMultiPageProps = {
  onSubmit: () => void,
  onClose: () => void,
  actionText: ReactNode,
  headerText: ReactNode,
  className?: string,
  leftChild: () => ReactNode,
  leftColumn: string,
  rightColumn: string,
  disabled?: boolean,
  modalPages: ReactNode[],
  useCustom?: boolean,
  customBack?: (index: number, setIndex: ()=>void) => void,
  customNext?: (index: number, setIndex: ()=>void) => void,
  disableBack?: boolean,
  nextText?: string,
};

const SplitModalMultiPage = ({
  onSubmit,
  onClose,
  actionText,
  headerText,
  className,
  leftChild,
  leftColumn,
  rightColumn,
  disabled,
  modalPages,
  customBack,
  customNext,
  useCustom,
  disableBack,
  nextText,
}: SplitModalMultiPageProps) => {
  const [index, setIndex] = useState(0);
  const rightChild = modalPages[index](setIndex);
  return (

    <div className={classNames(styles.splitModalMultiPage, className)}>

      <div className={styles.headerMultiPage}>

        <div className={styles.textHeader}>{headerText}</div>

        <div className={styles.close}>
          <Close onClick={onClose} />
        </div>
      </div>

      <div className={styles.childContent}>

        <div

          className={classNames(styles.childContentColumn, styles.leftColMultiPage)}
          style={{ gridColumn: leftColumn }}
        >

          {leftChild({ index })}
        </div>

        <div

          className={classNames(styles.childContentColumn, styles.rightColMultiPage)}
          style={{ gridColumn: rightColumn }}
        >
          {rightChild}
        </div>
      </div>

      <div className={styles.splitMultiPageButtons}>
        {index > 0 && !disableBack && (
          <Button
            text="Back"
            onClick={() => {
              if (useCustom) {

                return customBack(index, setIndex);
              }
              return setIndex(index - 1);
            }}
          />
        )}
        {index < (modalPages.length - 1) && (
          <Button
            disabled={disabled}
            text={nextText || 'Next'}
            className={styles.actionButton}
            onClick={() => {
              if (useCustom) {

                return customNext(index, setIndex, onSubmit);
              }
              return setIndex(index + 1);
            }}
          />
        )}
        {index === (modalPages.length - 1) && (
          <Button
            disabled={disabled}
            text={actionText}
            className={styles.actionButton}
            onClick={() => {
              onSubmit();
            }}
          />
        )}
      </div>
    </div>
  );
};

SplitModalMultiPage.defaultProps = {
  className: undefined,
  disabled: false,
  useCustom: false,
  customBack: () => {},
  customNext: () => {},
  disableBack: false,
  nextText: null,
};

export default SplitModalMultiPage;
