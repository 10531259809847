// @ts-nocheck
import React from 'react';

import { usePermissions } from 'common-ui/Hooks';
import { getColourForSiteType } from 'common-ui/Sites';

import { geofenceTypes, getMedianTimeOnSiteMinutes } from 'pages/Sites/utils/site.utils';


import { useCompanyContext } from 'utils/Hooks';
import Circle from './Circle';
import Polygon from './Polygon';



const SelectedSiteGeofence = ({
  openMarker,
  unfiltered
}: any) => {
  if (!openMarker) return null;

  const { selectedCompanyID } = useCompanyContext();
  const { ViewLiveCycleInsights: showCycles } = usePermissions(selectedCompanyID);
  const {
    id,
    activeCycles,
    completeCycles,
    siteType,
    coordinates,
    geofenceType,
    radiusInMetres
  } = openMarker.data;
  const allCycles = (completeCycles || []).concat(activeCycles || []);
  const medianTimeOnSite = getMedianTimeOnSiteMinutes(id, allCycles);
  const geoFenceColor = (showCycles && medianTimeOnSite >= 20)
    ? 'rgba(226, 22, 74, 0.8)'
    : getColourForSiteType(siteType);

  if (geofenceType === geofenceTypes.POLYGON) {
    return (

      <Polygon
        coordinates={coordinates}
        color={geoFenceColor}
      />
    );
  }
  return (

    <Circle
      lng={openMarker.lng}
      lat={openMarker.lat}
      radius={radiusInMetres}
      color={!unfiltered ? geoFenceColor : '#767676'}
    />
  );
};

SelectedSiteGeofence.defaultProps = {
  unfiltered: false,
};

export default SelectedSiteGeofence;
