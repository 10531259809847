// @ts-nocheck
import moment from 'moment-timezone';
import queryString from 'query-string';
import { useLocation, useHistory } from 'react-router-dom';

import { useTimezone } from 'common-ui/Hooks';

export const useURLDate = () => {
  const timezone = useTimezone();
  const location = useLocation();
  const history = useHistory();

  const urlDate = queryString.parse(location.search || '').date;

  const selectedDate = urlDate && moment(urlDate).isValid()
    ? moment.tz(urlDate, timezone)

    : moment().tz(timezone).startOf('day');

  const setSelectedDate = (newDate: string | moment) => {
    const formattedURL = queryString.parse(location.search || '');
    history.push({
      search: queryString.stringify({
        ...formattedURL,

        date: moment(newDate).format('YYYY-MM-DD'),
      }),
    });
  };

  const selectedDateIsPast = selectedDate.isBefore(moment().tz(timezone).startOf('day'));
  return [selectedDate, setSelectedDate, selectedDateIsPast];
};

// The Calendar component can accept as value either
// null, which means no pre-selected date, or
// an array of two Moment objects
export const useMultiURLDate = (

  defaultStart?: moment | null,

  defaultEnd?: moment | null,

  paramOverrides?:Record<string, unknown> | null = {},
  maxDaysRange?: null | number,
) => {
  const timezone = useTimezone();
  const location = useLocation();
  const history = useHistory();

  const startURLDate = queryString.parse(location.search || '').start;
  const endURLDate = queryString.parse(location.search || '').end;

  const selectedStartDate = startURLDate && moment(startURLDate).isValid()
    ? moment.tz(startURLDate, timezone)

    : (defaultStart || moment().tz(timezone).subtract(7, 'days').startOf('day'));

  const selectedEndDate = endURLDate && moment(endURLDate).isValid()
    ? moment.tz(endURLDate, timezone)

    : (defaultEnd || moment().tz(timezone).startOf('day'));
  const selectedDates = [selectedStartDate, selectedEndDate];


  const setSelectedDates = (newDates: [string | moment | null, string | moment | null]) => {
    if (newDates[0] === null || newDates[1] === null) {
      const dateNullURL = queryString.parse(location.search || '');
      delete dateNullURL.end;
      delete dateNullURL.start;

      history.push({
        search: queryString.stringify({
          ...dateNullURL,
          ...paramOverrides,
        }),
      });
    } else {

      const startDate = moment(newDates[0]);

      let endDate = moment(newDates[1]);
      if (maxDaysRange) {

        const maxDate = moment(newDates[0]).add(7, 'd');
        if (endDate.isAfter(maxDate)) {
          endDate = maxDate;
        }
      }
      history.push({
        search: queryString.stringify({
          ...queryString.parse(location.search || ''),
          start: startDate.format('YYYY-MM-DD'),
          end: endDate.format('YYYY-MM-DD'),
          ...paramOverrides,
        }),
      });
    }
  };
  return [selectedDates, setSelectedDates];
};
